.tag {
    border-radius: 10px;
    font-family: Open Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px;
    text-transform: uppercase;
    pointer-events: none;

    &-xsmall {
        padding: 1px 6px;
    }
}
