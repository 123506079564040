@import '../../../../../../../style/abstracts/variables.scss';

.select-pill-wrapper {
    display: flex;
}

.arrow-right-icon {
    margin: 0 15px;
}

.div-pill {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    height: 36px;
    background: $light-blue-5;
    border-radius: 18px;
    color: $blue-500;
}

.versus {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    color: $gray-400;
    margin: 0 15px;
}

.product-filter-menu {
    &__footer {
        display: flex;
        justify-content: space-between;
        padding: 10px 2px;
        border-top: 1px solid #e5e5e5;

        button {
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 700;
            padding: 6px 10px;
            margin-right: 20px;
        }
    }

    &__footer-sigle-item {
        display: flex;
        justify-content: flex-end;
        padding: 10px 2px;
        border-top: 1px solid #e5e5e5;

        button {
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 700;
            padding: 6px 10px;
            margin-right: 20px;
        }
    }
}

#is-empty {
    border: none !important;
    border-radius: 50px !important;
    background: #FEF7F7 !important;
    height: 36px;
    padding: 8px 30px 8px 12px !important;

    :global(.rs-picker-toggle-placeholder) {
        color: #D7373F !important;
    }

    &:hover {
        background: #FEF7F7 !important;
    }
}
