.modal-discrimination-table {
    :global(.rs-modal-body) {
        padding-bottom: 10px !important;
    }
}

.product-name-wrapper {
    border: 1px solid #eaeaea;
    border-radius: 6px;
    padding: 12px;
}

.label {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
}

.product {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
}

.content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}



