.wrapper {
    display: flex;
    align-items: center;
    gap: 4px;
}

.count {
    background: #3498ff;
    color: #fff;
    border-radius: 10px;
    padding: 0 10px;
    line-height: 20px;
}
