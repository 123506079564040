.subtitle-wrapper {
    display: flex;
    align-items: center;
}

.subtitle {
    font-size: 12px;
    font-weight: 400;
    color: #959595;
    margin-right: 8px;
}

.flex {
    display: flex;
    justify-content: space-between;
}

.btn-edit-table {
    background: var(--Light-Blue-5, #F5F9FE);
    color: var(--Blue-700, #0D66D0);
    font-size: 12px;
    font-weight: 400;
    height: 30px;
    margin-top: 6px;

    &:hover, &:focus {
        background: var(--Light-Blue-5, #F5F9FE);
        color: var(--Blue-700, #0D66D0);
    }
}

.btn-apply {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
}
