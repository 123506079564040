.map-chart {
    border-radius: 5px;
    margin: 0 10px 20px 20px !important;
    padding: 22px 16px;

    @media screen and (max-width:769px) {
        margin: 0 !important;
    }

    .header-area {
        margin-bottom: 15px;

        .rs-flex-box-grid-item {

            &.minimized {
                margin-bottom: 0 !important;
            }
        }

        h4 {
            display: inline;
        }
    }

    .more-options-section,
    .expand-section {
        text-align: end;

        button {
            &:focus {
                background-color: unset;
            }
        }

        &__btn {
            padding: 0 20px !important;
        }
    }

    .select-visualization-section {
        font-weight: 600;
        font-size: 12px;

        &__button {
            margin: 0 8px;
            font-size: 12px;
        }

        &__dropdown {
            span {
                color: #1E88E5;
                margin-left: 10px;
            }
        }
    }

    .select-product-section {
        &__title {
            font-weight: 600;
            font-size: 12px;
        }
    }

    .select-product-input {
        max-width: 300px;
    }

    .no-access {
        display: table;
        background-color: rgba(30, 136, 229, 0.32);

        &__main {
            height: 400px;
            display: table-cell;
            vertical-align: middle;
            text-align: center;
        }

        &__icon {
            font-size: 110px;
            color: #1e88e5;
        }

        &__message {
            margin: 20px;
            font-size: 20px;
            max-width: 312px;
            text-align: center;
            display: inline-block;
        }
    }

    .highcharts-container {
        width: 100%;

        .highcharts-root {
            width: 100%;
        }
    }

    .highcharts-label {
        p {
            font-size: 10px;

            img {
                margin-right: 5px;
            }
        }
    }

    .empty-state {
        text-align: center;

        h4 {
            font-size: 20px;
            color: rgba(117, 117, 117, 1);
            margin-bottom: 10px;
        }

        p {
            margin-bottom: 34px;
            color: rgba(117, 117, 117, 1);
        }
    }

    .warning-map {
        width: 100%;

        .warning-visualization {
            background: #FFFBF4;
            border: 1px solid #F29423;
            padding: 16px 20px;
            border-radius: 6px;
            text-align: center;

            p {
                font-size: 10px;
                color: #F29423;
            }
        }
    }


    .markers-map {
        margin-top: 15px;

        .cluster {
            img {
                min-width: 130px;
                min-height: 38px;
            }

            div {
                width: 122px !important;
                top: 11px;
                font-weight: 500;
            }

            &__stores {
                color: white;
                font-size: 9px;
                position: absolute;
                left: 32px;
                top: 2px;
            }

            &__price-average {
                position: absolute;
                left: 81px;
            }
        }


        .marker {
            width: 100px;
            height: 45px;

            img {
                height: 100%;
                width: 100%;
            }

            div {
                position: absolute;
                top: 12px;
                left: 9px;
                text-align: center;
                color: #1e88e5;
                font-weight: 600;
                width: 100px;
            }
        }

        .gm-style {
            .gm-style-iw-t {
                right: 0 !important;
                bottom: 0 !important;
                left: 50px !important;

                &::after {
                    display: none;
                }
            }

            .gm-style-iw {
                font-size: 12px;
                font-weight: normal;
                color: #000000;
            }

            .gm-style-iw-c {
                border-radius: 2px;
                background: #FFFFFF;
                border: 1px solid #1E88E5;
            }
        }

    }
}