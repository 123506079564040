.ipa-lite-onboarding {
    background: #f5f9fe;
    row-gap: 56px;

    &__logo__container {
        display: flex;
        align-items: center;
        gap: 8px;
    }
}
