@import '../../../../../../style/abstracts/variables.scss';

.time-evolution-chart {
    margin: 0 !important;
    border-radius: 6px;
    height: 100%;
    position: relative;
}



.header {
    margin-bottom: 16px;
    .more-options-section {
        text-align: end;
        position: relative;
        bottom: 36px;
        button {
            &:focus {
                background-color: unset;
            }
        }

        &__btn {
            padding: 0 20px !important;
        }
    }
}

.title {
    font-weight: 600;
    font-size: 14px;
    color: $gray-900;
}

.subtitle {
    font-weight: 400;
    font-size: 12px;
    color: $gray-800;
}

.group-by {
    font-size: 10px;
    font-weight: 600;
    margin-right: 8px;
}

.group-by-buttons {
    font-size: 10px;
    padding: 6px 8px;
    margin-right: 8px;

    &:global(.rs-btn-subtle) {
        background: $light-blue-5 !important;
        color: $blue-700 !important;
    }
}

.period-button {
    font-size: 10px;
    font-weight: 600;
    padding: 6px 8px;
}
