.onboarding-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
        text-align: center;
    }
    b {
        font-weight: 600;
    }

    &__container {
        border-radius: 16px;
        overflow: hidden;
        background-color: var(--white);
        width: 640px;
        padding: 56px 110px;
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    &__title {
        text-align: center;
        margin-block-end: 32px;
    }

    &__button__group {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__logout-btn {
        background: none;
        margin-block-start: 32px;
        color: var(--Gray-700, #747474);
        text-align: center;
        font-family: Open Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px; /* 150% */
        text-decoration-line: underline;
    }
}
