.empty-state-content {
    margin-top: 0 !important;
    border-radius: 6px;
}

.state-container {
    background: #FFF;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 16px;
}

.text-wrapper {
    width: 243px;
}

.text {
    font-size: 14px;
    font-weight: 400;
    color: #323232;
    text-align: center;
}

.title {
    font-size: 16px;
    font-weight: 600;
    color: #323232;
    text-align: center;
}
