@mixin secondary-button-themes {
    $gray-text: #505050;
    $gray-bg: #f4f4f4;
    $gray-text-hover: #505050;
    $gray-bg-hover: #eaeaea;
    $gray-text-active: #505050;
    $gray-bg-active: #d3d3d3;

    $blue-text: #0d66d0;
    $blue-bg: #f5f9fe;
    $blue-text-hover: #0d66d0;
    $blue-bg-hover: #d7e8fc;
    $blue-text-active: #ffffff;
    $blue-bg-active: #2680eb;

    $red-text: #d7373f;
    $red-bg: #fef7f7;
    $red-text-hover: #d7373f;
    $red-bg-hover: #fbdee0;
    $red-text-active: #ffffff;
    $red-bg-active: #ec5b62;

    $themes: (
        'gray' $gray-text $gray-bg $gray-text-hover $gray-bg-hover
            $gray-text-active $gray-bg-active,
        'blue' $blue-text $blue-bg $blue-text-hover $blue-bg-hover
            $blue-text-active $blue-bg-active,
        'red' $red-text $red-bg $red-text-hover $red-bg-hover $red-text-active
            $red-bg-active
    );

    border: 1px solid transparent;

    @each $name, $text-clr, $bg-clr, $text-clr-hover, $bg-clr-hover,
        $text-clr-active, $bg-clr-active in $themes
    {
        &[data-theme='#{$name}'] {
            color: $text-clr;
            background: $bg-clr;
            border-color: $bg-clr;

            &:hover {
                color: $text-clr-hover;
                background: $bg-clr-hover;
                border-color: $bg-clr-hover;
            }

            &:active {
                color: $text-clr-active;
                background: $bg-clr-active;
                border-color: $bg-clr-active;
            }
        }
        &:disabled {
            opacity: 0.4;
            pointer-events: none;
            cursor: not-allowed;
        }
    }
}

@mixin button-sizes {
    $spacing-lg: 10px 16px 10px 16px;
    $height-lg: 42px;
    $font-size-lg: 16px;

    $spacing-md: 8px 12px 8px 12px;
    $height-md: 36px;
    $font-size-md: 14px;

    $spacing-sm: 6px 10px 6px 10px;
    $height-sm: 30px;
    $font-size-sm: 12px;

    $spacing-xs: 4px 8px 4px 8px;
    $height-xs: 24px;
    $font-size-xs: 10px;

    $spacings: (
        'lg' $spacing-lg $height-lg $font-size-lg,
        'md' $spacing-md $height-md $font-size-md,
        'sm' $spacing-sm $height-sm $font-size-sm,
        'xs' $spacing-xs $height-xs $font-size-xs
    );

    @each $name, $spacing, $height, $font-size in $spacings {
        &[data-size='#{$name}'] {
            font-size: $font-size;
            padding: $spacing;
            height: $height;
        }
    }
}
