@import '../../../../../style/typography.scss';

.title {
    --text-color: var(--gray-900, #323232);
    @extend .font-size-200-semibold;
    font-size: 16px;
}

.subtitle {
    --text-color: var(--gray-900, #323232);
    @extend .font-size-75-regular;
    font-size: 12px;
}

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
    flex: 1 0 0;
    margin-bottom: 4rem;
}

.section {
    background-color: white;
    border-radius: 6px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    gap: 24px;
    &__content {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
}

.option {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__title {
        font-size: 12px;
        text-transform: uppercase;
        font-weight: 700;
    }
}

.option + .option::before {
    content: '';
    display: block;
    width: 100%;
    height: 1px;
    background: var(--gray-300, #eaeaea);
}

.footer {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    gap: 12px;
}
