.on-demand-page {
    padding: 24px;
    margin-bottom: 50px;

    .rs-header {
        padding: 24px !important;
        background-color: #FFFFFF;
        border-radius: 6px;

        h1 {
            font-size: 20px;
            line-height: 26px;
        }

        .btn-show {
            font-weight: bold;
            text-transform: uppercase;
        }

        .btn-send {
            i {
                margin-right: 24px;
                color: #378EF0;
            }
        }
    }

    .request-summary {
        max-height: 200px;
        border-radius: 6px;
        margin: 24px 0 !important;
        padding: 24px !important;

        &__title-wrapper {
            display: flex;
            margin-bottom: 12px;

            &__title {
                font-size: 16px;
                line-height: 26px;
                margin-right: 10px;
                font-weight: 600;
            }

            i {
                align-self: center;
                color: #378EF0;
            }
        }


        .tag-summary-content {
            align-items: center;

            .rs-divider {
                margin: 0 30px;
                height: 63px;
                width: 1.5px;
            }

            &__wrapper {
                margin: 0;

                .rs-tag {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 24px;
                    min-width: 140px;
                    height: 49px;
                    border-radius: 6px;
                    line-height: 0;

                    @media (min-width:1024px) {
                        width: 140px;
                        margin: auto;
                    }
                }

                p {
                    margin-top: 12px;
                    text-align: center;
                    font-size: 14px;
                    line-height: 18px;
                }

                &--active {
                    color: #12805C;
                    background: #F5FBF9;
                }

                &--available {
                    color: #CB6F10;
                    background: #FEF9F4;
                }

                &--onplan,
                &--additional {
                    color: #323232;
                    background: #F5F5F5;
                }

            }

            @media (min-width:1024px) {
                max-width: 1286px;
                margin: auto;
            }


        }
    }

    .MuiInput-underline {
        &:before {
            border-bottom: 1px solid #ccc;
        }

        &:after {
            border-bottom: 1px solid #1675e0;
        }

        &:hover {
            &:not(.Mui-disabled) {

                &:before {
                    border-bottom: 1px solid #1675e0;
                }
            }
        }
    }

    .rs-btn-primary {
        background: #1675e0 !important;
        color: #fff !important;
    }

    .data-table-filters-container {
        padding: 16px;
        border: 1px solid #EAEAEA;
        border-radius: 6px;
        margin-bottom: 12px;
        display: flex;

        div:not(:last-child) {
            margin-right: 8px;
        }
    }

    .block-plan {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        height: 100%;
        width: -webkit-fill-available;
        background: rgba(55, 142, 240, 0.2);
        top: 0;
        z-index: 12;

        .block-plan-icon-circle {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 96px;
            height: 96px;
            background: #EBF4FE;
            border-radius: 60px;
            margin-bottom: 24px;

            img {
                height: 63px;
            }
        }

        p {
            font-weight: 600;
            font-size: 20px;
            color: #323232;
            margin: 0;
        }

        button {
            margin-top: 24px;
            font-weight: 700;
            font-size: 16px;
            text-transform: uppercase;
        }
    }

}

.on-demand-modal-upload {
    .rs-modal-content {
        padding: 0 !important;
        border-radius: 4px;

        .rs-modal-header {
            padding: 24px;

            h4 {
                font-weight: 600;
                font-size: 20px;
                line-height: 26px;
            }

            p {
                font-size: 12px;
            }
        }

        .rs-modal-body {
            margin-top: 0;

            .content {
                padding: 24px;

                .rs-uploader {

                    .rs-uploader-trigger-btn {
                        width: 100%;
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 22px;
                        text-transform: uppercase;
                        padding: 13px;
                        background: #378EF0;
                        color: #FFFFFF;
                    }
                }

                .file-name-area {
                    background: #F5F5F5;
                    border-radius: 4px;
                    padding: 15px;
                    margin-bottom: 18px;

                    .icon {
                        display: inline-block;
                    }

                    .file-name {
                        display: inline-block;

                        p {
                            font-size: 14px;
                            line-height: 18px;
                            color: #747474;
                            margin-left: 15px;
                        }
                    }

                    .file-size {
                        float: right;
                        display: inline-block;

                        p {
                            font-size: 14px;
                            line-height: 18px;
                            color: #747474;
                            margin-left: 15px;
                        }
                    }

                }

                .upload-result {
                    text-align: center;
                    font-size: 12px;
                    line-height: 16px;

                    svg,
                    img {
                        font-size: 70px;
                        margin-bottom: 12px;
                    }

                    .message {
                        font-weight: 600;
                        margin-bottom: 2px;
                    }

                    .sub-message {
                        font-weight: 400;
                        margin-top: 0;
                    }

                    &.success {

                        svg {
                            color: #33AB84;
                        }
                    }

                    &.fail {
                        svg {
                            color: #EC5B62;
                        }
                    }

                    &.to-many-itens {
                        svg {
                            color: #F29423;
                        }
                    }

                    &.process {
                        .sub-message {
                            display: inline;
                        }

                        .rs-progress {
                            height: 70px;
                            width: 70px;
                            margin: 0 auto;
                            margin-bottom: 12px;
                        }

                        .btn-cancel {
                            background: transparent;
                            color: #2680EB;
                            display: inline;
                        }
                    }
                }

            }

            .modelo-area {
                background-color: #F5F9FE;
                padding: 24px;
                text-align: center;
                margin-top: 36px;

                p {
                    color: #505050;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    margin-bottom: 8px;
                }

                button {
                    background-color: transparent;
                    color: #2680EB;
                    font-size: 14px;
                    line-height: 18px;

                    i {
                        margin-left: 6px;
                    }
                }
            }

            .warning-duplicate-item {
                background: #FEF9F4;
                padding: 16px;
                text-align: center;

                &__icon-area {
                    display: inline-flex;
                    margin-right: 12px;
                    color: #F29423;
                    vertical-align: top;
                    font-size: 19px;
                }

                &__description-area {
                    display: inline-block;

                    button {
                        background-color: transparent;
                        color: #2680EB;
                        font-size: 12px;
                        line-height: 18px;

                        i {
                            margin-left: 6px;
                        }
                    }
                }

                p {
                    font-weight: 400;
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }

        .rs-modal-footer {
            padding: 24px;
            border-top: 1px solid #EAEAEA;

            button {
                text-transform: uppercase;
                font-weight: bold;
                padding: 8px 27px;
            }

            .rs-uploader {
                .rs-uploader-trigger-btn {

                    text-transform: uppercase;
                    font-weight: bold;
                    padding: 8px 27px;
                    color: #378EF0;
                    border: 1px solid #378EF0;
                    background-color: #FFFFFF;
                }
            }
        }
    }
}

.modal-confirm-bulk-selection {
    &__body {

        display: flex;
        justify-content: center;
        align-items: center;

        span {
            margin: 0 4px;
        }
    }
}

.row-option-menu-first {
    border-top: 1px solid #EAEAEA;
    border-bottom: 1px solid #EAEAEA;
}

.request-edit-success-notification {
    .rs-notification-item-content {

        background-color: #F5FBF9;

        .rs-notification-description {
            display: flex;

            img {
                margin-right: 12px;
            }

            p {
                color: #505050;
                font-size: 14px;
            }
        }
    }
}