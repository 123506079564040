.send-request-button-area-container {
    background: #ffffff;
    padding: 24px;
    border-radius: 6px;
    margin-top: 20px;
    margin-bottom: 80px;
    display: flex;
    justify-content: flex-end;
}

.request-button {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
}

.more-request-button {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    margin-right: 10px;
}
