@import '../../../../../../style/typography.scss';

.container {
    --row-height: 62px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 8px 6px;
    height: var(--row-height);

    & > * {
        display: flex;
        align-items: center;
        gap: 4px;
    }
}

.description {
    @extend .font-size-75-semibold;
    --text-color: var(--gray-700);
    text-transform: uppercase;
    cursor: pointer;
}

.product_id {
    @extend .font-size-100-semibold;
    --text-color: var(--gray-900);
}
