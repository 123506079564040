.content {
    width: 50%;
}

.form-wrapper {
    display: flex;
    gap: 8px;
}

.form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-row-gap: 0px;
}

.label {
    font-size: 14px;
    font-weight: 600;
}

.percent {
    font-size: 14px;
    font-weight: 400;
    margin-left: 4px;
}
