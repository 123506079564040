.results-section {
    margin: 0 20px 90px !important;
    padding: 12px !important;
    border-radius: 6px;
}

.charts-flex {
    display: flex;
    justify-content: space-between;
}
