.rs-alert-error {
    background-color: #FFF4F4 !important;
    border: 1px solid #D72E2E;
    border-radius: 6px;
    min-width: 300px;

    .rs-alert-item-content {
        color: #D72E2E !important;
        background-color: #FFF4F4 !important;
        font-size: 12px;
        padding: 16px 28px;
        text-align: center;

        i {
            display: none;
        }
    }

    .rs-alert-item-close {
        display: none;
    }
}

.rs-alert-success {
    background-color: #F2FFEE !important;
    border: 1px solid #2D9D78;
    border-radius: 6px;
    min-width: 300px;

    .rs-alert-item-content {
        background-color: #F2FFEE !important;
        color: #2D9D78 !important;
        font-size: 12px;
        padding: 16px 28px;
        text-align: center;

        i {
            display: none;
        }
    }

    .rs-alert-item-close {
        display: none;
    }
}
