@import '../../../../../style/typography.scss';

.margem-objetiva {
    &__header {
        display: flex;
        align-items: center;
        width: 100%;
        background-color: var(--white);
        padding: 12px;
        border-radius: 6px;
        @extend .font-size-75-regular;

        h1 {
            grid-area: title;
            white-space: nowrap;
            width: min-content;
        }

        .callout {
            --text-color: var(--gray-800);
            font: inherit;
            grid-area: callout;
            padding: 1px 4px;
            gap: 4px;

            & i {
                font-size: 14px;
            }
        }

        p {
            --text-color: var(--gray-900);
            grid-area: subtitle;
        }

        h4 {
            font: inherit;
            grid-area: integracao;
            padding-block: 2px;
        }

        &__left-container {
            display: grid;
            grid-template-areas:
                'title callout'
                'subtitle subtitle'
                'integracao integracao';
            column-gap: 8px;
        }

        &__right-container {
            grid-area: right-container;
            display: flex;
            align-items: center;
            gap: 8px;
            margin-inline-start: auto;
        }
    }

    &__filters {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        padding: 12px;
        border-radius: 6px;
        border: 1px solid var(--gray-300, #eaeaea);

        &-clear-btn {
            margin-inline-start: auto;
        }
    }
}
