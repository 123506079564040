.filter_section {
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 6px;
    border: 1px solid var(--Gray-300, #eaeaea);
    padding: 12px;
    > div {
        border-radius: 18px;
        &:last-child {
            margin-inline-start: auto;
        }
    }
}

.table_heading {
    display: flex;
    align-items: center;
    min-height: 52px;
}
