.wrapper {
    padding: 4px;
    height: 60px;
    width: 26px;
    margin: 0 auto;
    & [data-button] {
        display: grid;
        place-content: center;
        width: 100%;
        height: 100%;
        padding: 0px 4px;
        font-size: 10px;
        font-weight: 400;
        line-height: 16px; /* 160% */
    }
}
