.wrapper {
    --placeholder-color: #505050;
    --background-color: #f5f5f5;
    --border-radius: 9999px;
    --primary-color: #0d66d0;

    :global(.rs-picker-toggle) {
        height: 36px;
        background: var(--background-color) !important;
        border-radius: var(--border-radius) !important;
        transition: var(--base-transition);
        &:hover {
            --background-color: #eeeeee;
        }
    }

    :global(.rs-picker-toggle-placeholder) {
        color: var(--placeholder-color) !important;
    }

    &:global(.rs-picker-has-value) {
        :global(.rs-picker-toggle-clean),
        :global(.rs-picker-toggle-caret) {
            color: var(--primary-color) !important;
        }

        :global(.rs-picker-toggle-clean) {
            background: none !important;
            transform: translateX(160%);
        }

        :global(.rs-picker-toggle-caret) {
            transform: translateX(-160%);
        }

        :global(.rs-picker-toggle) {
            background: rgba(55, 142, 240, 0.05) !important;
        }
    }
}
