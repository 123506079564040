.totalizer-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 24px;
    width: fit-content;
    min-width: 220px;
    background: #F5F9FE;
    border-radius: 6px;

    .totalizer-text {
        font-size: 10px;
        font-weight: 700;
        line-height: 12px;
        text-transform: uppercase;
        color: #747474;
    }

    .totalizer-value {
        font-size: 16px;
        font-weight: 600;
        line-height: 18px;
        color: #0D66D0;
        margin: 0 0 0 12px;
        display: flex;
        align-items: center;

        .rs-loader-wrapper {
            margin-left: 10px;
        }
    }

    &.no-data {
        background-color: #FDEFDE;

        .totalizer-value {
            color: #F29423
        }
    }
}