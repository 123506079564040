.resumo-content {
    margin: 20px 0 0 0 !important;
    border-radius: 6px;
}

.title {
    font-size: 16px;
    font-weight: 600;
    color: #323232;
    margin-bottom: 16px;
}

.flex {
    display: flex;
}

.link-text {
    font-size: 10px;
    padding: 0 0 3px 4px;
    text-decoration: none !important;
    margin-left: 6px;
}
