@import '../../../../../../style/typography.scss';

.page {
    --z-index: 999999999;
    all: unset;
    background-color: #f4f4f4;
    margin-block-start: var(--header-height);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 0.5rem;
    flex: 1 0 0;
    align-self: stretch;
    padding: 4px 16px 80px 16px;

    &__title {
        color: var(--gray-900, #323232);
        font-family: Open Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    &__subtitle {
        --text-color: var(--gray-600);
        @extend .font-size-75-regular;
    }

    &__section {
        border-radius: 6px;
        background: var(--white, #fff);
        padding: 12px;
        width: 100%;
    }

    &-full-screen {
        position: absolute;
        inset: 0;
        z-index: var(--z-index);
        margin-block-start: 0px;
        padding: 0px;
        overflow-y: scroll;
    }
}
