.modal-family-precificacao {


    .cabecalho{
        border: 1px solid #ccc;
        border-radius: 5px;
        padding: 20px 0px 20px 20px;

        .description{

            margin-bottom: 0px;

            &__modal-label {

                color: #adadad;
                font-weight: 400;
                font-size: 13px;

            }

            &__modal-label-niveis {
                color: #adadad;
                font-weight: 400;
                font-size: 13px;
                position: relative;
                top: 20px
            }

        }

        .list{

            &__modal-label {
                font-weight: 500;
                font-size: 13px;
            }

            &__modal-label-niveis {
                font-weight: 500;
                font-size: 13px;
                padding: 20px 0px 0px 0px;
            }
            strong {
                font-weight: 600;
                margin-right: 3px;
            }

        }




    }

    .tabela{
        margin-top: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;

        .grid{
            &__tabel-modal{
                font-weight: 700;
                margin-right: 3px;

            }
        }
        strong {
            font-weight: 700;
            margin-right: 3px;
        }
    }

    .botao-fechar-modal{

        font-weight: 700;
    }
}

