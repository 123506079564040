.wrapper {
    padding: 4px 12px 14px 12px;
}

.search-container {
    position: relative;
}

.icon {
    position: absolute;
    top: 50%;
    left: 6px;
    transform: translateY(-50%);
    fill: var(--blue-400);
}

.button {
    position: absolute;
    top: 50%;
    right: 20px;
    transform: translateY(-50%);
}
