.box {
    border-radius: 4px;
    padding: 0 2px;
    display: flex;
    align-items: center;
    font-size: 10px;
    line-height: 11px;

    border: 1px solid #bcbcbc;
    background-color: #f5f5f5;
    color: #959595;
}

.box-negative {
    background: #fef7f7;
    color: #ec5b62;
    border-color: #ec5b62;
}

.box-positive {
    background: #f5fbf9;
    color: #33ab84;
    border-color: #33ab84;
}
