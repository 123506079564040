.done-icon {
    width: 16px !important;
    height: 16px !important;
    color: #33AB84;
}

.gtin-alert-icon {
    width: 18px !important;
    height: 18px !important;
    color: #EC5B62;
}
