@import '../../../../../../style/abstracts/variables.scss';

.detailing-panel-title {
    font-weight: 600;
    font-size: 14px;
    color: $gray-900;
    line-height: 20px;
}

.detailing-panel-subtitle {
    font-weight: 400;
    font-size: 12px;
    color: $gray-600;
    margin: 0;
    line-height: 18px;
}

.expand-button {
    font-weight: 400;
    font-size: 12px;
    padding: 2px 0;
    background: transparent;
    color: $blue-400;

    &:hover, &:focus {
        background: transparent;
        color: $blue-400;
    }
}

.icon-chevron-down {
    margin-right: 7px;
}

.cheap-expensive-text {
    font-weight: 400;
    font-size: 10px;
    color: $gray-600;
}

.cheap-expensive-number {
    font-weight: 600;
    font-size: 12px;
    color: #747474;
    margin-right: 2px;
}

.icon-arrow {
    margin-right: 4px;
    vertical-align: baseline;
}

.big-number-percentage {
    font-weight: 600;
    font-size: 16px;
    color: $gray-900;
    margin-right: 6px;
}

.small-number-percentage {
    font-weight: 600;
    font-size: 12px;
    color: $gray-700;
    margin-right: 4px;
}

.reference-light-text {
    font-weight: 400;
    font-size: 10px;
    color: $gray-600;
    margin: 0 0 4px 0;
}

.reference-dark-text {
    font-weight: 600;
    font-size: 10px;
    color: $gray-700;
    margin: 0;
}

.panel-header-content {
    cursor: default;
}

.input-search {
    border: 1px solid #e5e5ea !important;
    border-radius: 6px !important;
}

.cheap-expensive-indicator {
    margin: 0 10px;
}
