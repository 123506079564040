.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    :global(.rs-input-group) {
        width: 96px;
    }

    :global(.rs-input-group-addon) {
        padding: 8px;
    }
}

.label {
    font-size: 12px;
    font-weight: 400;
}

.label-wrapper {
    display: flex;
    flex-direction: column;
}

.currency-input {
    border: none !important;
}
