@import '../../../../../../../style/abstracts/variables.scss';

.filters-area-content {
    background: var(--white, #fff);
    border-radius: 6px;
    padding: 12px;
}

.div-title {
    width: 170px;
    font-size: 12px;
    font-weight: 600;
    color: $gray-900;
    text-transform: uppercase;
}

.inner-div {
    display: flex;
    border: 1px solid $gray-300;
    border-radius: 6px;
    padding: 16px;
}

.title-container {
    width: 170px;
}

.title {
    display: flex;
    align-items: center;
    height: 48px;

    p {
        font-size: 12px;
        font-weight: 600;
        color: $gray-900;
    }
}

.btn-clear-filters {
    background: #f5f5f5;
    color: $gray-800;
    border-radius: 18px;
    height: fit-content;
    margin-left: auto;
}

.filter-selection-wrapper {
    display: flex;
    align-items: center;
    height: 48px;
}

.render-extra-footer {
    border-top: 2px solid var(--gray-400, #d3d3d3);
    padding: 6px 4px;
    left: 0;
}
