.modal-edit-region-channel {
    min-width: 650px;

    .rs-modal-body {
        max-height: 100% !important;
    }

    .rs-row {
        margin: 0;
    }

    .rs-col {
        padding: 0;
    }

    .rs-modal-content {
        max-height: 682px;
        overflow: auto;
    }

    .header {
        &__title {
            font-size: 20px;
        }

        &__description {
            font-size: 14px;
        }
    }

    .channel-selection,
    .region-selection {
        &__radio {
            margin-left: 0;
            margin-bottom: 16px;
        }

        &__label {
            font-weight: 700;
            font-size: 12px;
            text-transform: uppercase;
            margin-bottom: 16px;
        }
    }

    .channel-selection {
        margin-bottom: 16px;

        &__btn-option {
            &.rs-btn-ghost {
                background: white;
                border: none;
                box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
                color: #2680eb;
            }

            &.rs-btn-primary {
                box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
                font-weight: bold;
            }

            height: 160px;
            margin-right: 24px;
            width: 160px;

            i {
                font-size: 80px;
            }

            p {
                font-size: 14px;
                margin-top: 16px;
            }
        }
    }

    .region-selection {
        margin-bottom: 16px;

        &__btn-option {
            &.rs-btn-ghost {
                background: white;
                border: none;
                box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
                color: #3498ff;
            }

            &.rs-btn-primary {
                box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
            }

            height: 160px;
            margin-right: 24px;
            width: 160px;

            i {
                font-size: 80px;
            }

            p {
                font-size: 14px;
                margin-top: 16px;
            }
        }
    }

    .subregion-area {
        margin: 0 0 16px 0;

        &__label {
            position: absolute;
            top: 7px;
        }

        &__input {
            input {
                padding-left: 125px;
            }

            &.invalid {
                border-bottom: 1px solid #ec5b62;
            }
        }
    }

    .help-block-warning {
        color: #ec5b62;
        font-size: 12px;
    }

    .description-area {
        background: #f7f9fe;
        border: 1px solid #378ef0;
        border-radius: 6px;
        margin: 0;
        padding: 16px 14px;
        text-align: center;
        font-size: 12px;

        &__message {
            color: #378ef0;
            font-weight: 400;
        }
    }

    .no-data-warning {
        background: #fff4f4;
        margin: 0;
        padding: 16px 14px;
        text-align: center;
        margin-top: 10px;

        &__message {
            color: #d72e2e;
            font-weight: 400;
        }

        &__link {
            background: transparent;
            border: none;
            color: #d72e2e;
            cursor: pointer;
            text-decoration: none;
        }

        &__icon-area {
            display: inline-block;
            margin-right: 12px;
            color: #d72e2e;
            font-size: 19px;
        }
    }

    .warning-area {
        background-color: #fef9f4;
        padding: 23px;
        margin-top: 24px;

        &__icon-area {
            display: inline-block;
            margin-right: 12px;
            color: #f29423;
            vertical-align: top;
            font-size: 19px;
        }

        &__description-area {
            display: inline-block;
        }

        p {
            font-size: 14px;
            line-height: 18px;
        }

        .bold {
            font-weight: 600;
        }
    }

    .footer {
        .rs-btn-default {
            color: #378ef0;
        }

        &__confirm-btn,
        &__cancel-btn {
            font-weight: bold;
            text-transform: uppercase;
        }
    }
}
