.arrow-up {
    color: #33AB84;
    height: 16px;
    margin-left: 2px;
}

.arrow-down {
    color: #EC5B62;
    height: 16px;
    margin-left: 2px;
}
