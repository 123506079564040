.modal-custos-gerais-tabela-base {
    padding-left: 0 !important;
    :global(.rs-modal-body) {
        padding-bottom: 10px !important;
    }
}

.container {
    display: flex;
    gap: 8px;
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    :global(.rs-input-group) {
        width: 96px;
    }

    :global(.rs-input-group-addon) {
        padding: 8px;
    }
}

.label {
    font-size: 12px;
    font-weight: 400;
}

.alert {
    margin-top: 8px;
}
