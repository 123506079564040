.table-scenario-container {
    margin: 0 0 10px 0;
    background: #ffffff;
    border-radius: 6px;
}

.main-bold-text, .regular-bold-text {
    font-size: 14px;
    color: #323232;
    margin: 0;
}

.main-bold-text, .subtext-bold {
    font-weight: 600;
}

.regular-text {
    font-size: 12px;
    font-weight: 400;
}

.subtext {
    font-size: 11px;
    font-weight: 400;
    color: #747474;
    margin: 0;
}

.subtext-bold {
    font-size: 12px;
    color: #747474;
    margin: 0;
}

.table-scenario {
    border-radius: 6px;
}

.table-scenario-row {
    :global(.rs-table-cell-header > .rs-table-cell > .rs-table-cell-content) {
        display: flex;
        align-items: center;
    }
}

.sub-flex {
    display: flex;
    align-items: center;

    :global(.indicator) {
        margin-left: 4px;
    }
}

.flex {
    display: flex;
    align-items: center;
    height: -webkit-fill-available;
}

.input-group, .input-group-alert {
    margin-bottom: 4px;
    input {
        height: 32px !important;
    }

    span {
        padding: 8px;
    }
}

.custo-fabricacao-cell {
    :global(.rs-table-cell-content) {
        padding: 4px;
    }
}

.neutro-indicator {
    border: 1px solid #BCBCBC;
    border-radius: 4px;
    background: #F5F5F5;
    padding: 1px 2px;
    font-size: 10px;
    font-weight: 600;
    width: fit-content;
    line-height: 10px;
}

.outros-indicator-flex {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 4px;
}

.pmz-indicator {
    margin-top: 4px;
}

.flex-column {
    display: flex;
    flex-direction: column;

    p {
        margin: 0;
        line-height: 14px;
    }
}

.currency-indicator {
    font-size: 11px;
    font-weight: 600;
    color: #747474;
}

.preco-medio-indicator {
    margin-left: 4px;
}

.btn-simulate {
    margin-top: 4px;
}

.edit-indicator, .applied-indicator {
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 10px;
    color: #ffffff;
    padding: 1px 6px;
    margin-left: 4px;
}

.applied-indicator {
    background: #33AB84;
}

.edit-indicator {
    background: #378EF0;
}

.margem-indicator {
    margin-right: 4px;
}

.line {
    :global(.rs-table-cell-content) {
        border-left: 1px solid #f2f2f5;
    }
}

.promo-cell {
    :global(.rs-table-cell-content) {
        padding: 0 !important;
    }
}

.btn-promo, .btn-promo-selected {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 0 !important;
    height: 100%;
    width: 100%;
}

.btn-promo {
    background: transparent;

    svg {
        width: 16px;
        height: 16px;
        color: #747474;
    }

    &:focus {
        background: transparent;
    }
}

.btn-promo-selected {
    background: #2680EB;

    svg {
        width: 16px;
        height: 16px;
        color: #fff;
    }

    p {
        color: #fff;
    }

    &:focus {
        color: #fff;
        background: #2680EB;
    }
}

.promo-unselected-text, .promo-selected-text {
    font-size: 10px;
    font-weight: 600;
    margin: 0;
}

.promo-unselected-text {
    color: #747474;
}

.promo-selected-text {
    color: #fff;
}

.product-code-wrapper {
    display: flex;
    align-items: center;
    gap: 4px;
}

.lider {
    height: fit-content;
    font-size: 10px;
    font-weight: 600;
    border-radius: 4px;
    padding: 1px 2px;
    color: #378EF0;
    background: #F5F9FE;
    text-transform: uppercase;
}

.alert-icon-wrapper {
    display: flex;
    align-items: center;
    height: 16px;
    border-radius: 4px;
    padding: 1px 2px;
    background: #FEF6E9;
}

.alert-icon {
    width: 12px !important;
    height: 12px !important;
    color: #F29423 !important;
}

.currency-input {
    border: none !important;
}

.input-group {
    border: 1px solid #378EF0 !important;

    :global(.rs-input-group-addon) {
        color: #378EF0;
        background: #F5F9FE;
    }
}

.input-group-alert {
    border: 1px solid #EC5B62 !important;

    :global(.rs-input-group-addon) {
        color: #EC5B62;
        background: #FEF7F7;
    }
}
