.modal-family-loja-confirmacao {

    .tabela{
        margin-top: 10px;
        border: 1px solid #ccc;
        border-radius: 5px;

        .grid{
            &__tabel-modal{
                font-weight: 700;
                margin-right: 3px;

            }
        }
        strong {
            font-weight: 700;
            margin-right: 3px;
        }
    }

    .botao{

        font-weight: 700;
    }
}
