.modal {
    :global(.rs-modal-content) {
        padding: 0 !important;

        :global(.rs-modal-header) {
            padding: 20px;
        }

        :global(.rs-modal-body) {
            padding: 0 20px 20px 20px;
            margin-top: 20px;
        }

        :global(.rs-modal-footer) {
            border-top: 1px solid #eaeaea;
            padding: 20px;
        }
    }
}

.btn-save {
    font-weight: 700;
    text-transform: uppercase;
    margin-left: 16px !important;
}

.btn-cancel {
    font-weight: 700;
    text-transform: uppercase;
}

.input-name {
    border: 1px solid #eaeaea !important;
    border-radius: 6px !important;
    margin-top: 6px;
}

