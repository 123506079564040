.modal-composicao-regras {
    display: flex !important;
    width: 520px !important;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 4px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.12),
        0px 0px 10px 0px rgba(0, 0, 0, 0.06);

    .flex-container {
        row-gap: 8px;
    }

    .divider-composition-modal {
        width: 100%;
    }

    .box-rule-name {
        display: flex;
        padding: 8px 8px;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        align-self: stretch;
        border-radius: 6px;
    }

    .box-rule-type {
        display: flex;
        padding: 4px 8px;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        border-radius: 6px;
    }

    .box-factor {
        display: flex;
        padding: 4px 8px;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        flex: 1 0 0;
        border-radius: 6px;
        border: 1px solid var(--Blue-400, #378ef0);
        background: var(--light-blue-5, #f5f9fe);
    }

    .box-competitive-price {
        display: flex;
        padding: 8px 8px;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        align-self: stretch;
        border-radius: 6px;
    }

    .box-min-variation {
        display: flex;
        padding: 4px 8px;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        flex: 1 0 0;
        border-radius: 6px;
    }

    .box-family {
        display: flex;
        padding: 4px 8px;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        flex: 1 0 0;
        border-radius: 6px;
    }

    .box-frequency {
        display: flex;
        padding: 8px 8px;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        border-radius: 6px;
    }

    .box-days-of-week {
        display: flex;
        padding: 4px 8px;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        border-radius: 6px;
    }

    .box-last-suggestion-price {
        display: flex;
        padding: 8px 8px;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        border-radius: 6px;
    }

    .box-next-suggestion-price {
        display: flex;
        padding: 4px 8px;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        border-radius: 6px;
    }

    .box-starting-date {
        display: flex;
        padding: 8px 8px;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        border-radius: 6px;
    }

    .box-expiration-date {
        display: flex;
        padding: 4px 8px;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        border-radius: 6px;
    }

    .box-author {
        display: flex;
        padding: 4px 8px;
        flex-direction: column;
        align-items: flex-start;
        gap: 4px;
        align-self: stretch;
        border-radius: 6px;
    }

    .box-buttons {
        display: flex;
        padding: 16px 24px;
        justify-content: flex-end;
        align-items: center;
        gap: 16px;
        align-self: stretch;
        &::after,
        &::before {
            display: none;
        }
    }

    .button-close {
        display: flex;
        padding: 8px 12px;
        justify-content: center;
        align-items: center;
        gap: 10px;
        border-radius: 4px;
        border: 1px solid var(--Blue-400, #378ef0);
    }

    .button-see-rule {
        display: flex;
        padding: 8px 12px;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
        border-radius: 4px;
        background: var(--Blue-400, #378ef0);
    }

    & p {
        font-size: 12px;
        line-height: 18px;
        color: var(--gray-900, #323232);
    }
}
