.scenarios {
    // padding: 0.75rem;
    border-radius: 0.375rem;

    background: var(--white, #fff);
}

.scenarios-wrapper {
    display: flex;
    gap: 0.75rem;
}

.scenario-container {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 0.5rem;
    padding: 0.75rem;

    border-radius: 0.375rem;
    border: 1px solid var(--gray-300, #eaeaea);

    & > p {
        color: var(--gray-900, #323232);

        font-family: 'Open Sans';
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.25rem; /* 125% */
        text-align: center;
        padding-top: 0.5rem;
    }

    .product-name {
        display: flex;
        justify-content: center;

        span {
            font-family: 'Open Sans';
            font-size: 0.75rem;
            font-style: normal;
            font-weight: 600;
            // line-height: 1.25rem; /* 125% */
            text-align: center;
            padding-bottom: 0.5rem;
        }
    }
}

.border {
    border-radius: 0.375rem;
    border: 2px solid transparent;
}

.border-highlight {
    border-color: #e663c9;
}

.button-wrapper {
    display: flex;
    justify-content: center;
    padding: 0.5rem 0;
}

.button {
    color: var(--White, #fff);
    text-align: center;
    font-feature-settings:
        'liga' off,
        'clig' off;

    /* Buttons/medium */
    font-family: 'Open Sans';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 700;
    line-height: 1.25rem; /* 142.857% */
    text-transform: uppercase;
}
