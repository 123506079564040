.title-wrapper {
    display: flex;
    align-items: center;
}

.select {

    :global(.rs-picker-toggle) {
        border: none !important;
        background: transparent !important;
        padding: 2px 32px 2px 6px!important;

        :global(.rs-picker-toggle-caret) {
            top: 3px !important;
        }
    }

    :global(.rs-picker-toggle-value) {
        font-size: 16px;
        font-weight: 600;
        color: #323232 !important;
    }
}

.tag {
    display: flex;
    padding: 1px 6px;
    border-radius: 10px;
    background: #33AB84;
    text-transform: uppercase;
    color: #fff;
    font-size: 10px;
    font-weight: 700;
    height: fit-content;
    margin-left: 6px;
}

.loading-tag {
    display: flex;
}

.loader {
    :global(.rs-loader) {
        width: 10px !important;
        height: 10px !important;
    }
    :global(.rs-loader-spin::before) {
        width: 10px !important;
        height: 10px !important;
    }

    :global(.rs-loader-spin::after) {
        width: 10px !important;
        height: 10px !important;
    }
}

.pending-alert {
    display: flex;
    align-items: center;
    background: #FEF6E9;
    padding: 2px 4px;
    border-radius: 4px;
    margin-left: 6px;
}

.alert-icon {
    width: 12px !important;
    height: 12px !important;
    color: #F29423;
    margin-right: 4px;
}

.alert-text {
    font-size: 12px;
}
