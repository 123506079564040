// neutral colors
$filter-clr-neutral: #959595;
$filter-clr-neutral-hover: #747474;
$filter-clr-neutral-focus: #747474;
$filter-bg-neutral: #eaeaea;
$filter-bg-neutral-hover: #d3d3d3;
$filter-bg-neutral-focus: #bcbcbc;
// primary colors
$filter-clr-primary: #0d66d0;
$filter-clr-primary-hover: #0d66d0;
$filter-clr-primary-focus: #ffffff;
$filter-bg-primary: #f5f9fe;
$filter-bg-primary-hover: #d7e8fc;
$filter-bg-primary-focus: #2680eb;
// error coclors
$filter-clr-error: #d7373f;
$filter-clr-error-hover: #d7373f;
$filter-clr-error-focus: #ffffff;
$filter-bg-error: #fef7f7;
$filter-bg-error-hover: #fbdee0;
$filter-bg-error-focus: #ec5b62;
// blue colors
$filter-clr-blue: var(--white);
$filter-clr-blue-hover: var(--white);
$filter-clr-blue-focus: var(--white);
$filter-bg-blue: var(--blue-400);
$filter-bg-blue-hover: var(--blue-600);
$filter-bg-blue-focus: var(--blue-700);

$filters: (
    'neutral' $filter-bg-neutral $filter-clr-neutral $filter-bg-neutral-hover
        $filter-clr-neutral-hover $filter-bg-neutral-focus
        $filter-clr-neutral-focus,
    'primary' $filter-bg-primary $filter-clr-primary $filter-bg-primary-hover
        $filter-clr-primary-hover $filter-bg-primary-focus
        $filter-clr-primary-focus,
    'error' $filter-bg-error $filter-clr-error $filter-bg-error-hover
        $filter-clr-error-hover $filter-bg-error-focus $filter-clr-error-focus,
    'blue' $filter-bg-blue $filter-clr-blue $filter-bg-blue-hover
        $filter-clr-blue-hover $filter-bg-blue-focus $filter-clr-blue-focus
);

.quick-action-filter {
    --height: 36px;
    --padding: 8px;
    --radius: 4px;
    display: flex;
    align-items: center;
    gap: var(--padding);

    & .rs-btn {
        display: grid;
        place-content: center;
        height: 100%;
        height: var(--height);
        padding: var(--padding);
        border-radius: var(--radius);
    }

    & .rs-dropdown {
        height: var(--height);
    }

    &__button {
        @each $name, $bg, $color, $bg-hover, $clr-hover, $bg-focus,
            $clr-focus in $filters
        {
            &-#{$name} {
                background-color: $bg !important;
                color: $color !important;

                &:hover {
                    color: $clr-hover !important;
                    background-color: $bg-hover !important;
                }

                &:active {
                    color: $clr-focus !important;
                    background-color: $bg-focus !important;
                }
            }
        }

        &:disabled {
            cursor: not-allowed;
            pointer-events: none;
            opacity: 0.6;
        }

        svg {
            width: 20px;
            height: 20px;
            fill: currentColor;
            display: block;
            margin: auto;
        }
    }

    &__toggle {
        display: flex;
        align-items: stretch;

        &__arrow {
            --padding: 0px;

            .rs-icon {
                height: var(--height);
                display: grid;
                place-content: center;
                font-size: 20px;
            }
        }
    }

    &__select-interval {
        padding: 20px 16px !important;

        &__dropdown-item {
            display: flex;
            justify-content: center;
            align-items: center;

            p:first-child {
                width: 68%;
            }

            .rs-input-number {
                width: 70px;
                margin: 0 8px;

                .rs-btn {
                    height: 100% !important;
                }
            }
        }
    }
}

// Overwrite border radius
.rs-btn-group:not(.rs-btn-group-vertical) > .rs-btn:first-child,
.rs-btn-group:not(.rs-btn-group-vertical)
    > .rs-dropdown:first-child
    > .rs-dropdown-toggle
    > .rs-btn {
    border-radius: 0px;
    border-bottom-left-radius: var(--radius);
    border-top-left-radius: var(--radius);
}

.rs-btn-group:not(.rs-btn-group-vertical) > .rs-btn:last-child,
.rs-btn-group:not(.rs-btn-group-vertical)
    > .rs-dropdown:last-child
    > .rs-dropdown-toggle
    > .rs-btn {
    border-radius: 0px;
    border-bottom-right-radius: var(--radius);
    border-top-right-radius: var(--radius);
}
