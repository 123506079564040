.flex-align-center {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.gerenciador-precos {
    &__header {
        @extend .flex-align-center;
        width: 100%;
        justify-content: space-between;
        gap: 8px;

        & div.flex-align-center {
            gap: 8px;
        }

        .tag {
            pointer-events: none;
        }
    }

    .big-numbers {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: space-between;
        gap: 8px;
    }

    &__no-data {
        display: grid;
        place-content: center;
        padding: 40px 24px;
    }
}

.rs-picker-disabled {
    opacity: 1;
    cursor: not-allowed;

    #select-picker-pill,
    #check-picker-pill,
    #check-picker-pill-default {
        .rs-picker-toggle-placeholder {
            color: var(--gray-500, #bcbcbc) !important;
        }

        pointer-events: none;
        cursor: not-allowed;
    }
}
