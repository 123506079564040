@import '../../../../../../style/typography.scss';

.container {
    --text-color: var(--gray-900, #323232);
    --height-row: 62px;
    --height-main: 62px;
    --height-sub: 62px;
    --padding: 4px 6px;

    display: flex;
    flex-direction: column;

    justify-content: center;
    gap: 2px;
    padding: var(--padding);

    & > div {
        display: flex;
        align-items: center;
    }

    :where(&__main:not(:has(input))) {
        @extend .font-size-100-semibold;
    }

    &__sub {
        color: var(--gray-700, #747474);
        font-size: 11px;
        font-style: normal;
        font-weight: 600;
        line-height: 14px;
        gap: 2px;
        height: 14px;
    }

    :global(.rs-divider-vertical) {
        margin: 0px 3px;
    }
}
