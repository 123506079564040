.freemium-setting-page {

    .rs-navbar {
        position: fixed;
        top: 0px;
        width: 100%;

        .rs-navbar-header {
            margin-right: 25px;
        }
    }



    .rs-steps {
        position: fixed;
        top: 55px;
        width: 100%;
        padding: 20px 24px;
        z-index: 2;
        background: #FFFFFF;
        box-shadow: 1px 0px 2px 2px #cccccc;

        @media screen and (max-width:600px) {
            padding: 15px 24px;

            .rs-steps-item {
                padding-left: 30px;
                margin-right: 10px;

                &:not(:first-child) {
                    padding-left: 30px;

                    .rs-steps-item-icon-wrapper {
                        left: 0px;
                    }
                }

                &:last-child {
                    margin-right: 0;
                }
            }

            .rs-steps-item-title {
                font-size: 10px;
            }
        }




        .rs-steps-item-icon-wrapper {
            &:not(.rs-steps-item-custom-icon) {
                background-color: #D3D3D3;
                border: 1px solid #D3D3D3;
                color: #959595;
            }

            @media screen and (max-width:600px) {
                width: 21px;
                height: 21px;
                font-size: 11px;
            }

        }

        .rs-steps-item-status-finish {
            .rs-steps-item-icon-wrapper {
                &:not(.rs-steps-item-custom-icon) {
                    background-color: #2D9D78;
                    border: 1px solid #2D9D78;

                    .rs-steps-item-icon {
                        &::before {
                            color: #FFFFFF;

                            @media screen and (max-width:600px) {
                                width: 20px;
                                height: 20px;
                                top: -4px;
                            }

                        }
                    }
                }
            }
        }

        .rs-steps-item-status-process {
            .rs-steps-item-icon-wrapper {
                &:not(.rs-steps-item-custom-icon) {
                    background: #2680EB;
                    border: 1px solid #2680EB;
                    color: #fff;
                }
            }

            .rs-steps-item-title {
                font-weight: 600;
            }
        }
    }

    .content-section {

        padding: 24px;
        padding-top: 125px;

        .first-col {
            padding: 24px 40px;
        }

        .rs-col-xs-24:nth-child(1) {
            background: #F5F5F5;


            @media screen and (max-width:600px) {
                padding: 24px 0;
                margin-top: 20px;
            }
        }

        .title {
            font-size: 20px;
            margin: 20px 20px 16px 20px;

            @media screen and (max-width:600px) {
                margin: 16px 12px;
            }
        }

        .subtitle {
            font-size: 14px;
            margin: 0 20px 16px 20px;

            @media screen and (max-width:600px) {
                margin: 16px 12px;
            }

        }

        .channel-selection,
        .region-selection,
        .user-section,
        .motive-list {
            margin: 0 20px;

            @media screen and (max-width:600px) {
                margin: 0 12px;
            }

            &__radio {
                margin-left: 0;
                margin-bottom: 16px;
            }

            &__label {
                font-weight: 700;
                font-size: 12px;
                text-transform: uppercase;
                margin-bottom: 16px;
            }
        }

        .group {
            display: inline-block;
            vertical-align: top;

            .rs-help-block {
                padding: 0;
                margin-bottom: 6px;
                color: #D72E2E;
                font-size: 12px;
            }
        }

        .channel-selection {
            margin-bottom: 16px;

            &__btn-option {
                @media screen and (max-width:600px) {
                    display: block;
                    width: 100%;
                    max-height: 66px;
                    margin-bottom: 10px;
                }

                &.rs-btn-ghost {
                    background: white;
                    border: none;
                    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
                    color: #3498ff;
                }

                &.rs-btn-primary {
                    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
                    font-weight: bold;
                }

                height: 160px;
                margin-right: 24px;
                width: 160px;

                i {
                    font-size: 80px;

                    @media screen and (max-width:600px) {
                        font-size: 40px;
                        display: inline;
                        margin-right: 12px;
                    }
                }

                p {
                    font-size: 14px;
                    margin-top: 16px;

                    @media screen and (max-width:600px) {
                        display: inline;
                        font-weight: bold;
                        vertical-align: super;
                    }
                }
            }
        }

        .region-selection {
            margin-bottom: 16px;

            &__btn-option {
                height: 160px;
                margin-right: 24px;
                width: 160px;

                @media screen and (max-width:600px) {
                    display: block;
                    width: 100%;
                    max-height: 66px;
                    margin-bottom: 10px;

                    img {
                        height: 40px;
                        width: 40px;
                        margin-right: 12px;
                    }
                }




                &.rs-btn-ghost {
                    background: white;
                    border: none;
                    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
                    color: #3498ff;
                }

                &.rs-btn-primary {
                    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.25);
                }

                p {
                    font-size: 14px;
                    margin-top: 16px;

                    @media screen and (max-width:600px) {
                        display: inline;
                        font-weight: bold;
                        vertical-align: middle;
                    }
                }
            }
        }


        .delete-btn {
            vertical-align: top;
        }

        .subregion-area {
            margin: 0 20px 16px 20px;
            padding: 0;

            &__input {
                input {
                    padding-bottom: 6px;
                    border: 1px solid #e5e5ea !important;
                    border-radius: 6px !important;

                    &:focus,
                    &:hover {
                        border: 1px solid #1E88E5 !important;
                    }
                }
            }

            .empty-value {
                input {
                    padding-bottom: 6px;
                    border: 2px solid yellow !important;
                    border-radius: 6px !important;

                    &:focus,
                    &:hover {
                        border: 2px solid yellow !important;
                    }
                }
            }
        }

        .description-area {
            background: #F7F9FE;
            border: 1px solid #1E88E5;
            border-radius: 6px;
            margin: 0 20px 12px 20px;
            padding: 16px 14px;
            text-align: center;

            @media screen and (max-width:600px) {
                margin: 0 12px 12px 12px;
            }

            &__message {
                color: #1E88E5;
                font-weight: 400;
            }
        }

        .no-data-warning {
            background: #FFF4F4;
            border: 1px solid #D72E2E;
            border-radius: 6px;
            margin: 0 20px 12px 20px;
            padding: 16px 14px;
            text-align: center;

            &__message {
                color: #D72E2E;
                font-weight: 400;
            }

            &__link {
                background: transparent;
                border: none;
                color: #D72E2E;
                cursor: pointer;
                font-weight: bold;
                text-decoration: underline;
            }
        }

        .update-warning-box {
            background: #FFFFFF;
            border-radius: 4px;
            margin: 0 20px 12px 0;
            width: 100%;
            padding: 26px 18px;

            &__icon-area {
                display: inline-block;
                width: 20%;

                i {
                    font-size: 50px;
                    color: #D7373F;
                }
            }

            &__description-area {
                display: inline-block;
                width: 80%;
            }
        }

        .warning-area {
            background-color: #EFEDED;
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 16px;
            padding: 12px 24px;

            &__title {
                font-weight: bold;
            }
        }



        .motive-list {

            .motive-option {
                border: 1px solid #9C9C9C;
                border-radius: 20px;
                color: #9C9C9C;
                margin: 0 10px 10px 0;

                @media screen and (max-width:600px) {
                    width: 100%;
                }


                &.selected {
                    background-color: #E0F1FF;
                    border: 1px solid #1E88E5;
                    color: #1E88E5;
                }
            }
        }

        .btn-area {

            .rs-col-xs-24 {
                padding: 0;
            }

            @media screen and (max-width:600px) {
                .rs-col-xs-24 {
                    padding: 0 20px;
                }
            }


            .footer {
                border-top: 2px solid #ccc;
                padding: 16px 24px;

                &__button {
                    font-weight: bold;
                    float: right;
                    text-transform: uppercase;
                }

                .replicate-users {
                    float: left;

                    &__checkbox {
                        display: inline-block;
                    }

                    &__tooltip {
                        display: inline-block;
                        margin-left: 10px;
                    }
                }
            }
        }

    }
}

.rs-picker-menu {
    &.subregion-area__menu {
        max-height: 200px;
        overflow-y: auto;
    }
}
