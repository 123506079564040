.product-elasticity-box {
    display: flex;
    min-width: 320px;
    flex: 1;
    height: 254px;
    padding: 8px;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;
    background: #f5f9fe;
}

.title {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}

.main-text {
    font-size: 14px;
    font-weight: 600;
    color: #323232;
    margin: 0;
}

.regular-text {
    font-size: 12px;
    font-weight: 400;
    color: #323232;
    margin: 0;
    text-align: center;
    margin-top: 4px;
}

.gauge-chart-container {
    height: fit-content;
    margin-top: -40px;

    :global(.highcharts-credits) {
        display: none !important;
    }
}

.bottom-text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75%;
    margin-top: -12px;
}

.empty-data {
    display: flex;
    min-width: 320px;
    height: 254px;
    padding: 8px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background: #fef7f7;

    .MuiSvgIcon-root {
        color: #e34850;
    }

    h3 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        color: #323232;
        margin-top: 8px;
    }

    p {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #323232;
        margin-top: 8px;
        text-align: center;
    }

    span {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #323232;
    }
}
