.modal-teste-um-ano {
    .rs-modal-header {
        button {
            color: white;
        }
    }

    .logo {
        text-align: center;

        img {
            width: 115px;
            margin-bottom: 15px;
        }
    }

    .rs-modal-content {
        background-color: #0857af;

        .description {
            text-align: center;
            color: white;

            h2 {
                font-size: 25px;
                margin-bottom: 15px;
            }

            p {
                padding: 0 50px;
                font-size: 17px;
                margin-bottom: 30px;
            }
        }

        .button-area {
            text-align: center;

            button {
                text-transform: uppercase;
                font-weight: 700;
                background-color: #ebb527f7;
                color: white;
                padding: 8px 40px;
            }
        }

    }
}