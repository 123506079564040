.saved-compare-content {
    margin: 0 20px 12px !important;
    padding: 12px !important;
    border-radius: 6px;
    &:global(.rs-content) {
        flex: none;
    }
}

.flex {
    display: flex;
    align-items: center;
}

.margin {
    margin-left: auto;
}

.regular-text, .bold-text {
    font-size: 14px;
}

.regular-text {
    font-weight: 400;
}

.bold-text {
    font-weight: 600;
}

.btn-clear {
    background-color: transparent;
    color: #378EF0;
    padding: 8px;

    &:hover, &:focus {
        background-color: transparent;
        color: #378EF0;
    }
}

.btn-gray {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
}

.icon-delete {
    color: #959595;
    font-size: 20px;
}

.btn-compare {
    font-weight: 700;
    text-transform: uppercase;
}

.selected {
    border: 1px solid #378EF0;
    background: #F5F9FE !important;
}
