.dialog-select {
    &__header {
        &.MuiAppBar-colorPrimary {
            color: #000000;
            background-color: #FFFFFF;
        }


        .header {
            &__search-input {
                position: relative;
                border-radius: 8px;
                background-color: #E5E5EA;
                width: 100%;

                .search-input {
                    &__icon {
                        padding: 10px;
                        height: 100%;
                        position: absolute;
                        pointer-events: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                    }
                }

                .MuiInputBase-root {
                    width: 100%;

                    input {
                        padding: 10px 10px 10px 40px;
                    }
                }
            }
        }
    }

    &__list {
        padding-top: 50px !important;
        padding-bottom: 50px !important;


        .MuiCheckbox-colorSecondary {
            &.Mui-checked {
                color: #3498ff;
            }
        }
    }

    &__footer{
        top: auto !important;
        bottom: 0 !important;
        padding: 16px 24px;
        display: inline !important;

        &.MuiAppBar-colorPrimary {
            color: #000000;
            background-color: #FFFFFF;
        }

        button{
            padding: 8px 16px;
            text-transform: uppercase;
            font-weight: bold;

            &.rs-btn-primary{
                width: 60%;
                float: right;
            }

            &.rs-btn-subtle{
                width: 30%;
                float: left;
            }
        }
    }
}