@import '../../../../../../style/abstracts/variables.scss';

.detailing-panel-content-table {
    border-radius: 6px;
    margin: 0 20px;

    :global(.rs-table-cell-header) {
        :global(.rs-table-cell-content) {
            padding: 7px 10px;
        }
    }

    :global(.rs-table-body-row-wrapper) {
        :global(.rs-table-cell-content) {
            padding: 7px 10px;
            font-size: 12px;
            color: $gray-900;
        }

        :global(.rs-table-cell-last) {
            :global(.rs-table-cell-content) {
                padding: 0;
                font-weight: 600;
            }
        }
    }
}

.last-cell {
    padding: 7px 10px;
}

.products-pagination {
    :global(.rs-divider-vertical) {
        display: none;
    }
}
