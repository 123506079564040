// border colors
$clr-border-gray: var(--gray-500);
$clr-border-blue: var(--blue-400);
$clr-border-red: var(--red-400);

// icon container colors
$clr-icon-gray: var(--gray-800);
$clr-icon-blue: var(--blue-400);
$clr-icon-red: var(--red-400);
$bg-icon-gray: var(--gray-200);
$bg-icon-blue: #f5f9fe;
$bg-icon-red: #fef7f7;

$borders: (
    'gray' $clr-border-gray $clr-icon-gray $bg-icon-gray,
    'blue' $clr-border-blue $clr-icon-blue $bg-icon-blue,
    'red' $clr-border-red $clr-icon-red $bg-icon-red
);

.currency-input {
    --max-height: 32px;
    @each $name, $border-color, $clr-icon, $icon-bg in $borders {
        &-#{$name} {
            --primary: #{$border-color};
            --secondary: #{$icon-bg};
            --clr-icon: #{$clr-icon};
            border-color: var(--primary);
        }
    }
    overflow: hidden;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
    gap: 4px;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    height: var(--max-height);
    transition: var(--base-transition);

    :where(input) {
        height: var(--max-height);
        outline: none;
        padding: 0px;
        width: 100%;
    }

    &-full-width {
        width: 100%;
    }

    &__input {
        margin: 0px;
        padding: 0px;
        border-bottom: none;
        &-padded {
            padding-left: 12px;
        }
    }

    &__icon {
        color: var(--clr-icon);
        background: var(--secondary);
        padding: 8px;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        // desabilitado eventos de clique, por enquanto
        pointer-events: none;
    }

    &:has(input:disabled) {
        --primary: var(--gray-500);
        --secondary: var(--white);
        background-color: var(--gray-300);
        color: var(--gray-600);
        cursor: not-allowed;
        & * {
            pointer-events: none;
        }
        & .currency-input__icon {
            --clr-icon: var(--gray-800);
        }
    }
    &:has(input:focus) {
        outline: 1px solid var(--primary);
    }
}
