.gerenciador-page {
    .gerenciador-page__table__row--red {
        background: var(--light-red-5, #fef7f7) !important;
        border: 2px solid var(--red-400, #ec5b62) !important;
        border-radius: 6px;
    }

    --_left-spacing_correction: -4px;

    :global(.rs-table-cell-group.rs-table-cell-group-scroll) {
        position: relative;
        left: var(--_left-spacing_correction);
    }

    :global([role='columnheader'] .rs-table-cell-content:has(.rs-checkbox)),
    :global(.rs-checkbox) {
        justify-content: center;
    }

    :global(.rs-table-body-row-wrapper) [role='row'] {
        min-width: 100% !important;
        border: 1px solid transparent;
        &:has([data-table-row-is-expanded]) {
            border-color: #bcbcbc;
        }
    }

    :global(.rs-table-row-expanded) {
        padding: 0px;
    }

    &__big-numbers {
        margin: 0px !important;
        padding: 12px !important;
    }

    &__table {
        --_table-border: 1px solid var(--gray-300, #eaeaea);
        border-radius: 6px;
        border: var(--_table-border);

        &__row {
            border-bottom: var(--_table-border);
        }

        &__header-cell {
            p {
                display: block;
                margin: 0px;
                line-height: 1;
            }
        }

        [role='row']:nth-child(odd) {
            border-bottom: 1px solid var(--gray-300, #eaeaea);
            background: #f9f9f9;
        }

        [role='gridcell'] {
            background: transparent;
        }
    }

    &__margem-wrapper {
        display: flex;
        align-items: center;
        gap: 4px;
    }
}

.table__cell {
    &__margem-objetiva {
        display: flex;
        align-items: center;
        gap: 2px;
        svg {
            color: var(--gray-600);
        }
    }
}

.layout {
    --_inline-spacing: 16px;
    --_header-spacing: calc(var(--header-height) + 4px);
    --_column-gap: 8px;

    flex: 1 1 auto;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: min-content 1fr;
    row-gap: var(--_column-gap);
    padding-inline: var(--_inline-spacing);
    margin-top: var(--_header-spacing);

    &-fixed {
        height: calc(100dvh - var(--_header-spacing));
    }

    &__fixed {
        display: flex;
        flex-direction: column;
        gap: calc(var(--_column-gap) / 2);

        &__content {
            display: flex;
            flex-direction: column;
            gap: var(--_column-gap);
        }
    }

    &__scroll {
        overflow-y: auto;
    }
}
