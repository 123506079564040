@media (max-width: 480px) {

    .dialog-mobile-menu {
        .MuiDialog-container {
            width: 100%;
            bottom: 0;
            position: absolute;

            .MuiPaper-root {
                background: #378EF0;
            }

            .btn-close{
                align-self: flex-end;
                margin: 6px;
                color: #FFFFFF;
            }

            img {
                width: 115px;
                align-self: center;
                margin-bottom: 20px;
            }

            .rs-nav-vertical {
                padding: 20px 20px 0 20px;
            }
        }

    }
}
