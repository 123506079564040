.requests-table {
    border-radius: 6px;

    :global(.rs-checkbox-wrapper ){
        top: 2px !important;
    }
}

.row-options-cell {
    :global(.rs-table-cell-content) {
        padding: 8px;
    }
}

.row-options-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 4px;
    color: #959595 !important;
    background-color: #f4f4f4;
}

.double-word {
    :global(.rs-table-cell-content) {
        display: flex;
        align-items: center;

        padding: 6px 10px !important;

        p {
            margin: 0;
            line-height: 14px;
        }
    }
}

.price-week-wrapper {
    display: flex;
    align-items: center;
}

.requests-pagination {
    padding: 12px 0px;

    :global(.rs-divider-vertical) {
        display: none;
    }
}
