.select-footer {
    padding: 10px 2px;
    border-top: 1px solid #e5e5e5;
}

.btn-footer {
    float: right;
    margin-right: 10;
    margin-top: 2;
}

.checkpicker-colunas {
    margin-right: 8px;
    a {
        border-radius: 6px !important;
    }
}

.render-value-text {
    color: #8e8e93;
}

.render-value-number {
    margin: 0 4px;
    background: #3498ff;
    color: #fff;
    border-radius: 10px;
    padding: 0 10px;
    line-height: 20px;
}
