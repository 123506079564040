.container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.title {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
}

.subtitle {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}

.btn-new-table {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
}

.input-group {
    max-width: 420px;
}

.search-input {
    border: 1px solid #eaeaea !important;
    border-radius: 6px !important;
}

.search-icon {
    color: #1473E6;
}

.btn-new-table {
    height: fit-content;
    padding: 8px 10px !important;
}
