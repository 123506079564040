.modal-alterar-precos {
    min-width: 540px;
}

.input-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-block-end: 1rem;

    &__edit-type {
        flex: 1 1 160px;
    }
    &__comportament-type {
        flex: 1 1 140px;
    }
    &__edited-value {
        flex: 1 1 140px;
    }
}

.arrow-svg {
    flex: initial;
    width: min-content;
    color: #d3d3d3;
}

.callout {
    margin-block-start: 24px;
}
