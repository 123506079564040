.container, .product-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #eaeaea;
    border-radius: 6px;
    padding: 8px;
}

.product-container {
    background: #F5F9FE;
}

.label {
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
}

.product {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}

.value-box-wrapper {
    display: flex;
    gap: 8px;
    margin-left: 8px;
}

.value-box {
    border: 1px solid #eaeaea;
    border-radius: 6px;
    padding: 8px;
    background: #fff;
    width: 126px;
}

.label-box {
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    margin: 0;
    color: #959595;
}

.value {
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    margin: 0;
}
