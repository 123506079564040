@import '../../../../../components/ButtonPrimary/mixins.scss';

.btn_group {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 1px;

    :global(.rs-dropdown-menu) {
        width: 172px !important;
    }

    :global(.rs-btn) {
        padding: 8px !important;
    }
}

.btn_primary {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.dropdown {
    transition: var(--base-transition);

    button {
        border-top-right-radius: 4px !important;
        border-bottom-right-radius: 4px !important;
    }
}

.dropdown_disabled {
    opacity: 0.4 !important;
    pointer-events: none !important;
    cursor: not-allowed !important;
}

.icon_button {
    max-width: 36px;
}
