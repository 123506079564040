.global-loader.rs-loader-wrapper {
    width: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 1000;
    vertical-align: middle;
    display: table;
    position: fixed;
    height: 100%;
}

.global-loader {
    .rs-loader {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
    }

    .rs-loader-spin {
        float: none;
    }
}

.wrapper-message-loader {
    max-width: 350px;
    left: 0;
    right: 0;
    top: -50px;
    bottom: 0;
    z-index: 9999;
    display: table;
    position: fixed;
    height: 100%;
    margin: 0 auto;

    .message-loader {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        color: #838486;
    }
}

.area-loader {
    width: 100% !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex !important;
    justify-content: center;
    align-items: center;
    z-index: 6;
    background-color: rgba(255, 255, 255, 0.7);
}
