.products-config-mpdv {

    .use-infopanel-price,
    .configuration {
        padding: 24px;
    }

    .use-infopanel-price-header {
        margin-bottom: 32px;

        h6 {
            font-size: 18px;
        }
    }

    .use-infopanel-description {
        margin-top: 12px;
        padding: 16px;
        background: #ebf4ff;
        display: flex;

        img {
            margin-right: 8px;
        }
    }

    .configuration {
        margin: 12px 0 60px 0;

        .configuration-title {
            margin-bottom: 14px;
        }

        .configuration-description {
            padding: 16px;
            background: #F5F5F5;
            border-radius: 6px;
            margin-bottom: 32px;
            font-size: 12px;
        }

        .retail-origin-price {
            margin: 0 0 10px 16px;
        }

        .data-integration {
            .data-integration-header {
                margin-bottom: 24px;
            }

            .wrapper-add-price-infopanel-title {
                margin-bottom: 12px;

                .add-price-infopanel-title {
                    display: flex;
                    margin: 15px 0 0;

                    span {
                        font-size: 12px;
                        line-height: 13px;
                        margin-left: 4px;
                    }
                }

                .subtitle {
                    font-size: 11px;
                }
            }


            .input-wrapper {
                margin-left: 6px;
            }
        }

        .parameterization,
        .reference {
            margin-top: 24px;
            border-top: 1px solid #EAEAEA;
            padding: 32px 0;

            .parameterization-header,
            .reference-header {
                margin-bottom: 24px;
            }

            .average-price,
            .store-reference {
                margin-bottom: 24px;
            }
        }

        .reference {
            .radius-wrapper {
                padding-left: 240px;
            }
        }
    }

    h6,
    .title,
    .subtitle,
    .input-label,
    .input-subtitle {
        font-family: 'Open Sans';
        font-style: normal;
    }

    h6 {
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        color: #323232;
    }

    .title {
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        color: #323232;
    }

    .subtitle {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #747474;
    }

    .input-label {
        font-weight: 400;
        font-size: 14px;
        line-height: 14px;
        color: #323232;
    }

    .input-subtitle {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #747474;
        margin-top: 6px;
    }

    .input-wrapper {
        display: flex;

        .rs-btn-toggle {
            margin-right: 8px;
        }
    }

    .footer {
        display: flex;
        justify-content: flex-end;
        margin-top: 64px;
        padding: 10px;

        .btn-cancel,
        .btn-save {
            font-weight: 700 !important;
        }

        .btn-save {
            margin-left: 12px;
            padding: 8px 30px;
        }

        .btn-cancel {
            color: #378EF0 !important;
            border: 1px solid #378EF0 !important;
        }
    }

    .alert {
        color: red;
    }
}

.modal-origin-price-change-alert {
    .modal-confirm__body {
        padding: 0;
        display: flex;

        img {
            align-self: baseline;
            margin: 6px 14px 0 0;
        }
    }
}
