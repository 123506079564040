@import '../../../style/typography.scss';

.description {
    @extend .font-size-75-semibold;
    --text-color: var(--gray-700);
    text-transform: uppercase;
}

.product_id {
    @extend .font-size-100-semibold;
    --text-color: var(--gray-900);
}
