.info-modal {
    .rs-modal-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 24px;
    }

    .rs-modal-content {
        padding: 0px;
    }

    .rs-modal-body {
        padding: 0px 24px 24px 24px;
        margin-top: 0px;
    }

    .rs-modal-title {
        color: #323232 !important;
    }

    .rs-modal-footer {
        padding: 16px 24px;
        border-top: 1px solid var(--gray-300, #eaeaea);
        display: flex;
        align-items: center;
        gap: 1rem;
        justify-content: flex-end;

        &::before,
        &::after {
            all: unset;
        }
    }

    .rs-divider {
        margin-block: 12px 8px;
    }
}

.modal-salvar-filtros {
    input {
        margin-block-start: 8px;
        border-radius: 4px !important;
        border: 1px solid var(--gray-500, #bcbcbc) !important;
        background: var(--white, #fff) !important;
    }
    .rs-modal-body {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
}
