.modal-resumo-precificacao {
    .description{
        &__qtd-lojas{
            color: #3597fa;
            font-weight: 600;
            cursor: pointer;
        }
    }

    .info-preco {
        &__tipo {
            font-weight: 600;

            &_margin-top {
                margin-top: 11px;
            }
        }

        &__icon-preco {
            color: #3597fa;
            margin-left: 14px;
            margin-top: 20px;
        }

        &__icon-percent {
            color: #3597fa;
            margin-left: 6px;
            margin-top: 20px;
        }

        &__group-label {
            height: 50px;
        }

        &__label {
            color: #adadad;
            font-weight: 600;
            font-size: 13px;
        }

        &__sub-label {
            font-style: italic;
            font-size: 12px;
        }
    }
}
