.search {
    display: flex;
    height: 38px;
    align-items: center;
    gap: 14px;
    flex: 1 0 0;
    border-bottom: 1px solid var(--gray-300, #eaeaea);
    width: 100%;
    padding: 0px 168px 0px 38px;
    outline: none;
    color: #575757;
    font-size: 14px;
    line-height: 20px;
    transition: all var(--base-transition);

    &:focus,
    &:hover {
        border-bottom: 1px solid var(--blue-400);
    }
}
