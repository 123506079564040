.modal {
    --_img-text-height: 84px;
    --_icon-check-size: 24px;
    --_gap: 1.5rem;

    width: min(600px, 90dvw);
    height: 80%;
    text-wrap: balance;

    img {
        display: block;
        max-width: 100%;
    }

    [role='document'] {
        padding: 2rem;
        background: #0D66D0;
    }

    &:global(.rs-modal) {
        margin-top: 10px;
    }

    :global(.rs-modal-body) {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--_gap);
        margin: 0px;
        padding: 0px;
        color: var(--white);
    }

    .logo {
        width: 150px;
        height: 48px;
    }

    .title {
        text-align: center;
        font-weight: 600;
        font-size: 22px;
        line-height: 1.2;
        strong {
            font-weight: 800;
        }
    }

    .img__container {
        position: relative;
        z-index: 100;
        margin-block-end: calc(var(--_img-text-height) / 2);

        span {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            z-index: 10;
            transform: translateY(50%);

            display: grid;
            place-content: center;

            margin-inline: 5%;
            padding: 8px 20px;
            background: #378EF0;
            border-radius: 0.5rem;

            font-weight: 500;
            line-height: 22px;
            font-size: 16px;
            text-align: center;
        }
    }

    ul,
    li {
        margin: 0px;
        padding: 0px;
        list-style: none;
    }

    ul {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
        align-items: center;
        gap: 1rem;
        width: 100%;
    }

    li {
        display: grid;
        grid-template-columns: min-content 1fr;
        align-items: start;
        gap: 0.5rem;
        line-height: 18px;
        font-weight: 400;
        font-size: 14px;

        &::before {
            content: '';
            display: inline-block;
            background: url('../../assets/icons/icon-check.svg') no-repeat;
            width: var(--_icon-check-size);
            height: var(--_icon-check-size);
            margin-block-start: 2px;
        }
    }

    button {
        margin: 0px;
        outline: none;
        background: #e8aa23;
        padding: 0.75rem 2rem;
        color: var(--white);
        text-decoration: none;
        border-radius: 0.5rem;
        font-weight: 600;
    }

    @media (max-height: 721px) {
        width: min(550px, 90dvw);

        [role='document'] {
            padding: 20px;
        }

        :global(.rs-modal-body) {
            gap: 18px;
        }

        .logo {
            width: 120px;
            height: 48px;
        }

        .title {
            font-size: 20px;
        }

        ul {
            grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
        }

        li {
            font-size: 13px;

            &::before {
                content: '';
                display: inline-block;
                background: url('../../assets/icons/icon-check.svg') no-repeat;
                width: 18px;
                height: 18px;
                margin-block-start: 2px;
            }
        }

        .img__container {
            span {
                line-height: 20px;
                font-size: 15px;
                padding: 8px 16px;
            }
        }

        button {
            margin-top: 12px;
            padding: 10px 20px;
        }
    }
}
