.ecom-table-container {
    margin-top: 0 !important;
}

.btn-column-help {
    width: 36px;
    height: 36px;
    margin-left: 8px;
    padding: 8px !important;

    svg {
        width: 20px;
        height: 20px;
        color: #959595;
    }
}

.content-empty-state {
    margin-top: 0 !important;
    vertical-align: middle;
    display: table;
    height: 56vh;
    color: #575757b0;
}
