@import '../../../../style/abstracts/variables.scss';

.container {
    border: 1px solid #EAEAEA;
    border-radius: 6px;
    padding: 12px;
    background: #fff;
    margin-top: 12px;
    width: 49%;
}

.title-wrapper {
    margin-bottom: 30px;
}

.title {
    font-size: 14px;
    font-weight: 600;
    color: #323232;
    margin: 0;
}

.subtitle {
    font-size: 12px;
    font-weight: 400;
    color: #323232;
    margin: 0;
}
