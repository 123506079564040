.info-secondary-button {
    --_btn-height: 38px;
    margin: 0px;
    padding: 0px;
    font: inherit;

    font-size: 16px;
    font-weight: 400;
    line-height: var(--_btn-height);
    letter-spacing: 0px;

    display: inline-flex;
    align-items: center;
    border-radius: 4px;
    overflow: hidden;
    transition: var(--base-transition);
    background-color: var(--_secondary-button-bg-clr);
    color: var(--_secondary-button-clr);

    &__icon__container {
        transition: var(--base-transition);
        place-content: center;
        display: grid;
        aspect-ratio: 1;
        min-width: var(--_btn-height);
        background-color: var(--_secondary-button-icon-clr-container);
        color: var(--_secondary-button-icon-clr);
    }

    &__content {
        padding-inline: 16px;
    }

    &:hover {
        --_secondary-button-bg-clr: var(--_secondary-button-bg-clr-hover);
        --_secondary-button-icon-clr: var(--_secondary-button-icon-clr-hover);
        --_secondary-button-icon-clr-container: var(--_secondary-button-icon-clr-container-hover)
    }

    &:focus {
        --_secondary-button-bg-clr: var(--_secondary-button-bg-clr-focus);
        --_secondary-button-icon-clr: var(--_secondary-button-icon-clr-focus);
        --_secondary-button-icon-clr-container: var(--_secondary-button-icon-clr-container-focus)
    }

    &-gray {
        --_secondary-button-clr: var(--Gray-800, #505050);

        --_secondary-button-bg-clr: var(--gray-200);
        --_secondary-button-bg-clr-hover: var(--gray-300);
        --_secondary-button-bg-clr-focus: var(--gray-400);

        --_secondary-button-icon-clr: var(--gray-600);
        --_secondary-button-icon-clr-hover: var(--gray-700);
        --_secondary-button-icon-clr-focus: var(--gray-700);

        --_secondary-button-icon-clr-container: var(--gray-300);
        --_secondary-button-icon-clr-container-hover: var(--gray-400);
        --_secondary-button-icon-clr-container-focus: var(--gray-500);
    }
}
