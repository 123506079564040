@import '../../../../../style/typography.scss';

.margem-objetiva-table {
    :global(:where(.rs-table-body-row-wrapper .rs-table-cell)) {
        --text-color: var(--gray-900);
        background: transparent;
    }

    :global(:where(.rs-table-row)) {
        border-bottom: 1px solid var(--gray-300);
    }

    &__row:nth-child(odd) {
        background-color: #f9f9f9;
    }

    input {
        max-height: 32px;
    }
}

.text-disruptive {
    --text-color: var(--red-400);
}
