.indicator-positive {
    border-radius: 4px;
    border: 1px solid #33AB84;
    background: #F5FBF9;
    padding: 1px 2px;
    color: #33AB84;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.indicator-negative {
    border-radius: 4px;
    border: 1px solid #EC5B62;
    background: #FEF7F7;
    padding: 1px 2px;
    color: #EC5B62;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}
