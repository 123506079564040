.gerenciador-table {
    width: 100%;
    border-radius: 6px !important;

    input {
        font: inherit;
        background: transparent;
        border: none;
        width: 100%;
    }

    .rs-table-cell-content {
        padding: 8px;
    }

    .rs-table-body-wheel-area .rs-table-cell {
        background: transparent;
    }

    .rs-table-row .rs-table-row-header,
    s .rs-table-body-wheel-area .rs-table-row,
    .rs-table-body-wheel-area .rs-table-row:last-child {
        border-bottom: 1px solid var(--gray-300, #eaeaea);
    }

    .rs-table-body-wheel-area .rs-table-row {
        &:nth-child(even) {
            background: var(--white);
        }
        &:nth-child(odd) {
            background: #f9f9f9;
        }
    }

    &__header-cell {
        color: var(--gray-700, #747474);
        font-family: Open Sans;
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        transition: color var(--base-transition);
        word-wrap: break-all;
        &:hover {
            color: var(--gray-800);
        }
        .rs-table-cell-content {
            display: flex !important;
            align-items: center !important;
            justify-content: flex-start !important;
            gap: 4px !important;
        }
    }

    &__cell {
        &__container {
            display: flex;
            flex-direction: column;
            justify-content: stretch;
            gap: 2px;
        }
        &__product {
            display: flex;
            flex-direction: column;
            gap: 2px;
            p {
                margin: 0;
            }
            > div {
                gap: 4px;
            }
        }
        &__pro {
            gap: 4px;
            background: none;
        }
    }
}
