.flex-center {
    display: flex;
    align-items: center;
}

.flex-base {
    display: flex;
    align-items: baseline;
}

.bold-text-value {
    margin: 0 2px;
}
