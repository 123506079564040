@import '../../../../../../../../style/abstracts/variables.scss';

.container {
    border-radius: 6px;
    background: #fff;
}

.container-loading {
    height: 400px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.title {
    font-size: 14px;
    font-weight: 600;
    color: #323232;
    margin: 0;
}

.subtitle {
    font-size: 12px;
    font-weight: 400;
    color: #323232;
    margin: 0;
}
