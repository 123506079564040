@import '../../../../../../style/abstracts/variables.scss';

.all-products-selected-table {
    border-radius: 6px;

    :global(.rs-table-row-header) {
        border: 1px solid #d7e8fc;
        border-radius: 6px;
    }

    :global(.rs-table-body-wheel-area) {
        :global(.rs-table-row) {
            border: none;
        }
    }

    :global(.rs-table-body-row-wrapper) {
        :global(.rs-table-cell-content) {
            padding: 13px 20px 13px 10px;
            font-size: 12px;
            color: $gray-900;
        }

        :global(.rs-table-cell-last) {
            :global(.rs-table-cell-content) {
                padding: 0;
                // font-weight: 600;
            }
        }
    }
}

.products-pagination {
    :global(.rs-divider-vertical) {
        display: none;
    }
}

.regular-header {
    :global(.rs-table-cell-content) {
        padding: 7px 10px;
        font-size: 12px;
        color: #747474;
        font-weight: 600;
    }
}

.dashed-header {
    :global(.rs-table-cell-content) {
        position: relative;
        padding: 7px 10px 7px 36px !important;
        font-size: 12px;
        color: #747474;
        font-weight: 600;
    }
}

.dashed-divider-header {
    position: absolute;
    left: 0;
    top: 4px;
    height: 24px;
    border-left: 1px dashed #d7e8fc;
}

.product-title {
    font-size: 14px;
    font-weight: 600;
    color: #323232;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
}

.product-info-wrapper {
    display: flex;
    align-items: center;
    line-height: 16px;
    margin-top: 6px;

    img {
        vertical-align: baseline !important;
        margin: 0 6px;
    }
}

.product-info-text {
    font-size: 12px;
    font-weight: 400;
    color: #959595;
}

.dashed-divider-cell {
    position: absolute;
    left: 1px;
    top: 8px;
    height: 52px;
    border-left: 1px dashed #d7e8fc;
}

.price-container {
    display: flex;
    align-items: center;
    height: 100%;
    margin-left: 28px;
}

.price-wrapper {
    display: flex;
    align-items: center;
    margin-right: 16px;
    margin-top: 3px;
    min-width: 70px;
}

.price-symbol {
    font-size: 12px;
    font-weight: 400;
    color: #959595;
    margin-right: 1px;
}

.price-value {
    font-size: 16px;
    font-weight: 600;
    color: #323232;
}

.last-price-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.price-regular-text {
    font-size: 10px;
    font-weight: 400;
    color: #959595;
    margin: 0;
}

.price-bold-text {
    font-size: 10px;
    font-weight: 700;
    color: #959595;
    margin-top: 2px;
}

.practiced-price-text-wrapper {
    line-height: 14px;
}

.image-cell {
    :global(.rs-table-cell-content) {
        padding: 14px 16px !important;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
}
