.modal-activate-product-promo {
    :global(.rs-modal-body) {
        padding-bottom: 10px !important;
    }

    :global(.rs-table-header-row-wrapper) {
        :global(.rs-table-cell-content) {
            background: #f5f5f5;
        }
    }
}

.content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.bold-background {
    background: #f5f5f5;
}
