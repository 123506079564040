.container {
    border-radius: 6px;
    border: 1px solid #D7E8FC;
    background: #F5F9FE;
    padding: 4px 12px;
    min-width: 140px;
    position: relative;
}

.up {
    border-radius: 6px;
    border: 2px solid  #33AB84;
    background: #F5FBF9;
    padding: 4px 12px;
    min-width: 140px;
    position: relative;
}

.down {
    border-radius: 6px;
    border: 2px solid  #EC5B62;
    background: #FEF7F7;
    padding: 4px 12px;
    min-width: 140px;
    position: relative;
}

.main-value {
    font-size: 20px;
    font-weight: 600;
    color: #323232;
    margin: 0;
}

.regular-text {
    font-size: 12px;
    font-weight: 400;
    color: #959595;
    margin: 0;
}

.bold-text {
    font-size: 14px;
    font-weight: 600;
    color: #747474;
    margin: 0;
    line-height: 14px;
}

.indicator-wrapper {
    display: flex;

    img {
        margin: 0 4px;
    }
}

.positive{
    color: #2D9D78;
    font-size: 12px;
    font-weight: 600;
}

.negative{
    color: #EC5B62;
    font-size: 12px;
    font-weight: 600;
}

.positive-icon{
    color: #2D9D78;
    font-size: 16px !important;
    margin: 0 3px;
}

.negative-icon{
    color: #EC5B62;
    font-size: 16px !important;
    margin: 0 3px;
}
