.nova-lista-component {
    background-color: #FFFFFF;
    padding: 24px;

    .input-area {
        label {
            text-transform: uppercase;
            font-size: 12px;
            font-weight: bold;
        }

        .react-tagsinput {
            border: 1px solid #CDCDCD;
            background-color: #FFFFFF;
            border-radius: 4px;
            max-height: 130px;
            height: 130px;
            overflow: auto;
            padding: 10px;
            margin-top: 16px;

            .react-tagsinput-tag {
                border: 1px solid #959595;
                border-radius: 12px;
                background-color: #FFFFFF;
                color: #959595;
                margin-right: 4px;
                padding: 3px 12px;
                font-size: 10px;
                font-weight: bold;

                .react-tagsinput-remove {
                    color: #959595;
                    font-size: 12px;
                    cursor: pointer;
                    margin-left: 7px;
                    position: relative;
                    top: 1px;
                }
            }

            .react-tagsinput-input {
                width: 450px;
            }
        }
    }

    .help-text {
        font-size: 12px;
        color: #7B7B7B;
    }

    .btn-result,
    .btn-clear-input {
        text-transform: uppercase;
        padding: 8px 39px;
        font-weight: bold;
    }

    .filter-area {
        .rs-row {
            margin-top: 24px;
        }

        &__label {
            display: inline-block;

            p {
                text-transform: uppercase;
                font-size: 12px;
                font-weight: bold;
                margin-right: 9px;
                display: inline-block;
            }

            .tooltip-info {
                color: #378EF0;
            }
        }

        .lojas-cadastradas {
            .rs-btn-toggle {
                margin-right: 8px;
            }
        }
    }
}

.modal-gtin-invalido {
    .rs-modal-content {
        padding: 0;
    }

    .rs-modal-body {
        padding: 24px;
        margin-top: 0;
    }

    .header {
        h1 {
            font-size: 16px;
            line-height: 26px;
            margin-bottom: 0;
        }
    }

    .content {
        margin-top: 24px;
        margin-bottom: 12px;
        max-height: 215px;

        &__title {
            font-size: 10px;
            line-height: 12px;
            font-weight: bold;
            text-transform: uppercase;
            margin-bottom: 12px;
        }

        &__subtitle {
            font-size: 12px;
            line-height: 16px;
            color: #747474;
            margin-top: 12px;
        }
    }

    .rs-modal-footer {
        border-top: 1px solid #EAEAEA;
        padding: 24px;

        button {
            text-transform: uppercase;
            font-weight: bold;
            padding: 8px 18px;
        }
    }
}

.modal-confirmacao-filtro {
    .rs-modal-content {
        padding: 24px;
    }

    .rs-modal-body {
        margin-top: 0;
    }

    .header {
        display: table;

        h1 {
            font-size: 14px;
            line-height: 18px;
            margin-bottom: 8px;
            font-weight: bold;
        }

        p {
            font-size: 14px;
            line-height: 18px;
        }

        &__icon,
        &__content {
            display: table-cell;
        }

        &__icon {
            padding-right: 15px;
            position: relative;
            top: 10px;

            i {
                color: #F29423;
                font-size: 22px;
            }
        }
    }

    .rs-modal-footer {

        button {
            text-transform: uppercase;
            font-weight: bold;
            padding: 8px 18px;
        }
    }
}

.nova-lista-copy-notification {
    .rs-notification-item-content {
        padding-right: 30px;

        .rs-notification-description {
            margin-top: 0;
        }
    }
}
