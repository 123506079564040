.ira-home-page {
    .rs-flex-box-grid-item {
        margin-bottom: 0;
    }

    .rs-content {
        border-radius: 6px;
        margin: 24px !important;
        margin-right: 12px !important;
    }

    .rs-icon {
        font-size: 32px;
        height: 32px;
    }

    .rs-content.no-margin-bottom {
        margin-bottom: 0 !important;
    }

    .page-header {
        height: 58px;
        padding: 16px !important;

        h2 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 26px;
            color: #323232;
        }
    }

    .item-card {
        cursor: pointer;

        &.disabled {
            opacity: 0.6;
            cursor: not-allowed;
        }

        .rs-badge {
            font-size: 10px;
        }

        i {
            margin-right: 16px;
            color: #0D66D0;
            margin-top: 5px;
            font-size: 24px;
        }

        h3 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 26px;
            color: #323232;
            margin-bottom: 8px;
        }

        p {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #323232;
        }
    }
}
