@import './mixins.scss';

$spacing-lg: 10px 16px 10px 16px;
$height-lg: 42px;

$spacing-md: 8px 12px 8px 12px;
$height-md: 36px;

$spacing-sm: 6px 10px 6px 10px;
$height-sm: 30px;

$spacing-xs: 4px 8px 4px 8px;
$height-xs: 24px;

$spacings: (
    'large' $spacing-lg $height-lg,
    'medium' $spacing-md $height-md,
    'small' $spacing-sm $height-sm,
    'xsmall' $spacing-xs $height-xs
);

.btn-primary {
    padding: 0px;
    margin: 0px;
    border-style: solid;
    border-width: 1px;
    display: flex;
    align-items: center;
    gap: 10px;
    overflow: hidden;
    border-radius: 4px;
    transition: var(--base-transition);
    text-transform: uppercase;

    @each $name, $spacing, $height in $spacings {
        &-#{$name} {
            padding: $spacing;
            height: $height;
        }
    }

    &:disabled {
        opacity: 0.4;
        pointer-events: none;
        cursor: not-allowed;
    }

    &-full-width {
        width: 100%;
        justify-content: center;
    }
}
