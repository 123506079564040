$engine-warning-orange-ascent: var(--orange-400);
$engine-warning-orange-secondary: #fdefde;

$engine-warning-blue-ascent: var(--blue-400);
$engine-warning-blue-secondary: #f5f9fe;

$engine-warning-red-ascent: var(--red-400);
$engine-warning-red-secondary: #fef7f7;

$clrs: (
    'orange' $engine-warning-orange-ascent $engine-warning-orange-secondary,
    'blue' $engine-warning-blue-ascent $engine-warning-blue-secondary,
    'red' $engine-warning-red-ascent $engine-warning-red-secondary
);

.engine-warning {
    display: flex;
    align-items: center;
    gap: 24px;
    flex-shrink: 0;
    padding: 8px 16px;
    border-radius: 6px;
    border-width: 2px;
    border-style: solid;
    font-weight: 600;

    @each $name, $ascent, $secondary in $clrs {
        &-skin-#{$name} {
            border-color: $ascent;
            background: $secondary;
            i,
            svg {
                color: $ascent;
            }
        }
    }
}

.message-container {
    display: flex;
    align-items: center;
    gap: 8px;
    margin: auto;

    i {
        font-size: 16px;
    }

    p {
        color: var(--gray-900);
    }

    button {
        margin-inline-start: 8px;
    }
}
