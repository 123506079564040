.modal-simulation-result {
    :global(.rs-modal-body) {
        padding-bottom: 10px !important;
    }
}

.product-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border-radius: 6px;
    border: 1px solid #eaeaea;
}

.product-label {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
}

.product-name {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
}

.input-label {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}

.form {
    display: flex;
    gap: 12px;
    padding: 8px;
    border-radius: 6px;
    border: 1px solid #f4f4f4;
    background: #f4f4f4;
}

.input-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

}


