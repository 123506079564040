.modal-no-access-infopanel {
    text-align: center;
    max-width: 415px;

    .description {
        max-width: 275px;
        display: inline-block;

        .icon-itens {
            font-size: 20px;
            margin-right: 10px;
        }

        .itens {
            margin-bottom: 5px;
        }
    }


    .subtitle {
        font-size: 15px;
        font-weight: 500;
        margin-bottom: 15px;

    }

    .rs-modal-footer {
        text-align: center;

        button {
            font-weight: 600;
            text-transform: uppercase;
            max-width: 275px;
            display: inline-block;
        }
    }
}
