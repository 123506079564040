.column {
    background: #ffffff;
    border-radius: 6px;
    padding: 12px;
}

.header {
    padding: 8px;
}

.title {
    font-size: 16px;
    font-weight: 600;
    color: #323232;
    margin-bottom: 8px;

    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;

    :global(.dash) {
        margin: 0 4px;
    }
}

.middle-subtitle,
.bottom-subtitle,
.subheader-subtitle {
    font-size: 12px;
    font-weight: 400;
    color: #747474;

    display: flex;
    justify-content: center;
    align-items: center;
}

.middle-subtitle {
    margin-bottom: 5px;

    :global(.days) {
        margin: 0 16px;
    }
}

.bottom-subtitle {
    :global(.competitor) {
        margin-right: 4px;
    }
}

.subheader {
    border: 1px solid #eaeaea;
    border-radius: 6px;
    padding: 8px;
    margin-top: 8px;
}

.subheader-title, .subheader-subtitle {
    display: flex;
    justify-content: center;
    align-items: center;
}

.subheader-title {
    font-size: 14px;
    font-weight: 600;

    :global(.dash) {
        margin: 0 4px;
    }
}

.subheader-subtitle {
    :global(.chevron) {
        margin: 0 4px;
    }
}

.forecast-box {
    margin-top: 8px;
}

.highlight-box {
    margin-top: 8px;
    border: 2px solid #E663C9;
}

.btn-wrapper {
    display: flex;
    justify-content: center;
    margin: 16px 0 8px 0;
    button {
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
    }
}
