.campanhas-page {

    .secao-busca {
        .rs-btn-primary {
            font-weight: 700 !important;
        }
    }

    .rs-content.secao-busca {
        flex: none;
        margin: 24px !important;
        border-radius: 6px;

        .campanhas-title {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            color: #323232;
        }

        .campanhas-busca {
            i {
                color: #1473E6;
            }
        }

        .rs-flex-box-grid-item {
            margin-bottom: 0;
        }
    }

    .rs-content.secao-tabela {
        margin: 0 24px 24px 24px !important;
        border-radius: 6px;
    }

    .campanhas-filtros {
        border: 1px solid #EAEAEA;
        border-radius: 6px;
        padding: 16px;
        gap: 6px;

        .rs-flex-box-grid-item {
            margin-bottom: 0;
        }

        .filtro-botao {
            background-color: #F5F5F5;
            border-color: #F5F5F5;
            border-radius: 18px;
            color: #505050;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;

            .rs-icon {
                margin-right: 0;
                margin-left: 8px;
            }

            &.active {
                background-color: #F5F9FE;
                color: #0D66D0;
                display: flex;
                align-items: center;

                .rs-badge {
                    margin-right: 4px;
                    font-weight: 700;
                }

                .rs-icon-close {
                    font-size: 10px;
                }
            }
        }

        .filtro-botao:hover {
            background-color: #EAEAEA;

            &.active {
                background-color: #F5F9FE;
                color: #1473E6;
            }
        }

        .filtros-container {
            gap: 6px;
        }
    }
}


.listagem-produtos {

    a:hover {
        text-decoration: none !important;
        // background-color: #F5F9FE;
    }
}


.rs-dropdown-menu {
    max-height: 350px;
    overflow: auto;
}


.campanhas-table-container {
    border: 1px solid #EAEAEA;
    border-radius: 6px;

    .table {
        .CURRENT {
            background: #268E6C;
            height: 18px;
            color: #fff;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 10px;

            .MuiChip-iconSmall {
                height: 12px;
                width: 12px;
            }
        }

        .DRAFT {
            background: #DA7B11;
            height: 18px;
            color: #fff;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 10px;

            .MuiChip-iconSmall {
                height: 12px;
                width: 12px;
            }
        }

        .SCHEDULED {
            background: #378EF0;
            height: 18px;
            color: #fff;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 10px;

            .MuiChip-iconSmall {
                height: 12px;
                width: 12px;
            }
        }

        .CLOSED {
            background: #959595;
            height: 18px;
            color: #fff;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 10px;

            .MuiChip-iconSmall {
                height: 12px;
                width: 12px;
            }
        }

        &__edit-button {
            padding: 0;
            text-align: center;
            display: table-cell;
            vertical-align: middle;

            .rs-btn-icon {
                margin: 0px 5px;
            }

            .rs-table-cell-content {
                padding: 5px;
            }
        }
    }
}

.rs-content.cadastro-campanhas-header {
    margin: 0 24px 24px 24px !important;

    .rs-flex-box-grid {
        gap: 16px;

        .DRAFT {
            background: #DA7B11;
            color: #fff;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 10px;
            padding: 14px;

            .MuiChip-iconSmall {
                margin-left: 8px;
            }
        }

        .CURRENT {
            background: #268E6C;
            color: #fff;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 10px;
            padding: 14px;

            .MuiChip-iconSmall {
                margin-left: 8px;
            }
        }

        .SCHEDULED {
            background: #378EF0;
            color: #fff;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 10px;
            padding: 14px;

            .MuiChip-iconSmall {
                margin-left: 8px;
            }
        }

        .CLOSED {
            background: #959595;
            color: #fff;
            text-transform: uppercase;
            font-weight: 700;
            font-size: 10px;
            padding: 14px;

            .MuiChip-iconSmall {
                margin-left: 8px;
            }
        }

        h3 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            color: #323232;
            line-height: normal;
        }

        button {
            padding: 0;
        }
    }

    .datas-campanha {
        display: flex;
        flex-direction: column;
        align-items: center;

        .datas-vigencia {
            background: #F5F9FE;
            border-radius: 6px;
            padding: 8px 16px;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 300;
            font-size: 24px;
            line-height: 33px;
            color: #323232;
            margin-bottom: 8px;
        }

        .vigencia-info {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #323232;
        }
    }

    .blocos-oferta {
        display: flex;
        flex-direction: column;
        align-items: center;

        .total-blocos {
            background: #F5F9FE;
            border-radius: 6px;
            padding: 8px 16px;
            width: 144px;
            text-align: center;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 300;
            font-size: 24px;
            line-height: 33px;
            color: #323232;
            margin-bottom: 8px;
        }

        .blocos-info {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #323232;

            .rs-icon {
                color: #378EF0;
                cursor: pointer;
            }

        }
    }
}
