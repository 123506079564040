.criar-senha-page {
    .rs-col {
        padding: 0 !important;
    }

    .invalid-input {
        border-color: red !important;
        border-width: 2px !important;
    }

    .rs-help-block {
        color: red;
    }

    input {
        border: 1px solid #e5e5ea !important;
        border-top: 1px solid #e5e5ea !important;
        border-right: 1px solid #e5e5ea !important;
        border-left: 1px solid #e5e5ea !important;
        border-radius: 6px !important;
    }

    .rs-input-group {
        input {
            border: none !important;
        }

        input.invalid-input {
            border: 1px solid !important;
            border-color: red !important;
            border-width: 2px !important;
        }
    }

    .form-area {
        height: 100vh;
        display: table;


        .new-account-form {
            padding: 0 190px;
            display: table-cell;
            vertical-align: middle;

            @media screen and (max-width:600px) {
                padding: 0 100px;
            }


            h4 {
                font-size: 24px;
                margin-bottom: 31px;
            }

            .instructions {
                margin: 30px 0;
                font-size: 14px;

                p {
                    margin-bottom: 15px;
                }

                ul {
                    padding-left: 15px;
                }
            }

            button {
                text-transform: uppercase;
                font-weight: 700;
            }

            .invalid-input {
                border-color: red !important;
                border-width: 2px !important;
            }

            .rs-help-block {
                color: red;
            }

            .link-login {
                text-align: center;
            }
        }
    }
}