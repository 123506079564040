.wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 1px solid #eaeaea;
    padding: 16px;
}

.row {
    display: flex;
    align-items: stretch;
    gap: 8px;
    flex-wrap: wrap;
}

.filter-button {
    border-radius: 18px;
    background: #f5f5f5;
}

.clear-button {
    @extend .filter-button;
    margin-inline-start: auto;
    grid-area: clearBtn;
}
