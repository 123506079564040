.row {
    display: flex;
    gap: 16px;
}

.input-group {
    width: 100%;

    :global(.rs-input) {
        margin-top: 8px;
    }
}

.select-group {
    display: flex;
    flex-direction: column;
    width: 40%;

    :global(.rs-picker-select) {
        margin-top: 8px;
    }
}

.input-group-tax {
    width: 20%;
    :global(.rs-input-group) {
        margin-top: 8px;
    }
}

.input {
    border: 1px solid #e5e5ea !important;
    border-radius: 6px !important;
}

.currency-input {
    border: none !important;
    height: 34px;
}
