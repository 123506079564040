.recuperar-senha-page {
    .rs-col {
        padding: 0 !important;
    }

    input {
        border: 1px solid #e5e5ea !important;
        border-top: 1px solid #e5e5ea !important;
        border-right: 1px solid #e5e5ea !important;
        border-left: 1px solid #e5e5ea !important;
        border-radius: 6px !important;
    }

    .form-area {
        height: 100vh;
        display: table;


        .recovery-form {
            padding: 0 190px;
            display: table-cell;
            vertical-align: middle;

            @media screen and (max-width:600px) {
                padding: 0 100px;
            }


            h4 {
                font-size: 24px;
                margin-bottom: 31px;
            }

            button {
                text-transform: uppercase;
                font-weight: 700;
            }

            .invalid-input {
                border-color: red !important;
                border-width: 2px !important;
            }

            .rs-help-block {
                color: red;
            }

            .link-login {
                text-align: center;
            }
        }
    }


}