.label-wrapper {
    width: 160px;
    margin: 0 12px 0 0;
}

.label {
    font-size: 12px;
}

.label-description-wrapper {
    display: flex;

    img {
        width: 10px;
        margin-left: 4px;
    }
}

.label-description {
    font-size: 10px;
    font-weight: 400;
    color: #747474;
    margin: 0;
}
