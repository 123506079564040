.flex-item {
    display: flex;
    align-items: center;
    gap: 8px;
}

.container ul[role='menu'] {
    > li:first-child {
        padding: 12px;
        border-bottom: 1px solid var(--gray-300, #eaeaea);
    }

    > li:nth-child(2) {
        p {
            padding: 12px 12px 4px 12px;
        }
        :global(.rs-checkbox-checker) {
            padding-block: 12px;
        }
        :global(.rs-checkbox-wrapper::after) {
            top: -12px;
            right: -12px;
            bottom: -12px;
            left: -12px;
        }
    }
}

.the-gray-one {
    border-color: var(--gray-600) !important;
    color: var(--gray-600) !important;
}

:global(.rs-input-group.rs-input-number) {
    width: 80px !important;
}
