@import '../../../../../../../../style/abstracts/variables.scss';

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 12px;
    padding: 12px;

    background-color: #fff;
    border-radius: 6px;

    .icon-button {
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 8px;
        border-radius: 4px;

        & > svg {
            width: 20px;
            height: 20px;
        }

        &:not(:disabled) {
            // & > svg {
            color: #959595;
            // }
        }
    }

    .buttons {
        display: flex;
        gap: 8px;
    }

    .main-button {
        display: flex;
        align-items: center;
        // gap: 10px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 14px;
        color: #fff;
    }
}
