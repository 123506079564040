.store-competitors-page {
    .tabs-navigation {
        margin-top: 0 !important;
        flex: none;
        border-radius: 6px;

        .rs-nav {
            margin-bottom: -20px !important;

            .rs-nav-waterline {
                display: none;
            }



            .rs-nav-item:nth-child(2) {
                margin-left: 12px;
                margin-right: 46px;
            }

            .coming-soon {
                border-width: 1px 1px 0px 1px;
                border-style: solid;
                border-color: #EAEAEA;
                border-radius: 2px 2px 0px 0px;
                position: relative;

                .rs-nav-item-content {
                    position: initial !important;
                }

                .coming-soon-badge {
                    position: absolute;
                    display: flex;
                    align-items: center;
                    width: 65px;
                    height: 14px;
                    background: #2680EB;
                    border-radius: 15px;
                    text-transform: uppercase;
                    font-weight: 700;
                    font-size: 10px;
                    padding: 1px 8px;
                    color: #fff;
                    top: -8px;
                    right: -32px;
                }
            }
        }
    }

    .selected-store {
        margin-top: 0 !important;
        margin-bottom: 32px;
        border-radius: 6px;

        .header-wrapper {
            padding: 18px;
            border: 1px solid #EAEAEA;
            border-radius: 6px;
            margin-bottom: 14px;

            .title-wrapper {
                display: flex;
                margin-bottom: 14px;

                img {
                    margin-right: 10px;
                }

                .selected-store-title {
                    font-size: 12px;
                    font-weight: 700;
                    text-transform: uppercase;
                    color: #323232;
                    margin-right: 8px;
                }

                .selected-store-subtitle {
                    font-size: 12px;
                    color: #505050;
                    margin: 0;
                }
            }

            .name-wrapper {
                display: flex;

                .name-badge {
                    padding: 10px;
                    min-width: 128px;
                    background: #F5F9FE;
                    border-radius: 6px;

                    .badge-label {
                        font-size: 12px;
                        color: #959595;
                        text-transform: uppercase;
                        margin-right: 12px;
                    }

                    .store-name {
                        font-size: 16px;
                        color: #323232;
                    }

                    &:nth-child(2) {
                        margin-right: 20px;
                        margin-left: 8px;
                    }
                }

                .detail-button {
                    text-decoration: none;

                    img {
                        margin-right: 12px;
                    }
                }
            }

            .detail-panel {
                border-top: 1px solid transparent;
                margin-top: 0;
                padding-top: 0;
                overflow: hidden;
                transition: all .3s ease-in-out;
                height: 0px;
                opacity: 0;

                .col-title {
                    font-size: 10px;
                    font-weight: 700;
                    color: #959595;
                    text-transform: uppercase;
                }

                .col-value {
                    font-size: 14px;
                    font-weight: 400;
                    color: #323232;

                    .copy-button {
                        text-decoration: none;
                        padding: 4px 2px;
                        margin-left: 8px;

                        img {
                            vertical-align: baseline !important;
                        }
                    }
                }
            }

            .detail-panel.active {
                opacity: 1;
                border-top: 1px solid #EAEAEA;
                margin-top: 16px;
                padding-top: 20px;
                height: 100%;
            }

        }
    }

    .store-location-map {
        border-radius: 6px;
        margin-top: 0 !important;

        .header-title {
            font-size: 14px;
            font-weight: 600;
            color: #323232;
            margin-bottom: 18px;
        }


        .map-wrapper {
            padding: 18px;
            border: 1px solid #EAEAEA;
            border-radius: 6px;



            form {
                margin-bottom: 20px;

                .rs-picker-default {
                    margin-right: 8px;
                }

                .picker-pill-wrapper {
                    &:not(:last-child) {
                        margin-right: 8px;
                    }
                }
            }

            .quantity-limit-message {
                background: #FEF9F4;
                color: #DA7B11;
                padding: 8px 16px;
            }

            .map-content {
                position: relative;

                .choose-store-message {
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    transform: translate(-50%, -30%);
                    z-index: 1;
                    padding: 10px;
                    background: #F5F9FE;
                    border-radius: 4px;

                    img {
                        margin-right: 8px;
                        margin-bottom: 2px;
                    }
                }

                .search-area-btn {
                    z-index: 1;
                    position: absolute;
                    top: 50px;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    background: #fff;
                    border-radius: 50px;
                    color: #505050;

                    img {
                        margin-right: 12px;
                    }
                }

                .cluster-store {
                    position: absolute;
                    top: 8px;
                    left: 34px;
                    font-size: 10px;
                }

                .gm-style-iw-tc {
                    display: none;
                }

                .gm-style-iw-c {
                    left: 30px;
                    transform: none;
                }

                .tooltip-button {
                    font-weight: 400;
                    cursor: pointer;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }

        }

        .search-input {
            border: none;
            width: 100%;

            .rs-picker-toggle-caret {
                display: none;
            }
        }

        .search-input-loading {
            position: absolute;
            top: 7px;
            right: 2%;
        }
    }

    .title {
        font-size: 18px;
        font-weight: 600;
        color: #323232;
    }

    .subtitle {
        font-size: 14px;
        color: #747474;
    }

    .content-wrapper {
        position: relative;
    }

    .block-plan {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: absolute;
        height: 100%;
        width: 100%;
        background: rgba(55, 142, 240, 0.2);
        top: 0;
        z-index: 10;

        .block-plan-icon-circle {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 96px;
            height: 96px;
            background: #EBF4FE;
            border-radius: 60px;
            margin-bottom: 24px;

            img {
                height: 63px;
            }
        }

        p {
            font-weight: 600;
            font-size: 20px;
            color: #ffffff;
            margin: 0;
        }

        button {
            margin-top: 24px;
            font-weight: 700;
            font-size: 16px;
            text-transform: uppercase;
        }
    }

}


.reference-store-autocomplete-menu {
    border-radius: 0px 0px 6px 6px !important;
    margin-top: 1px !important;
    padding-top: 0px !important;

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(204, 204, 204, 0.603);
        background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
        width: 6px;
        background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #ccc;
    }

    .menu-item {
        &__alias {
            font-size: 14px;
            font-weight: 400;
            line-height: 20px;
        }

        &__address {
            color: #959595;
            text-overflow: ellipsis;
            font-size: 12px;
            font-weight: 400;
            line-height: 18px;
        }

    }

    .rs-picker-none {
        display: none;
    }
}