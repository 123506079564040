.body {
    padding: 12px 0;
    margin: 0;
}

.title-wrapper {
    padding: 4px 0;
}

.bold-title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 10px;
}

.answer {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
}

.bold {
    font-weight: 700;
}

.panel {
    :global(.rs-panel-heading) {
        background: #f5f5f5;
        border: 1px solid #eaeaea;
        height: 44px;
        padding: 10px 20px;

        :global(.rs-panel-title) {
            font-size: 14px;
            font-weight: 600;
            color: #505050;
        }

        &::before {
            top: 13px;
        }
    }
}

.available-ufs-table {
    border-radius: 6px;
}
