@import '../../style/typography.scss';

.link {
    @extend .font-size-50-regular;
    --text-color: var(--blue-400, #378ef0);

    &:hover {
        text-decoration: none;
    }
}
