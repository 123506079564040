.top-prices-chart {
    border-radius: 5px;
    margin: 0 20px 20px 10px !important;

    @media screen and (max-width:769px) {
        margin: 0 !important;
    }

    .header-area {
        margin-bottom: 15px;

        .rs-flex-box-grid-item {

            &.minimized {
                margin-bottom: 0 !important;
            }
        }

        h4 {
            display: inline;
            text-transform: uppercase;
        }

        &__info {
            margin-left: 10px;

            .rs-icon {
                color: #bdbdbd;
                position: relative;
                top: -5px;
            }
        }
    }

    .more-options-section,
    .expand-section {
        text-align: end;

        button {
            &:focus {
                background-color: unset;
            }
        }

        &__btn {
            padding: 0 20px !important;
        }
    }

    .select-product-section {
        .title-select-product-input {
            font-size: 12px;
            font-weight: 600;
        }

        .select-product-input {
            max-width: 300px;
            width: 300px;

            @media screen and (max-width:600px) {
                width: 100%;
            }

            .rs-picker-toggle {
                border-radius: 6px !important;
            }
        }
    }

    .empty-state {
        text-align: center;

        h4 {
            font-size: 20px;
            color: rgba(117, 117, 117, 1);
            margin-bottom: 10px;
        }

        p {
            margin-bottom: 34px;
            color: rgba(117, 117, 117, 1);
        }
    }

    .subtitle {
        text-transform: uppercase;
        font-size: 12px;
        padding-left: 30px;
        font-weight: 600;

        @media screen and (max-width:600px) {
            padding-left: 5px;
        }
    }
}