.limite-preco-cadastro-page {
    .rs-picker-toggle,
    .rs-input,
    .rs-tag-group {
        border: 1px solid #e5e5ea !important;
        border-radius: 5px !important;
    }

    .rs-input-group {
        .rs-input {
            border: 0 !important;
        }

        .rs-input.form__input_invalid{
            border: 2px solid red !important;
        }

        &:hover,
        &:active,
        &:focus {
            border-color: #e5e5ea !important;
        }
    }

    .rs-input-group-focus {
        border-color: #e5e5ea !important;
    }

    .panel {
        margin-bottom: 20px;
        &__item {
            margin-right: 10px;
        }

        &__label-div {
            font-size: 15px;
        }

        &__input-div {
            margin-right: 10px;
        }

        &__section{
            &_margin-top{
                margin-top: 25px;
            }
            .rs-flex-box-grid-item{
                margin-bottom: 0 !important;
            }
        }
    }

    .toggle{
        margin-right: 15px;
    }

    .form {
        &__message {
            &_invalid {
                color: red;
            }
        }

        &__input {
            &_invalid {
                border-color: red !important;
                border-width: 2px !important;

                &:hover,
                &:active {
                    border-color: red !important;
                    border-width: 2px;
                }
            }
        }
    }

    .product-family {
        display: flex;
        align-items: center;
        span {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #323232;
            margin-left: 8px;
            margin-right: 6px;

            strong {
                font-weight: 600;
            }
        }
    }

    .buttons__wrapper{
        >div{
            margin-left: 15px;
            &:first-child{
                margin-left: 0;
            }
        }
    }
}
