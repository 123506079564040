.saved-filter-content {
    margin: 0 20px 12px !important;
    padding: 12px !important;
    border-radius: 6px;
    &:global(.rs-content) {
        flex: none;
    }
}

.in-flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #EAEAEA;
    padding: 12px;
    border-radius: 6px;
}

.flex {
    display: flex;
    align-items: center;

    :global(.rs-picker-select) {
        &:not(:last-child) {
            margin-right: 8px;
        }
    }

    :global(.picker-pill-wrapper) {
        &:not(:last-child) {
            margin-right: 8px;
        }
    }
}

.btn-clear-filters {
    border-radius: 18px;
    margin-left: 32px;
}

.btn-apply-filters {
    font-weight: 700;
    text-transform: uppercase;
}
