@import '../../../../style/abstracts/variables.scss';

.simulation-area-content {
    margin: 0 20px 12px !important;
    padding: 12px !important;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.simulation-numbers-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}

.simulation-numbers {
    display: flex;
    align-items: center;
    justify-content: center;

    &:not(:last-child) {
        margin-right: 16px;
    }
}

.title {
    font-size: 16px;
    font-weight: 600;
    color: #323232;
    margin-bottom: 5px;
}

.label, .value {
    font-size: 12px;
    color: #747474;
    margin: 0;
}

.value {
    font-weight: 600;
}

.label {
    font-weight: 400;
    margin-right: 4px;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin-bottom: 5px;
    font-size: 12px;

    button {
        text-decoration: none !important;
        padding: 0;
        margin-left: 12px;
    }
}

.title-scenario-name {
    font-size: 16px;
    font-weight: 600;
    color: #323232;
}
