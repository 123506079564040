.cadastro-ofertas-page {

    .rs-btn-primary,
    .rs-btn-ghost {
        font-weight: 700;
    }

    .rs-content {
        flex: none;
        border-radius: 6px;
        margin: 24px;
    }

    .rs-flex-box-grid-item {
        margin-bottom: 0;
    }

    .rs-content.ofertas {
        margin: 0 24px 24px 24px !important;

        h3 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            color: #323232;
            line-height: normal;

            i {
                color: #378EF0;
                margin-left: 10px;
            }
        }

        .ofertas-container {
            margin-top: 16px;

            .campanhas-filtros {
                margin-bottom: 16px;
            }

            .no-data {
                background: #F5F9FE;
                border-radius: 4px;
                padding: 16px;
                flex-direction: column;
                gap: 16px;

                p {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    color: #323232;
                }
            }
        }
    }

    .rs-content.navegacao-ofertas {
        margin: 0 24px 24px 24px !important;

        .rs-btn-link,
        .rs-btn-primary {
            display: flex;
            align-items: center;

            .MuiSvgIcon-root {
                margin-right: 15px;
                font-size: 20px;
            }
        }
    }
}

.rs-modal-body {
    max-height: 100% !important;

    .rs-flex-box-grid-item {
        margin-bottom: 0;
    }
}

.produtos-busca {
    .rs-input {
        border: 1px solid #e5e5ea !important;
        border-radius: 5px !important;
        padding: 11px;
        padding-left: 46px !important;
    }

    .rs-input:focus,
    .rs-input:hover {
        border-color: #378EF0 !important;
        outline: 0;
    }

    i {
        color: #1473E6;
        padding-top: 4px;
        padding-left: 6px;
    }
}

#select-bloco-oferta {
    padding: 12px;
    border: 1px solid #e5e5ea !important;

    :hover {
        border-color: #378EF0 !important;
    }
}

.bloco-ofertas-select.rs-picker-input {
    border: 1px solid #e5e5ea !important;
    border-radius: 5px !important;
    margin-right: 0;
    padding: 4px 0;

    &.rs-picker-input:focus,
    &.rs-picker-input:hover {
        border-color: #378EF0 !important;
        outline: 0;
    }

    i {
        color: #1473E6;
        padding-top: 4px;
        padding-left: 6px;
    }
}

.produtos-container {
    margin-top: 16px;

    .no-data {
        background: #F5F9FE;
        border-radius: 4px;
        padding: 32px;

        p {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #323232;
        }
    }

    .oferta-container {
        p {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            text-transform: uppercase;
            color: #323232;
            margin-bottom: 16px;
        }

        .rs-flex-box-grid-item {
            margin-bottom: 24px;
        }

        .tipo-oferta-container.rs-flex-box-grid-item {
            margin-bottom: 0;

            .rs-flex-box-grid-item {
                margin-bottom: 0;
            }
        }

        .tipo-oferta-container {
            .tipo-oferta {
                margin-bottom: 20px;
            }
        }

        .grupo-precos {
            flex-wrap: nowrap;

            .rs-flex-box-grid-item:first-child {
                margin-right: 12px;

                i {
                    margin-top: 24px;
                }
            }

            .rs-flex-box-grid-item {
                margin-right: 17px;
            }

            .rs-flex-box-grid-item:last-child {
                margin-right: 0;
            }

            .label {
                font-weight: bold;
                font-size: 12px;
            }
        }
    }
}

.rs-dropdown-menu {
    overflow-x: hidden;
}

.listagem-produtos-ofertas {
    width: 850px;
    max-height: 180px;
    top: 185px !important;

    span {
        padding: 10px 12px;
    }

    .arrow {
        display: none !important;
    }

    a:hover {
        text-decoration: none !important;
        // background-color: #F5F9FE;
    }
}

.alert-icon {
    color: #EC5B62;
    margin-right: 4px;
}

.modal-offer-campaign {
    .campaign-message {
        margin-bottom: 46px;

        span {
            font-weight: bold;
        }
    }

    .campaign-confirm-question {
        margin-bottom: 32px;
    }

    .campaign-price-info {
        display: flex;
        align-items: center;
        padding: 10px;
        background: #F5F9FE;
        font-size: 12px;
        border-radius: 6px;

        img {
            margin-right: 10px;
        }

        span {
            font-weight: bold;
        }
    }

    .rs-modal-body {
        padding-bottom: 0;
    }
}
