.modal-container {
    width: 650px;
    :global(.rs-modal-content) {
        padding: 0;

        :global(.rs-modal-footer) {
            padding: 24px;
            border-top: 1px solid #eaeaea;

            button {
                font-weight: 700;
                text-transform: uppercase;
            }
        }

        :global(.rs-modal-header) {

            button {
                z-index: 9;
            }
        }
    }
}

.address-first-section {
    display: flex;
    margin-top: 12px;
    justify-content: space-between;
}

.alert-input {
    border: 1px solid #EC5B62 !important;
    border-radius: 6px !important;

    &:hover {
        border: 1px solid #EC5B62 !important;
    }
}
