.inf-select-tag {

    &.rs-picker-input {
        margin: 0 !important;
        border: 1px solid #bcbcbc !important;
        border-radius: 4px !important;
        height: 40px;
        white-space: nowrap;
        overflow: hidden;
    }

    .rs-tag {
        border: 1px solid #378EF0;
        border-radius: 15px;
        background-color: #ffffff;
        color: #378EF0;
        margin-top: 8px !important;
        height: 22px !important;
        line-height: 1.4;
    }

    .rs-picker-toggle-clean, .rs-picker-toggle-caret {
        display: none;
    }
}

.inf-select-tag-menu {
    .rs-picker-check-menu-items {
        label {
            padding: 8px 12px !important;
            .rs-checkbox-wrapper {
                display: none;
            }
        }
    }
}
