@import '../../../style/typography.scss';

.container {
    --text-color: var(--gray-500);
    color: var(--text-color);
    padding: 8px 12px;
    display: flex;
    align-items: center;
    gap: 8px;

    p {
        @extend .font-size-100-regular;
    }
}

.loadingContainer {
    position: relative;
    margin-inline-start: 8px;
}
