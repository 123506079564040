@import '../../../../style/abstracts/variables.scss';

.view-results-area-content {
    margin: 0 20px 12px !important;
    padding: 12px !important;
    border-radius: 6px;
    display: flex;
}

.product-title {
    font-size: 16px;
    font-weight: 600;
    color: #323232;
    margin: 0;
}

.product-subtitle {
    font-size: 12px;
    font-weight: 400;
    color: #747474;
    margin: 0;
}

.product-origin {
    display: flex;
    align-items: center;
}

.side-arrow {
    margin: 0 4px;
}

.buttons-wrapper {
    display: flex;
    align-items: center;
    margin-left: auto;
}

.gray-dropdown-button {
    background: #eaeaea;
    border-radius: 4px;
    padding: 8px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
}

.expand-icon {
    color: #959595;
    width: 20px !important;
    margin-left: 6px;
}

.save-icon {
    color: #959595;
    width: 20px !important;
}

.result-button {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 8px 12px;
}
