.product-elasticity-box {
    display: flex;
    min-width: 320px;
    width: 320px;
    height: 254px;
    padding: 8px;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;
    background: #F5F9FE;
    margin-right: 12px;
}

.title {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
}

.main-text {
    font-size: 14px;
    font-weight: 600;
    color: #323232;
    margin: 0;
}

.regular-text {
    font-size: 12px;
    font-weight: 400;
    color: #323232;
    margin: 0;
    text-align: center;
    margin-top: 4px;
}

.gauge-chart-container {
    width: 100%;
    height: fit-content;
    margin-top: -40px;

    :global(.highcharts-credits) {
        display: none !important;
    }
}

.bottom-text-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75%;
    margin-top: -12px;
}
