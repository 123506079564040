.store__stores__section {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
}

.store__stores__loading__container {
    width: 100%;
    height: 84px;
    display: grid;
    place-content: center;
}
