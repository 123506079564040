.container {
    border: 1px solid #eaeaea;
    border-radius: 6px;
    padding: 12px;
    width: 226px;
}

.title {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 16px;
}

.footer {
    padding: 16px 0 4px 0;
    border-top: 1px solid #eaeaea;
}

.total-text {
    font-size: 12px;
    font-weight: 600;
}

.total-value {
    font-size: 14px;
    font-weight: 600;
}
