.administrador-usuario-page-modal {
    .form {
        &__message {
            &_invalid {
                color: red;
            }
        }

        &__input {
            &_invalid {
                border-color: red !important;
                border-width: 2px !important;

                &:hover,
                &:active {
                    border-color: red !important;
                    border-width: 2px;
                }
            }
        }
    }

    .rs-auto-complete {
        .rs-picker-search-input {
            padding-left: 0;
            padding-bottom: 0;
        }
    }
}

.administrador-usuario-page {
    padding-top: 40px;

    .MuiGrid-item {
        padding: 0 20px;
    }

    .MuiFormLabel-root,
    .MuiInputBase-root {
        font-size: 14px;
    }

    .MuiInputBase-root:hover {
        border-color: #323232;
    }

    .back-btn {
        text-transform: none;
    }

    .page-title {
        margin-bottom: 15px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        color: #323232;
    }

    .btn-add-user {
        text-transform: uppercase;
        font-weight: bold;
    }

    .rs-loader-wrapper {
        top: 15%;
    }

    .rs-loader {
        height: 80vh;
    }

    .actions {
        .rs-table-cell-content {
            padding: 5px 10px;

            .option {
                display: inline-block;
                margin-right: 5px;
                cursor: pointer;
            }
        }
    }

}
