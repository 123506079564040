.info-radio {
    .rs-radio-checker {
        padding-top: 8px !important;
        padding-bottom: 8px !important;
        padding-left: 24px !important;
        display: flex;
        align-items: center;
    }

    .rs-radio-wrapper {
        left: 0px;
    }

    .rs-radio-wrapper::after {
        top: 8px !important;
        bottom: 8px !important;
        right: 8px !important;
        left: 8px !important;
    }

    &-is-selected {
        font-weight: 600;
        color: var(--gray-900, #323232);
    }
}
