.wrapper {
    --color-background: transparent;
    --color-border: transparent;
    --color-placeholder: var(--gray-900);
    --color-caret: var(--gray-500);

    [role='combobox'] {
        background: var(--color-background) !important;
        border-color: var(--color-border) !important;

        &:hover {
            background: var(--color-background) !important;
            border-color: var(--color-border) !important;
            --color-placeholder: var(--blue-600);
        }

        :global(.rs-picker-toggle-placeholder),
        .render-value {
            color: var(--color-placeholder);
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
            text-transform: lowercase;
        }

        :global(.rs-picker-toggle-caret) {
            color: var(--color-caret);
        }
    }
}
