.modal-edit-confirmation {
    :global(.rs-modal-body) {
        padding-bottom: 10px !important;
    }
}

.content {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        width: 80px;
        height: 80px;
        margin-bottom: 24px;
    }
}

.title {
    font-size: 16px;
    margin-bottom: 12px;
    text-align: center;
}

.subtitle {
    text-align: center;
}
