.context-menu {
    &__content {
        background-color: var(--white, #ffffff);
        border-radius: 6px;
        box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
            0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
        width: 228px;
        color: var(--gray-900, #323232);
        font-family: Open Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 18px;

        &:has(.context-menu__item) {
            padding: 12px;
        }
    }

    &__item {
        font: inherit;
        font-size: 12px;
        padding: 6px;
        text-align: start;
        cursor: pointer;
        transition: var(--base-transition, ease 250ms);
        border-radius: 4px;
    }

    &__separator {
        margin-block: 4px;
        border-bottom: 1px solid var(--gray-300, #eaeaea);
    }

    &__item[data-highlighted],
    &__checkbox-item[data-highlighted],
    &__radio-item[data-highlighted],
    &__sub-trigger[data-highlighted] {
        background-color: var(--gray-200, #eaeaea);
        border-color: transparent;
        outline: none;
    }
}
