.data-table {
    border-radius: 6px;
    margin: 20px 0 0 0 !important;

    :global(.rs-grid-container) {
        width: 100%;
    }

    :global(.rs-dropdown) {
        display: block;
        margin-bottom: 12px;

        :global(.rs-dropdown-toggle) {
            display: block;
        }
    }
}

.request-list-dropdown {
    .rs-btn {
        font-size: 12px;
        color: #747474;

        &:hover, &:focus {
            background-color: transparent !important;
        }
    }
}

.data-table-container {
    position: relative;
}

.bulk-action-buttons {
    display: flex;
    justify-content: space-between;
    position: absolute;
    top: 6px;
    right: 36px;
    z-index: 3;
    width: 92%;

    button:first-child {
        margin-right: 10px;
    }

    @media (max-width: 1280px) {
        width: 90%;
    }
}

.all-selection-wrapper {
    display: flex;
    align-items: center;

    button {
        padding: 0;
        text-decoration: none;
        margin: 0 8px;
    }
}

.title {
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 14px;
}

.empty-results-container {
    padding: 24px 16px 65px;
    text-align: center;
}

.empty-results-title {
    font-size: 20px;
    font-weight: 600;
}

.download-type {
    background-color: #fff;

    :global(.rs-picker-toggle) {
        border: none !important;
        font-size: 12px;
        width: auto;
    }
}
