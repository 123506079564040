@import '../../../../../../style/abstracts/variables.scss';

.select-pill-wrapper {
    display: flex;
}

.arrow-right-icon {
    margin: 0 15px;
}

.div-pill {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    height: 36px;
    background: $light-blue-5;
    border-radius: 18px;
    color: $blue-500;
}

.versus {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    color: $gray-400;
    margin: 0 15px;
}
