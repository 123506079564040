.cadastro-campanha-steps {
    margin-top: 24px;
    margin-bottom: 0;
    display: flex;
    list-style: none;
    gap: 2px;
    padding-left: 35px;

    li {
        padding: 8px 12px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        color: #747474;

        a {
            color: #747474;
            text-decoration: none;
        }
    }

    li.active {
        border-bottom: 2px solid #378EF0;
        color: #378EF0;
        a {
            color: #378EF0;
        }

    }

    li.disabled {
        color: #BCBCBC;
        a {
            color: #BCBCBC;
        }
    }
}

.currency {
    font-weight: 600;
}
