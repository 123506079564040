@mixin emphasis-text() {
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #323232;
}

.section-table {
    border-radius: 0.375rem;
    background: var(--white, #fff);

    .padding-inline-xxs {
        padding: 0 8px;
    }

    .text-header {
        flex-direction: column;

        margin-bottom: 16px;
        h3 {
            @include emphasis-text();
        }
    }

    .cell-wrapper {
        display: flex;
        flex-direction: column;

        .main-content {
            @include emphasis-text();
        }

        .secondary-content {
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 18px;
            color: #747474;
        }

        .highlight {
            border: 1px solid;
            border-radius: 6px;
            padding: 0 2px;
            width: 100px;
            height: 12px;
            margin-left: 4px;
        }
    }

    .icon-button {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f4f4f4;

        padding: 8px;
        border-radius: 4px;

        & > svg {
            width: 20px;
            height: 20px;
        }

        &:not(:disabled) {
            color: #959595;
        }
    }

    .loading {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 300px;
    }
}
