.warning{
    background-color: #f5c31d !important;
    z-index: 2 !important;
    position: fixed !important;
    width: 100% !important;
    top: 57px;
    margin-bottom: 4px;
    border-radius: 0 !important;

    .rs-message-container {
        padding: 7px 20px !important;
    }
}

.has-warning{
    .main-container{
        margin-top: 122px;
    }

    .rs-sidenav{
        margin-top: 65px;
    }
}