@import '../../style/mixins.scss';

.input-group {
    display: flex;
    align-items: center;
    border-radius: 4px !important;
    height: 36px;
    overflow: hidden;

    input[data-skin] {
        border: none !important;
        background: none !important;
    }

    @include input-skin();
}
