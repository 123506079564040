@import '../../style/typography.scss';
@import '../../style/app.scss';

.setup-menu {
    @extend .setup-menu__reset;
    @extend .font-size-100-semibold;
    @extend .card;
    --color: var(--gray-900, #323232);
    --bg-color: var(--white, #fff);
    transition: var(--base-transition);
    color: var(--color);

    ul {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        gap: 8px;
    }

    a {
        text-decoration: none;
        text-align: start;
        justify-content: space-between;
        align-items: center;
        gap: 4px;
        padding: 12px 8px 12px 12px !important;
        background: var(--bg-color);
        border-radius: 4px;
        width: 100%;
        transition: var(--base-transition);
        color: var(--color);

        &:not(.setup-menu__item-active) {
            &:hover {
                --bg-color: var(--gray-100, #f5f5f5);
            }
        }

        &:global(.active) {
            --color: var(--gray-800, #505050);
            --bg-color: var(--light-blue-5, #f5f9fe);
        }
    }
}

.setup-menu__reset {
    ul,
    li,
    a {
        padding: 0px;
        margin: 0px;
    }

    li {
        list-style: none;
    }

    :where(a) {
        background: transparent;
        display: inline-flex;
        font: inherit;
    }
}
