.context-menu {
    width: 228px;
    padding: 12px;
    background-color: white;
    border-radius: 6px;
    box-shadow: 0px 3px 6px -4px rgba(0, 0, 0, 0.12), 0px 6px 16px 0px rgba(0, 0, 0, 0.08), 0px 9px 28px 8px rgba(0, 0, 0, 0.05);

        &__options {
            padding: 6px;
            align-items: flex-start;
            gap: 10px;
            align-self: stretch;
            font-size: 12px;
            cursor: pointer;
            margin-bottom: 0px;
        }

}
