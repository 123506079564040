@import '../../../../../../style/abstracts/variables.scss';

.btn-preference {
    display: flex;
    align-items: center;
    padding: 8px 12px !important;
    height: 36px;
    color: $gray-800;
    background: #F5F5F5;
    border-radius: 18px;

    img {
        margin-right: 6px;
    }
}

.preference-popover {
    background: #fff;
    width: 486px;
    border-radius: 6px;
    position: absolute;
    margin-top: 6px;
    box-shadow:
        0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
}

.form-wrapper {
    background: #fff;
    padding: 16px;
}

.popover-title {
    font-weight: 700;
    font-size: 12px;
    color: $gray-900;
    margin-bottom: 4px;
}

.popover-subtitle {
    font-weight: 400;
    font-size: 12px;
    color: $gray-600;
    margin: 0 0 4px 8px;
}

.input-title {
    font-weight: 600;
    font-size: 14px;
    color: $gray-900;
}

.input-subtitle {
    font-weight: 400;
    font-size: 12px;
    color: $gray-600;
}

.canal-title-wrapper {
    display: flex;
}

.divider {
    margin: 12px 0 !important
}

.buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 54px;
    padding: 0 16px;
}

.button {
    font-weight: 700;
    font-size: 12px;
}

.btn-apply {
    margin-left: 10px;
}