.simulation-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 1rem;

    border-radius: 0.375rem;
    background: var(--white, #fff);
    padding: 12px;

    h3 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        color: #323232;
    }

    .page-title {
        gap: 8px;
    }

    .inputs-wrapper {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        // max-width: 880px;
        max-width: fit-content;
    }

    .input-item {
        display: flex;
        border: 1px solid var(--gray-300, #eaeaea);
        border-radius: 0.375rem;
        padding: 12px;
        gap: 1rem;
    }

    .input-section {
        display: flex;
        gap: 1rem;

        .simulation-input-label {
            font-family: 'Open Sans';
            text-transform: uppercase;
            color: #323232;
            text-align: center;
            font-size: 0.625rem;
            font-weight: 700;
            text-transform: uppercase;
        }

        .divider {
            height: 72px;
            border-left: 1px solid #eaeaea;
            margin: 0 8px;
        }

        .input-wrapper {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 0.5rem;

            .input-container {
                display: flex;
                width: 12.375rem;
                height: 3rem;
                padding: 0.5rem;
                justify-content: center;
                align-items: center;
                gap: 0.625rem;

                color: var(--gray-900, #323232);
                text-align: center;
                font-family: 'Open Sans';
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 300;
                line-height: 2rem; /* 133.333% */
            }

            .simulation-input-label {
                color: var(--gray-900, #323232);
                text-align: center;

                font-family: 'Open Sans';
                font-size: 0.625rem;
                font-style: normal;
                font-weight: 700;
                line-height: 0.75rem; /* 120% */
                text-transform: uppercase;
            }

            .input-empty {
                text-align: left;
                color: var(--gray-500, #969696);
            }
        }
    }

    .actions {
        display: flex;
        align-items: flex-start;
        gap: 1rem;

        color: var(--gray-500, #bcbcbc);
        text-align: center;

        /* Text/font-size-200-semibold */
        font-family: 'Open Sans';
        font-size: 1rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.25rem; /* 125% */

        button {
            padding: 0;
            background-color: transparent;
        }
    }

    .add-scenario-button {
        background-color: transparent;
        color: var(--blue-400, #378ef0);
        font-feature-settings:
            'liga' off,
            'clig' off;

        /* Buttons/Small/Lowecase */
        font-family: 'Open Sans';
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.125rem; /* 150% */
        padding: 0;
    }

    .simulacao-input-error {
        color: #ec5b62;
    }
}
