.container {
    padding: 8px;
    display: flex;
    justify-content: space-between;
}

.flex {
    display: flex;
    align-items: center;
    gap: 4px;
}

.flex-action {
    display: flex;
    align-items: center;
    gap: 8px;
}

.text, .bold-text {
    font-size: 14px;
    color: #323232;
}

.text {
    font-weight: 400;
}

.bold-text {
    font-weight: 600;
}

.btn-select-all {
    margin-left: 6px;
    padding: 4px !important;
}
