.modal-confirmacao-revisao{
    .span{
        font-weight: 600;
        &__primary{
            color: #3597fa;
        }

        &__warning{
            color: red;
        }
    }
}