.modal-excluir-filtros {
    text-align: center;

    .rs-modal-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-block-start: 24px;
    }

    .rs-modal-body {
        margin-top: 24px;
        padding-bottom: 24px;
    }

    .rs-modal-content {
        padding: 0px 24px;
    }

    .rs-modal-footer {
        padding-block: 16px;
        border-top: 1px solid var(--gray-300, #eaeaea);
    }

    strong {
        font-weight: 700;
    }

    &__actions-container {
        display: flex;
        align-items: center;
        gap: 1rem;
        justify-content: flex-end;
    }
}
