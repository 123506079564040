@import '../../../../../../style/abstracts/variables.scss';

.filters-area-content {
    margin-top: 0 !important;
    border-radius: 6px;
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.inner-div {
    display: flex;
    border: 1px solid $gray-300;
    border-radius: 6px;
    padding: 16px;
}

.title-container {
    width: 100px;
}

.title {
    display: flex;
    align-items: center;
    height: 48px;

    p {
        font-size: 12px;
        font-weight: 600;
        color: $gray-900;
    }
}

.filter-selection-wrapper {
    display: flex;
    align-items: center;
    height: 48px;
}

.preference-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: auto;
}

.preference-dropdown {
    margin-left: auto;
    margin-top: auto;
}

.competitivity-type-wrapper {
    margin-bottom: 16px;

    :global(.rs-flex-box-grid-item) {
        margin-bottom: 0 !important;
    }
}

.competitivity-type {
    background: #fff;
    border: 1px solid #A5CCF8;
    border-radius: 6px;
    padding: 8px 12px;
    margin-right: 8px;
    color: #505050;
    text-align: start !important;

    &:hover {
        background: transparent !important;
    }
}

.competitivity-type-title {
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    margin: 0;
}

.competitivity-type-subtitle {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin: 0;
}

.competitivity-type-active {
    background: #F5F9FE;
    color: #1473E6;
    border: 1px solid #A5CCF8;
    border-radius: 6px;
    padding: 8px 12px;
    margin-right: 8px;
    text-align: start !important;

    &:focus, &:hover {
        background: #F5F9FE;
        color: #1473E6;
    }
}

.save-filter-wrapper {
    display: flex;
}

.save-filter {
    margin-right: 8px;
}

.btn-save-filter {
    height: fit-content;
    border-radius: 18px;
    background: #f5f5f5;
    display: flex;
    align-items: center;
}

.save-icon{
    color: #959595;
    font-size: 16px !important;
    margin: 0 4px;
}

.btn-delete-saved-filter {
    margin-right: 8px;
}
