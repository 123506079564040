.label {
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    color: #323232;
}

.input-name {
    border: 1px solid #e5e5ea !important;
    border-radius: 4px !important;
    margin-top: 6px;
}

.input-name-error {
    border: 1px solid #EC5B62 !important;
    border-radius: 4px !important;
    margin-top: 6px;
}

.error-text {
    color: #D7373F;
    font-size: 12px;
    line-height: 18px;
}

.toggle-wrapper {
    display: flex;
    align-items: center;
    margin-top: 25px;
}

.bold-text {
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    color: #323232;
    margin: 0 6px;
}

.sub-text {
    font-weight: 400;
    font-size: 12px;
    color: #323232;
    margin: 0;
}

.time-frequency-container {
    background: #F5F9FE;
    border-radius: 6px;
    padding: 16px;
    margin-top: 16px;
}

.radio-wrapper {
    display: flex;
    align-items: center;
}

.select-wrapper {
    padding: 0 140px 0 78px;
    margin-top: 8px;

    :global(.rs-picker-select) {
        display: block !important;
    }

    :global(.rs-picker-check) {
        display: block !important;
    }
}

.date-wrapper {
    display: flex;
    align-items: center;
    margin-top: 24px;

    p {
        margin-right: 16px;
    }

    img {
        margin-left: 10px;
    }

    :global(.rs-picker-date) {
        width: 307px;
    }
}

.date-container {
    position: relative;
}

.date-error-text {
    position: absolute;
    left: 0;
    color: #D7373F;
    font-size: 12px;
    line-height: 18px;
}

.check-picker-error {
    :global(.rs-picker-toggle) {
        border: 1px solid #EC5B62;
    }
}
