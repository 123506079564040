@import '../../../../../../style/abstracts/variables.scss';

.empty-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    margin-top: 0 !important;
    padding: 40px !important;
}

.text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
}

.title {
    font-weight: 600;
    font-size: 16px;
    color: $gray-900;
}

.subtitle {
    font-size: 14px;
    line-height: 20px;
    color: $gray-900;
    margin: 0;
}
