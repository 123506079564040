@import 'mixins.scss';

.button {
    font-family: inherit;
    display: flex;
    align-items: center;
    border-radius: 4px;
    transition: var(--base-transition);
    @include secondary-button-themes;
    @include button-sizes;
}
