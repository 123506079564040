@import '../../../../../../../../style/abstracts/variables.scss';

.container-loading {
    height: 400px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

[data-highcharts-chart] {
    overflow: visible !important;
}
