.cadastro-lojas-participantes-page {

    .rs-btn-primary,
    .rs-btn-ghost {
        font-weight: 700;
    }

    .rs-content {
        flex: none;
        border-radius: 6px;
        margin: 24px;
    }

    .rs-flex-box-grid-item {
        margin-bottom: 0;
    }

    .rs-content.lojas-participantes {
        margin: 0 24px 24px 24px !important;

        h3 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            color: #323232;
            line-height: normal;

            i {
                color: #378EF0;
                margin-left: 10px;
            }
        }

        .lojas-participantes-container {
            margin-top: 16px;

            .campanhas-filtros {
                margin-bottom: 16px;
            }

            .no-data {
                background: #F5F9FE;
                border-radius: 4px;
                padding: 16px;
                flex-direction: column;
                gap: 16px;

                p {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    color: #323232;
                }

                .skip-button {
                    font-size: 12px;
                    padding: 0;
                }
            }
        }
    }

    .rs-content.navegacao-lojas-participantes {
        margin: 0 24px 24px 24px !important;

        .rs-btn-link,
        .rs-btn-primary {
            display: flex;
            align-items: center;

            .MuiSvgIcon-root {
                margin-right: 15px;
                font-size: 20px;
            }
        }
    }
}

.lojas-participantes-modal {
    gap: 16px;
}

.input-outline {
    .rs-input {
        border: 1px solid #e5e5ea !important;
        border-radius: 5px !important;
        padding: 10px;
        padding-left: 46px !important;
    }

    .rs-input:focus,
    .rs-input:hover {
        border-color: #378EF0 !important;
        outline: 0;
    }

    i {
        padding-top: 4px;
        padding-left: 6px;
    }

    .icon-busca {
        color: #1473E6;
    }
}

.campanhas-filtros {
    border: 1px solid #EAEAEA;
    border-radius: 6px;
    padding: 16px;
    gap: 6px;

    .rs-flex-box-grid-item {
        margin-bottom: 0;
    }

    .filtro-botao {
        background-color: #F5F5F5;
        border-color: #F5F5F5;
        border-radius: 18px;
        color: #505050;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;

        .rs-icon {
            margin-right: 0;
            margin-left: 8px;
        }

        &.active {
            background-color: #F5F9FE;
            color: #0D66D0;
            display: flex;
            align-items: center;

            .rs-badge {
                margin-left: 8px;
                font-weight: 700;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 700;
                font-size: 14px;
                background: #1473E6 !important;
                border-radius: 15px;
                width: 25px;
                height: 20px;
                line-height: 18px;
                text-align: center;
            }

            .rs-icon-close {
                font-size: 10px;
            }
        }
    }

    .filtro-botao:hover {
        background-color: #EAEAEA;

        &.active {
            background-color: #F5F9FE;
            color: #1473E6;
        }
    }

    .filtros-container {
        gap: 6px;
    }
}

.rs-modal-body {
    max-height: 100% !important;
}

.check-lojas {
    .rs-table-cell-content {
        padding: 5px !important;
    }
}
