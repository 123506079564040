@import '../../../../style/abstracts/variables.scss';

.forecast-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;
    padding: 8px 16px;
    background: #F5F9FE;
}

.forecast-title {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: #323232;
}

.forecast-title-wrapper {
    display: flex;
}

.forecast-side-title {
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    color: #323232;
    margin: 0 0 0 4px;
}

.main-value, .sub-value {
    font-weight: 600;
    color: #323232;
}


