@import '../../../../../style/typography.scss';


.detalhes-da-negociacao {
    &__header, &__header > div, &__heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__header p {
        --text-color: var(--gray-600, #959595);
        @extend .font-size-100-regular;
    }

    &__header > div  {
        gap: 10px;
        p {
            color: var(--gray-800, #505050);
            font-family: Open Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }
}
