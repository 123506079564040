.dialog-date{
    .MuiDialog-paperFullScreen{
    //     height: 80vh;
        bottom: 0;
    }

    .MuiDialog-paper{
        position: absolute
    }

    


    &__header {
        &.MuiAppBar-colorPrimary {
            color: #000000;
            background-color: #FFFFFF;
        }

        &.MuiAppBar-positionFixed{
            position: absolute;
        }


        .header {
            &__search-input {
                position: relative;
                border-radius: 8px;
                background-color: #E5E5EA;
                width: 100%;

                .search-input {
                    &__icon {
                        padding: 10px;
                        height: 100%;
                        position: absolute;
                        pointer-events: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                    }
                }

                .MuiInputBase-root {
                    width: 100%;

                    input {
                        padding: 10px 10px 10px 40px;
                    }
                }
            }
        }
    }

    &__content{
        .content{
            &__calendar{
                margin-top: 48px;
                margin-top: 48px;
                width: 100%;
                max-width: 100%;
                box-shadow: none;
            }
        }

        .short-cut{
            margin-top: 25px;

            button{
                background-color: #fff;
            }
        }
    }

    &__footer{
        top: auto !important;
        bottom: 0 !important;
        padding: 16px 24px !important;
        display: inline !important;

        &.MuiAppBar-colorPrimary {
            color: #000000;
            background-color: #FFFFFF;
        }

        button{
            padding: 8px 16px;
            text-transform: uppercase;
            font-weight: bold;

            &.rs-btn-primary{
                width: 60%;
                float: right;
            }

            &.rs-btn-subtle{
                width: 30%;
                float: left;
            }
        }
    }
}