@import '../../style/typography.scss';

.modal {
    --_img-text-height: 84px;
    --_icon-check-size: 24px;

    width: 610px;
    max-height: 764px;
    text-wrap: balance;

    img {
        display: block;
        max-width: 100%;
    }

    :global(.rs-modal-content) {
        padding: 32px 24px;
        background: #6e3aaf;
    }

    :global(.rs-modal-body) {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        margin: 0px;
        padding: 0px;
        color: var(--white);
    }

    .logo {
        max-width: 6.5rem;
    }

    .title {
        text-align: center;
        font-weight: 600;
        font-size: 24px;
        line-height: 1.2;
        strong {
            font-weight: 800;
        }
    }

    .subtitle {
        @extend .font-size-400-semibold;
        --text-color: var(--white, #fff);
        text-align: center;
    }

    .inner-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 24px;
        align-self: stretch;
    }

    ul,
    li {
        margin: 0px;
        padding: 0px;
        list-style: none;
    }

    ul {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
        align-items: center;
        column-gap: 16px;
        row-gap: 12px;
        width: 100%;
    }

    li {
        display: grid;
        grid-template-columns: min-content 1fr;
        align-items: start;
        gap: 0.5rem;
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;

        &::before {
            content: '';
            display: inline-block;
            background: url('../../assets/icons/new-icon-check.svg') no-repeat;
            width: var(--_icon-check-size);
            height: var(--_icon-check-size);
            margin-block-start: 2px;
        }
    }

    button {
        margin: 0px;
        outline: none;
        background: #e8aa23;
        padding: 0.75rem 2rem;
        color: var(--white);
        text-decoration: none;
        border-radius: 0.5rem;
        font-weight: 600;
        text-transform: uppercase;
    }
}
