@import '../abstracts/variables.scss';

.how-to-use-module-report-banner {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 24px 240px !important;
    height: 112px;

    background: $blue-700 !important;
    border-radius: 6px;
    color: #ffffff;

    .banner-title {
        font-weight: 600;
        font-size: 24px;

        p {
            margin: 0;
        }
    }

    .banner-subtitle {
        font-weight: 400;
        font-size: 16px;
        width: fit-content;
    }

    button {
        font-size: 16px;
        font-weight: 700;
        text-transform: uppercase;
    }

    .diagonal-banner {
        width: 150px;
        padding: 6px;
        font-weight: 700;
        background-color: $green-400;
        color: #fff;
        text-align: center;
        transform: rotate(-45deg) translate(-27%, -33%);
        text-transform: capitalize;
    }

    .diagonal-banner-container {
        width: 140px;
        height: 112px;
        overflow: hidden;
        position: absolute;
        top: 0px;
        left: 0px;
    }
}

.how-to-use-module-report-modal {
    .rs-modal-content {
        padding-top: 54px !important;
    }
}

@media screen and (max-width: 1440px) {
    .how-to-use-module-report-banner {
        padding: 24px 96px !important;

        .banner-subtitle {
            width: 260px;
        }
    }
}


