.inf-pill-button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 8px 12px;
    margin-right: 8px;
    margin-top: 8px;

    background: #F5F5F5;
    border-radius: 18px;

    &:global(.active) {
        color: #2680EB;
        background: #F5F9FE;
    }
}

.select-latest-wrapper {
    display: flex;
    flex-flow: wrap;
    margin-top: 8px;
}

.select-interval {
    margin-top: 8px;

    &:global(.active) {
        :global(#select-picker-pill) {
            background: #F5F9FE !important;

            :global(.rs-picker-toggle-placeholder) {
                color: #2680EB !important;
            }
        }
    }
}

.select-interval-menu {
    padding: 20px 16px !important;
    :global(.rs-picker-none) {
        display: none !important;
    }
}

.select-interval-input-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;

    :global(.rs-input-number) {
        width: 70px;
        margin: 0 8px;
    }
}

.counter {
    position: relative;
    font-size: 12px;
}

.counter-number {
    font-size: 14px;
    color: #1473E6;
}
