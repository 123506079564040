.modal-update-scenario {
    display: flex;
    flex-direction: column;
    width: 428px;

    :global(.rs-modal-footer) {
        text-align: center;
    }

    :global(.rs-modal-body) {
        text-align: center;
    }
}

.header {
    text-align: center;
    margin-top: 16px;
    padding-left: 20px;

    :global(.rs-modal-title) {
        font-size: 20px;
        font-weight: 600;
    }
}

.description1, .description2 {
    margin: 0;
}

.description1 {
    margin-bottom: 16px;
}

.btn-cancel, .btn-delete {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
}
