.new-modal-confirmacao {
    .rs-modal-content {
        padding: 0 !important;
        border-radius: 4px;
    }

    .rs-modal-header {
        padding: 25px 24px 0 24px;
    }

    .rs-modal-body {
        padding: 0 24px 24px 24px;
        margin: 0;
    }

    hr {
        margin: 0;
    }

    .rs-modal-footer {
        padding: 20px;

        .rs-btn-lg {
            font-weight: 700;
            font-size: 14px;
            text-transform: uppercase;
            border-radius: 4px;
        }
    }

    .rs-modal-footer .rs-btn+.rs-btn {
        margin-left: 16px;
    }

    .title {
        font-weight: 600 !important;
        font-size: 20px !important;
        color: #323232 !important;
        margin-bottom: 24px !important;
    }

    .subtitle {
        font-family: 'Open Sans';
        font-weight: 400 !important;
        font-size: 14px !important;
        color: #000000 !important;
        margin-bottom: 24px !important;
    }

    .list-title {
        font-weight: 700;
        font-size: 12px;
        text-transform: uppercase;
        color: #323232;
    }
}
