$skins: (
    'gray' #bcbcbc #f4f4f4 #505050,
    'blue' #378ef0 #f5f9fe #378ef0,
    'red' #ec5b62 #fef7f7 #ec5b62
);

@mixin input-skin() {
    @each $skin, $clr-primary, $clr-secondary, $clr-addon in $skins {
        background: var(--white);

        &[data-skin='#{$skin}']:not(:disabled),
        &:has([data-skin='#{$skin}']:not(:disabled)) {
            border: 1px solid $clr-primary;

            &:hover {
                border-color: darken($clr-primary, 10);
            }

            &:focus,
            &:has(:focus-within) {
                border-color: darken($clr-primary, 15);
            }

            [data-input-addon] {
                color: $clr-addon;
                background-color: $clr-secondary;
            }
        }

        &:disabled,
        &:has([data-skin]:disabled) {
            border: 1px solid var(--gray-500);
            background: var(--gray-300);
            cursor: not-allowed;
            [data-input-addon] {
                opacity: 0.75;
            }
        }
    }
}
