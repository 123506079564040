@import '../../../../../../../style/typography.scss';

.table-negociacoes-salvas {
    background: #ffffff;
    border-radius: 6px;
    overflow: hidden;

    &__cell-content {
        @extend .font-size-100-regular;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
    }

    &__button {
        display: grid;
        place-content: center;
        width: 34px;
        aspect-ratio: 1;
    }

    &__status {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .cell-description-text {
        --text-color: var(--gray-600, #959595);
        @extend .font-size-75-regular;
    }

    .cell-main-text {
        --text-color: var(--gray-900, #323232);
        @extend .font-size-100-semibold;
    }
}
