.table-edit-base-container {
    margin: 0 0 10px 0;
    background: var(--white);
    border-radius: 6px;
}

.main-bold-text, .regular-bold-text {
    font-size: 14px;
    color: var(--gray-900);
    margin: 0;
}

.main-bold-text, .subtext-bold {
    font-weight: 600;
}

.regular-text {
    font-size: 12px;
    font-weight: 400;
}

.subtext {
    font-size: 11px;
    font-weight: 400;
    color: var(--gray-700);
    margin: 0;
}

.leader-text {
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--blue-400);
    margin-left: 6px;
}

.subtext-bold {
    font-size: 12px;
    color: var(--gray-700);
    margin: 0;
}

.table-edit-base {
    border-radius: 6px;
}

.table-edit-base-row {
    :global(.rs-table-cell-header > .rs-table-cell > .rs-table-cell-content) {
        display: flex;
        align-items: center;
    }
}

.sub-flex {
    display: flex;
    align-items: center;

    :global(.indicator) {
        margin-left: 4px;
    }
}

.flex {
    display: flex;
    align-items: center;
    height: -webkit-fill-available;
}

.input-group, .input-group-editado {
    margin-bottom: 4px;
    input {
        height: 32px !important;
    }

    span {
        padding: 8px;
    }
}

.input-group-editado {
    border: 1px solid #378EF0 !important;

    :global(.rs-input-group-addon) {
        color: #378EF0;
        background: #F5F9FE;
    }
}

.input-cell {
    :global(.rs-table-cell-content) {
        padding: 4px;
    }
}

.neutro-indicator {
    border: 1px solid var(--gray-500);
    border-radius: 4px;
    background: var(--gray-100);
    padding: 1px 2px;
    font-size: 10px;
    font-weight: 600;
    width: fit-content;
    line-height: 10px;
}

.outros-indicator-flex {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 4px;
}

.pmz-indicator {
    margin-top: 4px;
}

.flex-column {
    display: flex;
    flex-direction: column;

    p {
        margin: 0;
        line-height: 14px;
    }
}

.currency-indicator {
    font-size: 11px;
    font-weight: 600;
    color: var(--gray-700);
}

.preco-medio-indicator {
    margin-left: 4px;
}

.btn-simulate {
    margin-top: 4px;
}

.edit-indicator, .applied-indicator {
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 10px;
    color: var(--white);
    padding: 1px 6px;
    margin-left: 4px;
}

.applied-indicator {
    background: var(--green-400);
}

.edit-indicator {
    background: var(--blue-400);
}

.margem-indicator {
    margin-right: 4px;
}

.line {
    :global(.rs-table-cell-content) {
        border-left: 1px solid #f2f2f5;
    }
}

.currency-input {
    border: none !important;
}

.warning-icon {
    height: 12px !important;
    width: 12px !important;
    color: #F29423;
    margin-left: 4px;
}
