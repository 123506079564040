.extracao-datapoint-page {
    .download__wrapper{
        margin-left: auto;
    }

    table {
        width: 100%;
        table-layout: fixed;
    }

    th {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .wide {
        width: 200px;
    }

    .table {
        &__description {
            min-width: 200px;
            width: 200px;
        }
    }

    .table-row-second-header {
        overflow: hidden;
        height: 36px;
        width: 100%;
        top: 0;

        th {
            border-style: solid;
            border-width: 0;
            border-color: #f2f2f5;
            overflow: hidden;
            white-space: normal;
            background: #fff;
            padding: 10px 10px;
            font-size: 12px;
            line-height: 1.66666667;
            color: #8e8e93;
            font-weight: 300;
        }
    }

    .table-row-principal-header {
        overflow: hidden;
        height: 36px;
        width: 100%;
        top: 0;
        border-bottom: 1px solid #f2f2f5;

        th {
            border-style: solid;
            border-width: 0;
            border-color: #f2f2f5;
            overflow: hidden;
            white-space: normal;
            background: #fff;
            padding: 10px 10px;
            font-size: 12px;
            line-height: 1.66666667;
            color: #8e8e93;
            font-weight: 300;
            text-align: start;
        }

        .text-center {
            text-align: center;
        }
    }

    .table-row {
        overflow: hidden;
        width: 100%;
        height: 46px;
        top: 0;
        border-bottom: 1px solid #f2f2f5;

        .cell-options {
            padding: 0;
        }

        td {
            border-style: solid;
            border-width: 0;
            border-color: #f2f2f5;
            overflow: hidden;
            white-space: normal;
            background: #fff;
            padding: 13px 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .text-center {
            text-align: center;
        }
    }
}
