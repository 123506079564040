.container {
    background: var(--white, #fff);

    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding: 0.75rem;
    border-radius: 0.375rem;
}

.data-info {
    display: flex;
    flex: 1;
    justify-content: center;
    background: var(--light-Blue-5, #f5f9fe);
    border-radius: 0.375rem;

    p {
        color: var(--gray-900, #323232);
        text-align: center;

        font-family: 'Open Sans';
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem; /* 142.857% */
    }
}

.data-info-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #f5f9fe;
    border-radius: 6px;
    padding: 16px 0;

    p {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #323232;
    }
}
