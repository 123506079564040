.revisao-page {
    --row-height: 62px;

    .rs-table-cell-header .rs-table-cell-content {
        display: flex;
        align-items: center;
    }

    .rs-picker-toggle,
    .rs-input,
    .rs-tag-group {
        border: 1px solid #e5e5ea !important;
        border-radius: 5px !important;
    }

    .rs-input-group {
        .rs-input {
            border: 0 !important;
        }

        &:hover,
        &:active,
        &:focus {
            border-color: #e5e5ea !important;
        }
    }

    .rs-input-group-focus {
        border-color: #e5e5ea !important;
    }

    .rs-table-row.new-cell-height {
        max-height: 70px !important;
    }

    &__title .rs-flex-box-grid-item {
        margin-bottom: 0px;
    }

    &__engine-warning,
    &__title {
        margin: 10px 20px 8px 20px;
    }

    .data-integracao-container .rs-flex-box-grid-item {
        margin-bottom: 16px;
    }

    .aplicar-precos-container {
        margin-block: 16px;
        align-items: center;

        &__results-indicator {
            gap: 8px;
            display: flex;
            align-items: center;

            & span {
                color: var(--gray-900, #323232);
                font-size: 14px;
                font-style: normal;
                line-height: 20px;
                font-weight: 400;

                & strong {
                    text-align: center;
                    font-size: inherit;
                    line-height: inherit;
                    font-weight: 600;
                }
            }
        }

        &__link-button {
            padding-inline: 0px;
            background: none;
            color: var(--blue-400, #378ef0);
            font-feature-settings:
                'clig' off,
                'liga' off;
            font-family: Open Sans;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
        }

        &__filtros-container {
            gap: 8px;
            flex-flow: initial;
        }
    }

    .rs-dropdown-menu {
        display: flex;
        width: 253px;
        padding-bottom: 0px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        border-radius: 0px 0px 6px 6px;
        background: var(--white, #fff);
        box-shadow:
            0px 3px 6px -4px rgba(0, 0, 0, 0.12),
            0px 6px 16px 0px rgba(0, 0, 0, 0.08),
            0px 9px 28px 8px rgba(0, 0, 0, 0.05);
    }

    .radio-ordenation-table-revisao {
        fill: #000;
        padding: 8px 2px;
        gap: 8px;
        align-self: stretch;
        .not-selected {
            font-family: Open Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
        }
        .selected {
            font-family: Open Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 20px;
        }
    }

    .title-dropdown-options-table-revisao {
        display: flex;
        padding: 12px 12px 0px 12px;
        align-items: center;
        gap: 8px;
        align-self: stretch;

        &__label {
            color: #747474;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 18px;
            text-transform: uppercase;
        }
    }

    .dropdown-option-revisao {
        bottom: -13px;
        right: -58px;
    }

    .table {
        .rs-table-cell-header .rs-checkbox-inline {
            margin-left: 0px;
        }

        .rs-table-body-wheel-area [role='row'] {
            .rs-table-cell-content {
                padding: 0;
                & > div {
                    width: 100%;
                    height: var(--row-height);
                }
            }
        }

        &__input-group {
            .rs-table-cell-content {
                padding: 5px;
            }
        }

        &__quebra-limite-preco {
            background-color: red;
            color: white;
            cursor: pointer;
        }

        &__limite-quebrado {
            background-color: red;
            color: white;
        }

        &__input-addon-click {
            cursor: pointer;
        }

        &__tag-tipo-preco {
            border-radius: 20px;
        }

        .cards-divider {
            position: relative;
            bottom: 20px;
            right: 12px;
            min-height: 160px;
        }

        .table-cell-expandible {
            height: 100%;
            width: 100%;
            padding: 13px 10px;

            &.product-id {
                div {
                    display: flex;
                    align-items: center;

                    .MuiSvgIcon-root {
                        color: #378ef0;
                        margin-right: 3px;
                    }

                    p {
                        max-width: 100%;

                        strong {
                            font-weight: 600;
                            margin-right: 3px;
                        }
                    }
                }
            }
        }

        .previsao-demanda {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding: 11px 8px;
            cursor: pointer;

            .calculo {
                width: 100%;
                display: flex;
                justify-content: center;

                .rs-loader {
                    display: flex;
                    align-items: center;

                    .rs-loader-content {
                        margin-left: 6px;
                    }
                }
            }

            .positive {
                color: #33ab84;
                margin-left: 4px;
            }

            .negative {
                color: #ec5b62;
                margin-left: 4px;
            }

            .rs-btn {
                padding: 4px 0;
                border-radius: 4px;
                background: #f5f9fe;
                color: #0d66d0;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                line-height: 16px;
            }
        }
    }
}

.modal-previsao-demanda {
    .rs-flex-box-grid-item {
        margin-bottom: 0;
    }

    .rs-modal-body {
        padding-bottom: 0;
        margin-bottom: 24px;
    }

    .rs-modal-footer {
        text-align: center;
    }

    .rs-row {
        margin-top: 8px;
    }

    .rs-col.rs-col-xs-8 {
        padding-left: 4px;
        padding-right: 4px;
    }

    .rs-col.rs-col-xs-8:first-child {
        padding-left: 0;
    }

    .rs-col.rs-col-xs-8:nth-child(3) {
        padding-right: 0;
    }

    h1 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #323232;
        margin-bottom: 4px;
        display: flex;
        align-items: center;

        span {
            border: 1px solid #747474;
            border-radius: 15px;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 10px;
            line-height: 12px;
            text-align: center;
            text-transform: uppercase;
            color: #747474;
            padding: 1px 8px;
            margin-left: 8px;
        }
    }

    h2 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #323232;
    }

    .confiabilidade-info {
        width: 100%;
        padding: 8px;
        background: #f5f5f5;
        border-radius: 6px;
        text-align: center;

        p {
            margin: 0;
        }

        strong {
            font-weight: 700;
            color: #323232;
        }

        i {
            color: #378ef0;
            margin-left: 6px;
        }
    }

    .simulacao-value-card {
        padding: 8px 26px;
    }

    .grafico-card-elasticidade {
        background: #f5f9fe;
        border-radius: 6px;
        width: 100%;
        text-align: center;
        padding: 8px;

        h2 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 10px;
            line-height: 12px;
            text-transform: uppercase;
            color: #505050;
            margin-bottom: -30px;
            position: relative;
            z-index: 100;
        }

        h3 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #323232;
            margin-top: -8px;
            position: relative;
            z-index: 100;
            margin-bottom: 4px;
        }

        p {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #323232;
        }
    }

    .grafico-indisponivel-elasticidade,
    .grafico-indisponivel {
        background-color: #fef7f7;
        border-radius: 6px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 16px;

        .MuiSvgIcon-root {
            color: #e34850;
            margin: 8px 0;
        }

        h2 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 10px;
            line-height: 12px;
            text-transform: uppercase;
            color: #505050;
        }

        h3 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #323232;
            margin-top: 0px;
            margin-bottom: 8px;
        }

        p {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            text-align: center;
            color: #323232;
        }
    }
}

.popover-quebra-limite {
    &__description {
        size: 12px;
        font-weight: 700;
        color: #ec5b62;
    }

    &__row {
        width: 300px;

        .rs-flex-box-grid-item {
            margin-bottom: 0;
        }
    }

    &__title {
        text-align: center;
        font-weight: 600;
        font-size: 14px;

        p {
            font-size: 12px;
            font-weight: 400;
        }
    }

    &__section-value {
        text-align: end;
    }
}

.popover-limite {
    &__row {
        width: 300px;

        .rs-flex-box-grid-item {
            margin-bottom: 0;
        }
    }

    &__title {
        text-align: center;
        font-weight: 600;
        font-size: 14px;

        p {
            font-size: 12px;
            font-weight: 400;
        }
    }

    &__description {
        font-size: 13px;
        color: #a5a5af;
        font-weight: 600;

        &_invalid {
            color: red;
        }
    }

    &__text {
        font-weight: 600;
    }

    &__section-value {
        text-align: end;
    }
}

.CompetitorIndicator {
    color: #378ef0;
    font-weight: 600;
    font-size: 14px;
}

.rs-popover.popover-competitors.fade.in.placement-bottom {
    padding: 8px;
    .buttons-competitor-price {
        display: flex;
        gap: 8px;
        .competitor-button {
            font-size: 12x;
            font-weight: 400;
        }
    }
}

.rs-notification-item-content {
    padding: 8px !important;
}

.rs-notification-content {
    display: flex !important;
    gap: 10px;
    align-items: center;
    padding: 12px;

    .rs-notification-title {
        width: min-content;

        i {
            margin-right: 0px;
            font-size: 18px;
        }
    }

    .rs-notification-description {
        margin-top: 0px !important;
        margin-left: 0px !important;
        color: var(--gray-800, #505050);
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
    }
}

.rs-table-row-expanded {
    padding: 20px;

    .competitor-cards-title {
        font-weight: 600;
        font-size: 14px;
        justify-content: end;
        color: #000000;
        position: relative;
        left: 35px;
    }

    .competitor-cards-row {
        display: flex;
        height: 96%;
        justify-content: space-between;

        .arrow-storage {
            font-weight: 400;
            font-size: 14px;
            position: relative;
            left: 460px;
            bottom: 5px;
            justify-content: space-between;

            .arrow-right-storage {
                transform: rotate(90deg);
                color: #378ef0;
            }
        }

        .descripition-arrow-storage {
            position: relative;
            left: 468px;
            bottom: 5px;
        }

        .competitor-cards-concorrencia {
            overflow: hidden;
            // overflow: auto;
            display: flex;
            flex-grow: 1;
            margin-left: -55px;
            scroll-behavior: smooth;

            &.no-margin {
                margin-left: unset;
            }
        }

        .competitor-card-concorrencia {
            min-width: 415px;
            max-width: 540px;
            height: 125px;
            border: 1px #afadad;
            border-radius: 4px;
            padding: 8px;
            margin: 15px;
            background-color: #f5f9fe;
            position: relative;

            .competitor-title-concorrencia-card {
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
            }

            .competitor-title-concorrencia {
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
            }

            .competitor-produto-encontrado-concorrencia {
                position: relative;
                left: 6px;

                .competitor-title-concorrencia-amount-store {
                    font-weight: 600;
                    font-size: 12px;
                    line-height: 16px;
                }
            }

            .competitor-info-concorrencia {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-top: 15px;

                > div {
                    // flex-grow: 1;
                    // flex-shrink: 1;
                    min-width: 125px;
                    max-width: 62px;
                    margin: 0 5px;
                    overflow: hidden;
                    text-align: center;
                    border: 1px solid #eaeaea;
                    border-radius: 4px;
                    background-color: #ffffff;
                    padding: 8px;
                    text-transform: uppercase;
                    position: relative;
                    bottom: 5px;
                    cursor: pointer;
                }

                .highlight-price {
                    border-color: #378ef0;
                }

                h6 {
                    font-weight: 700;
                    font-size: 10px;
                    line-height: 16px;
                    color: #aba4a4;
                }

                span {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 19px;
                    color: #000000;
                }
            }
        }

        .competitor-diviser {
            position: relative;
            left: 15px;
        }

        .competitor-cards {
            overflow: hidden;
            // overflow: auto;
            display: flex;
            flex-grow: 5;
            scroll-behavior: smooth;
            margin-right: -10px;
        }

        .arrow-right,
        .arrow-left {
            margin: auto;
            padding: 12px;
            cursor: pointer;
            width: 1px;

            i {
                color: #747474;
                font-size: 24px;
            }
        }
    }

    .competitor-empty {
        margin: auto;
    }

    .loader-integracao {
        top: 3px;
        position: relative;
    }

    table {
        width: 100%;
        table-layout: fixed;
    }

    th {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .wide {
        width: 200px;
    }

    .table {
        &__description {
            min-width: 200px;
            width: 200px;
        }
    }

    .table-row-second-header {
        overflow: hidden;
        height: 36px;
        width: 100%;
        top: 0;

        th {
            border-style: solid;
            border-width: 0;
            border-color: #f2f2f5;
            overflow: hidden;
            white-space: normal;
            background: #fff;
            padding: 10px 10px;
            font-size: 12px;
            line-height: 1.66666667;
            color: #8e8e93;
            font-weight: 300;
        }
    }

    .table-row-principal-header {
        overflow: hidden;
        height: 36px;
        width: 100%;
        top: 0;
        border-bottom: 1px solid #f2f2f5;

        th {
            border-style: solid;
            border-width: 0;
            border-color: #f2f2f5;
            overflow: hidden;
            white-space: normal;
            background: #fff;
            padding: 10px 10px;
            font-size: 12px;
            line-height: 1.66666667;
            color: #8e8e93;
            font-weight: 300;
            text-align: start;
        }

        .text-center {
            text-align: center;
        }
    }

    .table-row {
        overflow: hidden;
        width: 100%;
        height: 46px;
        top: 0;
        border-bottom: 1px solid #f2f2f5;

        .cell-options {
            padding: 0;
        }

        td {
            border-style: solid;
            border-width: 0;
            border-color: #f2f2f5;
            overflow: hidden;
            white-space: normal;
            background: #fff;
            padding: 13px 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .text-center {
            text-align: center;
        }
    }
}

.competitor-card {
    width: 418px;
    height: 125px;
    border: 1px #afadad;
    border-radius: 4px;
    padding: 8px;
    margin: 8px;
    background-color: #f5f9fe;
    position: relative;
    right: 16px;
    top: 8px;

    .competitor-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 19px;
        justify-content: space-between;
    }

    .competitor-label-type-storage {
        position: relative;
        left: 5px;

        display: flex;
        align-items: center;
        gap: 4px;

        .tag-origem-preco {
            border-style: solid;
            border-width: 1px;
            border-radius: 20px;
            background-color: white;
            color: #747474;
            text-transform: uppercase;
            font-size: 10px;
            font-weight: 700;
            padding: 1px, 6px, 1px, 6px;
            line-height: 12px;
        }

        .competitor-type-storage {
            font-size: 10px;
            font-weight: 600;
            color: #747474;
            text-transform: uppercase;
        }
    }

    .toggle-competitiviness {
        position: relative;
        left: 187px;
    }

    .competitor-competitorStore {
        position: relative;
        right: 21px;
    }

    .competitor-info {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 15px;
        position: relative;
        right: 12px;

        > div {
            // flex-grow: 1;
            // flex-shrink: 1;
            min-width: 126px;
            max-width: 50px;
            margin: 0 5px;
            overflow: hidden;
            border: 1px solid #eaeaea;
            border-radius: 4px;
            background-color: #ffffff;
            padding: 8px;
            position: relative;
            bottom: 9px;
            left: 9px;
        }

        h6 {
            margin: 5;
            font-weight: 700;
            font-size: 10px;
            line-height: 16px;
            text-transform: uppercase;
            color: #aba4a4;
            position: relative;
            left: 5px;
        }

        span {
            margin: 5;
            font-weight: 600;
            font-size: 14px;
            line-height: 19px;
            color: #000000;
            position: relative;
            left: 3px;
        }
    }
}

.revisao-page .rs-picker-toggle {
    border: none !important;
}

.rs-table-header-row-wrapper {
    z-index: 0 !important;
}
