.big-numbers-container {
    display: flex;
}

.flex-center {
    display: flex;
    align-items: center;
}

.flex-base {
    display: flex;
    align-items: baseline;
}

.link-text {
    font-size: 10px;
    padding: 0 0 3px 4px;
    text-decoration: none !important;
    margin-left: 6px;
}

.bold-text-value {
    margin: 0 2px;
}

.update-button {
    border: 1px solid #eaeaea;
    background: #f5f5f5;
    padding: 6px 12px;
    text-align: start;

    &:focus {
        border: 1px solid #eaeaea;
        background: #f5f5f5;
    }
}

.blue-text {
    font-size: 12px;
    color: #378ef0;
}
