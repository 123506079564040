.container {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 6px;
    background: #FEF9F4;
}

.message-area {
    margin-left: 12px;
}

.title {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 6px;
}

.message {
    font-size: 14px;
    font-weight: 400;
    margin: 0;
}

.warning-icon {
    width: 20px;
    height: 20px;
    color: #F29423;
    margin-left: 6px;
}
