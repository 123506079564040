.photo-modal {
    &__body {
        position: relative;
        display: flex;
        padding: 4px;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        flex: 1 0 0;
        align-self: stretch;
        margin-top: 0px !important;
    }

    button {
        outline: 0;
        position: absolute;
        right: 20px;
        top: 20px;
        font-size: 12px;
        line-height: 1.66666667;
        color: #575757;
        width: 20px;
        padding: 0 4px;
        background: none;
        border-style: none;

        span {
            display: none;
        }

        &::before {
            font-family: 'rsuite-icon-font';
            font-style: normal;
            line-height: 1;
            content: '\f00d';
        }
    }
}
