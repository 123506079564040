.navbar-mobile-container {
    display: none;
}

.no-mobile-nav-notification {
    .rs-notification-item-content {
        background: #F5F9FE;
        .rs-notification-description {
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 14px;
            width: 280px;

            img {
                margin-right: 11px;
            }
        }
    }
}

@media (max-width: 480px) {
    .navbar-mobile-container {
        display: block;
        height: 105px;
        background: #378EF0;
        position: fixed;
        width: 100%;
        z-index: 8;
        padding: 48px 20px 12px;

        .sidebar-button {
            width: 38px;
            height: 38px;
            padding: 6px;

            &:active, &:focus {
                background-color: transparent !important;
            }
        }

        .logo-button {
            width: 163px;
            height: 44px;
            background: #1473E6;
            border-radius: 4px;
            padding: 8px;
            position: relative;
            left: calc(50% - 38px);
            transform: translate(-50%, 0);

            img + img {
                position: absolute;
                top: 15px;
                margin-left: 12px;
            }

            &:active, &:focus {
                background-color: transparent !important;
            }
        }
    }

    .sidebar-desktop {
        display: none !important;
    }

    .dialog-mobile-menu {
        .MuiDialog-container {
            .rs-nav-vertical {
                .rs-btn-subtle{
                    border: 1px solid #8e8e93;
                }

                .primary-nav-item {
                    background: #1473E6;
                    height: 66px;
                    border-radius: 0;

                    .rs-nav-item-content {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 100%;
                        color: #FFFFFF;

                        a {
                            color: #FFFFFF;
                            text-decoration: none;
                        }

                        &:active, &:focus, &:hover {
                            background-color: transparent !important;
                        }
                    }
                }

                .rs-nav-item-active {
                    background: #0D66D0;
                }

                .rs-nav-item-disabled {
                    opacity: 0.4;
                }

                .secondary-nav-item-wrapper {
                    margin-top: 40px;
                    width: 100%;

                    .secondary-nav-item {
                        background: transparent;
                        border: 1px solid #FFFFFF;
                        border-radius: 4px;
                        // padding: 8px 16px;
                        .rs-nav-item-content {
                            text-align: center;
                            color: #FFFFFF;
                            a {
                                color: #FFFFFF;
                                text-decoration: none;
                            }

                            &:active, &:focus, &:hover {
                                background-color: transparent !important;
                            }
                        }
                    }
                }

                .rs-flex-box-grid-item {
                    margin-bottom: 6px;
                }
            }
        }

    }
}
