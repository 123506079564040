.container {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
    padding-inline: 1rem;
    padding-bottom: 1rem;
    padding-top: 1rem;
}

@media (max-width: 1464px) {
    .container {
        padding-top: 4.5rem;
    }
}
