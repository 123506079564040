@import '../../../../../../style/typography.scss';

.fechar-negocio-modal {
    text-align: center;
    &__title {
        @extend .font-size-400-semibold;
        font-size: 20px !important;
        line-height: 26px !important;
    }

    &__body {
        @extend .font-size-100-regular;
        strong {
            @extend .font-size-100-semibold;
        }
        padding-bottom: 0px !important;
    }

    &__footer {
        border: none !important;
        justify-content: center !important;
    }
}
