.content {
    border-radius: 6px;
    position: relative;
}

.bottom-bold-text {
    font-size: 12px;
    font-weight: 600;
    color: #323232;
    margin-right: 4px;
}

.bottom-regular-text {
    font-size: 12px;
    font-weight: 400;
    color: #323232;
}

.see-less {
    display: flex;
    align-items: baseline;
}

.see-less-button {
    padding: 0;
    font-size: 12px;
    margin-left: 4px;
    text-decoration: none !important;
    img {
        margin-left: 5px;
    }
}

.more-options-button {
    width: 24px;
    height: 24px !important;
    padding: 4px !important;
    border-radius: 4px;
    background: #F5F9FE !important;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 !important;

    &:focus, &:hover {
        background-color: unset;
    }

    img {
        height: 12px;
    }
}
