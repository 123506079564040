.send-new-request {
    background: #ffffff;
    padding: 24px;
    border-radius: 6px;
    margin-top: 16px;
}

.title {
    font-size: 16px;
    font-weight: 600;
    color: #323232;
    margin-bottom: 8px;
}

.content-wrapper {
    display: flex;
    padding: 20px;
    background: #F5F9FE;
    border-radius: 6px;

    img {
        height: 16px;
        margin: 2px 10px 0 0;
    }
}

.content-text {
    font-size: 14px;
    font-weight: 400;
    color: #505050;
}
