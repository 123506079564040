.btn-delete {
    height: 36px;
    padding: 8px !important;

    svg {
        width: 20px;
        height: 20px;
        color: #959595;
    }
}

.btn-change, .btn-save {
    height: 36px;
    padding: 8px !important;
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
}

.save-wrapper {
    display: flex;
    justify-content: flex-end;
}

.btn-save {
    margin-left: auto;
}

.table-panel {
    border: none;
    background-color: var(--white);
}

.table-header {
    padding: 0 !important;
    margin: 8px 0 16px !important;
}
