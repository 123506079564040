.modal-composicao-precos{
    .rs-modal-dialog {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 10px;
        padding: 24px 24px 24px 24px;
    }
    .rs-modal-body {
        margin-top: 24px;
    }
    .rs-table {
        border-radius: 6px;
    }
    .rs-modal-content{
        padding: 0px 24px 24px 24px;
    }
    .rs-table-cell-content{
        display: flex;
        padding: 8px;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        gap: 10px;
        flex: 1 0 0;
        align-self: stretch;
    }
    .info-modal{
        &__container {
            padding: 0px;
        }
        &__header {
            padding: 24px 12px 0px 0px;
            &__title {
                font-size: 16px;
                font-weight: 600;
            }
            &__subtitle {
                font-size: 14px;
                font-weight: 400;
            }
        }
        &__body {
            padding: 0px 0px 0px 0px;
        }
        &__content {
            &__charts {
                &__container {
                    .highcharts-container {
                        border-radius: 6px;
                    }
                    display: -webkit-inline-box;
                    margin-bottom: 8px;
                    flex-direction: column;
                    align-items: center;
                    align-self: stretch;
                    border-radius: 6px;
                    border: 1px solid;
                    border-color: #EAEAEA;
                    background: white;
                }
                &__clrcle {
                    width: 12px;
                    height: 12px;
                    color: black;
                    z-index: 1;
                }
            }
            &__table {
                &__container {
                    margin-top: 8px;
                    border-radius: 6px;
                    border: 1px solid;
                    border-color: #EAEAEA;
                    background: white;
                    #table-composition-price .rs-table-body-wheel-area .rs-table-row:nth-child(odd){
                        .rs-table-cell{background-color: #EAEAEA;}
                    }
                }
                &__value {
                    font-weight: 600;
                    font-size: 14px;
                }
                &__type {
                    display: flex;
                    font-weight: 400;
                    font-size: 14px;
                }
                &__percentage {
                    display: inline-flex;
                    padding: 1px 6px;
                    align-items: flex-start;
                    font-size: 10px;
                    font-weight: 700;
                    gap: 2px;
                    border-radius: 10px;
                    background: #747474;
                    position: relative;
                    bottom: 2px;
                }
                &__title-columns {
                    font-size: 12px;
                }
            }
        }
    }
}
