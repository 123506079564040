@import '../../../../../../style/abstracts/variables.scss';

.content-box {
    padding: 12px;
    height: 62px;
    background: #FFFFFF;
    border-radius: 6px;
    max-width: 363px;
}

.content-box-flex {
    padding: 12px;
    height: 62px;
    background: #FFFFFF;
    border-radius: 6px;
    max-width: 363px;
    display: flex;
    align-items: center;
}

.item-name-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.item-name {
    font-weight: 600;
    font-size: 14px;
    color: $gray-900;
}

.item-subtitle {
    font-weight: 400;
    font-size: 12px;
    color: $gray-600;
}

.reference-badge {
    display: flex;
    align-items: center;
    width: 73px;
    background: $blue-400;
    border-radius: 10px;
    color: #FFFFFF;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 10px;
    padding: 1px 6px;
    height: 14px;
}
