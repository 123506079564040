.box {
    display: flex;
    border: 1px solid #FDEFDE;
    border-radius: 6px;
    padding: 8px 12px;
    height: 55px;

    svg {
        height: 12px;
        width: 12px;
        color: #F29423;
        margin: 3px 4px 0 0;
    }
}

.text {
    font-size: 12px;
    font-weight: 600;
    align-self: center;
}
