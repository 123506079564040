.family-modal {
    width: 1200px;

    :global(.rs-modal-body) {
        padding: 0;
        margin-top: 0;
    }

    :global(.rs-modal-header) {
        padding: 10px;
    }

    :global(.rs-modal-content) {
        padding: 20px 0 20px 0;
    }

    :global(.rs-table-pagination-toolbar) {
        padding-top: 20px;
    }
}

.modal-title {
    margin-bottom: 26px;
}

.header-container {
    display: flex;
    border: 1px solid #eaeaea;
    padding: 12px;
    border-radius: 6px;

    &__content {
        display: flex;
        align-items: center;
        flex-direction: column;
        align-items: flex-start;
        flex: 1;

        p {
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: var(--gray-700);
            font-size: 10px;
        }

        span {
            font-size: 14px;
            line-height: 20px;
            color: var(--gray-900);
            line-height: 20px;
            font-weight: 600;
        }
    }

    &__content--large {
        flex-grow: 2;
    }
}

.footer {
    background-color: #fff;
    display: flex;
    gap: 12px;
    justify-content: flex-end;
}

.manage-family-list-modal {
    :global([role='columnheader'] .rs-table-cell-content:has(.rs-checkbox)),
    :global(.rs-checkbox) {
        justify-content: center;
    }

    &__table {
        --_table-border: 1px solid var(--gray-300, #eaeaea);
        border-radius: 6px;
        border: var(--_table-border);


        &__row {
            border-bottom: var(--_table-border);
        }

        &__header-cell {
            p {
                display: block;
                margin: 0px;
                line-height: 1;
            }
        }

        [role='row']:nth-child(odd) {
            border-bottom: 1px solid var(--gray-300, #eaeaea);
            background: #f9f9f9;
        }

        [role='gridcell'] {
            background: transparent;
        }
    }
}
