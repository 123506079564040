.wrapper {
    border: 1px solid #eaeaea;
    padding: 16px;

    > div {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    > div + div {
        margin-top: 8px;
    }

    > div:nth-child(1) {
        button {
            margin-inline-start: auto;
        }
    }
}
