@import '../../style/typography.scss';

.wrapper {
    display: flex;
    align-items: center;
    gap: 4px;
}

.content {
    padding: 12px 12px 16px 12px;
    min-width: 260px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    border-radius: 0px 0px 6px 6px;
    background: var(--white, #fff);
    box-shadow:
        0px 9px 28px 8px rgba(0, 0, 0, 0.05),
        0px 6px 16px 0px rgba(0, 0, 0, 0.08),
        0px 3px 6px -4px rgba(0, 0, 0, 0.12);
}

.content-header {
    display: flex;
    align-items: center;
    gap: 8px;
    h5 {
        @extend .font-size-75-semibold;
        --text-color: var(--gray-700);
    }
}

.content-data-list {
    gap: 0px;
    &__item {
        padding-block: 8px;
        color: var(--gray-900);
    }
}

.trigger {
    display: grid;
    place-content: center;
    border-radius: 4px;
    padding: 1px;
    overflow: hidden;
    background-color: var(--gray-200);
    color: var(--gray-500);
    transition: var(--base-transition);
    &:hover {
        color: var(--gray-700);
    }
}
