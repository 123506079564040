.skeleton {
    background: #eeeeee;
    padding: 4px;
    background-color: #e2e5e7;
    background-image: linear-gradient(
        90deg,
        rgba(#fff, 0),
        rgba(#fff, 0.5),
        rgba(#fff, 0)
    );
    background-size: 40px 100%; // width of the shine
    background-repeat: no-repeat; // No need to repeat the shine effect
    background-position: left -40px top 0; // Place shine on the left side, with offset on the left based on the width of the shine - see background-size
    animation: shine 2s ease infinite; // increase anima
}

@keyframes shine {
    to {
        // Move shine from left to right, with offset on the right based on the width of the shine - see background-size
        background-position: right -40px top 0;
    }
}
