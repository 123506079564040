.configuration-products-page {

    .rs-content {
        margin: 8px !important;
        border-radius: 6px;
    }

    .channel-card,
    .region-card {
        border: 1px solid #E5E5EA;
        border-radius: 8px;
        padding: 24px;
        margin-right: 30px;

        .rs-row {
            margin-left: 0;
            margin-right: 0;
        }

        h3 {
            font-size: 14px;
            color: #323232;
        }

        .image-area {
            height: 50%;
        }


        .info-area {
            margin-bottom: 15px;
            margin-top: 15px;

            h4 {
                color: #323232;
                text-transform: uppercase;
                font-size: 12px;
            }

            .description {
                margin: 0;
            }

            .description-allow {
                color: #12805C;
            }

            .period {
                color: red;
            }
        }

        .rs-btn {
            font-weight: bold;
            margin-top: 5px;
            text-transform: uppercase;
        }
    }
}
