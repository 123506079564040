.nova-senha-page {
    .rs-col {
        padding: 0 !important;
    }

    .invalid-input {
        border-color: red !important;
        border-width: 2px !important;
    }

    .rs-help-block {
        color: red;
    }

    .rs-input-group {

        &:not(.rs-input-group-inside),
        &:not(.rs-input-group-disabled) {
            &:hover {
                border-color: #e5e5ea !important;
            }

            &.rs-input-group-focus {
                &:hover {
                    border-color: #e5e5ea !important;
                }
            }
        }

        input.invalid-input {
            border: 1px solid !important;
            border-color: red !important;
            border-width: 2px !important;
            border-radius: 6px !important;
        }
    }


    .form-area {
        height: 100vh;
        display: table;


        .new-password-form {
            padding: 0 190px;
            display: table-cell;
            vertical-align: middle;

            @media screen and (max-width:600px) {
                padding: 0 100px;
            }


            h4 {
                font-size: 24px;
                margin-bottom: 31px;
            }

            .instructions {
                margin-bottom: 35px;

                p {
                    margin-bottom: 15px;
                }

                ul {
                    padding-left: 15px;
                }
            }

            button {
                text-transform: uppercase;
                font-weight: 700;
            }

            .invalid-input {
                border-color: red !important;
                border-width: 2px !important;
            }

            .rs-help-block {
                color: red;
            }

            .link-login {
                text-align: center;
            }
        }
    }
}