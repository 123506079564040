.extracao-dados-page {

    &.main-container {
        max-width: 94vw;

        @media (min-width:400px) {
            max-width: 86vw;
        }

        @media (min-width:600px) {
            max-width: 87vw;
        }

        @media (min-width:700px) {
            max-width: 92vw;
        }

        @media (min-width:1000px) {
            max-width: 94vw;
        }

        @media (min-width:1200px) {
            max-width: 94vw;
        }
    }


    h1 {
        font-size: 20px;
        line-height: 35px;
    }

    .filtros {
        .tooltip-info.rs-icon {
            color: #bdbdbd;
            position: relative;
            top: 5px;
        }
    }

    .rs-picker-input {
        margin-right: 0 !important;
    }

    .table {
        .rs-icon {
            margin-left: 5px;
            font-size: 10px;
            color: #bdbdbd;
        }
    }

    .WAMuiChipInput-chipContainer-4 {
        border-top: none !important;
        border-radius: 0 !important;
        border-right: none !important;
        border-left: none !important;
        position: relative;
        min-height: 20px;
    }

    .WAMuiChipInput-underline-14 {
        border: 1px solid #e5e5ea;

        &:hover,
        &:active,
        &:focus {
            border-bottom: 1px solid #1675e0;
        }

        &:after {
            border-bottom: 1px solid #e5e5ea !important;
            transform: none;
            transition: none;
        }

        &:before {
            border-bottom: none;
        }
    }

    .WAMuiChipInput-underline-14:hover:not(.WAMuiChipInput-disabled-13):before {
        border-bottom: none;
    }

    .WAMuiChipInput-underline-14:active:not(.WAMuiChipInput-disabled-13):before {
        border-bottom: none;
    }

    .WAMuiChipInput-inputRoot-2 {
        font-size: 14px;
        line-height: 1.42857143;
        padding: 2px 6px 2px 1px;
    }

    .WAMuiChipInput-underline-68 {
        &:before {
            border-bottom: 1px solid #e5e5ea;
        }
    }

    .WAMuiChipInput-underline-122:hover:not(.WAMuiChipInput-disabled-121):before {
        border-bottom: 1px solid #1675e0 !important;
    }

    .WAMuiChipInput-underline-122 {
        &:before {
            border-bottom: 1px solid #e5e5ea;
        }

        &:after {
            border-bottom: 1px solid #1675e0 !important;
            transition: none;
            transform: none;
        }
    }

    .WAMuiChipInput-chip-16 {
        margin: 0 5px 8px 0;
    }

    .WAMuiChipInput-underline-68 {
        &:after {
            border-bottom: 1px solid #1675e0 !important;
        }
    }

    .MuiChip-root {
        padding: 2px 8px;
        background-color: #f7f7fa;
        color: #575757;
        border-radius: 6px;
        font-size: 12px;
        line-height: 1.66666667;
        margin-bottom: 0 !important;
        margin-left: 5px !important;
        height: fit-content;
        padding-right: 5px;
        position: relative;
        top: 4px;
        font-family: "Apple-System", "Arial", "Helvetica", "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "STXihei", sans-serif;

        &:hover,
        &:active,
        &:focus {
            padding: 2px 8px;
            background-color: #f7f7fa;
            color: #575757;
            border-radius: 6px;
            font-size: 12px;
            line-height: 1.66666667;
            margin-bottom: 0 !important;
            margin-left: 5px !important;
            padding-right: 5px;
        }
    }

    .MuiFormControl-root {
        margin-left: 5px;
    }

    .MuiChip-label {
        margin-left: 0;
        padding-left: 0;
        color: #575757;
        border-radius: 6px;
        font-size: 12px;
        line-height: 1.66666667;
    }

    .MuiChip-deleteIcon {
        top: 2px;
        right: 8px;
        font-style: normal;
        font-family: "rsuite-icon-font";
        cursor: pointer;
        font-size: 12px;
        margin-right: 0;
        width: 19px;
        height: 17px;
        color: #ccc;

        &:hover {
            color: #ccc;
        }
    }

    .rs-tag-icon-close {
        top: 5px;
        background-color: #ccc;
        border-radius: 50%;
        color: white;
        font-weight: 900;
        font-size: 8px;
        height: 15px;
        width: 15px;
        text-align: center;
        padding: 1px 3px;
        margin-left: 5px;

        &:hover {
            background-color: #ccc;
            border-radius: 50%;
            color: white !important;
            font-weight: 900;
            font-size: 8px;
            height: 15px;
            width: 15px;
            text-align: center;
            padding: 1px 3px;
            margin-left: 5px;
        }
    }

    .rs-tag-text {
        margin-right: 5px;
    }

    .navbar {
        background-color: #2196f3 !important;
        z-index: 8;
        margin-right: 57px;
        width: -webkit-fill-available;

        .rs-loader-wrapper {
            vertical-align: middle;
            margin-left: 5px;
        }

        .rs-nav-item-text {
            margin-right: 5px;
        }
    }

    .content-empty-state {
        vertical-align: middle;
        display: table;
        height: 56vh;
        color: #575757b0;
    }

    .empty-state {
        display: table-cell;
        vertical-align: middle;
        text-align: center;

        .empty-state-icon {
            font-size: 115px;
            margin-bottom: 15px;
        }
    }

    .rs-table-scrollbar-handle {
        background-color: #262829 !important;
    }

    .clear-button-chip-input {
        background-color: transparent;
        display: inline-block;
        margin-left: 8px;
        position: absolute;
        top: 6px;
        right: 3px;
        font-weight: normal;
        background: inherit;
        color: #a6a6a6;
        -webkit-transition: 0.2s color linear;
        transition: 0.2s color linear;
        cursor: pointer;
        outline: none;

        &:hover {
            color: red;
        }
    }

    .rs-help-block {
        color: red;
    }

    .invalid-input {
        border-color: red !important;
        border-width: 2px !important;

        &:hover,
        &:active {
            border-color: red !important;
            border-width: 2px;
        }
    }

    .checkpicker-colunas {
        a {
            border-radius: 6px !important;
        }
    }

    .container {
        max-height: -moz-max-content;
        max-height: -webkit-max-content;
    }

    #container {
        display: -ms-grid;
        -ms-grid-columns: max-content;
    }

    .total-itens {
        color: rgb(142, 142, 147);
    }

    .button-mais-filtros {
        button {
            width: 100%;
        }
    }

    .tab-nav {
        margin-bottom: 40px;
    }
}

.pd-top-10 {
    padding-top: 10px;
}

.display-flex {
    display: flex;
}
