.table_skeleton {
    $surface-clr: #f9fafb;
    $border: 1px solid var(--gray-300);
    width: 100%;
    border-radius: 6px;
    overflow: hidden;
    background: var(--white, #fff);

    tr {
        height: 64px;
    }

    tr td:first-child {
        max-width: 100%;
    }

    td,
    th {
        padding: 0 16px;
    }

    &__header {
        background-color: $surface-clr;
        height: 64px;
        border-bottom: $border;
    }

    &__body {
        border-bottom: $border;
    }

    &__footer {
        border-top: $border;
        height: 42px;
    }
}
