@import '../../../../../style/typography.scss';

.page {
    --text-color: var(--gray-900, #323232);
    padding: 16px;

    h4 {
        @extend .font-size-200-semibold;
    }

    .inner-header {
        margin-block-end: 24px;
        display: flex;
        flex-direction: column;

        p {
            @extend .font-size-100-regular;
            --text-color: var(--gray-800, #505050);
            margin-block-start: 4px;
        }

        &:has(p) {
            margin-block-end: 1rem;
        }
    }

    label {
        display: flex;
        align-items: center;
        gap: 4px;
    }

    ul,
    li {
        padding: 0px;
        margin: 0px;
        list-style: none;
    }
}

.tooltip {
    color: var(--gray-600);
}

.name,
.filter,
.rule,
.additional-rule,
.details,
.validity {
    display: flex;
    flex-direction: column;
}

.name {
    --_gap: 32px;
    h1 {
        @extend .font-size-400-semibold;
        margin-block-end: var(--_gap);
    }
    input {
        max-width: 600px;
    }
    > div:first-child {
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
    }
}

.rule {
    & > div {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 16px;

        > div:has([role='combobox']) {
            flex-basis: 200px;
            max-width: 296px;
        }

        .input-box {
            max-width: 140px;
            min-width: 70px;
        }
    }
}

.additional-rule {
    .inner-header {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    > div:last-child {
        display: flex;
        align-items: flex-start;
        gap: 1rem;
        & > div:has([role='menu']) {
            flex-basis: 200px;
            max-width: 296px;
        }
        & > div:has(input[type='text']) {
            max-width: 140px;
            min-width: 70px;
        }
    }
}

.details {
    > div:last-child {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    .recurrenceType__container {
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 16px;

        > div:has([role='combobox']) {
            flex-basis: 180px;
        }

        > div:first-child {
            flex-basis: 200px;
            max-width: 296px;
        }
    }
}

.minimumVariation label ~ * {
    max-width: 140px;
    min-width: 70px;
}

.productFamily {
    flex-direction: row-reverse;
    align-items: center;
    justify-content: start;
    gap: 4px;
    label {
        margin: 0px;
    }
}

.validity {
    &__header {
        display: flex;
        align-items: center;
        justify-items: start;
        flex-wrap: wrap;
        gap: 16px;

        p {
            @extend .font-size-100-regular;
            --text-color: var(--gray-900, #323232);
        }

        margin-block-end: 24px;
    }

    > div:last-child {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 16px;

        > * {
            flex: 1 1 160px;
            max-width: 196px;
        }
    }
}

.expirationDate {
    label {
        display: flex;
        align-items: center;
        gap: 8px;
    }
}

.cattegory-container {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    button {
        background: none;
        border: none;
        display: grid;
        place-content: center;
        svg {
            color: var(--gray-400);
        }
    }
}

li[role='menuitem']:has(.productIds):not(:first-child) {
    --border-width: 1px;
    margin-top: var(--border-width);
    border-top: var(--border-width) solid var(--gray-300);
}

.filter {
    ul {
        padding: 16px;
        border-radius: 6px;
        border: 1px solid var(--gray-300, #eaeaea);
    }

    li {
        flex: 1;
        align-items: center;
        width: 100%;

        &:first-child {
            align-items: flex-start;
            label {
                margin-block-start: 9px;
            }
        }
    }

    li + li {
        margin-top: 0.5rem;
    }

    label {
        flex-basis: 110px;
    }

    .innerFilterContainer {
        display: flex;
        align-items: center;
        gap: 12px;
    }
}

.wrapper-dias-concorrente {
    display: flex;
    align-items: flex-end;
    gap: 16px;
    > span {
        @extend .font-size-75-regular;
        --text-color: #959595;
        padding-block: 8px;
    }
}

.rule-action-buttons-wrapper {
    --_link-color: #1473e6;
    display: flex;
    align-items: center;
    gap: 16px;
    margin-block-start: 1rem;

    & div > * {
        display: flex;
        align-items: center;
    }
}
