:global(.rs-table-row-expanded) {
    padding: 0px;
}

.wrapper {
    display: grid;
    align-items: center;
    grid-template-columns: 60px 1fr 64px 251px 132px 116px 116px 140px 26px;
    position: relative;
    z-index: 1;

    &.is_loading {
        pointer-events: none;
        &::before {
            content: '';
            display: block;
            background-color: var(--white);
            position: absolute;
            inset: 0;
            z-index: 2;
            opacity: 0.7;
        }
    }
}

.close_button {
    all: unset;
    display: grid;
    place-content: center;
    cursor: pointer;
    padding: 4px;
    color: #378ef0;
    margin-inline-start: 2px;
}

.percentage_indicator {
    color: var(--gray-700, #747474);
    font-size: 11px;
    font-weight: 600;
    line-height: 14px; /* 127.273% */
}

.preco_atacado [data-button] {
    pointer-events: none;
}

.closeButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #378ef0;
}

.box {
    border-radius: 4px;
    padding: 0 2px;
    display: flex;
    align-items: center;
    font-size: 10px;
    line-height: 11px;

    border: 1px solid #bcbcbc;
    background-color: #f5f5f5;
    color: #959595;
}


.wholesaleaddon {
    width: 80px;
}
