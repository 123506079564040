.negociacao-fornecedor {
    &__header {
        > div {
            display: flex;
            gap: 8px;
            align-items: center;
        }
    }

    .link-button {
        display: flex;
        align-items: center;
        font-size: 12px;
    }

    a,
    a:hover {
        text-decoration: none;
    }
}
