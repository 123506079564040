@import '../../../../../../style/typography.scss';

.priceTag {
    padding-top: 15.1px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .price {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        color: var(--gray-900, #323232);
        @extend .font-size-100-semibold;
    }

    .redPrice {
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        @extend .font-size-100-semibold;
        color: var(--red-600, #d7373f);
    }

    .extraPadding {
        padding-top: 5px;
        padding-left: 20px;
    }

    .details {
        display: flex;
        align-items: center;
        gap: 2px;

        .discount {
            @extend .font-size-50-semibold;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 12px;
            color: var(--gray-600, #959595);
            padding: 0 2px;
            border-radius: 4px;
            border: 1px solid var(--gray-500, #bcbcbc);
            background: var(--gray-100, #f5f5f5);
        }

        .redDiscount {
            @extend .font-size-50-semibold;
            font-size: 10px;
            font-style: normal;
            font-weight: 600;
            line-height: 12px;
            padding: 0 2px;
            border-radius: 4px;
            color: var(--red-400, #d7373f);
            border: 1px solid var(--red-400, #ec5b62);
            background: var(--light-red-5, #fef7f7);
        }

        .date {
            font-size: 11px;
            font-style: normal;
            font-weight: 400;
            line-height: 14px;
            color: var(--gray-700, #747474);
        }
    }
}
