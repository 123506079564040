.selected-filters-mobile {
    margin: 16px 10px 16px 0px !important;

    &__wrapper {
        padding: 8px 16px;
        color: #1E88E5;
        background-color: #E0F1FF;
        border-radius: 18px;
        border: 1px solid #1E88E5;
        margin-bottom: 0 !important;
        display: inline-block;
        margin-right: 10px;

        .quantity {
            margin-left: 10px;
            background-color: #1E88E5;
            color: #fff;
            border-radius: 20px;
            padding: 3px 6px;
            font-size: 10px;
        }
    }
}

.dialog-more-filters {

    &__header {
        &.MuiAppBar-colorPrimary {
            color: #000000;
            background-color: #FFFFFF;
        }


        .header {
            &__search-input {
                position: relative;
                border-radius: 8px;
                background-color: #E5E5EA;
                width: 100%;

                .search-input {
                    &__icon {
                        padding: 10px;
                        height: 100%;
                        position: absolute;
                        pointer-events: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                    }
                }

                .MuiInputBase-root {
                    width: 100%;

                    input {
                        padding: 10px 10px 10px 40px;
                    }
                }
            }
        }
    }

    &__content {
        margin-top: 60px;

        .filter-type {

            &__category {
                &.rs-flex-box-grid-item {
                    margin-bottom: 8px;
                }

                p {
                    font-size: 14px;


                    i {
                        color: #3597fa;
                        margin-right: 10px;
                    }
                }
            }

            &__itens {
                &.rs-flex-box-grid-item {
                    margin-bottom: 16px;
                }

                .selected {
                    color: #1E88E5;
                    background-color: #E0F1FF;
                    border-radius: 18px;
                    border: 1px solid #1E88E5;
                }

                button {
                    border-radius: 18px;
                    margin-right: 10px;
                    border: 1px solid #ccc;
                }

                .item {
                    &__block-icon {
                        margin-left: 10px;
                        font-size: 14px;
                    }
                }
            }

        }

    }

    &__footer {
        top: auto !important;
        bottom: 0 !important;
        padding: 16px 24px;
        display: inline !important;

        &.MuiAppBar-colorPrimary {
            color: #000000;
            background-color: #FFFFFF;
        }

        button {
            padding: 8px 16px;
            text-transform: uppercase;
            font-weight: bold;


            &.rs-btn-subtle {
                width: 60%;
                float: left;
            }
        }
    }
}


.dialog-list-itens {
    &__header {
        &.MuiAppBar-colorPrimary {
            color: #000000;
            background-color: #FFFFFF;
        }


        .header {
            &__search-input {
                position: relative;
                border-radius: 8px;
                background-color: #E5E5EA;
                width: 100%;

                .search-input {
                    &__icon {
                        padding: 10px;
                        height: 100%;
                        position: absolute;
                        pointer-events: none;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                    }
                }

                .MuiInputBase-root {
                    width: 100%;

                    input {
                        padding: 10px 10px 10px 40px;
                    }
                }
            }
        }
    }

    &__content {
        margin-top: 60px;

        .filter-type {

            &__category {
                &.rs-flex-box-grid-item {
                    margin-bottom: 8px;
                }

                p {
                    font-size: 14px;


                    i {
                        color: #3597fa;
                        margin-right: 10px;
                    }
                }
            }

            &__itens {
                &.rs-flex-box-grid-item {
                    margin-bottom: 16px;
                }

                button {
                    border-radius: 18px;
                    margin-right: 10px;
                    border: 1px solid #ccc;
                }
            }

        }

    }

    &__footer {
        top: auto !important;
        bottom: 0 !important;
        padding: 16px 24px;
        display: inline !important;

        &.MuiAppBar-colorPrimary {
            color: #000000;
            background-color: #FFFFFF;
        }

        button {
            padding: 8px 16px;
            text-transform: uppercase;
            font-weight: bold;

            &.rs-btn-primary {
                width: 60%;
                float: right;
            }

            &.rs-btn-subtle {
                width: 30%;
                float: left;
            }
        }
    }
}