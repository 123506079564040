.request-address-container {
    background: #ffffff;
    padding: 24px;
    border-radius: 6px;
    margin-top: 20px;
}

.add-address-button {
    background: #F5F9FE;
    color: #0D66D0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin-top: 16px;

    svg {
        margin-right: 18px;
    }

    &:hover {
        background: #dbebff;
        color: #0D66D0;
    }
}

.progress-bar {
    margin-top: 16px;
}

.disabled-btn {
    background: #F5F9FE;
    color: #0D66D0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    margin-top: 16px;
    opacity: 0.5;
    cursor: default;

    svg {
        margin-right: 18px;
    }

    &:hover, &:focus {
        background: #F5F9FE;
        color: #0D66D0;
    }
}
