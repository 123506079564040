.inf-picker {
    width: 100%;
    .rs-picker-toggle {
        border-top: none !important;
        border-radius: 0 !important;
        border-right: none !important;
        border-left: none !important;
        z-index: 0 !important;
    }
}

#select-picker-pill {
    border: none !important;
    border-radius: 50px !important;
    background: #f5f5f5 !important;
    height: 36px;
    padding: 8px 30px 8px 12px !important;

    .rs-picker-toggle-placeholder {
        color: #505050 !important;
    }

    &:hover {
        background: #eeeeee !important;
    }
}

#select-picker-pill.active {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 18px !important;
    border-top-right-radius: 18px !important;
}

#check-picker-pill,
#check-picker-pill-default,
#date-range-picker {
    border: none !important;
    border-radius: 50px !important;
    background: #f5f5f5 !important;
    height: 36px;
    padding: 8px 30px 8px 12px !important;

    .rs-picker-toggle-placeholder {
        color: #505050 !important;
    }

    &:hover {
        background: #eeeeee !important;
    }
}

#check-picker-pill.active,
#check-picker-pill-default.active,
#date-range-picker.active {
    border-bottom-left-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
    border-top-left-radius: 18px !important;
    border-top-right-radius: 18px !important;
}

.rs-picker-has-value {
    #check-picker-pill {
        background: rgba(55, 142, 240, 0.05) !important;
        padding: 8px 48px 8px 12px !important;

        .rs-picker-toggle-clean,
        .rs-picker-toggle-caret {
            color: #0d66d0 !important;
        }

        .rs-picker-toggle-clean {
            right: 12px !important;
        }

        .rs-picker-toggle-caret {
            right: 30px !important;
        }

        .rs-picker-toggle-value {
            position: relative;
            .rs-picker-value-list {
                display: none !important;
            }
            .rs-picker-value-count {
                display: none !important;
            }
        }

        .rs-picker-toggle-clean,
        .rs-picker-toggle-caret {
            background: transparent;
        }

        &:hover {
            background: rgba(55, 142, 240, 0.05) !important;
        }

        .counter {
            margin-left: 4px;
            background: #3498ff;
            color: #fff;
            border-radius: 10px;
            padding: 0 10px;
            line-height: 20px;
        }
    }

    #check-picker-pill-default,
    #select-picker-pill,
    #date-range-picker {
        background: #f5f9fe !important;
        padding: 8px 48px 8px 12px !important;

        .rs-picker-toggle-clean,
        .rs-picker-toggle-caret {
            color: #0d66d0 !important;
        }

        .rs-picker-toggle-clean {
            right: 12px !important;
        }

        .rs-picker-toggle-caret {
            right: 30px !important;
        }
    }

    &.no-clean {
        #check-picker-pill-default,
        #select-picker-pill,
        #date-range-picker {
            background: #f5f9fe !important;
            padding: 8px 30px 8px 12px !important;

            .rs-picker-toggle-clean,
            .rs-picker-toggle-caret {
                color: #0d66d0 !important;
            }

            .rs-picker-toggle-clean {
                right: 12px !important;
            }

            .rs-picker-toggle-caret {
                right: 12px !important;
            }
        }
    }
}

.picker-pill-wrapper {
    position: relative;

    &.picker-side-button {
        #check-picker-pill,
        #check-picker-pill-default,
        #select-picker-pill {
            padding: 8px 72px 8px 12px !important;

            .rs-picker-toggle-caret {
                right: 52px !important;
            }
        }
    }

    .side-button {
        position: absolute !important;
        top: 0px;
        right: 0px;
        z-index: 5;
        background-color: #eaeaea;
        display: flex;
        align-items: center;
        justify-content: center;

        .rs-icon {
            color: #747474;
        }
    }
}

#select-pill-is-empty {
    border: none !important;
    border-radius: 50px !important;
    background: #fef7f7 !important;
    height: 36px;
    padding: 8px 30px 8px 12px !important;

    .rs-picker-toggle-placeholder {
        color: #d7373f !important;
    }

    &:hover {
        background: #fef7f7 !important;
    }
}
