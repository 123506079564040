.previsao-salva-page {
    .rs-flex-box-grid-item {
        margin-bottom: 0;
        padding: 0;
    }

    .rs-content {
        padding: 16px !important;
        margin: 24px !important;
        border-radius: 6px;
    }


    .rs-row {
        margin-left: -12px !important;
        margin-right: -12px !important;
    }

    .buttons-container {
        margin: 38px 24px 0 24px;

        .rs-btn.rs-btn-primary {
            text-transform: uppercase;
            font-weight: 700;
        }

        .rs-btn.rs-btn-subtle {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #505050;
        }
    }


    .page-header {
        h2 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 26px;
            color: #323232;
        }

    }

    .previsao-salva-info {
        border: 1px solid #EAEAEA;
        border-radius: 6px;
        padding: 24px;
        display: grid;
        grid-template-columns: 200px 320px 320px;
        grid-template-rows: 42px 42px;
        grid-gap: 24px;
        margin-top: 16px;

        label {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 12px;
            line-height: 18px;
            text-transform: uppercase;
            color: #959595;
        }

        p {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #323232;
        }
    }

    .previsao-volume-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h3 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 26px;
            color: #323232;
            margin-top: 8px;
            display: flex;
            align-items: center;

            .previsao-beta-badge {
                width: 42px;
                height: 14px;
                border: 1px solid #747474;
                border-radius: 15px;
                margin-left: 8px;
                color: #747474;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 700;
                font-size: 10px;
                line-height: 12px;
                text-align: center;
            }
        }

        p {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #323232;
            margin-top: 8px;
        }

        .confiabilidade-info {
            width: 100%;
            padding: 8px;
            background: #F5F5F5;
            border-radius: 6px;
            text-align: center;
            margin-top: 32px;
            margin-bottom: 16px;

            p {
                margin: 0;
            }

            strong {
                font-weight: 600;
            }
        }

        .previsao-volume-sem-dados {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background: #FEF7F7;
            padding: 24px 0;
            margin-top: 40px;

            .MuiSvgIcon-root {
                color: #E34850;
            }

            h3 {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 26px;
                color: #323232;
                margin-top: 8px;
            }

            p {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                color: #323232;
                margin-top: 8px;
            }

            span {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 18px;
                color: #323232;
            }
        }

        .cards-grid {
            margin: 0;
            width: 100%;

            .rs-row:first-child {
                margin-bottom: 16px;
            }

            .container-graficos {
                margin-top: 16px;

                .grafico-card {
                    border: 1px solid #A5CCF8;
                    border-radius: 6px;
                    padding: 16px;

                    h2 {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 20px;
                        color: #000000;
                    }

                    p {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 10px;
                        line-height: 14px;
                        color: #505050;
                        margin-bottom: 16px;
                    }

                    .grafico-indisponivel {
                        background-color: #FEF7F7;
                        border-radius: 6px;
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        padding: 24px 16px;
                        gap: 16px;
                        width: 100%;
                        height: 400px;

                        .MuiSvgIcon-root {
                            color: #E34850;
                        }

                        h3 {
                            font-family: 'Open Sans';
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 20px;
                            text-align: center;
                            color: #323232;
                        }

                        p {
                            font-family: 'Open Sans';
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            text-align: center;
                            color: #323232;
                        }
                    }
                }

                .grafico-card-elasticidade {
                    background: #F5F9FE;
                    border-radius: 6px;
                    width: 100%;
                    margin-top: 16px;
                    text-align: center;
                    padding: 16px;

                    h2 {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 10px;
                        line-height: 12px;
                        text-transform: uppercase;
                        color: #505050;
                        margin-bottom: -30px;
                        position: relative;
                        z-index: 100;
                    }

                    h3 {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 20px;
                        color: #323232;
                        margin-top: -20px;
                        position: relative;
                        z-index: 100;
                        margin-bottom: 4px;
                    }

                    p {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 18px;
                        color: #323232;
                    }
                }

                .grafico-indisponivel-elasticidade {
                    background-color: #FEF7F7;
                    border-radius: 6px;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                    gap: 16px;
                    width: 100%;
                    height: 200px;
                    margin-top: 16px;

                    .MuiSvgIcon-root {
                        color: #E34850;
                    }

                    h2 {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 700;
                        font-size: 10px;
                        line-height: 12px;
                        text-transform: uppercase;
                        color: #505050;
                    }

                    h3 {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 20px;
                        text-align: center;
                        color: #323232;
                        margin-top: 0px;
                    }

                    p {
                        font-family: 'Open Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        text-align: center;
                        color: #323232;
                    }
                }
            }
        }
    }
}