.table {
    padding: 0px;
    border-collapse: collapse;
    thead {
        height: var(--_header-height);
        border-bottom: 1px solid #f5f5f5;

        th {
            color: var(--Gray-700, #747474);
            font-family: 'Open Sans';
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            text-align: left;
            padding-inline: 6px;
            background-color: #fcfcfc;
        }
    }

    tbody {
        tr:not(:first-child) {
            border-top: 1px solid #eaeaea;
        }

        tr:nth-child(odd) {
            border-bottom: 1px solid #eaeaea !important;
        }
    }
}
