.infoprice-logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-block: 56px;
    &__container {
        display: flex;
        align-items: center;
        gap: 8px;
    }
    img {
        padding: 6.425px 8.031px;
        width: 161.432px;
        height: 35.338px;
    }
    h2 {
        color: var(--Gray-800, #505050);
        font-family: Open Sans;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }
}
