.content-carousel {
    margin-bottom: 0;
    padding: 0 20px !important;
}

.products-cards {
    text-align: center;

    &.margin-20 {
        margin: 20px;
    }

    .carosel {
        box-shadow: #c5c6c7 0px 2px 5px 2px;
        border-radius: 5px;
        width: 83%;
        text-align: left;
    }

    .wrapper {
        box-shadow: #c5c6c7 0px 2px 5px 2px;
        border-radius: 5px;
        width: 95%;
        text-align: left;
    }

    .wrapper-slick {
        border-radius: 5px;
        width: 95%;
        text-align: left;
    }

    .slick-slider {
        display: inline-block;
    }

    .card-products {
        margin: 0 0 10px 0 !important;
        padding: 15px !important;
        border-radius: 5px;

        &__product-img {
            text-align: center;
        }

        &__img {
            margin: 3px auto;
        }

        img {
            aspect-ratio: attr(width) / attr(height);
        }

        &__info-section {
            .rs-row {
                margin-bottom: 5px;

                .rs-col {
                    padding: 0;
                }
            }

            span {
                font-size: 10px;
                font-weight: 700;
                color: #8e8e93;
                text-transform: uppercase;
            }

            p {
                font-size: 13px;
            }
        }

        &__secao-section {
            padding: 0;
        }
    }

    .slick-prev:before,
    .slick-next:before {
        font-size: 40px;
        color: #ccc;
    }

    .slick-prev {
        width: 40px;
        height: 40px;
        left: -45px;
    }

    .slick-next {
        width: 40px;
        height: 40px;
        right: -70px;
    }

    .slick-next::before {
        background: url("../../assets/icons/icon_arrow-right.svg") no-repeat;
        content: "";
        display: block;
        height: 22px;
        width: 22px;
    }

    .slick-prev::before {
        background: url("../../assets/icons/icon_arrow-left.svg") no-repeat;
        content: "";
        display: block;
        height: 22px;
        width: 22px;
    }

    .column {
        float: left;
    }

    .left {
        width: 25%;
    }

    .right {
        width: 75%;

        .column {
            padding: 10px 0;
        }
    }

    .row:after {
        content: "";
        display: table;
        clear: both;
    }

    .mg-right-40 {
        margin-right: 40px
    }

    .more-info {
        width: 30%;
        margin-right: 3.3%;
        word-break: break-all;
    }
}

.resume-area {
    height: 151px;
    padding: 20px !important;
    padding-left: 40px !important;

    &__wrapper {
        display: table-cell;
        vertical-align: middle;
    }

    &__products-quantity {
        margin-bottom: 25px;
        font-size: 14px;
    }

    &__more-products {
        color: rgba(30, 136, 229, 1);
        cursor: pointer;
        font-weight: 600;
        background-color: transparent;

        svg {
            margin-right: 10px;
        }

        &_disabled {
            color: rgba(30, 136, 229, 0.2);
            cursor: auto;
        }
    }

    &__less-products {
        color: rgba(30, 136, 229, 1);
        cursor: pointer;
        font-weight: 600;
        background-color: transparent;

        svg {
            margin-right: 10px;
        }
    }
}

.scroll-area {
    height: 289px;
    overflow-y: auto;
    padding-top: 10px;
}

.pd-0 {
    padding: 0;
}


@media screen and (max-width: 500px) {

    .content-carousel {
        .rs-flex-box-grid-item {
            margin-bottom: 0;
        }
    }

    .products-cards {

        .slick-prev {
            width: 40px;
            height: 40px;
            left: -25px;
        }

        .slick-next {
            width: 40px;
            height: 40px;
            right: -50px;
        }

        .column {
            float: left;
        }

        .left {
            width: 100%;
        }

        .right {
            width: 100%;

            .column {
                padding: 2px 0;
            }
        }

        .more-info {
            &:first-child {
                width: 100%;
            }

            &:nth-child(2) {
                width: 45%;
            }

            &:nth-child(3) {
                width: 45%;
            }
        }
    }
}
