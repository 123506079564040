.body {
    margin-top: 0 !important;
    padding: 24px;
}

.content-title {
    font-size: 16px;
    font-weight: 600;
    color: #323232;
}

.content-subtitle {
    font-size: 12px;
    font-weight: 400;
    color: #505050;
}

.row {
    margin-top: 32px;
}

.last-row {
    padding: 32px 0 24px;
    border-bottom: 1px solid #eaeaea;
}

.item-title {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
}

.item-value {
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
}

.status-container {
    display: flex;
    align-items: center;
    margin: 7px 0 0 -5px;
}

.status-active-text {
    font-size: 12px;
    font-weight: 400;
    color: #268E6C;
    margin-left: 2px;
}

.status-inactive-text {
    font-size: 12px;
    font-weight: 400;
    color: #959595;
    margin-left: 2px;
}

.active-icon {
    height: 18px !important;
    color: #378EF0;
}

.inactive-icon {
    height: 18px !important;
    color: #959595;
}

.edit-form {
    margin-top: 24px;
}

.regular-input-group {
    margin-top: 6px;
    width: 180px;
}

.regular-input {
    border: 1px solid #e5e5ea !important;
    border-radius: 6px !important;

    &:focus {
        border: 1px solid #e5e5ea !important;
    }
}

.input-button {
    display: flex;
    align-items: center;
    &:hover {
        background: transparent !important;
    }
}

.done-icon {
    width: 16px !important;
    height: 16px !important;
    color: #33AB84;
}

.gtin-alert-icon {
    width: 18px !important;
    height: 18px !important;
    color: #EC5B62;
}

.edit-radio-group {
    margin-left: 0 !important;

    :global(.rs-radio-inline) {
        margin-left: 0 !important;
    }
}

.address-wrapper {
    margin-top: 16px;

    label {
        margin-bottom: 8px;
    }
}

.address-first-section {
    display: flex;
    margin-top: 12px;
    justify-content: space-between;
}

.input-uf-wrapper {
    display: flex;
    flex-direction: column;
    width: 110px;
}

.input-city-wrapper {
    display: flex;
    flex-direction: column;
    margin: 0 8px;
    width: 270px;
}

.radius-wrapper {
    display: flex;
    flex-direction: column;

    :global(.rs-radio-inline) {
        margin-left: 0 !important;
    }
}

.blue-address-wrapper {
    padding: 12px;
    margin-top: 12px;
    border-radius: 6px;
    background: #F5F9FE;
}

.input-cep-wrapper {
    display: flex;
    flex-direction: column;
    width: 120px;
    position: relative;

    :global(.area-loader) {
        width: 18px !important;
        transform: none !important;
        top: 9px;
        left: 75% !important;
        background: transparent;
    }
}

.input-address-wrapper {
    display: flex;
    flex-direction: column;
    width: 336px;
}

.input-number-wrapper {
    display: flex;
    flex-direction: column;
    width: 90px;
}

.alert-input {
    border: 1px solid #EC5B62 !important;
    border-radius: 6px !important;

    &:hover {
        border: 1px solid #EC5B62 !important;
    }

    &:focus {
        border: 1px solid #EC5B62 !important;
    }
}

.alert-icon {
    position: absolute;
    top: 9px;
    left: 75% !important;
    width: 18px !important;
    height: 18px !important;
    color: #EC5B62;
    z-index: 9;
}
