.vendas-gerais-page {
    .rs-flex-box-grid-item {
        margin-bottom: 0;
    }

    .rs-content {
        border-radius: 6px;
        margin: 24px !important;
        margin-bottom: 16px !important;
        flex: 0;
    }

    .rs-content.no-margin-bottom {
        margin-bottom: 0 !important;
    }

    .page-header {
        padding: 16px !important;

        h2 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 26px;
            color: #323232;
        }

        span {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 10px;
            line-height: 14px;
            color: #959595;
        }
    }

    .filtros-header {
        margin-top: 16px !important;
        margin-bottom: 12px !important;
    }

    .periodo-header {
        padding: 12px !important;
        margin-top: 2px !important;
        margin-bottom: 4px !important;
        text-align: center;

        h3 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
            color: #323232;
        }

        p {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 18px;
            color: #747474;
        }
    }

    .campanhas-filtros>.filtros-container {
        gap: 8px;
    }

    .graficos-container {
        padding: 0 20px;

        .rs-content {
            margin: 4px !important;
            padding: 16px !important;
        }

        .grafico-competitividade-media-geral-loading {
            height: 272px;
        }

        .grafico-competitividade-media-geral {
            h2 {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #323232;
            }
        }

        .ira-card-loading {
            height: 218px;

            &.full-height {
                height: 445px;
            }
        }

        .ira-card-sem-dados {
            height: 218px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 24px 0;

            &.full-height {
                height: 445px;
            }

            h3 {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #323232;
            }

            p {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                color: #323232;
            }
        }

        .ira-card {
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            height: 218px;

            &.full-height {
                height: 445px;
            }

            h3 {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 300;
                font-size: 32px;
                line-height: 44px;
                color: #323232;
                text-align: center;
            }

            p {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                line-height: 18px;
                color: #505050;
                text-align: center;
                margin-bottom: 4px;
            }

            span {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: #747474;
                display: flex;
                justify-content: center;

                i {
                    font-size: 10px;
                    margin-right: 6px;
                }

                .currency {
                    margin-right: 8px;
                }

                .display-porcentagem {
                    display: flex;
                    align-items: center;

                    &.positive {
                        color: #2D9D78;

                        .currency {
                            color: #2D9D78;
                        }
                    }

                    &.negative {
                        color: #D7373F;

                        .currency {
                            color: #D7373F;
                        }
                    }
                }
            }
        }

        .grafico-loading {
            height: 445px;
        }

        .grafico {
            h2 {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #323232;
                margin-bottom: 24px;
            }

            .sem-dados {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 24px 0;
                height: 390px;
                margin-top: 12px;

                h3 {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    color: #323232;
                }

                p {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    text-align: center;
                    color: #323232;
                }
            }
        }

        .componente-tabela-loading {
            height: 445px;
        }

        .componente-tabela {
            height: 445px;
            padding: 12px !important;

            h2 {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #323232;
            }

            p {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 14px;
                color: #505050;
            }

            .sem-dados {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 24px 0;
                height: 390px;
                margin-top: 12px;

                h3 {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    color: #323232;
                }

                p {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    text-align: center;
                    color: #323232;
                }
            }

            .table-container {
                border: 1px solid #EAEAEA;
                border-radius: 6px;
                padding: 2px;
                margin-top: 12px;

                .linha-tabela {
                    height: 100%;
                    padding: 8px 5px;
                }

                .header-tabela {
                    margin-top: 8px;

                    .rs-table-cell-last .rs-table-cell-content {
                        padding-right: 6px !important;
                    }

                    .rs-table-cell-first .rs-table-cell-content {
                        padding-left: 6px !important;
                    }
                }

                .rs-table-cell-content {
                    padding: 0 !important;
                }
            }

        }
    }
}
