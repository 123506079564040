.regra-atacado-page {
    .rs-input,
    .rs-tag-group {
        border: 1px solid #e5e5ea !important;
        border-radius: 5px !important;
    }

    .rs-input-group {
        .rs-input {
            border: 0 !important;
        }

        &:hover,
        &:active,
        &:focus {
            border-color: #e5e5ea !important;
        }
    }

    .rs-input-group-focus {
        border-color: #e5e5ea !important;
    }
}
