.indicator-positive {
    border-radius: 4px;
    border: 1px solid #33ab84;
    background: #f5fbf9;
    padding: 1px 2px;
    color: #33ab84;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.indicator-negative {
    border-radius: 4px;
    border: 1px solid #ec5b62;
    background: #fef7f7;
    padding: 1px 2px;
    color: #ec5b62;
    width: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}

.indicator-value {
    display: flex;

    padding: 0rem 0.125rem;
    align-items: center;
    gap: 0.125rem;

    span {
        font-family: 'Open Sans';
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.125rem; /* 150% */
        text-transform: lowercase;
    }
}
