.gerenciador-page {
    &__table {
        --_table-border: 1px solid var(--gray-300, #eaeaea);
        border-radius: 6px;
        overflow-x: hidden !important;

        &__row {
            border-bottom: var(--_table-border);
        }

        &__header-cell {
            p {
                display: block;
                margin: 0px;
                line-height: 1;
            }
        }

        [role='row']:nth-child(odd) {
            border-bottom: 1px solid var(--gray-300, #eaeaea);
            background: #f9f9f9;
        }

        [role='gridcell'] {
            background: transparent;
        }

        .table__input-group {
            width: 130px;
            input {
                width: 100%;
            }
        }
    }

    &__margem-wrapper {
        display: flex;
        align-items: center;
        gap: 4px;
    }
}

.table__cell {
    &__margem-objetiva {
        display: flex;
        align-items: center;
        gap: 2px;
        svg {
            color: var(--gray-600);
        }
    }
}
.footer {
    background-color: #fff;
    display: flex;
    gap: 12px;
    justify-content: flex-end;
}

.layout-section {
    height: calc(100vh - 310px);
    margin-top: -4px;
}
