@import '../../style/typography.scss';

.modal-price-event-history__content {
    --text-color: var(--gray-900);
    max-height: 65dvh;
    overflow-y: auto;
    padding: 4px 8px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    strong {
        font-weight: 600;
    }
}

.date {
    @extend .font-size-50-bold;
    display: inline-block;
    margin-block-end: 0.75rem;
    font-size: 12px;
}

.title {
    @extend .font-size-75-regular;
}

.subtitle {
    @extend .font-size-75-regular;
    --text-color: var(--gray-700);
}

.blue-subtitle {
    @extend .font-size-75-regular;
    color: #378ef0;
}

.event-list {
    list-style: none;
    margin: 0px;
    padding: 0px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.event-item {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    &:not(.no-bullet) .title::before {
        display: inline-block;
        content: '>';
        margin-inline-end: 2px;
    }
}

.link {
    font-size: 12px;
}
