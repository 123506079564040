.box-container {
    border: 1px solid #EAEAEA;
    border-radius: 6px;
    padding: 12px;
    height: fit-content;
}

.box-title {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: #747474;
    margin-bottom: 6px;
}

.box-content {
    display: flex;
    flex-direction: column;
}

.box-row {
    display: flex;
    align-items: center;

    &:not(:last-child) {
        margin-bottom: 12px;
    }
}

.label-wrapper {
    display: flex;
    align-items: center;
    height: 34px;
    margin-right: 12px;
}

.label {
    font-size: 12px;
    font-weight: 400;
    color: #323232;
}
