#icon-button {
    position: relative;
    padding-left: 50px;

    .icon-background {
        position: absolute;
        background: #000000;
        width: 36px;
        height: 36px;
        top: 0;
        left: 0;
        opacity: 0.03;
    }

    .icon-main {
        position: absolute;
        top: 50%;
        left: 18px;
        transform: translate(-50%, -50%);
    }

    .bg-sm {
        width: 30px;
        height: 30px;
    }

    .bg-lg {
        width: 42px;
        height: 42px;
    }

    .icon-sm {
        left: 15px;
    }

    .icon-lg {
        left: 21px;
    }

    &.btn-sm {
        padding: 5px 8px 5px 42px;
        height: 30px;
    }

    &.btn-lg {
        padding-left: 56px;
    }

    &.rs-btn-disabled {
        .icon-background {
            opacity: 0.02;
        }

        .icon-main {
            filter: brightness(1.8);
        }
    }
}
