.result-scenario-container {
    border-radius: 0.375rem;

    .scenarios-container {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        border-radius: 0.375rem;

        .big-numbers-container {
            background: var(--white, #fff);

            display: flex;
            flex-direction: column;
            gap: 0.75rem;
            padding: 0.75rem;
            border-radius: 0.375rem;
        }
    }

    .charts-container {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;

        border-radius: 0.375rem;
    }

    .charts-wrapper {
        display: flex;
        flex-direction: column;
        gap: 0.75rem;
        padding: 0.75rem;

        border-radius: 0.375rem;
        background: var(--white, #fff);

        .charts-curve {
            display: flex;
            justify-content: space-between;
            gap: 0.75rem;
        }

        .chart-sales {
            display: flex;
            padding: 0.75rem;
            border-radius: 0.375rem;
            border: 1px solid var(--Gray-300, #eaeaea);
        }
    }
}
