.notification-success {
    border-radius: 6px;

    :global(.rs-notification-item-content) {
        background-color: #F5FBF9;

        :global(.rs-notification-description) {
            display: flex;
            align-items: center;
        }
    }
}

.notification-error {
    border-radius: 6px;

    :global(.rs-notification-item-content) {
        background-color: #fff4f4;

        :global(.rs-notification-description) {
            display: flex;
            align-items: center;
        }
    }
}

.success-icon, .error-icon {
    width: 18px !important;
    height: 18px !important;
    margin-right: 16px;
}

.success-icon {
    color: #33AB84;
}

.error-icon {
    color: #ec5b62;
}
