.header-container {
    margin: 16px 20px 12px;
    .product-title-container {
        background-color: #F5F9FE;
        padding: 8px 18px 6px;
        width: fit-content;
        display: flex;
        align-items: center;
        border-radius: 6px 6px 0 0;

        .product-title {
            font-weight: 600;
            font-size: 14px;
            color: #0D66D0;
        }

        .inf-badge {
            margin-left: 8px;
        }
    }

    .page-header {
        border-radius: 6px;
        display: flex;
        padding: 12px !important;
        &.rs-content {
            margin: 0 !important;
        }

        .page-header-icon {
            width: 44px;
            height: 44px;
            background: #F5F9FE;
            border-radius: 22px;
            padding: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 8px;

            .rs-icon {
                color: #378EF0;
                font-size: 15px;
            }

            img {
                width: 15px;
                height: 15px;
            }
        }

        .page-header-title-container {
            width: 100%;
            .page-header-title {
                font-weight: 600;
                font-size: 18px;
                color: #323232;
            }

            .page-header-subtitle {
                font-weight: 400;
                font-size: 14px;
                color: #747474;
                margin: 0 !important;
            }

            .page-header-subtitle-flex {
                display: flex;

                .rs-btn-link {
                    padding: 0 5px 0 10px;

                    &:hover {
                        text-decoration: none;
                    }
                }
            }
        }

        .page-header-icon-modal {
            top: 13px;
            position: relative;
            justify-content: flex-end;
            display: flex !important;

        }
    }
}
