.limite-preco-page {
    .rs-content.secao-filtros {
        flex: none;

        .limites-title {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 26px;
            color: #323232;
        }
    }

    .rs-content {
        margin: 24px !important;
        border-radius: 6px;
    }

    .rs-input-group {
        border-top: none !important;
        border-radius: 0 !important;
        border-bottom-left-radius: 6px !important;
        border-right: none !important;
        border-left: none !important;
    }

    .rs-btn-primary.rs-input-group-btn {
        color: white;
    }

    .content {
        flex: none;
    }

    .filtros {
        &__add-filtro-section {
            margin-right: 10px;
        }
    }

    .table {
        &__edit-button {
            padding: 0;
            text-align: center;
            display: table-cell;
            vertical-align: middle;

            .rs-table-cell-content {
                padding: 5px;
                overflow: unset;

                button {
                    margin: 0 5px;
                }
            }
        }

        &__cell-condicoes {
            .rs-table-cell-content {
                padding-top: 5px;
                font-size: 12px;
            }

            .rs-flex-box-grid-item {
                margin-bottom: 0;
            }

            .label {
                color: #8e8e93;
            }
        }
    }
}