@import '../../style/typography.scss';

$bg-clr-warning: var(--light-orange-5, #fef9f4);
$icon-clr-warning: var(--orange-400, #f29423);

$bg-clr-info: var(--light-blue-5, #f5f9fe);
$icon-clr-info: var(--blue-400, #f29423);

$variants: (
    'warning' $bg-clr-warning $icon-clr-warning,
    'info' $bg-clr-info $icon-clr-info
);

$sizes: (
    'md' 16px 16px 20px 16px,
    'sm' 16px 16px 20px 14px,
    'xxs' 4px 1px 14px 12px
);

.callout {
    @extend .font-size-100-regular;
    --text-color: var(--gray-900, #323232);
    display: flex;
    align-self: stretch;
    align-items: center;
    border-radius: 6px;

    > div {
        display: flex;
        flex-direction: column;
        gap: 4px;
    }

    &-title {
        @extend .font-size-100-semibold;
    }

    &-description {
        @extend .font-size-100-regular;
    }

    @each $name, $bg-clr, $icon-clr in $variants {
        &-variant-#{$name} {
            background-color: $bg-clr;
            i {
                color: $icon-clr;
            }
        }
    }

    @each $name, $padding-inline, $padding-block, $icon-size,
        $font-size in $sizes
    {
        &[data-size='#{$name}'] {
            padding-inline: $padding-inline;
            padding-block: $padding-block;
            gap: $padding-inline;
            font-size: $font-size;
            i {
                font-size: $icon-size;
            }
        }
    }
}
