@import '../../style/typography.scss';

.filter-placeholder {
    display: flex;
    align-items: center;
    gap: 4px;
}

.placeholder {
    color: var(--gray-800, #505050);
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: 'Open Sans';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px; /* 142.857% */
}

.placeholder-level {
    @extend .font-size-75-bold;
    --text-color: var(--gray-600);
}
