.request-form {
    border-radius: 6px;
    :global(.rs-panel-heading) {
        background: #f5f5f5;
        padding: 16px !important;

        &::before {
            top: 16px !important
        }
    }

    :global(.rs-panel-body) {
        padding: 18px 16px !important;
    }
}

.header {
    display: flex;
    align-items: center;
}

.title {
    font-size: 14px;
    font-weight: 600;
    color: #505050;
    margin: 0 8px 0 0;
}

.text {
    font-size: 12px;
    font-weight: 400;
    color: #747474;
    margin: 0;
}

.form-bold-title {
    font-size: 12px;
    font-weight: 700;
    color: #323232;
    text-transform: uppercase;
    margin-bottom: 2px;
}

.text-input {
    border: 1px solid #e5e5ea !important;
    border-radius: 6px !important;
}

.address-wrapper {
    margin-top: 8px;
}

.input-wrapper {
    display: flex;
    flex-direction: column;
    position: relative;

    label {
        margin-bottom: 8px;
    }

    :global(.area-loader) {
        width: 18px !important;
        transform: none !important;
        top: 37px;
        left: 75% !important;
    }
}

.required-star {
    color: #D7373F;
    margin-right: 2px;
}

.text-area {
    margin-top: 10px;
}

.code-bottom-wrapper {
    display: flex;
    align-items: center;
    margin-top: 4px;
}

.link-text {
    display: flex;
    align-items: center;
    font-size: 10px;
    padding: 0 0 0 4px;
    text-decoration: none !important;

    svg {
        margin: 2px 2px 0 0;
    }
}

.remove-button-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;
}

.remove-link-text {
    display: flex;
    align-items: center;
    font-size: 12px;
    padding: 6px;
    text-decoration: none !important;

    svg {
        margin: 2px 2px 0 0;
    }
}

.code-input {
    border: 1px solid #e5e5ea;
    border-radius: 6px;
    padding: 7px 11px;
    margin-top: 8px;
    height: 130px;
    overflow: auto;

    :global(.react-tagsinput-tag) {
        border: 1px solid #959595;
        border-radius: 12px;
        color: #959595;
        background: #fff;
        padding: 4px 14px;
        font-size: 12px;
        font-weight: 700;
        text-transform: uppercase;
        line-height: 12px;
    }

    :global(.react-tagsinput-remove) {
        color: #959595;
        font-size: 18px;
        font-weight: 400;
        text-decoration: none !important;
        margin-left: 2px;
    }

    :global(.react-tagsinput-input) {
        width: 100%;
        &::placeholder {
            color: #bcbcbc;
        }
    }
}

.error-text {
    font-size: 10px;
    color: #EC5B62;
}
