// gray colors
$btn-clr-gray: var(--gray-800);
$btn-clr-gray-hover: var(--gray-800);
$btn-clr-gray-focus: var(--gray-800);
$btn-bg-gray: var(--gray-200);
$btn-bg-gray-hover: var(--gray-300);
$btn-bg-gray-focus: var(--gray-400);

// blue colors
$btn-clr-blue: var(--white);
$btn-clr-blue-hover: var(--white);
$btn-clr-blue-focus: var(--white);
$btn-bg-blue: var(--blue-400);
$btn-bg-blue-hover: var(--blue-600);
$btn-bg-blue-focus: var(--blue-700);

// orange colors
$btn-clr-orange: var(--white);
$btn-clr-orange-hover: var(--white);
$btn-clr-orange-focus: var(--white);
$btn-bg-orange: var(--orange-400);
$btn-bg-orange-hover: var(--orange-600);
$btn-bg-orange-focus: var(--orange-700);

// purple colors
$btn-clr-purple: var(--white);
$btn-clr-purple-hover: var(--white);
$btn-clr-purple-focus: var(--white);
$btn-bg-purple: var(--purple-400);
$btn-bg-purple-hover: var(--purple-600);
$btn-bg-purple-focus: var(--purple-700);

// green colors
$btn-clr-green: var(--white);
$btn-clr-green-hover: var(--white);
$btn-clr-green-focus: var(--white);
$btn-bg-green: var(--green-400);
$btn-bg-green-hover: var(--green-600);
$btn-bg-green-focus: var(--green-700);

// red colors
$btn-clr-red: var(--white);
$btn-clr-red-hover: var(--white);
$btn-clr-red-focus: var(--white);
$btn-bg-red: var(--red-400);
$btn-bg-red-hover: var(--red-600);
$btn-bg-red-focus: var(--red-700);

$clrs: (
    'gray' $btn-bg-gray $btn-clr-gray $btn-bg-gray-hover $btn-clr-gray-hover
        $btn-bg-gray-focus $btn-clr-gray-focus,
    'blue' $btn-bg-blue $btn-clr-blue $btn-bg-blue-hover $btn-clr-blue-hover
        $btn-bg-blue-focus $btn-clr-blue-focus,
    'orange' $btn-bg-orange $btn-clr-orange $btn-bg-orange-hover
        $btn-clr-orange-hover $btn-bg-orange-focus $btn-clr-orange-focus,
    'purple' $btn-bg-purple $btn-clr-purple $btn-bg-purple-hover
        $btn-clr-purple-hover $btn-bg-purple-focus $btn-clr-purple-focus,
    'green' $btn-bg-green $btn-clr-green $btn-bg-green-hover
        $btn-clr-green-hover $btn-bg-green-focus $btn-clr-green-focus,
    'red' $btn-bg-red $btn-clr-red $btn-bg-red-hover $btn-clr-red-hover
        $btn-bg-red-focus $btn-clr-red-focus
);

.info-skin {
    @each $name, $bg, $color, $bg-hover, $clr-hover, $bg-focus,
        $clr-focus in $clrs
    {
        &-#{$name} {
            border: 1px solid $bg;
            background-color: $bg;
            color: $color;
            &:hover {
                color: $clr-hover;
                background-color: $bg-hover;
            }
            &:active {
                color: $clr-focus;
                background-color: $bg-focus;
            }
        }
        &-border-color-#{$name} {
            border-color: $bg;
            &:hover {
                border-color: $bg-hover;
            }
            &:active {
                border-color: $bg-focus;
            }
        }

        &-ghost-#{$name} {
            background-color: transparent;
            color: $bg;
        }
    }
}
