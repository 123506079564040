@mixin title-default-bold() {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: var(--gray-900, #323232);
}

.simulation {
    display: flex;
    align-items: center;
    border-radius: 0.375rem;
    background: var(--white, #fff);
    padding: 12px;

    .simulation-selected-items {
        flex: 1;
        h3 {
            @include title-default-bold();
        }
    }

    .simulation-buttons {
        display: flex;
        gap: 8px;

        .action-buttons {
            display: flex;
            gap: 8px;
        }

        .main-button {
            display: flex;
            height: 2.25rem;
            padding: 0.5rem 0.75rem;
            justify-content: center;
            align-items: flex-start;
            gap: 0.625rem;
            width: 209px;

            color: var(--white, #fff);
            text-align: center;
            font-feature-settings:
                'liga' off,
                'clig' off;
            /* Buttons/medium */
            font-family: 'Open Sans';
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.25rem; /* 142.857% */
            text-transform: uppercase;
        }

        .icon-button {
            display: flex;
            height: 2.25rem;
            padding: 0.5rem 0.75rem;
            justify-content: center;
            align-items: flex-start;
            gap: 0.625rem;

            &:not(:disabled) {
                color: #959595;
            }
        }
    }
}
