.dialog-select-option {
    .MuiDialog-container {
        height: auto;
        width: 100%;
        bottom: 0;
        position: absolute;

        .btn-close{
            margin: 6px;
        }


        .content {
            padding: 20px 20px 0 20px;

            .rs-btn-subtle{
                border: 1px solid #8e8e93;
            }

            button{
                padding: 16px;
            }
        }
    }

}