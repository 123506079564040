.wrapper {
    margin-inline-start: auto;
    display: flex;
    align-items: stretch;
    gap: 8px;
}

.button {
    display: grid;
    place-content: center;
    height: 100%;
    border-radius: 18px;
    background: #f5f5f5;
}
