.filter-box-container {
    row-gap: 10px;
    border: 1px solid #eaeaea;
    border-radius: 6px;
    padding: 16px;

    .rs-flex-box-grid-item {
        margin-bottom: 0;
    }

    .filtros-container {
        gap: 8px;
    }

    .mais-filtros-btn {
        margin-right: 8px;
    }

    .clear-filtros-btn,
    .mais-filtros-btn {
        background: #f5f5f5;
        border-radius: 18px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #505050;
        padding: 8px 12px;
        min-height: 36px;
        svg {
            display: block;
            margin: auto;
        }
    }

    .sub-menu {
        justify-content: space-between;
        gap: 8px;
    }
}

.rs-picker-check-menu-items .rs-picker-check-menu-group-title {
    font-family: 'Open Sans' !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 12px !important;
    line-height: 18px !important;
    text-transform: uppercase !important;
    color: #378ef0 !important;
}

.placeholder-level {
    color: var(--gray-600, #959595);
    text-align: center;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    text-transform: uppercase;
}
