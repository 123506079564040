.found-results {
    color: var(--gray-900, #323232);
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    strong {
        color: var(--gray-900, #323232);
        font-weight: 600;
    }
}
