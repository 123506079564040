.status-container {
    display: flex;
    align-items: center;
    margin: 7px 0 0 -5px;
}

.status-active-text {
    font-size: 12px;
    font-weight: 400;
    color: #268E6C;
    margin-left: 2px;
}

.status-inactive-text {
    font-size: 12px;
    font-weight: 400;
    color: #959595;
    margin-left: 2px;
}

.active-icon {
    height: 18px !important;
    color: #378EF0;
}

.inactive-icon {
    height: 18px !important;
    color: #959595;
}
