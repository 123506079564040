.main-container {
    margin-top: 55px;
    z-index: 0;
    max-width: 100vw;
    padding: 24px;
}

.go-back-button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 0 20px 0;
    width: fit-content;

    img {
        margin-right: 14px;
    }
}

.send-request-success {
    border-radius: 6px;

    :global(.rs-notification-item-content) {
        background-color: #F5FBF9;

        :global(.rs-notification-description) {
            display: flex;

            img {
                margin-right: 16px;
            }
        }
    }
}
