.panel-header-wrapper {
    display: flex;
    align-items: center;
    gap: 8px;
}

.panel-header-title {
    font-size: 16px;
    font-weight: 600;
}

.panel-header-subtitle, .panel-header-status {
    font-size: 12px;
    font-weight: 400;
}

.panel-header-status {
    color: var(--blue-400);
}
