@import '../../../../../style/typography.scss';
@import '../../../../../style/app.scss';

.setup-farma-config {
    padding: 16px;
    background-color: #fff;
    border-radius: 6px;
    margin: 0;

    h3 {
        @extend .font-size-200-semibold;
    }

    &__header {
        &__subtitle {
            @extend .font-size-100-regular;
        }
    }
}

.setup-farma__price-preference {
    border-radius: 6px;
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    padding: 16px;
    background-color: #fff;

    &__content {
        display: flex;
        flex-direction: column;
        margin-top: 8px;
        margin-bottom: 24px;

        &:last-child {
            margin-bottom: 0;
            margin-top: 0;
        }

        h4 {
            @extend .font-size-100-semibold;
            text-transform: none;
        }

        p {
            margin-bottom: 16px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}

.setup-farma__pmc-toggle-container {
    display: flex;
    align-items: center;
    gap: 12px;
    margin-top: 16px;
}

.setup-farma__pmc-text {
    display: flex;
    flex-direction: column;
}

.setup-farma__pmc-title {
    @extend .font-size-100-semibold;
    font-size: 14px;
    --text-color: var(--gray-900, #323232);
}

.setup-farma__pmc-description {
    @extend .font-size-75-regular;
    --text-color: var(--gray-600);
    margin: 0 !important;
}

.setup-farma__main-config__footer {
    padding: 16px;
    background-color: #fff;
    margin-top: 16px;
}

.footer {
    padding: 16px;
    background-color: #fff;
    margin-top: 16px;
    display: flex;
    gap: 12px;
    justify-content: flex-end;
}
