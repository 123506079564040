@import '../../style/typography.scss';

.info-chip {
    --_content-color: var(--gray-800);
    --_icon-color: var(--gray-600);
    --_background-color: var(--gray-300);

    font: inherit;
    display: flex;
    align-items: center;
    gap: 6px;
    padding: 4px 8px;
    border-radius: 6px;
    background: var(--_background-color);
    transition: var(--base-transition);
    height: 26px;
    cursor: pointer;

    &__content {
        --text-color: var(--_content-color);
        @extend .font-size-75-regular;
    }

    &__icon {
        color: var(--_icon-color);
        display: grid;
        place-content: center;
    }

    &:disabled {
        cursor: not-allowed;
        --_background-color: var(--gray-100);
        --_content-color: var(--gray-500);
        --_icon-color: var(--gray-500);
    }

    &:not(:disabled):hover {
        --_content-color: var(--gray-900);
        --_icon-color: var(--gray-700);
    }

    &--filled {
        --_background-color: var(--light-blue-5, #f5f9fe);
        --_icon-color: var(--blue-500);
        --_content-color: var(--blue-500);

        &:not(:disabled):hover {
            --_icon-color: var(--blue-600);
            --_content-color: var(--blue-600);
        }
    }
}
