.table-fornecedor-container {
    background: #ffffff;
    border-radius: 6px;
}

.table-fornecedor {
    border-top-right-radius: 6px;
    border-top-left-radius: 6px;

    :global(.rs-table-header-row-wrapper) {
        z-index: 0;
    }
    :global(.rs-table-body-wheel-area .rs-table-cell-content) {
        padding: 0px;
        & > div {
            padding: 13px 10px;
            width: 100%;
            max-height: 64px;
        }
        .custom-cell {
            padding: 7px 10px !important;
        }
    }
    .rs-btn {
        padding: 0px;
    }
}

.table-fornecedor-row {
    :global(.rs-table-cell-header > .rs-table-cell > .rs-table-cell-content) {
        display: flex;
        align-items: center;
    }
}

.table-header-text {
    margin: 0;
    line-height: 14px;
}

.produto-numbers-wrapper {
    display: flex;
    align-items: center;

    :global(.produto-box) {
        margin-left: 4px;
    }
}

.cell-main-text {
    color: #323232;
}

.cell-subtext {
    color: #747474;
    text-transform: uppercase;
}

.produto-second-value {
    font-size: 10px;
    font-weight: 600;
    color: #378ef0;
}

.blue-box {
    display: flex;
    align-items: center;
    background: #f5f9fe;
    font-size: 10px;
    font-weight: 600;
    color: #378ef0;
    border-radius: 4px;
    padding: 0 2px;
    text-transform: uppercase;
}

.produto-second-icon {
    color: #378ef0;
    font-size: 10px !important;
    margin-right: 2px;
}

.produto-name {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: #747474;
    margin: 0;
}

.loja-cell-wrapper {
    line-height: 36px;
    :global(.loja-regional) {
        margin-top: 4px;
    }
}

.flag-icon,
.trophy-icon,
.money-icon {
    color: #959595;
    font-size: 12px !important;
}

.expand-icon {
    color: #378ef0;
    font-size: 10px !important;
    margin: 0 3px 0 2px;
}

.margem-objetiva-cell-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.novo-custo-cell-wrapper,
.novo-preco-cell-wrapper,
.margem-objetiva-cell-wrapper {
    :global(.rs-input) {
        height: 32px;
    }

    :global(.rs-input-group-addon) {
        padding: 8px;
    }
}

.margem-objetiva-sub-wrapper,
.novo-custo-sub-wrapper,
.novo-preco-sub-wrapper,
.preco-vigente-sub-wrapper,
.preco-concorrencia-sub-wrapper {
    display: flex;
    align-items: center;
    margin-top: 2px;
}

.margem-objetiva-sub-wrapper,
.novo-preco-sub-wrapper,
.preco-vigente-sub-wrapper,
.preco-concorrencia-sub-wrapper {
    :global(.margin-right) {
        margin-right: 2px;
    }
}

.novo-custo-sub-wrapper {
    :global(.box-indicator) {
        margin-left: 4px;
    }
}

.novo-preco-sub-wrapper,
.preco-vigente-sub-wrapper,
.preco-concorrencia-sub-wrapper {
    :global(.rs-divider) {
        margin: 0 3px !important;
    }
}

.preco-ideal-wrapper {
    position: relative;

    img {
        position: absolute;
        top: 8px;
        right: -35px;
    }
}

.btn-expand {
    background: transparent;
    color: #378ef0;
    padding: 0px !important;
    border: 0;
    font-size: 10px;
    display: flex;
    align-items: center;

    &:hover,
    &:focus {
        background: transparent;
        color: #378ef0;
    }
}

.flex {
    display: flex;
    align-items: center;
}

.left-icon {
    color: #378ef0;
    font-size: 12px;
    margin-left: 3px;
}

.cell-main-text {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}

.cell-subtext {
    font-size: 11px;
    font-weight: 600;
    margin: 0;
}

.icon-info {
    width: 12px;
    height: 12px;
}

.input-group-focus {
    border: 1px solid #378ef0 !important;

    :global(.rs-input-group-addon) {
        background: #f5f9fe;
        color: #378ef0;
    }
}
