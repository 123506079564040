// default colors
$info-card-clr-bg-default: #ffffff;
$info-card-clr-bg-default-hover: #f5f9fe;

// filled colors
$info-card-clr-bg-filled: #f5f9fe;
$info-card-clr-bg-filled-hover: #f5f9fe;

$cards: (
    'default' $info-card-clr-bg-default $info-card-clr-bg-default-hover,
    'filled' $info-card-clr-bg-filled $info-card-clr-bg-filled-hover
);

.info-card {
    --clr-border: transparent;
    padding: 12px;
    border-radius: 6px;
    border-width: 1px;
    border-style: solid;
    border-color: var(--clr-border);
    transition: var(--base-transition);

    @each $name, $bg, $bg-hover in $cards {
        &-#{$name} {
            background-color: $bg;

            &:not(.info-card-hover-disabled):hover {
                background-color: $bg-hover;
            }
        }
    }

    &__icon {
        color: #378ef0;
        fill: #378ef0;
    }

    &__header {
        gap: 6px;
    }

    &__title {
        display: flex;
        align-items: center;
        gap: 6px;
        font: inherit;
        color: var(--gray-900, #323232);
        font-size: 16px;
        font-weight: 600;
        line-height: 20px;
    }

    &__content {
        color: var(--gray-700, #747474);
        font-size: 12px;
        line-height: 14px;
    }

    &-pointer {
        cursor: pointer;
    }

    &-bordered {
        --clr-border: #d7e8fc;
    }

    &-is-primary {
        --clr-border: #a5ccf8;
    }
}
