@import '../../style/typography.scss';

.competitors-section {
    --_height: 180px;
    --_icon-clr: #378ef0;
    position: relative;

    :where(menu, li, button) {
        padding: 0px;
        margin: 0px;
    }

    li {
        list-style: none;
    }

    button {
        background: none;
        cursor: pointer;
    }

    &__scrollable-container {
        overflow-x: hidden;
        display: flex;
        gap: 16px;
        padding: 4px;
        align-items: center;
        transition: var(--base-transition);
        margin-inline: 24px;
        height: var(--_height);
    }

    &__nav-icon {
        position: absolute;
        font-size: 20px;
        color: #747474;
        cursor: pointer;
        padding: 4px;
        pointer-events: initial;
        top: 50%;
        transform: translateY(-50%);

        &-right {
            right: 0px;
        }

        &-left {
            left: 0px;
        }
    }

    &__section {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;

        &:first-child {
            text-align: center;
        }
    }

    &__content {
        display: flex;
        align-items: stretch;
        gap: 16px;
    }

    &__header {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    &__card {
        display: flex;
        padding: 12px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-evenly;
        gap: 10px;
        border-radius: 6px;
        height: 116px;
        min-width: 418px;
        background: var(--Light-Blue-5, #f5f9fe);

        &__header {
            display: grid;
            grid-template-areas:
                'title subtitle toggle'
                'action action action';
            align-items: center;
            column-gap: 4px;

            &:has(.competitors-section__card__header-toggle) {
                justify-items: start;
                width: 100%;
            }

            &-action {
                grid-area: action;
                display: flex;
                align-items: center;
                gap: 4px;
            }

            &-toggle {
                grid-area: toggle;
                justify-self: end;
            }

            &__photo-icon {
                display: inline-grid;
                place-content: center;
                background-color: #d5e1ef;
                padding: 2px;
                border-radius: 4px;
                height: min-content;
                cursor: pointer;
                svg {
                    color: var(--clr-blue-400, #378ef0);
                    fill: var(--clr-blue-400, #378ef0);
                    width: 12px;
                    height: 12px;
                }
            }
        }

        &__content {
            width: 100%;
            display: flex;
            align-items: center;
            gap: 8px;
        }
    }

    &__infobox {
        padding: 8px;
        display: flex;
        flex-direction: column;
        flex: 1;
        gap: 2px;
        border-radius: 4px;
        border: 1px solid var(--Gray-300, #eaeaea);
        background: var(--White, #fff);

        &-active {
            border-color: var(--blue-400, #378ef0);
        }
    }

    &__separator {
        width: 1px;
        height: 154px;
        background-color: var(--gray-300, #eaeaea);
    }

    p {
        margin: 0px;
    }

    &__title {
        @extend .font-size-100-semibold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 100%;
    }

    &__subtitle {
        @extend .font-size-75-regular;
        strong {
            font-weight: 600;
            line-height: 16px;
        }
    }

    &__info-label,
    &__tag {
        @extend .font-size-50-bold;
        font-size: 10px;
        color: var(--Gray-600, #959595);
    }

    &__tag {
        background: none;
        border-radius: 10px;
        border: 1px solid var(--Gray-700, #747474);
    }

    &__info-value {
        @extend .font-size-100-semibold;
        text-transform: uppercase;
    }

    .competitors-section__icon {
        color: var(--_icon-clr);
    }
}
