@import '../../../../../style/typography.scss';

.header {
    @extend .font-size-75-regular;
    display: block;

    background: var(--white);
    padding: 12px;
    border-radius: 6px;
    margin-block-end: 12px;

    & > * {
        display: flex;
        align-items: center;
        gap: 0.5rem;
        flex-wrap: wrap;
    }

    a {
        display: flex;
        align-items: center;
        gap: 4px;
    }
}
