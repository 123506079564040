@import '../abstracts/variables.scss';

.edit-saved-report-form {
    .go-back-button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 20px 0 20px 32px;

        img {
            margin-right: 14px;
        }
    }

    .rs-content {
        border-radius: 6px;
    }

    .edit-saved-header {
        font-weight: 600;
        font-size: $font-size-20;
        color: $gray-900;
    }

    .edit-saved-name {
        .input-name-label {
            font-weight: 700;
            font-size: $font-size-14;
            text-transform: uppercase;
            color: $gray-900;
        }

        .input-name {
            border: 1px solid #e5e5ea !important;
            border-radius: 4px !important;
            margin-top: 6px;
            width: 584px;
        }
    }

    .edit-saved-recorrencia {
        .bold-text {
            font-weight: 600;
            font-size: $font-size-12;
            text-transform: uppercase;
            color: $gray-900;
            margin: 0 6px;
        }

        .sub-text {
            font-weight: 400;
            font-size: $font-size-12;
            color: $gray-900;
            margin: 0;
        }

        .toggle-wrapper {
            display: flex;
            align-items: center;
            // margin-top: 25px;

            .bold-text, .sub-text {
                font-size: $font-size-14;
            }

        }



        .time-frequency-container {
            border-radius: 6px;
            padding: 16px;
            margin-top: 16px;
            border: 1px solid $gray-300;
            position: relative;

            .disable-recorrencia {
                position: absolute;
                top: 0;
                right: 0;
                width: 100%;
                height: 100%;
                background: #ffffff;
                opacity: 0.6;
                z-index: 9;
                border-radius: 6px;
            }
        }

        .radio-wrapper {
            display: flex;
            align-items: center;

            .bold-text {
                margin: 0 66px 0 0;
            }

            .rs-radio-checker {
                label {
                    display: flex;
                    align-items: center;

                    img {
                        margin-right: 10px;
                    }
                }
            }
        }

        .select-wrapper {
            padding: 0 0 0 140px;
            margin-top: 8px;
            width: 447px;

            .rs-picker-select {
                display: block !important;
            }

            .rs-picker-check {
                display: block !important;
            }
        }

        .date-wrapper {
            display: flex;
            align-items: center;
            margin-top: 24px;

            p {
                margin: 0 16px 0 0;
            }

            img {
                margin-left: 10px;
            }

            .rs-picker-date {
                width: 307px;
            }

            .error {
                margin-bottom: 16px;

                .rs-picker-toggle {
                    border: 1px solid #EC5B62;
                }
            }
        }

    }

}
