.modal-competition {
    :global(.rs-modal-body) {
        padding-bottom: 10px !important;
    }
}

.content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}



