.historico-preco-page {

    h1 {
        font-size: 20px;
        line-height: 35px;
    }

    .rs-btn-primary {
        text-transform: uppercase;
        font-weight: 600;
    }

    .filtros {
        .tooltip-info.rs-icon {
            color: #bdbdbd;
            position: relative;
            top: 12px;
        }
    }

    .rs-picker-input {
        margin-right: 0;
    }

    .valid-form {
        border-color: red;
        border-width: 2px;
    }

    .rs-help-block {
        color: red;
    }

    .rs-auto-complete {
        .rs-picker-search-input {
            padding-left: 0;
            padding-bottom: 0;
        }
    }

    .invalid-input {
        border-color: red !important;
        border-width: 2px !important;

        &:hover,
        &:active {
            border-color: red !important;
            border-width: 2px;
        }
    }


    .mg-bottom-0 {
        margin-bottom: 0 !important;
    }

    .warning-limit-lojas {
        margin-left: 5px;
        color: #ffc107;
    }

    .container {
        max-height: -moz-max-content;
        max-height: -webkit-max-content;
    }

    #container {
        display: -ms-grid;
        -ms-grid-columns: max-content;
    }

    .product-filter-grid {
        margin-top: 6px;
    }
}

.chart-tooltip {
    max-height: 550px;
    width: 323px;
    box-shadow: 1px 1px 1px 1px #ccc;
    background-color: #ffffff;
    padding: 15px;
    border-radius: 4px;
    overflow: auto;

    h1 {
        color: #525252;
        font-family: "Roboto";
        font-size: 14px;
        letter-spacing: 0.15px;
        line-height: 19px;
        text-align: center;
    }

    .date-coleta {
        color: #9e9e9e;
        font-family: "Roboto";
        font-size: 15px;
        letter-spacing: 0.15px;
        line-height: 19px;
        text-align: center;
        margin-bottom: 22px;
    }

    .div-img {
        text-align: center;
        margin: 15px 0;

        img {
            height: 210px;
            width: 280px;
        }
    }

    .div-promo {
        box-sizing: border-box;
        height: auto;
        width: 285px;
        border: 1px solid #2188e5;
        border-radius: 4px;
        background-color: #cde7ff;
        padding: 6px 7px;
        margin-bottom: 15px;
    }

    .div-clube-desconto {
        box-sizing: border-box;
        height: 29px;
        width: 138px;
        border: 1px solid #2188e5;
        border-radius: 4px;
        background-color: #cde7ff;
        padding: 6px 7px;
        margin-bottom: 15px;
    }

    .div-promo p,
    .div-clube-desconto p {
        color: #2188e5;
        font-family: "Roboto";
        font-size: 12px;
        letter-spacing: 0.12px;
        line-height: 16px;
    }

    .label-item {
        color: #9a9a9a;
        font-family: "Roboto";
        font-size: 11px;
        font-weight: bold;
        letter-spacing: 0.11px;
        line-height: 13px;
    }

    .item-description {
        color: #9a9a9a;
        font-family: "Roboto";
        font-size: 14px;
        letter-spacing: 0.15px;
        line-height: 19px;
        margin-top: 3px;
    }

    &::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(204, 204, 204, 0.603);
        background-color: #f5f5f5;
    }

    &::-webkit-scrollbar {
        width: 6px;
        background-color: #f5f5f5;
    }

    &::-webkit-scrollbar-thumb {
        background-color: #ccc;
    }
}
