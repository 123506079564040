.table-competitors {
    border-radius: 6px;

    .rs-table-cell-header {
        .rs-table-cell-content {
            display: flex;
            align-items: center;

            line-height: normal;
            overflow: unset;
            text-overflow: unset;
            white-space: normal;
        }
    }
}
