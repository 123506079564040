@import '../../../../../../../style/abstracts/variables.scss';

.first-chart-container {
    display: flex;
    background-color: white;
}

.product-elastic {
    min-width: 320px;
    width: 320px;
    height: 254px;
    border: 1px solid;
    background-color: red;
}

.forecast-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;

    section {
        display: flex;
        justify-content: space-between;
        gap: 12px;

        div {
            flex: 1;
        }
    }
}

.margin-level {
    border-radius: 0.375rem;
    background: var(--gray-100, #f5f5f5);
}
