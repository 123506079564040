@import '../../../../style/abstracts/variables.scss';

.first-chart-container {
    display: flex;
}

.product-elastic {
    min-width: 320px;
    width: 320px;
    height: 254px;
    border: 1px solid;
    margin-right: 12px;
}

.forecast-container {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    grid-template-rows: repeat(2, 121px);
    grid-column-gap: 12px;
    grid-row-gap: 12px;
    width: 100%;
}

.div1 { grid-area: 1 / 1 / 2 / 3; }
.div2 { grid-area: 1 / 3 / 2 / 5; }
.div3 { grid-area: 1 / 5 / 2 / 7; }
.div4 { grid-area: 2 / 1 / 3 / 4; }
.div5 { grid-area: 2 / 4 / 3 / 7; }
