.indicator-wrapper {
    display: flex;
    align-items: center;
}

.text-negative, .text-positive, .text-default {
    font-size: 11px;
    font-weight: 600;
    margin: 0;
    text-transform: uppercase;
}

.text-default, .icon-default {
    color: #747474;
}

.icon-negative, .text-negative {
    color: #EC5B62;
}

.icon-positive, .text-positive {
    color: #33AB84;
}

.icon-negative, .icon-positive, .icon-default {
    font-size: 12px !important;
}
