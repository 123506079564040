.notification {
    :global(.rs-notification-item-content) {
        background: #F5FBF9;
        border-radius: 6px;
    }

    :global(.rs-notification-content) {
        gap: 0;
        padding-right: 16px;
    }
}

.description-flex {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 400;

    img {
        width: 18px;
    }
}

.link-flex {
    display: flex;
    align-items: center;
    font-size: 12px;
    font-weight: 400;
    padding: 0 0 0 12px;
}

.text {
    margin-left: 12px;
}
