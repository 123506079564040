.cadastro-blocos-ofertas-page {

    .rs-btn-primary,
    .rs-btn-ghost {
        font-weight: 700;
    }

    .rs-content {
        flex: none;
        border-radius: 6px;
        margin: 24px;
    }

    .rs-flex-box-grid-item {
        margin-bottom: 0;
    }

    .rs-content.bloco-de-ofertas {
        margin: 0 24px 24px 24px !important;

        h3 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            color: #323232;
            line-height: normal;

            i {
                color: #378EF0;
                margin-left: 10px;
            }
        }

        .blocos-de-oferta-container {
            margin-top: 16px;

            .no-data {
                background: #F5F9FE;
                border-radius: 4px;
                padding: 16px;
                flex-direction: column;
                gap: 16px;

                p {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    color: #323232;
                }

                .skip-button {
                    font-size: 12px;
                    padding: 0;
                }
            }
        }
    }

    .rs-content.navegacao-blocos-ofertas {
        margin: 0 24px 24px 24px !important;

        .rs-btn-link,
        .rs-btn-primary {
            display: flex;
            align-items: center;

            .MuiSvgIcon-root {
                margin-right: 15px;
                font-size: 20px;
            }
        }
    }
}

.bloco-ofertas-modal {
    gap: 20px;
}

.input-outline-no-icon {
    .rs-input {
        border: 1px solid #e5e5ea !important;
        border-radius: 5px !important;
        padding: 6.7px;
    }

    .rs-input:focus,
    .rs-input:hover {
        border-color: #378EF0 !important;
        outline: 0;
    }
}
