.sidebar-mobile-menu {
    width: 210px;
    height: 100vh;
    position: absolute;
    top: 105px;
    background: #FFFFFF;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    transition: all 0.5s ease;
    left: -210px;

    .rs-sidenav {
        background-color: #FFFFFF !important;

        .rs-sidenav-body {
            .rs-nav {
                ul {
                    li {
                        a {
                            font-size: 12px;

                            i {
                                line-height: 1;
                            }
                        }
                    }
                }
            }
        }
    }

    &.open {
        left: 0;
    }
}
