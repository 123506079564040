.cadastro-campanhas-extracao-page {

    .rs-btn-primary,
    .rs-btn-ghost {
        font-weight: 700;
    }

    .rs-content {
        flex: none;
        border-radius: 6px;
        margin: 24px;
    }

    .rs-flex-box-grid-item {
        margin-bottom: 0;
    }

    .rs-content.navegacao-extracao {
        margin: 0 24px 24px 24px !important;

        .rs-btn-link,
        .rs-btn-primary {
            display: flex;
            align-items: center;

            .MuiSvgIcon-root {
                margin-right: 15px;
                font-size: 20px;
            }
        }
    }
}
