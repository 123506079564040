.no-data-results {
    display: flex;
    flex-direction: column;
    align-items: center;

    img {
        aspect-ratio: 1;
        width: 250px;
        margin-block-end: 8px;
    }

    button {
        margin-block-start: 16px;
    }

    a {
        all: unset;
    }
}
