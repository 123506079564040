.store-card {
    background: none;
    padding: 0px;
    width: 100%;
    display: block;

    &__card {
        border-color: #559ff2;
    }
    .info-card__title {
        white-space: nowrap;
        .font-size-75-regular {
            color: var(--gray-600);
        }
    }

    &__content {
        margin-block-start: 4px;
        text-align: justify;

        div {
            margin-block-start: 8px;
        }
    }

    &:disabled {
        &__card {
            border: 1px solid var(--gray-500, #bcbcbc);
            background: var(--gray-100, #f5f5f5);
        }
    }
}
