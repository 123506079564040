.btn-back {
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin: 16px 0 0 20px;

    img {
        margin-right: 12px;
    }

    &:hover, &:focus {
        background: transparent;
    }
}

.header {
    background: #ffffff;
    border-radius: 6px;
    margin: 12px 20px;
    text-align: center;
    padding: 12px;
}

.title {
    font-size: 18px;
    font-weight: 600;
    color: #323232;
}

.flex-container {
    display: flex;
    margin: 0 20px 90px;
}

.columns-container {
    width: 100%;
    &:not(:last-child) {
        margin-right: 16px;
    }
}
