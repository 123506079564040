.table-scenario-container {
    margin: 0 20px 90px 20px;
    background: #ffffff;
    border-radius: 6px;
}

.main-bold-text, .regular-bold-text {
    font-size: 14px;
    color: #323232;
    margin: 0;
}

.main-bold-text {
    font-weight: 600;
}

.regular-text {
    font-weight: 400;
}

.subtext {
    font-size: 11px;
    font-weight: 400;
    color: #747474;
    margin: 0;
}

.table-scenario {
    border-radius: 6px;
}

.table-scenario-row {
    :global(.rs-table-cell-header > .rs-table-cell > .rs-table-cell-content) {
        display: flex;
        align-items: center;
    }
}

.table-header-text {
    margin: 0;
    line-height: 14px;
}

.btn-edit {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
}

.icon-edit {
    color: #959595;
    font-size: 20px;
}

.sub-flex {
    display: flex;
    align-items: center;

    :global(.indicator) {
        margin-left: 4px;
    }
}

.product-subtext-flex {
    display: flex;
    align-items: center;
}

.chevron-right {
    margin: 0 4px;
    font-size: small;
}
