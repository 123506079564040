.chip {
    background-color: transparent;
    color: #a5ccf8;
    border-color: #a5ccf8;

    border-style: solid;
    border-width: 1px;
    border-radius: 9999px;
    padding: 1px 6px;

    font-family: Open Sans;
    font-size: 10px;
    font-style: normal;
    font-weight: 700;
    line-height: 12px; /* 120% */
    text-transform: uppercase;
}
