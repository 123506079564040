.table {
    margin: 0 0 10px 0;
    background: #ffffff;
    border-radius: 6px;
    border: 1px solid var(--gray-300);
}

.regular-text {
    font-weight: 400;
}

.text-11 {
    font-size: 11px;
    margin: 0;
}

.text-12 {
    font-size: 12px;
    color: #747474;
    margin: 0;
}

.text-14 {
    font-size: 14px;
    color: #323232;
    margin: 0;
}

.bold {
    font-weight: 600;
}

.e {
    margin: 0 3px;
}

.leader {
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    background: var(--gray-200);
    border-radius: 4px;
    padding: 1px;
    margin-left: 6px;
}

.table-new-price {
    border-radius: 6px;
}

.table-new-price-row {
    :global(.rs-table-cell-header > .rs-table-cell > .rs-table-cell-content) {
        display: flex;
        align-items: center;
    }
}

.sub-flex {
    display: flex;
    align-items: center;

    :global(.indicator) {
        margin-left: 4px;
    }
}

.flex {
    display: flex;
    align-items: center;
    height: -webkit-fill-available;
}

.input-group {
    margin-bottom: 4px;
    input {
        height: 32px !important;
    }

    span {
        padding: 8px;
    }
}

.flex-column {
    display: flex;
    flex-direction: column;

    p {
        margin: 0;
        line-height: 14px;
    }
}

.btn-delete {
    width: 36px;
    height: 36px;
    background: #eaeaea;
    padding: 8px !important;

    svg {
        width: 20px;
        height: 20px;
        color: #959595;
    }
}
