.subtitle-wrapper {
    display: flex;
    align-items: center;
}

.subtitle {
    font-size: 12px;
    font-weight: 400;
    color: #959595;
    margin-right: 8px;
}

.header-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.btn-consult {
    padding: 6px 10px !important;
    color: #0D66D0;
    background: #F5F9FE;
    font-size: 12px;
    height: fit-content;
}

.btn-apply {
    font-size: 14px;
    font-weight: 700;
    text-transform: uppercase;
}
