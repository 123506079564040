.modal-banner-component {

    .rs-modal-content {
        padding: 0;
        display: table;
    }

    .image-area {
        display: table-cell;

        img {
            border-top-left-radius: 6px;
            border-bottom-left-radius: 6px;
        }
    }

    .message-area {
        display: table-cell;
        padding: 0 63px;
        vertical-align: middle;
        background-color: #CCE9FA;
        border-top-right-radius: 6px;
        border-bottom-right-radius: 6px;

        p {
            font-size: 24px;
            margin-bottom: 32px;
            max-width: 234px;
            text-align: center;
        }

        button {
            font-weight: bold;
            text-transform: uppercase;
        }
    }

    .container {
        background-color: transparent;
        bottom: 0;
        height: 5px;
        position: absolute;
        right: 0;
        width: 100%;
        border-bottom-left-radius: 6px;
        border-bottom-right-radius: 6px;
    }

    .container .progress-bar {
        animation: progress-animation 13s infinite;
        background-color: #1E88E5;
        border-radius: 0px 6px 6px 6px;
        height: 100%;
        position: absolute;
    }

    @keyframes progress-animation {
        0% {
            width: 0%;
        }

        100% {
            width: 100%
        }
    }
}
