.cadastro-dados-campanha-page {

    .rs-btn-primary,
    .rs-btn-ghost {
        font-weight: 700;
    }

    .rs-content {
        flex: none;
        border-radius: 6px;
    }

    .rs-content.dados-campanha-header {
        margin: 0 24px 24px 24px !important;

        .rs-form-group {
            margin-bottom: 0 !important;

            .rs-form-control-wrapper {
                margin-left: 0;
            }
        }

        .nome-campanha {
            width: 512px;
        }

        .data-campanha {
            width: 167px;
        }
    }

    .rs-flex-box-grid-item {
        margin-bottom: 0;
    }

    .dados-campanha-header {
        h2 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            color: #323232;
            line-height: normal;
            margin-bottom: 32px;
        }

        .label {
            margin-bottom: 5px;
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            color: #323232;
        }

        .dados-campanha-form-container {
            gap: 16px;

            .dados-campanha-input-container {
                display: flex;
                flex-direction: column;
            }
        }
    }

    .rs-content.navegacao-dados-campanha {
        margin: 0 24px 24px 24px !important;

        .rs-btn-link,
        .rs-btn-primary {
            display: flex;
            align-items: center;

            .MuiSvgIcon-root {
                margin-right: 15px;
                font-size: 20px;
            }
        }
    }
}
