@import '../../../../../../style/abstracts/variables.scss';

.generate-average-box {
    padding: 12px;
    background: #FFFFFF;
    border-radius: 6px;
    max-width: 363px;
}

.generate-average-title {
    font-weight: 600;
    font-size: 14px;
    color: $gray-900;
    margin-bottom: 25px;
}

.gauge-chart-container {
    margin-bottom: 12px;
}

.average-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.result-text-wrapper {
    display: flex;
    justify-content: center;
}

.regular-text {
    font-weight: 400;
    font-size: 12px;
    color: $gray-600;
}

.bold-text {
    font-weight: 600;
    font-size: 12px;
    color: $gray-700;
    margin: 0 0 0 4px;
}

.item-selected-text {
    font-weight: 600;
    font-size: 12px;
    color: $gray-800;
    margin-top: 8px;
}
