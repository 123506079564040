.filtro-dinamico {
    margin-bottom: 0px !important;

    >div:first-child {
        min-width: 110px;
    }

    &__tag {
        padding: 5px 10px !important;
        margin-right: 10px;
        width: 100%;
        text-align: center;
        border: 2px solid #3597fa;
        color: #3597fa !important;
    }

    &__delete-botton {
        margin-left: 10px;
    }
}

.nav-filtro-dinamico {
    width: 100%;

    &__title-div {
        color: #1787e8;
        display: inline-flex;
        align-items: center;
    }

    &__title {
        padding-left: 10px;
        line-height: 15px;
        font-weight: 700;
    }

    .nav-item {

        a {
            color: #575757;
        }

        &__block-icon {
            margin-left: 10px;
            font-size: 14px;
        }
    }

    .info-icon {
        margin-left: 10px;
    }
}

.popover-filtro-dinamico {
    &__row {
        display: flex;
        width: 100%;

        @media (max-width: 760px) {
            flex-direction: column;
        }
    }

    &__col {
        margin-bottom: 15px;
        padding-right: 15px;
        min-width: 140px;
    }

    .location-margin-right {
        margin-right: 1px;
    }

    .shopping-brasil {
        padding: 8px;
        background: #F5F9FE;
        border: 1px solid #E1EEFD;
        border-radius: 6px;

        .shopping-brasil-logo {
            display: flex;
            justify-content: flex-end;
            margin-top: 8px;
        }
    }

    .shopping-brasil-locked {
        padding: 8px;
        background: #F5F5F5;
        border: 1px solid #EAEAEA;
        border-radius: 6px;

        .shopping-brasil-logo {
            display: flex;
            justify-content: flex-end;
            margin-top: 8px;
        }
    }
}
