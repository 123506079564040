@import '../../../../style/abstracts/variables.scss';

.container {
    border: 1px solid #EAEAEA;
    border-radius: 6px;
    padding: 12px;
    background: #fff;
    margin-top: 12px;
    width: 49%;
}

.header-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.select {
    :global(.rs-picker-toggle) {
        border: none;
        background: #f7f7fa !important;
        font-size: 12px !important;
        padding: 6px 32px 6px 12px !important;

        :global(.rs-picker-toggle-caret) {
            top: 6px;
        }
    }
}

.title {
    font-size: 14px;
    font-weight: 600;
    color: #323232;
    margin: 0;
}

.subtitle {
    font-size: 12px;
    font-weight: 400;
    color: #323232;
    margin: 0;
}
