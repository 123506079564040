$format-default-icon-container-size: 36px;
$format-default-icon-size: 18px;

$format-compact-icon-container-size: 26px;
$format-compact-icon-size: 14px;

$formats: (
    'default' $format-default-icon-container-size $format-default-icon-size,
    'compact' $format-compact-icon-container-size $format-compact-icon-size
);

.chip-icon {
    display: grid;
    place-content: center;
    border-radius: 50%;
    background: var(--gray-300, #eaeaea);

    @each $format, $container-size, $icon-size in $formats {
        &-format-#{$format} {
            width: $container-size;
            aspect-ratio: 1;
            svg {
                aspect-ratio: 1;
                width: $icon-size;
            }
        }
    }
}
