.share-analyze {
    .share-content {
        background-color: #ffffff;
        border-radius: 6px;
        padding: 24px;
    }

    .share-analyze-navbar {
        .nav-title {
            line-height: 56px;
            margin-left: 42px;
        }

        .rs-nav-item-content {
            padding: 10px !important;

            &:hover {
                background-color: #1e88e5 !important;
            }

            .rs-btn-primary {
                background-color: #1e88e5 !important;
            }

            .rs-btn-ghost {
                background-color: #ffffff !important;
                margin-left: 10px;
            }
        }
    }

    .share-analyze-container {
        background-color: #f5f5f5;
        padding: 94px 40px 40px;

        .share-analyze-header {

            .filter-wrapper {
                margin-top: 40px;

                .filter-title {
                    font-size: 16px;
                }

                .filter-subtitle {
                    color: #747474;
                }

                .filter-items {
                    margin-top: 24px;

                    .data-content-items-wrapper {
                        display: flex;
                        flex-wrap: wrap;
                    }
                }

                .filter-values:not(:last-child) {
                    margin-right: 4px;
                }

                .filter-values {
                    height: 36px;
                    background: #F5F9FE;
                    border-radius: 18px;
                    color: #0D66D0;
                    padding: 8px 12px;
                    width: fit-content;
                    margin-top: 8px;
                }

                .expired-date {
                    height: 36px;
                    background: #FEF9F4;
                    border-radius: 18px;
                    color: #E68619;
                    padding: 8px 12px;
                    width: fit-content;
                    margin-top: 8px;
                }
            }
        }

        .share-analyze-chart-views {
            margin-top: 24px;

            .chart-views-title {
                font-size: 20px;
            }

            .chart-views-subtitle {
                color: #747474;
            }

            .product-card-wrapper {
                margin-top: 40px;
            }

            .box-plot-chart, .top-prices-chart {
                padding: 22px 16px;
            }

            .time-evolution-wrapper, .map-chart-wrapper {
                margin-top: 40px;
            }

            .expired-analysis {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-direction: column;
                margin-top: 64px;

                h4 {
                    font-size: 20px;
                    margin-bottom: 8px;
                }

                p {
                    margin-bottom: 24px;
                }
            }
        }
    }
}
