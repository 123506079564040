@import '../../../../../../../../style/abstracts/variables.scss';

.container {
    border: 1px solid #eaeaea;
    border-radius: 6px;
    padding: 12px;
    background: #fff;
    margin-top: 12px;
    width: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.header-wrapper {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
}

.title {
    font-size: 14px;
    font-weight: 600;
    color: #323232;
    margin: 0;
}

.subtitle {
    font-size: 12px;
    font-weight: 400;
    color: #323232;
    margin: 0;
}
