.modulo-relatorio {
    .content-area {
        margin: 20px;
        position: relative;

        .rs-content {
            margin: 0 !important;
            border-radius: 0 6px 6px 6px;

            .panel-header-title-wrapper {
                display: flex;
                align-items: baseline;

                p {
                    margin-right: 8px;
                }

                .panel-header-subtitle {
                    font-weight: 400;
                    font-size: 12px;
                    color: #747474;
                    margin: 0;
                }
            }
        }

        .content-title {
            font-weight: 600;
            font-size: 16px;
            color: #323232;
            margin-bottom: 32px;
        }

        .content-subtitle {
            margin-bottom: 16px;

            .bold-text {
                font-weight: 700;
                font-size: 14px;
                text-transform: uppercase;
                color: #505050;
                margin-right: 8px;
            }

            .regular-text {
                font-size: 12px;
            }

            .link-text {
                font-size: 12px;

                button {
                    background: none;
                    border: none;
                    color: #378EF0;
                }
            }
        }

        .rs-panel {
            border: 1px solid #EAEAEA;
            margin-bottom: 24px;

            .rs-panel-heading {
                padding: 12px 16px;
                background: #F5F5F5 !important;
                border-radius: 6px 6px 0px 0px;
            }

            .dnd-columns-wrapper {
                display: flex;

                .column {
                    width: 50%;
                    padding: 18px;

                    .dnd-column-title {
                        font-size: 12px;
                        color: #505050;
                        margin-bottom: 10px;
                    }

                    .dnd-column-content {
                        display: flex;
                        flex-flow: wrap;
                    }
                }

                .first-column {
                    border-right: 1px solid #EAEAEA;
                }

                .second-column {
                    border-left: 1px solid #EAEAEA;
                }

                .dnd-buttons-wrapper {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;

                    .button-send-right,
                    .button-send-left {
                        padding: 5px;
                        margin: 2px;
                        background: transparent;
                    }
                }

                .dnd-movable-item {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    align-items: center;
                    padding: 8px 12px 8px 16px;
                    margin: 4px;
                    width: fit-content;
                    height: 36px;
                    background: #F5F5F5;
                    border-radius: 18px;
                    cursor: grab;

                    img {
                        margin-right: 8px;
                    }

                    &.column-2 {
                        color: #2680EB !important;
                        background: #F5F9FE !important;
                    }

                    &.disabled {
                        opacity: 0.5 !important;
                    }
                }

                .dnd-movable-select {
                    position: relative;
                    margin: 4px;
                    cursor: grab;

                    img {
                        position: absolute;
                        top: 12px;
                        left: 14px;
                        z-index: 8;
                    }

                    #check-picker-pill, #select-picker-pill {
                        padding: 8px 30px 12px 30px !important;
                    }

                    .rs-picker-has-value {
                        #check-picker-pill, #select-picker-pill {
                            padding: 8px 48px 12px 30px !important;
                        }
                    }

                    &.column-2 {
                        #check-picker-pill, #select-picker-pill {
                            background: #F5F9FE !important;

                            .rs-picker-toggle-placeholder {
                                color: #2680EB !important;
                            }
                        }
                    }
                }
            }
        }

        .date-interval-panel {
            .rs-panel-collapse {
                .rs-panel-body {
                    padding-top: 18px;

                    .section-title-wrapper {
                        display: flex;
                        align-items: center;
                        margin-bottom: 12px;

                        p {
                            font-weight: 600;
                            font-size: 12px;
                            text-transform: uppercase;
                            color: #323232;
                            margin-right: 8px;
                        }
                    }

                    .inf-pill-button {
                        display: flex;
                        flex-direction: row;
                        justify-content: center;
                        align-items: center;
                        padding: 8px 12px;
                        margin-right: 8px;

                        background: #F5F5F5;
                        border-radius: 18px;

                        &.active {
                            color: #2680EB;
                            background: #F5F9FE;
                        }
                    }

                    .section-period {
                        margin-bottom: 32px;
                    }

                    .buttons-wrapper {
                        display: flex;

                        .select-interval {
                            margin-right: 8px;
                            &.active {
                                #select-picker-pill {
                                    background: #F5F9FE !important;

                                    .rs-picker-toggle-placeholder {
                                        color: #2680EB !important;
                                    }
                                }
                            }
                        }



                    }
                }
            }

        }

        .rs-panel-collapsible .rs-panel-heading::before {
            top: 14px;
        }

        .product-panel,
        .metricas-panel {
            .rs-panel-body {
                padding: 0;
            }
        }

        .metricas-panel {

            .dnd-buttons-wrapper {
                padding-top: 50px;
            }

            .dnd-columns-wrapper:last-of-type {
                .dnd-buttons-wrapper {

                    padding-bottom: 30px;
                }
            }

            .text-border-wrapper {
                display: flex;
                align-items: center;
                padding: 0 0 12px 0;

                .toggle-wrapper {
                    display: flex;
                    align-items: center;
                    margin-right: 8px;

                    .rs-btn-toggle {
                        margin-right: 4px;
                    }

                    label:first-of-type {
                        margin-right: 10px;
                    }
                }

                .text {
                    font-size: 12px;
                    font-weight: 600;
                    color: #323232;
                    margin-right: 8px;
                    white-space: nowrap;
                }

                .side-border {
                    border-top: 1px solid #EAEAEA;
                    width: 100%;
                }
            }
        }

        .generate-report-wrapper {
            display: flex;
            justify-content: flex-end;
            margin: 0 0 72px 0 !important;

            .generate-report-button, .edit-save-report-button {
                font-weight: 700;
                font-size: 16px;
                text-transform: uppercase;
            }

            .save-report-button, .edit-go-back-button {
                font-weight: 700;
                font-size: 16px;
                text-transform: uppercase;
                margin-right: 12px;

                img {
                    margin-right: 14px;
                }
            }

            .edit-save-report-button {
                display: flex;
                align-items: center;

                img {
                    margin-right: 14px;
                    filter: brightness(0) invert(1);
                }
            }
        }

        .history-report {
            .content-title-wrapper {
                display: flex;
                margin-bottom: 16px;

                .content-title {
                    margin: 0 10px 0 0;
                }
            }

            .history-report-table {
                border-radius: 6px;

                .download-cell {
                    .rs-table-cell-content {
                        padding: 5px 10px !important;
                    }
                }

                .status-element-wrapper {
                    display: flex;
                    align-items: center;

                    img {
                        margin-right: 10px;
                    }

                    p {
                        margin: 0;
                    }
                }
            }

            .history-report-pagination {
                padding-bottom: 40px;

                .rs-divider-vertical {
                    display: none;
                }
            }
        }

        .consolidate-items {
            margin: 24px 0 !important;

            .consolidate-items-title-wrapper {
                display: flex;
                align-items: center;
                margin-bottom: 18px;

                .title {
                    font-weight: 700;
                    font-size: 14px;
                    text-transform: uppercase;
                    color: #505050;
                    margin-right: 8px;
                }

                .subtitle {
                    font-weight: 400;
                    font-size: 12px;
                    color: #747474;
                    margin: 0;
                }
            }

            .consolidate-picker {
                border-radius: 4px;

                a {
                    background: #F5F9FE !important;
                    border: 1px solid #F5F9FE !important;
                }

                .rs-picker-toggle-clean,
                .rs-picker-toggle-caret,
                .rs-picker-toggle-placeholder {
                    color: #0D66D0 !important;
                }

                &.rs-picker-has-value {
                    .rs-picker-toggle-clean {
                        display: none;
                    }

                    a {
                        padding-right: 32px !important;
                    }
                }
            }

            .consolidate-item-pill-wrapper {
                display: flex;
                margin: 16px 0;

                .consolidate-item-pill {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    padding: 8px 12px 8px 14px;
                    margin-right: 8px;
                    height: 36px;
                    width: fit-content;

                    background: #F5F5F5;
                    border-radius: 18px;

                    button {
                        background: #F5F5F5;
                        padding: 12px;
                    }
                }
            }
        }

        .block-plan {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            position: absolute;
            height: 100%;
            width: 100%;
            background: rgba(55, 142, 240, 0.2);
            top: 0;
            z-index: 5;

            .block-plan-icon-circle {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 96px;
                height: 96px;
                background: #EBF4FE;
                border-radius: 60px;
                margin-bottom: 24px;

                img {
                    height: 63px;
                }
            }

            p {
                font-weight: 600;
                font-size: 20px;
                color: #323232;
                margin: 0;
            }

            button {
                margin-top: 24px;
                font-weight: 700;
                font-size: 16px;
                text-transform: uppercase;
            }
        }

        .coming-soon {
            position: absolute;
            font-weight: 700;
            font-size: 10px;
            text-transform: uppercase;
            background: #378EF0;
            border-radius: 10px;
            color: #FFFFFF;
            padding: 1px 6px;
            top: -10px;
            left: 200px;
            z-index: 2;
        }

        .saved-report-content {
            .content-title-wrapper {
                display: flex;
                justify-content: space-between;
                margin-bottom: 16px;

                .content-title {
                    margin: 0 10px 0 0;
                }

                .input-search-wrapper {
                    position: relative;

                    .rs-icon {
                        position: absolute;
                        top: 11px;
                        left: 16px;
                    }

                    .input-search-report {
                        border: 1px solid #e5e5ea !important;
                        border-radius: 4px !important;
                        width: 500px;
                        padding-left: 42px;
                    }

                }
            }

            .saved-report-table {
                border-radius: 6px;

                .popup-cell {
                    .rs-table-cell-content {
                        padding: 5px 16px 5px 10px !important;
                        text-align: -webkit-right;

                        .rs-btn-default {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            height: -webkit-fill-available;
                            width: 36px;
                        }
                    }
                }
            }

            .saved-report-pagination {
                .rs-divider {
                    display: none;
                }
            }
        }
    }

    .banner-alert {
        display: flex;
        background: #FEF9F4;
        padding: 16px;
        margin: 0 20px;
        border-radius: 6px;

        .banner-alert-icon {
            width: 20px;
            height: 20px;
            color: #F29423;
            margin-right: 10px;
        }
    }

    @media (max-width: 769px) {
        .content-area {
            .coming-soon {
                left: 120px;
            }
        }
    }
}

.dnd-select-list-footer {
    .footer-first-row {
        display: flex;
        padding: 12px 12px 24px 12px;
        border-top: 1px solid #e5e5e5;
        border-bottom: 1px solid #e5e5e5;

        .rs-btn-toggle {
            margin: 3px 12px 0 0;
        }

        .text-wrapper {
            .title {
                font-size: 14px;
                color: #323232;
            }

            .subtitle {
                font-size: 12px;
                color: #959595;
                margin-top: 2px;
            }
        }
    }

    .footer-second-row {
        display: flex;
        justify-content: flex-end;
        padding: 14px;
    }
}

.modulo-relatorio-modal-understand-better {
    .rs-modal-content {
        padding: 0 !important;
        border-radius: 4px;

        .rs-modal-body {
            margin-top: 0;
            padding-bottom: 0;

            .content {
                padding: 24px;

                h1 {
                    font-weight: 600;
                    font-size: 16px;
                    margin-bottom: 24px;
                    line-height: 20px;
                }
            }
        }
    }

    .rs-modal-footer {
        padding: 24px;
        border-top: 1px solid #EAEAEA;

        button {
            text-transform: uppercase;
            font-weight: bold;
            padding: 8px 27px;
        }

        .rs-uploader {
            .rs-uploader-trigger-btn {

                text-transform: uppercase;
                font-weight: bold;
                padding: 8px 27px;
                color: #378EF0;
                border: 1px solid #378EF0;
                background-color: #FFFFFF;
            }
        }
    }

}

.select-interval-menu {
    padding: 20px 16px !important;
    .rs-picker-none {
        display: none !important;
    }

    .select-interval-input-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        .rs-input-number {
            width: 70px;
            margin: 0 8px;
        }
    }
}

.modal-generate-report-error {
    .rs-modal-header {
        .rs-modal-title {
            white-space: unset !important;
        }
    }
}

.info-blue {
    svg {
        height: 12px;
        color: #378EF0;
    }
}

.info-gray {
    svg {
        height: 12px;
        color: #505050;
    }
}
