.closeButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #378ef0;
}


.box {
    border-radius: 4px;
    padding: 0 2px;
    display: flex;
    align-items: center;
    font-size: 10px;
    line-height: 11px;

    border: 1px solid #bcbcbc;
    background-color: #f5f5f5;
    color: #959595;
}

.addonoffer {
    width: 80px;
}
