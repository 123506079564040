.input-addon {
    border: 1px solid #e5e5ea !important;
    height: fit-content;

    &:hover, &:focus, &.rs-input-group, &:focus-visible {
        border: 1px solid #e5e5ea !important;
        border-color: #e5e5ea !important;
    }

    :global(.rs-input-group-addon) {
        padding: 8px;
    }
}
