.modal-banner-ondemand-component {
    position: relative;
    margin: 60px auto 0 auto !important;

    .rs-modal-content {
        padding: 0;
        display: table;
    }

    .close-button {
        position: absolute;
        right: -8px;
        top: -34px;
        padding: 8px;

        &:hover, &:focus {
            background-color: transparent;
        }
    }

    .image-area {
        cursor: pointer;
    }

    .checkbox-container {
        background-color: transparent;
        bottom: -36px;
        position: absolute;
        right: -206px;
        width: 100%;
        display: flex;
        align-items: center;

        p {
            font-size: 14px;
            color: #ffffff;
        }

        .rs-checkbox-wrapper .rs-checkbox-inner::before {
            background-color: #ffffff;
        }

        .rs-checkbox-checked .rs-checkbox-wrapper .rs-checkbox-inner::before {
            background-color: #3498ff;
        }
    }
}
