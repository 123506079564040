.filters-area-container {
    margin: 0 0 8px 0;
}

.inside-container {
    display: flex;
    justify-content: space-between;
    border: 1px solid #EAEAEA;
    border-radius: 6px;
    padding: 12px;
}

.filters-wrapper {
    display: flex;
    gap: 8px;
}

.btn-clear-filters {
    background: #f5f5f5;
    border-radius: 18px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #505050;
    padding: 8px 12px !important;
    min-height: 36px;
}
