@import '../../style/typography.scss';

.info-radio {
    @extend .font-size-100-regular;

    &-wrapper {
        display: grid;
        place-content: center;
    }

    &-checker label {
        display: flex;
        align-items: center;
        gap: 8px;
    }

    input[type='radio'] {
        --_clr-bg: #fff;
        --_clr-border: var(--gray-400, #d3d3d3);

        display: grid;
        place-content: center;
        appearance: none;
        background-color: var(--_clr-bg);
        margin: 0;
        font: inherit;
        color: currentColor;
        width: 16px;
        height: 16px;
        transition: var(--base-transition);

        border-radius: 50% !important;
        border-top: 1px solid var(--_clr-border) !important;
        border-bottom: 1px solid var(--_clr-border) !important;
        border-right: 1px solid var(--_clr-border) !important;
        border-left: 1px solid var(--_clr-border) !important;

        &::before {
            content: '';
            width: 6px;
            height: 6px;
            border-radius: 50%;
            transform: scale(0);
            transition: var(--base-transition);
            box-shadow: inset 16px 16px var(--white, #fff);
        }

        &:hover {
            --_clr-border: var(--blue-400, #378ef0);
        }
    }

    &-disabled {
        --text-color: var(--gray-900, #323232);
        pointer-events: none;
        input[type='radio'] {
            --_clr-border: var(--gray-400, #d3d3d3);
            --_clr-bg: var(--gray-100, #f5f5f5);
        }
    }

    &-checked {
        font-weight: 600;
        input[type='radio'] {
            --_clr-border: transparent;
            --_clr-bg: var(--blue-400, #378ef0);
            &::before {
                transform: scale(1);
            }
        }
    }
}
