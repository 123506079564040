.container {
    > div:first-child {
        gap: 2px;
    }

    button {
        display: flex;
        color: var(--blue-400, #378ef0);
        align-items: center;
        gap: 2px;
        font-size: 10px;
        font-weight: 400;
        line-height: 16px;
    }
}
