.premium-chip {
    padding: 8px;
    display: flex;
    align-items: center;
    gap: 6px;
    border-radius: 6px;
    border: 1px solid var(--light-blue-45, #a5ccf8);
    background: var(--white, #fff);

    color: var(--gray-900, #323232);
    font-family: Open Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
}
