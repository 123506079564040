.locked-stores-container {
    .locked-stores-title-container {
        padding: 24px !important;
        background-color: white;
        margin: 20px;
    }

    .locked-stores-content {
        .locked-stores-iframe-container {
            margin-top: 40px;
            height: 100vh;

            iframe {
                width: 100%;
                height: 100%;
            }
        }
    }
}