@import '../../../../../../../style/abstracts/variables.scss';

.forecast-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 6px;
    padding: 8px 16px;
    background: #f5f9fe;
    flex: 1;
    height: 100%;
}

.forecast-title {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    color: #323232;
}

.forecast-title-wrapper {
    display: flex;
}

.forecast-side-title {
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    color: #323232;
    margin: 0 0 0 4px;
}

.main-value,
.sub-value {
    font-weight: 600;
    color: #323232;
}

.main-value {
    font-size: 1.5rem;
}

@media (max-width: 1420px) {
    .forecast-container {
        padding: 8px;
    }

    .forecast-title {
        font-size: 10px;
    }

    .main-value {
        font-size: 1rem;
    }
}
