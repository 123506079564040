.previsoes-salvas-page {
    .rs-flex-box-grid-item {
        margin-bottom: 0;
        padding: 0;
    }

    .rs-row:first-child {
        margin-bottom: 16px;
    }

    .rs-content {
        padding: 16px !important;
        margin: 24px !important;
        border-radius: 6px;
    }

    .page-header {
        display: flex;
        align-items: center;

        h2 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 26px;
            color: #323232;
        }
    }

    .page-filtros {
        h3 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 26px;
            color: #323232;
        }

        .campanhas-filtros {
            margin-top: 8px;
        }
    }

    .header-tabela {
        margin-bottom: 16px;
        padding: 17px;

        h3 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #323232;

            strong {
                font-weight: 600;
            }
        }

        &.itens-selecionados {
            background: #F5F9FE;
            border: 1px solid #959595;
            border-radius: 6px;
            padding: 16px;
        }
    }

    .rs-picker-daterange .rs-picker-toggle {
        background: none !important;
        color: transparent !important;

        a {
            color: transparent !important;
        }

        .rs-picker-toggle-placeholder,
        .rs-picker-toggle-caret,
        .rs-picker-toggle-clean,
        .rs-ripple-pond {
            color: transparent !important;
        }
    }

    .filtro-data {
        border: none !important;
        border-radius: 50px !important;
        background: #F5F5F5 !important;
        height: 36px;
        padding: 8px 30px 8px 12px !important;

        i {
            font-size: 10px;
            position: absolute;
            top: 13px;
            margin-left: 8px;
            color: #8e8e93;

            &.remove-icon {
                margin-left: 25px;
            }
        }

        &.active {
            border-bottom-left-radius: 0 !important;
            border-bottom-right-radius: 0 !important;
            border-top-left-radius: 18px !important;
            border-top-right-radius: 18px !important;
        }

        &.selected {
            background: #F5F9FE !important;
            color: #1473E6;
            padding: 8px 46px 8px 12px !important;

            i {
                color: #1473E6;

                &.remove-icon {
                    font-size: 12px;
                }
            }
        }
    }

    .filtro-data:hover {
        background: #eeeeee !important;
    }
}
