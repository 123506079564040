.table {
    border-radius: 6px 6px 0 0;
    border: 1px solid var(--Gray-300, #eaeaea);
    overflow: hidden;

    :global(.rs-table-cell-content) {
        padding: 0px;
        display: flex;
        align-items: center;

        &:has(:global(.rs-checkbox)) {
            display: grid;
            place-content: center;
            padding: 8px;
        }
    }

    :global(.rs-table-cell-header .rs-table-cell-content) {
        padding: 8px;
    }

    :global(.rs-table),
    :global(.rs-table-cell) {
        background-color: transparent;
        border: none;
    }

    :global(.rs-table-row) {
        border-bottom: 1px solid #e5e5e5;
        border-bottom: 1px solid var(--Gray-300, #eaeaea);
    }

    :global(.rs-table-body-row-wrapper .rs-table-row) {
        background-color: var(--white);
        &:nth-child(odd) {
            background-color: #f9f9f9;
        }
    }

    // :global(.rs-checkbox) {
    //     margin: 0 auto;
    // }
}
