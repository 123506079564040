.modal-videos {
    width: 548px !important;
    height: 860px !important;

    .rs-modal-content {
    padding: 24px;
    }

    .modal-panel {
        margin-bottom: 24px;
        .modal-content-panel {
            background-color: #F5F5F5;
            font-weight: 600;
            font-size: 14px;
        }
    }

    .modal-videos-title {
        font-weight: 600;
        font-size: 16px;
        padding-bottom: 24px;
    }

    .rs-panel-heading {
        background: #f5f5f5;
        border: 1px solid #eaeaea;
        height: 44px;
        padding: 10px 20px;

        &::before {
            top: 13px;
        }
    }

    .rs-panel-title {
        font-size: 14px;
        font-weight: 600;
        color: #505050;
        &::before {
            top: 13px;
        }
    }

    .rs-panel-body {
        padding: 0px !important;
        height: 283px;
    }

    .divider-modal-videos {
        position: relative;
        width: 546px;
        right: 23px;
        margin-top: 12px;
        margin-bottom: 12px;
    }

    .button-modal-videos {
        margin-top: 12px;
        position: relative;
        left: 421px;
    }
}

.button-video {
    padding: 0px;
    margin: 0px;
}
