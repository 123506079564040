.regra-atacado-cadastro-page {
    .rs-input,
    .rs-tag-group {
        border: 1px solid #e5e5ea !important;
        border-radius: 5px !important;
    }

    .rs-input-group {
        .rs-input {
            border: 0 !important;
        }

        &:hover,
        &:active,
        &:focus {
            border-color: #e5e5ea !important;
        }
    }

    .rs-input-group-focus {
        border-color: #e5e5ea !important;
    }

    .rs-control-label {
        font-weight: 600;
        color: #8e8e93;
    }

    .margin-10{
        margin: 10px;
    }

    .info-section {
        background-color: #e5e5ea;
        border-radius: 5px;
        padding: 15px 15px;
    }
}
