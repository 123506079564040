@import '../abstracts/variables.scss';

.top-ten-header {
    font-size: $font-size-12;
    margin-bottom: 14px;

    .main-title-header {
        display: flex;

        .top-ten-header-title {
            font-weight: 700;
            text-transform: uppercase;
            color: $gray-900;
            margin-right: 6px;
        }

        .top-ten-header-subtitle {
            margin-right: 6px;
        }

        .rs-btn-link {
            padding: 0;
            font-size: $font-size-12;
            &:hover, &:focus {
                text-decoration: none;
            }
        }

        p {
            margin: 0;
        }
    }

    .sub-header {
        margin-top: 16px;
        .flex-wrapper {
            display: flex;

            p {
                font-size: $font-size-12;
                margin: 0 8px 0 0;
                color: $gray-900;
            }

            .rs-icon {
                font-size: $font-size-12;
            }
        }

        .flex-wrapper:first-of-type {
            margin-bottom: 8px;
        }

        .flex-wrapper-pill {
            display: flex;
            align-items: center;

            .reference-title {
                font-weight: 700;
            }

            .pill-reference {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: $font-size-10;
                font-weight: 700;
                text-transform: uppercase;
                padding: 6px 16px;
                min-width: 74px;
                height: 24px;
                background: #FFFFFF;

                border: 1px solid $gray-600;
                border-radius: 12px;
            }
        }

        .first-wrapper {
            margin: 0 24px 0 0;
        }
    }

}
