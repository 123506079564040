.content {
    display: flex;
}

.flex-center {
    display: flex;
    align-items: center;
}

.flex-base {
    display: flex;
    align-items: baseline;
}

.total-products-box {
    width: fit-content !important;
    min-width: 0 !important;
}
