.rs-navbar.navbar-admin {
    background-color: #378EF0 !important;
    color: white !important;

    a.rs-nav-item-content {
        color: white !important;
    }

    a.link {
        color: white;

        &:hover {
            color: white;
            text-decoration: none;
        }
    }
}

.navbar-admin.rs-navbar.rs-navbar-inverse .rs-nav-item-active>.rs-nav-item-content,
.navbar-admin.rs-navbar.rs-navbar-inverse .rs-dropdown .rs-dropdown-menu-active~.rs-dropdown-toggle,
.navbar-admin.rs-navbar.rs-navbar-inverse .rs-nav-item-active>.rs-nav-item-content:hover,
.navbar-admin.rs-navbar.rs-navbar-inverse .rs-dropdown .rs-dropdown-menu-active~.rs-dropdown-toggle:hover,
.navbar-admin.rs-navbar.rs-navbar-inverse .rs-nav-item-active>.rs-nav-item-content:focus,
.navbar-admin.rs-navbar.rs-navbar-inverse .rs-dropdown .rs-dropdown-menu-active~.rs-dropdown-toggle:focus,
.navbar-admin.rs-navbar.rs-navbar-inverse .rs-nav-item-active>.rs-nav-item-content:active,
.navbar-admin.rs-navbar.rs-navbar-inverse .rs-dropdown .rs-dropdown-menu-active~.rs-dropdown-toggle:active:active:focus:hover {
    background-color: #0D66D0 !important;
    color: white !important;
}

.navbar-admin.rs-navbar.rs-navbar-inverse .rs-navbar-nav .rs-nav-item>.rs-nav-item-content:hover,
.navbar-admin.rs-navbar.rs-navbar-inverse .rs-navbar-nav .rs-dropdown .rs-dropdown-toggle:hover,
.navbar-admin.rs-navbar.rs-navbar-inverse .rs-navbar-nav .rs-nav-item>.rs-nav-item-content:focus,
.navbar-admin.rs-navbar.rs-navbar-inverse .rs-navbar-nav .rs-dropdown .rs-dropdown-toggle:focus {
    background-color: #0D66D0 !important;
    color: white !important;
}

.logo>img {
    width: 177px;
}