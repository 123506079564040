.box-plot-chart {
    border-radius: 5px;
    margin-top: 0 !important;

    .header-area {
        margin-bottom: 15px;

        .rs-flex-box-grid-item {

            &.minimized {
                margin-bottom: 0 !important;
            }
        }
    }

    .more-options-section,
    .expand-section {
        text-align: end;

        button {
            &:focus {
                background-color: unset;
            }
        }

        &__btn {
            padding: 0 20px !important;
        }
    }

    .select-visualization-section {
        font-weight: 600;
        font-size: 12px;

        &__button {
            margin: 0 4px;
            font-size: 12px;
        }

        &__dropdown {
            span {
                color: #1E88E5;
                margin-left: 10px;
            }
        }
    }

    .select-visualize-section {
        &__button {
            margin: 0 8px;

            &.rs-btn-primary {
                svg {
                    vertical-align: middle;

                    rect {
                        fill: white;
                    }

                    path {
                        stroke: white;
                    }
                }
            }

            &.rs-btn-subtle {
                svg {
                    vertical-align: middle;

                    rect {
                        fill: #8e8e93;
                    }

                    path {
                        stroke: #8e8e93;
                    }
                }
            }
        }
    }

    .no-access {
        display: table;
        background-color: rgba(30, 136, 229, 0.32);

        &__main {
            height: 400px;
            display: table-cell;
            vertical-align: middle;
            text-align: center;
        }

        &__icon {
            font-size: 110px;
            color: #1e88e5;
        }

        &__message {
            margin: 20px;
            font-size: 20px;
            max-width: 312px;
            text-align: center;
            display: inline-block;
        }
    }

    .highcharts-container {
        width: 100%;

        .highcharts-root {
            width: 100%;
        }
    }

    .highcharts-label {
        p {
            font-size: 10px;

            img {
                margin-right: 5px;
            }
        }
    }

    .tooltip-boxplot {
        width: 230px;
    }

    .empty-state {
        text-align: center;

        h4 {
            font-size: 20px;
            color: rgba(117, 117, 117, 1);
            margin-bottom: 10px;
        }

        p {
            margin-bottom: 34px;
            color: rgba(117, 117, 117, 1);
        }
    }
}