.rule-status {
    color: #fff;
    border-radius: 16px;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    padding: 5px 16px;

    i {
        margin-right: 6px;
    }

    &.ACTIVE {
        background: #378ef0;
    }
    &.EXPIRED {
        background: #959595;
    }
}
