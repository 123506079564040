@import '../../../../../../../../style/abstracts/variables.scss';

.container {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 16px;

    background-color: #fff;
    border-radius: 6px;

    .section-title {
        display: flex;
        gap: 16px;
        align-items: center;

        color: var(--Gray-900, #323232);
        text-align: center;

        font-family: 'Open Sans';
        font-size: 0.875rem;
        font-style: normal;

        span + span {
            font-weight: 400;
            line-height: 1.25rem; /* 142.857% */
        }

        & > span {
            font-weight: 600;
        }
    }

    .section-filters-container {
        display: flex;
        justify-content: space-between;
        border: 1px solid $gray-300;
        border-radius: 6px;
        padding: 12px;

        .section-filters {
            display: flex;
            gap: 12px;
        }
    }
    .btn-clear-filters {
        background: #f5f5f5;
        color: $gray-800;
        border-radius: 18px;
        height: fit-content;
        margin-left: auto;
    }
}
