.dnd-raio-wrapper {
    display: flex;
}

.arrow-right-icon {
    margin: 0 4px;
}

.dnd-raio {
    position: relative;
    margin: 4px;

    img {
        position: absolute;
        top: 12px;
        left: 14px;
        z-index: 8;
    }

    :global(#select-picker-pill) {
        padding: 8px 30px 12px 30px !important;
        background: #F5F9FE !important;

        :global(.rs-picker-toggle-placeholder) {
            color: #2680EB !important;
        }
    }

    :global(.rs-picker-has-value) {
        :global(#select-picker-pill) {
            padding: 8px 48px 12px 30px !important;
            background: #F5F9FE !important;

            :global(.rs-picker-toggle-placeholder) {
                color: #2680EB !important;
            }
        }
    }
}
