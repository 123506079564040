.inf-badge {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 14px;
    width: fit-content;

    &.pill-primary {
        border-radius: 15px;
        background: #2680EB;
        color: #ffffff;
        padding: 1px 8px;
        font-size: 10px;
        font-weight: 700;
        text-transform: uppercase;
    }

    &.pill-secondary {
        border-radius: 15px;
        background: #ffffff;
        color: #2680EB;
        border: 1px solid #2680EB;
        padding: 1px 8px;
        font-size: 10px;
        font-weight: 700;
        text-transform: uppercase;
    }

    &.pill-tertiary {
        border-radius: 15px;
        background: #ffffff;
        color: #747474;
        border: 1px solid #747474;
        padding: 1px 8px;
        font-size: 10px;
        font-weight: 700;
        text-transform: uppercase;
    }

}
