.competition-container {
    border-radius: 6px;
    border: 1px solid  #EAEAEA;
    background: #F5F9FE;
    padding: 12px;
}

.title-wrapper {
    margin-bottom: 10px;
}

.title {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
}

.subtitle {
    font-size: 12px;
    font-weight: 400;
    color: #747474;
    margin: 0;
}

.product-title {
    font-size: 14px;
    font-weight: 600;
    color: #323232;
    margin: 0;
}

.price-area {
    margin-top: 16px;
}

.price-row {
    display: flex;

    &:not(:last-child) {
        margin-bottom: 18px;
    }
}

.price-label-wrapper {
    width: 160px;
}

.label {
    font-size: 12px;
    font-weight: 400;
    color: #323232;
    margin: 0;
}

.price {
    font-size: 14px;
    font-weight: 600;
    color: #323232;
    margin: 0;
}
