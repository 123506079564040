@import '../../../../style/abstracts/variables.scss';

.price-settings-area-content {
    margin: 0 20px 12px !important;
    padding: 12px !important;
    border-radius: 6px;
    display: grid;
    grid-template-columns: 356px 212px repeat(2, 292px);
    grid-template-rows: 206px 60px;
    grid-column-gap: 8px;
    grid-row-gap: 8px;
}

.div1 { grid-area: 1 / 1 / 3 / 2; }
.div2 { grid-area: 1 / 2 / 3 / 3; }
.div3 { grid-area: 1 / 3 / 2 / 4; }
.div4 { grid-area: 2 / 3 / 3 / 4; }
.div5 { grid-area: 1 / 4 / 2 / 5; }

