.table {
    --_table-cell-content-padding: 12px;
    &:global(.rs-table-cell-content) > div {
        padding-inline: var(--_table-cell-content-padding);
    }

    .padding-inline-xxs {
        --_table-cell-content-padding: 4px;
    }
}
