.header {
    display: flex;
    flex-direction: column;

    border-radius: 0.375rem;
    background: var(--white, #fff);
    padding: 16px;

    h1 {
        color: var(--gray-900, #323232);
        font-size: 1.125rem;
        font-weight: 600;
        line-height: 1.625rem; /* 144.444% */
    }

    p {
        color: var(--gray-700, #747474);

        font-size: 0.75rem;
        font-weight: 400;
        line-height: 1.125rem; /* 150% */
    }
}

.header-saved-simulation {
    display: flex;
    justify-content: space-between;

    font-family: 'Open Sans', sans-serif;
    font-style: normal;

    .title {
        font-size: 14px;
        color: #505050;
    }

    .icon {
        height: 24px;
        width: 24px;
    }

    .back {
        display: flex;
        align-items: center;
        gap: 10px;

        color: var(--Gray-800, #505050);
        font-family: 'Open Sans';
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }

    .button {
        display: flex;
        align-items: center;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 14px;
        color: #fff;
    }
}
