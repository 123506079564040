.navbar {
    background-color: #eeee !important;
    color: white !important;

    a.rs-nav-item-content {
        color: white !important;

        &:hover {
            background-color: #1d88e5 !important;
            color: white !important;
        }
    }
}

#link-navbar {
    color: white !important;
    position: relative;

    &:before {
        position: absolute;
        content: '';
        top: -15px;
        right: -10px;
        left: -10px;
        bottom: -15px;
    }

    &:hover,
    &:focus {
        color: white !important;
        text-decoration: none !important;
    }
}

.modal-ira {
    .description {
        .icon-itens {
            font-size: 25px;
            margin-right: 10px;
        }

        .itens {
            margin-bottom: 5px;
        }
    }

    img {
        width: 90%;
    }

    .subtitle {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 20px;
    }
}

.logo > img {
    width: 177px;
}

.config-icon-margin-right {
    margin-right: 20px;
}

@media screen and (max-width: 1400px) {
    .rs-nav.rs-nav-horizontal .rs-dropdown,
    .rs-nav.rs-nav-horizontal .rs-nav-item {
        max-width: 240px;

        .rs-nav-item-content {
            padding: 18px 12px;
        }
    }
    .help-center {
        display: contents;
    }
}

@media screen and (max-width: 1300px) {
    .rs-nav.rs-nav-horizontal .rs-dropdown,
    .rs-nav.rs-nav-horizontal .rs-nav-item {
        max-width: 190px;
    }

}

@media screen and (max-width: 1200px) {
    .rs-nav.rs-nav-horizontal .rs-dropdown,
    .rs-nav.rs-nav-horizontal .rs-nav-item {
        max-width: 130px;
    }
}

@media screen and (max-width: 1100px) {
    .logo > img {
        width: 130px !important;
    }

    .rs-nav.rs-nav-horizontal .rs-dropdown,
    .rs-nav.rs-nav-horizontal .rs-nav-item {
        max-width: 130px;
    }
}

@media screen and (max-width: 1000px) {
    .logo > img {
        width: 120px !important;
    }

    .rs-nav.rs-nav-horizontal .rs-dropdown,
    .rs-nav.rs-nav-horizontal .rs-nav-item {
        max-width: 110px;
    }
}

@media screen and (max-width: 900px) {
    .logo > img {
        width: 100px !important;
    }

    .rs-nav.rs-nav-horizontal .rs-dropdown,
    .rs-nav.rs-nav-horizontal .rs-nav-item {
        max-width: 95px;
    }

    #add-filter {
        width: 100%;
    }
}

@media screen and (max-width: 800px) {
    .logo > img {
        width: 80px !important;
    }

    .rs-nav.rs-nav-horizontal .rs-dropdown,
    .rs-nav.rs-nav-horizontal .rs-nav-item {
        max-width: 82px;
    }
}

@media screen and (max-width: 700px) {
    .rs-navbar-header {
        display: none !important;
    }

    .rs-nav.rs-nav-horizontal .rs-dropdown,
    .rs-nav.rs-nav-horizontal .rs-nav-item {
        max-width: 93px;
    }

    .rs-navbar-nav .rs-nav-item > .rs-nav-item-content {
        padding: 18px 8px;
    }

    .config-icon-margin-right {
        margin-right: 0;
    }
}

@media screen and (max-width: 600px) {
    .rs-navbar-header {
        display: none !important;
    }

    .rs-nav.rs-nav-horizontal .rs-dropdown,
    .rs-nav.rs-nav-horizontal .rs-nav-item {
        max-width: 74px;
    }

    .rs-navbar-nav .rs-nav-item > .rs-nav-item-content {
        padding: 18px 8px;
    }
}

@media screen and (max-width: 500px) {
    .help-center {
        display: none !important;
    }

    .rs-navbar-header {
        display: none !important;
    }

    .rs-nav.rs-nav-horizontal {
        .rs-dropdown,
        .rs-nav-item {
            max-width: 70px;

            &:first-child {
                max-width: 100%;
            }

            &.no-access {
                display: none;
            }
        }
    }

    .config-icon-margin-right {
        margin-right: 0;
    }
}

@media (max-width: 480px) {
    .navbar-desktop {
        display: none;
    }
}
