.resultado-validacao-component {
    background-color: #FFFFFF;
    margin-top: 40px;
    padding: 24px;

    .rs-dropdown {
        width: 100%;
    }

    #download {
        width: 100%;
    }

    .checkpicker-colunas {
        width: 100%;
        margin-right: 10px;

        a {
            border-radius: 6px !important;
        }
    }

    .itens-count {
        margin-bottom: 55px;
    }
}


.resultado-column-picker {
    &__footer {
        padding: 10px 2px;
        border-top: 1px solid #e5e5e5;

        .rs-checkbox-inline {
            margin-left: 0 !important;
        }
    }

    &__footer-btn {
        float: right;
        margin-right: 10px;
        margin-top: 2px;
    }
}
