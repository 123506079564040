.login-page {

    .rs-col {
        padding: 0 !important;
    }

    input {
        border: 1px solid #e5e5ea !important;
        border-top: 1px solid #e5e5ea !important;
        border-right: 1px solid #e5e5ea !important;
        border-left: 1px solid #e5e5ea !important;
        border-radius: 6px !important;
    }

    .rs-input-group input {
        border: none !important;
    }

    .form-area {
        height: 100vh;
        display: table;



        .login-form {
            padding: 0 190px;
            display: table-cell;
            vertical-align: middle;

            @media screen and (max-width:600px) {
                padding: 0 100px;
            }


            h4 {
                font-size: 24px;
                margin-bottom: 31px;
            }

            button {
                text-transform: uppercase;
                font-weight: 700;
            }

            .forgot-password {
                text-align: center;
                color: #1E88E5, 100%;
                padding: 24px 0;
            }

            .new-account {
                margin-top: 20px;
                padding: 8px;
                background-color: #F5F9FE;
                border-radius: 6px;

                .new-account-button {
                    background-color: #ffffff;
                }

                .new-account-text-wrapper {
                    padding: 10px;
                    margin-top: 10px;

                    .new-account-text {
                        font-weight: 400;
                        font-size: 14px;
                        color: #0D66D0;
                        margin: 0;
                        text-align: center;

                        .new-account-text-bold {
                            font-weight: 700;
                        }
                    }
                }
            }
        }
    }

}
