.listas-cadastradas-component {
    background-color: #FFFFFF;
    padding: 24px;

    .row-btn {
        background-color: #F5F9FE;
        padding: 11px 8px;

        .btn-list-review {
            text-transform: uppercase;
            padding: 8px 39px;
            font-weight: bold;
        }

        .info-area {
            line-height: 35px;

            span {
                display: inline-block;
            }

            .tooltip-info {
                color: #378EF0;
                margin-right: 9px;
            }
        }
    }

    .filter-area {
        .rs-row {
            margin-top: 24px;
        }

        &__label {
            display: inline-block;

            p {
                text-transform: uppercase;
                font-size: 12px;
                font-weight: bold;
                margin-right: 9px;
            }
        }
    }
}
