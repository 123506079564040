.analisys-counter {
    display: flex;
    align-items: center;
    margin: 0 !important;
    border-radius: 6px;
    width: 30%;
}

.analysis-quantity-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    display: flex;
    align-items: baseline;
    font-weight: 600;
}

.big-quantity {
    font-size: 16px;
}

.small-quantity {
    font-size: 10px;
}

.chart-container {
    width: 50px;
    height: 50px;
    margin-right: 16px;
}

.title-wrapper {
    margin-bottom: 4px;
}

.title {
    font-size: 12px;
    font-weight: 700;
}

.subtitle {
    font-size: 12px;
    font-weight: 400;
    margin: 0;
}

.btn-redirect {
    color: #378EF0;
    border: transparent !important;
    padding: 0 !important;
    font-size: 10px;

    &:hover, &:focus {
        background-color: transparent !important;
    }
}
