.modal-share-analyzed-views {
    .subtitle {
        margin-top: 24px;
    }

    .date-alert {
        height: 42px;
        background-color: #f5f9fe;
        padding: 12px;
        display: flex;
        align-items: center;
        margin-top: 8px;

        .date {
            font-weight: 600;
            margin-left: 4px;
        }
    }


    .rs-modal-body {

        label {
            display:inline-block;
            margin-bottom: 8px;
        }

        .message-wrapper {
            margin-top: 24px;

            textarea {
                border: 1px solid #bcbcbc !important;
                border-radius: 4px !important;
            }
        }
    }

    .rs-modal-footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: 1px solid #eaeaea;
        padding-top: 20px;

        .link-copy-wrapper {
            display: flex;
            color: #0D66D0;

            .rs-btn-link {
                padding: 0;
                &:hover, &:focus {
                    text-decoration: none;
                }
            }

            img {
                margin-right: 8px;
            }
        }

        .rs-btn-ghost {
            width: 120px;
        }

        .rs-btn-primary {
            width: 170px;
        }

        &::after, &::before {
            display: none;
        }
    }
}

.email-select-menu {
    .rs-picker-check-menu-items {
        label {

            .item-email {
                font-size: 12px;
                color: #959595;
                margin-top: 0;
            }
        }
    }
}

.copy-link-tooltip {
    .rs-tooltip-inner {
        max-width: 260px !important;
    }
}

.invalid-email {
    &.rs-picker-input {
        background: #ffcbcb;

        a {
            background: #ffcbcb !important;
        }
    }
}
