.header {
    border-radius: 6px;
    border-bottom: 1px dashed #D7E8FC;
    border-top: 1px solid #D7E8FC;
    border-right: 1px solid #D7E8FC;
    border-left: 1px solid #D7E8FC;
    background: #F5F9FE;
    color: #747474;
    font-size: 12px;
    font-weight: 700;
    padding: 4px 12px;
    max-width: 200px;
}

.content {
    border-radius: 6px;
    border-bottom: 1px solid #D7E8FC;
    border-right: 1px solid #D7E8FC;
    border-left: 1px solid #D7E8FC;
    background: #F5F9FE;
    padding: 4px 12px;
    height: 85px;

    @media (max-width: 1280px) {
        min-width: 0;
    }
}

.price-symbol {
    color: #959595;
    font-size: 12px;
    font-weight: 400;
    margin-right: 3px;
}

.price-number {
    color: #323232;
    font-size: 32px;
    font-weight: 600;

    @media (max-width: 1280px) {
        font-size: 30px;
    }
}

.bottom-text {
    color: #959595;
    font-size: 12px;
    font-weight: 400;
    @media (max-width: 1280px) {
        font-size: 11px;
    }
}

.bottom-value {
    color: #747474;
    font-size: 14px;
    font-weight: 600;
    margin: 0 3px;
    @media (max-width: 1280px) {
        font-size: 13px;
    }
}
