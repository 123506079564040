.modal-price-analysis {
    :global(.rs-modal-body) {
        padding-bottom: 10px !important;
    }
}

.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 12px;

    :global(.rs-input-group) {
        width: 96px;
    }

    :global(.rs-input-group-addon) {
        padding: 8px;
    }
}

.table-price-analysis {
    border-radius: 6px;
}

.base {
    :global(.rs-table-body-row-wrapper) {
        :global([aria-rowindex='2']) {
            border-bottom: none !important;
        }

        :global([aria-rowindex='4']) {
            border-bottom: none !important;

            :global(.rs-table-cell-content) {
                background-color: #F5F9FE !important;
            }
        }

        :global([aria-rowindex='5']) {
            border-bottom: none !important;
            :global(.rs-table-cell-content) {
                background-color: #F5F9FE !important;
            }
        }
    }
}

.comercial {
    :global(.rs-table-body-row-wrapper) {
        :global([aria-rowindex='2']) {
            border-bottom: none !important;
        }

        :global([aria-rowindex='3']) {
            border-bottom: none !important;
        }

        :global([aria-rowindex='5']) {
            border-bottom: none !important;
            :global(.rs-table-cell-content) {
                background-color: #F5F9FE !important;
            }
        }

        :global([aria-rowindex='6']) {
            :global(.rs-table-cell-content) {
                background-color: #F5F9FE !important;
            }
        }
    }
}

.label {
    font-size: 12px;
    font-weight: 400;
}

.alert {
    margin-top: 8px;
}

.main-bold-text {
    font-weight: 600;
}

.diff {
    color: var(--red-400);
}
