.infopanel {
    &.main-container {

        @media (min-width:600px) {
            max-width: 87vw;
        }

        @media (min-width:700px) {
            max-width: 92vw;
        }

        @media (min-width:1000px) {
            max-width: 93vw;
        }

        @media (min-width:1200px) {
            max-width: 94vw;
        }


    }

    .mg-bottom-12 {
        margin-bottom: 12px !important;
    }

    .filtros {
        border-radius: 6px;

        .tooltip {
            &__icon {
                color: #bdbdbd;
                position: relative;
                top: 5px;
            }
        }

        &__input {
            &_invalid {
                border-color: red !important;
                border-width: 2px !important;
            }
        }

        .rs-help-block.warning-no-data {
            color: #f5c31d;
        }

        .invalid-input {
            border-color: red !important;
            border-width: 2px !important;
        }

        &__message {
            &_invalid {
                color: red;
            }
        }

        .tooltip-info.rs-icon {
            color: #bdbdbd;
            position: relative;
            top: 5px;
        }

        .price-type-info-icon {
            margin-left: 6px;
        }

        .channel-type-container,
        .ad-container {
            border: 1px solid #EAEAEA;
            border-radius: 6px;
            padding: 10px 16px;
            display: flex;
            align-items: center;

            .toggle-title {
                margin-right: 10px;
                font-weight: 700;
            }

            .toggle-container {
                display: inline;
                width: 100%;

                .channel-selling-point,
                .channel-app,
                .ad {
                    margin-right: 14px;
                    display: inline;

                    @media (max-width: 480px) {
                        display: block;
                    }
                }

                .toggle-info-icon {
                    margin-left: 6px;
                }
            }

            .shopping-brasil-btn {
                padding: 0 5px;
                overflow: inherit;

                &:hover,
                &:focus {
                    background: transparent;
                }
            }
        }

        div#badge {
            position: absolute;
            font-weight: 700;
            font-size: 8px;
            text-transform: uppercase;
            background: #378ef0;
            border-radius: 10px;
            color: #ffffff;
            padding: 1px 6px;
            top: -7px;
            left: 145px;
            z-index: 2;
        }
    }

    .toolbar {
        text-align: end;
        margin-bottom: 12px !important;

        &__column-picker {
            width: 155px;
            margin-right: 10px;

            a {
                border-radius: 6px !important;
            }
        }

        &__button-icon {
            margin-left: 5px;
            margin-right: 0px !important;
        }
    }

    .data-content {
        &__iframe {
            width: 100%;
            display: block;
        }

        &__content {
            box-shadow: 1px 1px 4px 2px #ccc;
            border-radius: 5px;
        }
    }

    .empty-state-content {
        vertical-align: middle;
        display: table;
        color: #575757b0;
        margin-top: 0 !important;
        border-radius: 6px;

        .empty-state {
            text-align: center;

            h4 {
                font-size: 20px;
                color: #323232;
                margin-bottom: 10px;
            }

            p {
                margin-bottom: 34px;
                color: #323232;
            }
        }

        &__div {
            &_vertical-align-middle {
                display: table-cell;
                vertical-align: middle;
                text-align: center;
            }
        }

        &__subtitle {
            &_font-wight-500 {
                font-weight: 500;
            }
        }

        &__icon {
            font-size: 115px;
            margin-bottom: 15px;
        }
    }

    .warning-detalhes-produtos {
        margin-bottom: 20px;
        background: #FFFBF4;
        border: 1px solid #F29423;
        padding: 16px 20px;
        border-radius: 6px;

        p {
            color: #F29423;
        }

        .rs-flex-box-grid-item {
            margin-bottom: 5px;
        }

        &__title {
            font-weight: 600;
        }
    }

    .dashboard-area {
        .share-analyzed-button {
            margin-left: 16px;
            background: #F5F9FE;
            border: 1px solid #F5F9FE !important;

            i {
                color: #378EF0;
                font-size: 16px;
            }

            &:hover {
                background: #CCE9FA !important;
            }
        }

    }

    @media screen and (max-width:600px) {
        .dashboard-area {
            .rs-content {
                padding: 20px 0 !important;
                margin: 20px 0 !important;
            }

            .data-content {
                padding: 0 !important;
                margin-bottom: 0 !important;


                .rs-content {
                    padding: 15px 0px 15px 10px !important
                }

            }

        }

        h4 {
            font-size: 14px;
        }
    }

    .MuiAutocomplete-root {
        .MuiFormControl-marginNormal {
            margin-top: 0;
        }

        .MuiInput-underline:after {
            border-bottom: 1px solid #3597fa;
        }

        .MuiInput-underline:hover:not(.Mui-disabled):before {
            border-bottom: 1px solid #3597fa;
        }
    }
}

.infopanel-header-container {
    display: flex;
    margin: 20px;
    gap: 20px;
}

.infopanel-header {
    padding: 16px;
    border-radius: 6px;
    margin: 0 !important;
    width: 70%;

    .infopanel-header-title-container {
        display: flex;
        align-items: center;
        margin-bottom: 8px;

        .infopanel-header-title {
            font-weight: 600;
            font-size: 20px;
            line-height: 26px;
            margin-right: 8px;
        }
    }

    .infopanel-header-subtitle-container {
        .infopanel-header-subtitle {
            font-size: 12px;
        }
    }
}

.infopanel-charts-container {
    position: relative;
}

.infopanel-column-picker {
    &__footer {
        padding: 10px 2px;
        border-top: 1px solid #e5e5e5;

        .rs-checkbox-inline {
            margin-left: 0 !important;
        }
    }

    &__footer-btn {
        float: right;
        margin-right: 10px;
        margin-top: 2px;
    }
}

.product-filter-menu {
    &__footer {
        display: flex;
        justify-content: space-between;
        padding: 10px 2px;
        border-top: 1px solid #e5e5e5;

        button {
            text-transform: uppercase;
            font-size: 12px;
            font-weight: 700;
            padding: 6px 10px;
            margin-right: 20px;
        }
    }

    .rs-check-item {
        .rs-checkbox-checker {
            .tag {
                margin-left: 10px;
                color: white;
                font-size: 11px;
                padding: 5px 9px;
                border-radius: 15px;

                &__muitos-dados {
                    background-color: #4CAF50;
                }

                &__diversos-dados {
                    background-color: #2196F3;
                }

                &__poucos-dados {
                    background-color: #FFC107;
                }
            }

            .product-volume-icon {
                margin-right: 10px;
                margin-bottom: 2px;
            }


        }
    }

    .new-badge {
        position: absolute;
        font-weight: 700;
        font-size: 8px;
        text-transform: uppercase;
        background: #33AB84;
        border-radius: 10px;
        color: #FFFFFF;
        padding: 1px 6px;
        right: 9px;
        bottom: 38px;
        z-index: 2;
    }
}

.rs-picker-check-menu {
    .rs-picker-check-menu-items {
        .product-volume-icon {
            margin-right: 10px;
            margin-bottom: 2px;
        }
    }
}

.download-big-numbers {
    border-radius: 6px;

    .rs-notification-item-content {
        background-color: rgb(255, 238, 216);

        .rs-notification-description {
            display: flex;

            .rs-loader-wrapper {
                margin-right: 16px;
            }
        }
    }
}

.download-big-numbers-success {
    border-radius: 6px;

    .rs-notification-item-content {
        background-color: #bcf7f3;

        .rs-notification-description {
            display: flex;

            img {
                margin-right: 16px;
            }
        }
    }
}

.download-big-numbers-error {
    border-radius: 6px;

    .rs-notification-item-content {
        background-color: rgb(252, 202, 202);
    }
}
