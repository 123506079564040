.home-carousel {
    background-color: #F7F9FE;
    height: 100vh;

    header {
        padding: 48px;

        img {
            max-width: 200px;
            max-height: 32px;

        }
    }

    .carousel-content {
        height: 65%;

        .rs-flex-box-grid {
            height: 100%;
        }

        .carousel {

            .slide-home {
                padding: 0 100px;

                &__img {
                    img {
                        width: 261px;
                        height: 196px;
                        margin: 0 auto;
                    }
                }

                &__description {
                    color: #323232 !important;
                    text-align: center;
                    margin-top: 30px;

                    h5 {
                        font-size: 18px;
                        font-weight: 700;
                        margin-bottom: 16px;
                    }

                    p {
                        font-size: 14px;
                        font-weight: 400;
                        margin-bottom: 33px;
                        max-width: 300px;
                        text-align: center;
                        display: inline-block;
                    }
                }
            }

            .slick-dots {
                li {
                    &.slick-active {
                        button {
                            &:before {
                                color: #1E88E5;
                            }

                        }
                    }
                }
            }
        }
    }
}