@import '../../../../../style/typography.scss';

.form {
    :global(.info-radio-checked) {
        color: var(--gray-900);
    }
}

.form,
.wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.form > * {
    background: var(--white, #fff);
    padding: 16px;
    border-radius: 6px;
}

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px;

    .header::after,
    .option-radio::after {
        content: '';
        display: block;
        height: 1px;
        width: 100%;
        background: var(--gray-300, #eaeaea);
    }

    .option-check {
        :global(.rs-checkbox) {
            h5 {
                @extend .font-size-100-semibold;
                --text-color: var(--gray-900);
            }
            p {
                @extend .font-size-75-regular;
                --text-color: var(--gray-600);
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            gap: 8px;
        }
    }
}

.footer {
    display: flex;
    gap: 12px;
    justify-content: flex-end;
}
