.salvar-negociacao-modal__body {
    display: grid;
    grid-template-areas:
        'name name'
        'supplier supplier'
        'start end'
    ;
    row-gap: 12px;
    column-gap: 24px;

    & .inputbox-name {
        grid-area: name;
    }

    & .inputbox-supplier {
        grid-area: supplier;
    }

    & .inputbox-start {
        grid-area: start;
    }

    & .inputbox-end {
        grid-area: end;
        display: grid;
        grid-template-columns: 30px 1fr;
        row-gap: 8px;
        align-items: center;
        grid-template-areas:
            'toggle label'
            'input input'
        ;

        > div {
            grid-area: input;
        }
    }
}
