@mixin emphasis-text() {
    color: var(--Gray-900, #323232);
    font-family: 'Open Sans';
    font-size: 0.875rem;
    font-style: normal;
    font-weight: 600;
    line-height: 1.25rem;
}

.section-table {
    .padding-inline-xxs {
        padding: 0 8px;
    }

    padding: 12px;

    .text-header {
        flex-direction: column;

        margin-bottom: 16px;
        h3 {
            @include emphasis-text();
        }
    }

    .cell-wrapper {
        display: flex;
        flex-direction: column;

        .main-content {
            @include emphasis-text();
        }

        .secondary-content {
            color: var(--Gray-700, #747474);
            font-family: 'Open Sans';
            font-size: 0.6875rem;
            font-style: normal;
            font-weight: 400;
            line-height: 1.125rem;
        }
    }
}
