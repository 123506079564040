.modal {
    :global(.rs-modal-content) {
        padding: 16px;
    }

    :global(.rs-modal-body) {
        padding: 0px;
        margin: 0px;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 8px;
        margin-top: 10px;
    }

    p {
        --text-color: var(--gray-900);
    }
}
