.modal-analisys-plan {
    width: 588px;

    :global(.rs-modal-content) {
        background: linear-gradient(177deg, #D7E8FC 2.45%, #FFF 41.1%);
    }
}

.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    padding: 0 !important;
}

.title {
    font-size: 16px;
    font-weight: 600;
    margin: 24px 0 12px 0;
}

.subtitle, .label {
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin: 0;
}

.lock-icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    width: 70px;
    height: 70px;
    background: #378EF0;
}

.bold {
    font-weight: 700;
}

.label {
    margin: 0 0 24px 0;
}

.box-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-column-gap: 16px;
    grid-row-gap: 16px;
}

.box {
    border: 1px solid #eaeaea;
    border-radius: 6px;
    padding: 16px;
}

.box-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 27px;
    height: 27px;
    border-radius: 4px;
    background: #F29423;
    color: #fff;

    :global(.MuiSvgIcon-root) {
        width: 17px;
        height: 17px;
    }

    img {
        filter: brightness(0) invert(1);
    }
}

.box-title {
    font-size: 12px;
    font-weight: 700;
    margin: 8px 0;
}

.box-subtitle {
    font-size: 10px;
    font-weight: 400;
    margin: 0;
}

.footer {
    display: flex;
    justify-content: center;
    gap: 16px;

    button {
        font-weight: 700;
        text-transform: uppercase;
        margin: 0 !important;
    }
}
