.previsao-demanda-page {
    .rs-flex-box-grid-item {
        margin-bottom: 0;
        padding: 0;
    }

    .rs-row:first-child {
        margin-bottom: 16px;
    }

    .rs-content {
        padding: 16px !important;
        margin: 24px !important;
        border-radius: 6px;
    }

    .rs-input-group {
        height: 48px;

        &.error {
            border-color: #EC5B62;
        }
    }

    .rs-input-group-addon,
    .rs-input {
        font-size: 24px;
    }

    .rs-input {
        height: 48px;
    }

    .rs-btn-primary {
        height: 48px;
        padding: 2px 27px;
        font-weight: bold;
    }

    .page-header {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .grupo-titulo {
            display: flex;
            align-items: center;
        }

        h2 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            line-height: 26px;
            color: #323232;
        }
    }

    .page-filtros {
        h3 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 26px;
            color: #323232;
        }

        .campanhas-filtros {
            margin-top: 8px;
        }
    }

    .page-campos-simulacao {
        h3 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 26px;
            color: #323232;
        }

        .simulacao-info {
            padding: 16px;
            background: #F5F9FE;
            border-radius: 6px;
            margin-top: 16px;
            margin-bottom: 32px;
        }

        .divider {
            height: 72px;
            border-left: 1px solid #EAEAEA;
            margin: 0 24px;
        }



        .simulacao-input-label {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 700;
            font-size: 10px;
            line-height: 12px;
            text-transform: uppercase;
            color: #323232;
            text-align: center;
            margin-top: 8px;
        }

        .simulacao-input-error {
            color: #EC5B62;
        }
    }

    .simulacao-data-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #F5F9FE;
        border-radius: 6px;
        padding: 16px 0;

        p {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            text-align: center;
            color: #323232;
        }
    }

    .previsao-volume-info {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        h3 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 26px;
            color: #323232;
            margin-top: 8px;
            display: flex;
            align-items: center;

            .previsao-beta-badge {
                width: 42px;
                height: 14px;
                border: 1px solid #747474;
                border-radius: 15px;
                margin-left: 8px;
                color: #747474;
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 700;
                font-size: 10px;
                line-height: 12px;
                text-align: center;
            }
        }

        p {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #323232;
            margin-top: 8px;
        }

        .confiabilidade-info {
            width: 100%;
            padding: 8px;
            background: #F5F5F5;
            border-radius: 6px;
            text-align: center;
            margin-top: 32px;
            margin-bottom: 16px;

            p {
                margin: 0;
            }

            strong {
                font-weight: 600;
            }
        }
    }

    .previsao-volume-sem-dados {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background: #FEF7F7;
        padding: 24px 0;
        margin-top: 40px;

        .MuiSvgIcon-root {
            color: #E34850;
        }

        h3 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 26px;
            color: #323232;
            margin-top: 8px;
        }

        p {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            color: #323232;
            margin-top: 8px;
        }

        span {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            color: #323232;
        }
    }

    .cards-grid {
        gap: 16px;
    }

    .container-graficos {
        margin-top: 16px;

        .grafico-card {
            border: 1px solid #A5CCF8;
            border-radius: 6px;
            padding: 16px;

            h2 {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #000000;
            }

            p {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                line-height: 14px;
                color: #505050;
                margin-bottom: 16px;
            }

            .grafico-indisponivel {
                background-color: #FEF7F7;
                border-radius: 6px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 24px 16px;
                gap: 16px;
                width: 100%;
                height: 400px;

                .MuiSvgIcon-root {
                    color: #E34850;
                }

                h3 {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 20px;
                    text-align: center;
                    color: #323232;
                }

                p {
                    font-family: 'Open Sans';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    text-align: center;
                    color: #323232;
                }
            }
        }

        .grafico-card-elasticidade {
            background: #F5F9FE;
            border-radius: 6px;
            width: 100%;
            margin-top: 16px;
            text-align: center;
            padding: 16px;
            h2 {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 700;
                font-size: 10px;
                line-height: 12px;
                text-transform: uppercase;
                color: #505050;
                margin-bottom: -30px;
                position: relative;
                z-index: 100;
            }

            h3 {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #323232;
                margin-top: -20px;
                position: relative;
                z-index: 100;
                margin-bottom: 4px;
            }

            p {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: #323232;
            }
        }

        .grafico-indisponivel-elasticidade {
            background-color: #FEF7F7;
            border-radius: 6px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 16px;
            width: 100%;
            height: 200px;
            margin-top: 16px;

            .MuiSvgIcon-root {
                color: #E34850;
            }

            h2 {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 700;
                font-size: 10px;
                line-height: 12px;
                text-transform: uppercase;
                color: #505050;
            }

            h3 {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                color: #323232;
                margin-top: 0px;
            }

            p {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 20px;
                text-align: center;
                color: #323232;
            }
        }
    }
}

.simulacao-value-card {
    padding: 16px 26px;
    background: #F5F9FE;
    border-radius: 6px;
    text-align: center;

    .simulacao-value-card-title {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase;
        color: #505050;
    }

    .simulacao-value-card-value {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 300;
        font-size: 32px;
        line-height: 44px;
        color: #323232;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    hr {
        margin: 4px auto;
        width: 32px;
        border: 1px solid #0D66D0;
    }

    .simulacao-value-card-new-value,
    p {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #505050;
    }

    &.positive {
        background: #F5FBF9;
    }

    &.negative {
        background: #FEF7F7;
    }

    .MuiSvgIcon-root.positive {
        color: #33AB84;
    }

    .MuiSvgIcon-root.negative {
        color: #EC5B62;
    }
}
