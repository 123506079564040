@import '../abstracts/variables.scss';

.top-ten-competitors-content {
    padding: 25px 16px;
    background: #FFFFFF;
    border: 1px solid $gray-300;
    box-shadow: 0px 9px 28px 8px rgba(0, 0, 0, 0.05), 0px 6px 16px rgba(0, 0, 0, 0.08), 0px 3px 6px -4px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
}

.top-ten-chain {
    margin-top: 32px;
}
