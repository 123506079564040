.title-wrapper {
    display: flex;
    align-items: center;
}

.title {
    font-size: 16px;
    font-weight: 600;
    color: #323232;
    margin-right: 8px;
}

.tag {
    display: flex;
    padding: 1px 6px;
    border-radius: 10px;
    background: #33AB84;
    text-transform: uppercase;
    color: #fff;
    font-size: 10px;
    font-weight: 700;
    height: fit-content;
}

.loading-tag {
    display: flex;
}

.loader {
    :global(.rs-loader) {
        width: 10px !important;
        height: 10px !important;
    }
    :global(.rs-loader-spin::before) {
        width: 10px !important;
        height: 10px !important;
    }

    :global(.rs-loader-spin::after) {
        width: 10px !important;
        height: 10px !important;
    }
}
