.modal-price-limit {
    :global(.rs-modal-body) {
        padding-bottom: 0 !important;
    }
}

.percent-over {
    width: 230px;
    padding: 10px 8px;
    justify-content: space-between;
}

.label {
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    margin-bottom: 6px;
}

.text {
    font-size: 12px;
}

.divider {
    margin: 0 4px;
}

.content, .percent-over {
    display: flex;
}

.content {
    align-items: center;
}

.alert-icon {
    width: 16px !important;
    height: 16px !important;
    color: #EC5B62;
}

.alert-status {
    border: 1px solid #EC5B62;
    background: #FEF7F7;
    border-radius: 4px;
}

.regular-status {
    border: 1px solid #378EF0;
    background: #F5F9FE;
    border-radius: 4px;
}

.price-value {
    font-size: 12px;
    font-weight: 600;
}

.price-container {
    margin-left: 16px;
}
