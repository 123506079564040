.margem_objetiva_fallback {
    display: flex;
    padding: 16px 12px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 6px;
    border: 1px solid var(--light-blue-20, #d7e8fc);
    background: var(--light-blue-5, #f5f9fe);
    min-height: 255px;
    text-align: center;

    & p {
        margin-block-start: 4px;
    }
}
