.header {
    padding: 24px !important;
    background-color: #FFFFFF;
    border-radius: 6px;
}

h1 {
    font-size: 20px;
    line-height: 26px;
}

.title-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
}

.icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    height: 44px;
    border-radius: 44px;
    margin-right: 4px;

    background-color: #F5F9FE;
}

.icon {
    width: 20px;
    color: #378EF0;
}

.subtitle {
    font-size: 14px;
    margin-bottom: 24px;
}

.link-text {
    padding: 0 0 3px 4px;
    text-decoration: none !important;

}

.btn-show {
    font-weight: bold;
    text-transform: uppercase;
}

.btn-send {
    i {
        margin-right: 24px;
        color: #378EF0;
    }
}

.nav-box-wrapper {
    display: flex;
}

.nav-box {
    padding: 8px 12px;
    border-radius: 6px;
    border: 1px solid  #A5CCF8;
    background: #FFF;
    width: 340px;
}

.nav-box-title {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}

.nav-box-subtitle {
    font-size: 12px;
    font-weight: 400;
    margin: 0;
}

.upload-icon {
    margin-left: 6px;
}

.ondemand-nav-type {
    background: #fff;
    border: 1px solid #A5CCF8;
    border-radius: 6px;
    padding: 8px 12px;
    margin-right: 8px;
    color: #505050;
    text-align: start !important;

    &:focus, &:hover {
        background: transparent !important;
    }
}

.ondemand-nav-type-active {
    background: #F5F9FE;
    color: #1473E6;
    border: 1px solid #A5CCF8;
    border-radius: 6px;
    padding: 8px 12px;
    margin-right: 8px;
    text-align: start !important;

    &:focus, &:hover {
        background: #F5F9FE;
        color: #1473E6;
    }
}
