.status-tag {
    pointer-events: none;
    display: flex;
    align-items: center;
    gap: 4px;
    svg {
        width: 10px;
        height: 10px;
    }
}
