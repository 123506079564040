.download-relatorio-page {
    .rs-table-row.is-active {
        .rs-table-cell {
            background-color: rgba(76, 171, 247, 0.2);
        }
    }
    .mg-5 {
        margin: 5px;
    }
    .rs-loader {
        height: 80vh;
    }
}
