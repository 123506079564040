.cadastro-regra-page {
    .rule-status {
        color: #fff;
        border-radius: 16px;
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        padding: 5px 16px;

        i {
            margin-right: 6px;
        }
    }

    .rule-status.ACTIVE {
        background: #378EF0;
    }

    .rule-status.EXPIRED {
        background: #959595;
    }

    .rs-picker-toggle,
    .rs-input,
    .rs-tag-group {
        border: 1px solid #e5e5ea !important;
        border-radius: 5px !important;
    }

    .rs-tag-group {
        margin: 0;
        padding: 5px 0px;

        .rs-tag {
            margin-top: 0;
        }
    }

    .rs-input-group {
        .rs-input {
            border: 0 !important;
        }

        .rs-input.form__input_invalid {
            border: 2px solid red !important;
        }

        &:hover,
        &:active,
        &:focus {
            border-color: #e5e5ea !important;
        }
    }

    .rs-input-group-focus {
        border-color: #e5e5ea !important;
    }

    .margin-left-20 {
        margin-left: 20px;
    }

    .tipo-competitividade {
        position: relative;
        top: 16px;
    }

    .currency-input-competitividade {
        position: relative;
        top: 16px;
    }

    .select-concorrencia {
        position: relative;
        bottom: 8px;
    }

    .form {
        &__message {
            &_invalid {
                color: red;
            }
        }

        &__input {
            &_invalid {
                border-color: red !important;
                border-width: 2px !important;

                &:hover,
                &:active {
                    border-color: red !important;
                    border-width: 2px;
                }
            }
        }
    }

    .product-family {
        display: flex;
        align-items: center;

        span {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #323232;
            margin-left: 8px;
            margin-right: 6px;

            strong {
                font-weight: 600;
            }
        }
    }
}

.cadastro-segmento-modal {
    .rs-modal-body {
        max-height: none !important;
    }

    .cell-checkbox {
        div.rs-table-cell-content {
            padding-top: 5px !important;
            padding-bottom: 5px !important;
        }
    }
}

.buttons__wrapper {
    >div {
        margin-left: 15px;

        &:first-child {
            margin-left: 0;
        }
    }
}