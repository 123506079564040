.link-button {
    --color: #378ef0;
    background: transparent;
    padding: 0px;
    display: inline-flex;
    align-items: center;
    gap: 4px;
    font: inherit;
    font-size: 1px;
    transition: ease 250ms;
    color: var(--color);

    a {
        display: block;
        text-decoration: none;
    }

    &:hover {
        --color: #1473e6;
    }

    &:focus {
        --color: #0d66d0;
    }

    &-small {
        font-size: 12px;
        line-height: 18px;
    }

    & svg {
        color: var(--color);
        fill: var(--color);
    }

    &-disabled {
        opacity: 0.4;
        pointer-events: none;
    }
}
