.highlight {
    // border: 1px solid;
    // border-radius: 6px;
    border-radius: 0.25rem;
    padding: 0 2px;
    width: 100px;
    height: 12px;
    margin-left: 4px;

    font-family: 'Open Sans', sans-serif;
    font-size: 0.625rem;
    font-style: normal;
    font-weight: 600;
    line-height: 0.75rem;
}

.positive {
    border: 1px solid var(--Green-400, #33ab84);
    color: var(--Light-Green-5, #33ab84);
}

.negative {
    border: 1px solid var(--Red-400, #ec5b62);
    color: var(--Red-400, #ec5b62);
}
