.precificacao-manual-loja-page {
    .rs-picker-toggle,
    .rs-input,
    .rs-tag-group {
        border: 1px solid #e5e5ea !important;
        border-radius: 5px !important;
    }

    .rs-input-group {
        .rs-input {
            border: 0 !important;
        }

        .rs-input.info-preco__input_invalid {
            border: 2px solid red !important;
        }

        &:hover,
        &:active,
        &:focus {
            border-color: #e5e5ea !important;
        }
    }

    .rs-input-group-focus {
        border-color: #e5e5ea !important;
    }

    .produto-em-campanhas-info {
        background: #F5F9FE;
        padding: 16px;
        border-radius: 6px;
        display: flex;
        align-items: center;
        .MuiSvgIcon-root {
            color: #3498ff;
            margin-right: 16px;
        }
    }

    .info-loja {
        &__label {
            color: #adadad;
            font-weight: 600;
            font-size: 13px;
        }
    }

    .instruction {
        &__title {
            font-weight: 600;
            font-size: 15px;
        }

        &__options {
            margin-bottom: 10px;
        }

        &__subtitle {
            font-style: italic;

            &_bold {
                font-weight: bold;
            }
        }
    }

    .info-preco {
        .rs-flex-box-grid-item {
            margin-bottom: 0;
        }

        &__tipo {
            font-weight: 600;
        }

        &__group-label {
            height: 50px;
        }

        &__label {
            color: #adadad;
            font-weight: 600;
            font-size: 13px;
        }

        &__sub-label {
            font-style: italic;
            font-size: 12px;
        }

        &__input-group {
            width: 60%;
        }

        &__message {
            &_invalid {
                color: red;
            }
        }

        &__input {
            &_invalid {
                border-color: red !important;
                border-width: 2px !important;

                &:hover,
                &:active {
                    border-color: red !important;
                    border-width: 2px;
                }
            }
        }
    }

    .date {
        &__toggle {
            margin-right: 15px;
        }

        &__toggle-familia {
            margin-right: 15px;
        }
    }

    .info-adicionais {
        &__title {
            font-weight: 600;
        }
    }

    .panel {
        .rs-panel-heading {
            font-size: 14px;
            color: #737379;
        }

        .rs-badge {
            .rs-badge-content {
                background-color: #3498ff;
                right: -14px;
                border-radius: 10px;
                font-size: 15px;
                line-height: 22px;
                padding: 0px 7px;
            }
        }
    }

    .table-limite {
        &__cell-condicoes {
            .rs-table-cell-content {
                padding-top: 5px;
                font-size: 12px;
            }

            .label {
                color: #8e8e93;
            }
        }
    }
}
