.tabela-precos-page {

    .container-title {
        .tabela-preco-title {
            font-size: 20px;
        }
        .container-preco-title {
            border-radius: 6px;
        }
    }

    .tabela-preco-texto-explicativo {

        .container-preco-texto-explicativo {
            border-radius: 6px;
            min-height: 242px;
            position: relative;
            bottom: 50px;
            // right: 5px;

            .content-title {
                font-weight: 600;
                font-size: 16px;
            }
        }

        .download-modelo {
            font-weight: 600;
            color: #378EF0;
            text-decoration: none;
            cursor: pointer;
        }
    }

    .container-upload {
        border-radius: 6px;
        position: relative;
        bottom: 50px;
        min-width: 600px;

        .content-title {
            font-weight: 600;
            font-size: 16px;
        }

        .container-preco-upload {
            border-radius: 6px;
            min-height: 342px;
        }

        .botao-arquivo {
            font-size: 16px;
            min-width: 240px;
            min-height: 42px;
            font-weight: 600;
        }

        .download-modelo {
            font-weight: 600;
            color: #378EF0;
            text-decoration: none;
            cursor: pointer;
        }
    }

    .container-message {
        border-radius: 6px;
        position: relative;
        bottom: 75px
    }

    .message {
        background-color: #FEF9F4;

    }

    .container-preco-message {
        border-radius: 6px;
    }

}
