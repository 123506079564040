.retorno .container{
    margin-top: 56px;
}
.retorno .iframe-retorno{
    width: 100%;
    height: 88vh;
}

.retorno .navbar{
    background-color: #2196F3 !important;
    z-index: 8;
    margin-right: 57px;
    width: -webkit-fill-available;
}

.retorno .navbar .rs-nav-item-text{
    margin-right: 5px;
}

.retorno .rs-nav.rs-nav-horizontal .rs-nav-item{
    vertical-align: sub;
}
.retorno .rs-nav-item-content:active:hover:focus{    
    transition: none;    
}
.retorno .rs-navbar-nav .rs-nav-item > .rs-nav-item-content{
    padding: 13px 16px;
}
.retorno .rs-nav-item-content:hover{
    background-color: #2196F3 !important;
}