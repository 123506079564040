.upload-arquivos-page {
    padding-top: 40px;

    .MuiFormLabel-root,
    .MuiInputBase-root {
        font-size: 14px;
    }

    .MuiInputBase-root:hover {
        border-color: #323232;
    }

    .back-btn {
        text-transform: none;
    }

    .upload-arquivos-card {
        padding: 24px 24px 0 24px;

        .upload-arquivos-card-content {
            padding: 0;

            h6 {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                line-height: 26px;
                color: #323232;
            }
        }
    }

    .upload-arquivos-modelo-card {
        padding: 24px;

        h6 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 26px;
            color: #323232;
        }

        p {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            color: #323232;
        }

        .upload-arquivos-modelo-select {
            border-color: #fff;
            border-width: 5px;
        }

        .upload-exemplo-download {
            padding-bottom: 40px;
        }
    }

    .upload-arquivos-modelo-upload {
        padding: 24px;

        .upload-sem-modelo-info {
            background: #f5f9fe;
            padding: 23px 0 19px 0;

            p {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                text-align: center;
                color: #323232;

                span {
                    font-weight: bold;
                }
            }
        }

        .upload-info {
            h6 {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 26px;
                color: #323232;
            }

            p {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: #323232;
            }
        }

        .upload-arquivo-botao {
            background: #378ef0;
            padding: 6px 50px;
            font-family: 'Open Sans';
            font-weight: 700;
            font-size: 16px;
        }

        .upload-arquivo-botao:hover {
            background: #2680eb;
        }

        .upload-arquivos-modelo-info {
            background: #f5f9fe;
            padding: 6px 20px !important;

            .MuiAlert-icon {
                color: #2680eb;
                margin-top: 5px;
            }

            .MuiAlert-message {
                font-family: 'Open Sans';
                font-style: normal;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: #323232;
                width: 608px;
            }
        }
    }

    .upload-arquivos-tabela {
        padding: 24px;

        h6 {
            font-family: 'Open Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 26px;
            color: #323232;
        }

        .upload-arquivos-tabela-content {
            padding: 0;

            .SENT {
                background: #268e6c;
                height: 18px;
                color: #fff;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 10px;

                .MuiChip-iconSmall {
                    height: 12px;
                    width: 12px;
                }
            }

            .SENDING {
                background: #da7b11;
                height: 18px;
                color: #fff;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 10px;

                .MuiChip-iconSmall {
                    height: 12px;
                    width: 12px;
                }
            }

            .ERROR {
                background: #d7373f;
                height: 18px;
                color: #fff;
                text-transform: uppercase;
                font-weight: 700;
                font-size: 10px;

                .MuiChip-iconSmall {
                    height: 12px;
                    width: 12px;
                }
            }

            .botao-download-arquivo.MuiButton-root {
                text-transform: none;
                font-family: 'Open Sans';
                font-weight: 400;
                font-size: 12px;
                line-height: 18px;
                color: #0d66d0;

                .MuiChip-iconSmall {
                    height: 12px;
                    width: 12px;
                }
            }

            .botao-download-arquivo.Mui-disabled {
                color: '#0D66D0';
                opacity: 0.3;
            }

            .upload-arquivos-tabela-header {
                th {
                    color: #747474;
                    font-weight: 400;
                    font-size: 12px;
                }
            }
        }
    }

    .rs-picker-daterange .rs-picker-toggle {
        background: none !important;
        color: transparent !important;

        a {
            color: transparent !important;
        }

        .rs-picker-toggle-placeholder,
        .rs-picker-toggle-caret,
        .rs-picker-toggle-clean,
        .rs-ripple-pond {
            color: transparent !important;
        }
    }

    .upload-arquivos-container {
        margin-bottom: 0 !important;
    }

    .flatfile-button-wrapper {
        justify-content: space-around;
        text-align: center;

        .flatfile-border-button {
            width: 2px;
            height: 80px;
            background-color: #eaeaea;
            margin-top: 15px;
        }

        .flatfile-button {
            color: #323232;
            min-height: 175px;

            button {
                background: #1473e6;
                border-radius: 6px;
                width: 240px;
                height: 48px;
                color: #fff;
                margin: 15px auto 5px;
                text-transform: uppercase;
            }

            span {
                display: block;
            }

            .flatfile-button-title {
                font-weight: 600;
                font-size: 16px;
                line-height: 22px;
            }

            .flatfile-button-subtitle {
                font-style: italic;
                font-weight: normal;
                font-size: 12px;
                line-height: 21px;
            }

            .flatfile-button-text {
                margin-top: 20px;
                font-style: italic;
                font-weight: normal;
                font-size: 14px;
                line-height: 21px;
            }
        }
    }

    .flatfile-info-container {
        padding: 0 !important;
        margin: 0 20px 0 20px !important;

        .rs-flex-box-grid-item {
            margin: 0;
        }

        .flatfile-info {
            background: #eaeaea;
            border-radius: 0;

            .rs-panel-heading {
                font-weight: bold;
                padding-bottom: 0px;
            }

            span {
                font-style: normal;
                font-size: 14px;
                line-height: 24px;
                display: block;
            }
        }
    }

    .upload-arquivos-table {
        margin-top: 0 !important;

        h5 {
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 22px;
            color: #000000;
            margin-bottom: 24px;
        }

        .label {
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            text-transform: uppercase;
            color: #747474;
        }

        .upload-arquivos-filtros {
            .rs-flex-box-grid-item {
                margin-bottom: 0;
                margin: 0 12px;
            }
        }
    }

    .rs-table {
        .rs-table-cell-header {
            .rs-table-cell-content {
                font-weight: bold;
                font-size: 12px;
                line-height: 16px;
                text-transform: uppercase;
                color: #323232;
            }
        }

        .flatfile-download {
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            padding: 0;

            i {
                margin-right: 10px;
            }
        }

        .status-tag {
            border-radius: 15px;
            padding: 5px;
            display: flex;
            justify-content: center;

            i {
                margin-right: 6px;
            }

            .rs-tag-text {
                font-style: normal;
                font-weight: bold;
                font-size: 10px;
                text-transform: uppercase;
            }
        }

        .SENT {
            background: #268e6c;
            color: #fff;
        }

        .SENDING {
            background: #da7b11;
            color: #fff;
        }

        .ERROR {
            background: #f44336;
            color: #fff;
        }
    }
}

.product-config-success-notification {
    .rs-notification-item-content {
        background-color: #f5fbf9;

        .rs-notification-description {
            display: flex;
            img {
                margin-right: 12px;
            }

            p {
                color: #505050;
                font-size: 14px;
            }
        }
    }
}
