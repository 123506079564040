@import '../../../../../style/typography.scss';

.callout {
    margin-block-start: 16px;
}

.radio-description label {
    display: grid;
    grid-template-areas:
        'radio title'
        'radio description';
    grid-template-columns: min-content 3fr;
    row-gap: 0px;
    align-items: start;

    span:first-child {
        grid-area: radio;
        margin-top: 3px;
    }

    span:nth-child(2) {
        grid-area: title;
    }

    p {
        grid-area: description;
    }
}

.option__content {
    display: flex;
    flex-direction: column;
    gap: 24px;

    :global(.rs-picker-toggle) {
        max-width: 320px;
    }
}

.select-container {
    display: flex;
    padding: 12px;
    flex-direction: column;
    gap: 8px;
    border-radius: 6px;
    border: 1px solid var(--Gray-300, #eaeaea);
    label {
        @extend .font-size-100-regular;
    }
}
