@mixin title-default-bold() {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 26px;
    color: var(--gray-900, #323232);
}

.page-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;

    background: var(--white, #fff);

    padding: 16px;

    h2 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 26px;
        color: #323232;
    }
}

.title-wrapper {
    display: flex;
    align-items: center;

    h3 {
        @include title-default-bold();
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 600;
        line-height: 1.625rem; /* 144.444% */
    }

    button {
        color: var(--blue-700, #0d66d0);
        text-align: center;
        font-family: 'Open Sans';
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.125rem; /* 150% */
    }
}
