.result-button-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 6px;

    @media (max-width:830px) {
        display: block;
    }
}

.drop-down-download-button {
    .rs-dropdown-menu {
        z-index: 9;
    }
}

.result-button {
    font-weight: 700;
    font-size: 14px;
}

.download-button {
    margin-left: 16px;
    font-weight: 700;
    font-size: 14px;
}

.download-button-icon {
    margin-right: 14px;
}

.footer {
    padding: 10px 2px;
    border-top: 1px solid #e5e5e5;

    .rs-checkbox-inline {
        margin-left: 0 !important;
    }
}

.footer-btn {
    float: right;
    margin-right: 10px;
    margin-top: 2px;
}

.check-all-checkbox {
    margin-right: 20px;
}

.share-analyzed-button {
    margin-left: 16px;
    background: #F5F9FE;
    border: 1px solid #F5F9FE !important;

    i {
        color: #378EF0;
        font-size: 16px;
    }

    &:hover {
        background: #CCE9FA !important;
    }

    &:focus {
        background: #F5F9FE !important;
    }
}