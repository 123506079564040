
$blue-400: #378EF0;
$blue-500: #2680EB;
$blue-600: #1473E6;
$blue-700: #0D66D0;
$light-blue-5: #F5F9FE;
$light-blue-20: #D7E8FC;
$gray-200: #F4F4F4;
$gray-300: #EAEAEA;
$gray-400: #D3D3D3;
$gray-500: #BCBCBC;
$gray-600: #959595;
$gray-700: #747474;
$gray-800: #505050;
$gray-900: #323232;
$red-400: #ec5b62;
$red-500: #e34850;
$red-600: #d7373f;
$red-700: #c9252d;
$orange-400: #f29423;
$orange-500: #e68619;
$orange-600: #da7b11;
$orange-700: #cb6f10;
$green-400: #33ab84;
$green-500: #2d9d78;
$green-600: #268e6c;
$green-700: #12805c;
$purple-400: #9256d9;
$purple-500: #864ccc;
$purple-600: #7a42bf;
$purple-700: #6f38b1;

$font-size-8: 8px;
$font-size-10: 10px;
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-20: 20px;
$font-size-22: 22px;
$font-size-24: 24px;

$breakpoints-xs: 320px;
$breakpoints-sm: 576px;
$breakpoints-md: 768px;
$breakpoints-lg: 992px;
$breakpoints-xl: 1200px;

$media-query-sm: '@media screen and (min-width: 576px)';
$media-query-md: '@media screen and (min-width: 768px)';
$media-query-lg: '@media screen and (min-width: 992px)';
$media-query-xl: '@media screen and (min-width: 1182px)';
