.modal-confirm {
    &__icon {
        margin: 10px 0 24px;
        display: flex;
        justify-content: center;
    }

    &__header {
        padding-right: 0 !important;

        &--title {
            padding: 0;
        }

        &--message {
            margin-top: 12px;
        }
    }

    &__footer {
        display: flex;
        margin-top: 24px;

        &__btn--cancel,
        &__btn--confirm {
            font-weight: 700 !important;
            text-transform: uppercase;
            border-radius: 4px !important;
        }

        &__btn--cancel {
            color: #378EF0 !important;
            border: 1px solid #378EF0 !important;
        }
    }

    .title-center {
        text-align: center;
    }

    .title-end {
        text-align: end;
    }

    .message-center {
        text-align: center;
    }

    .message-end {
        text-align: end;
    }

    .footer-center {
        justify-content: center;
    }

    .footer-end {
        justify-content: flex-end;
    }
}
