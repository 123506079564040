.info-filterbox {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 1px solid #eaeaea;
    border-radius: 6px;
    padding: 16px;

    .row {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        column-gap: 8px;
        row-gap: 10px;

        div:first-child {
            display: flex;
            gap: 8px;
            align-items: flex-start;
            flex-wrap: wrap;
        }

        @media (max-width: 450px) {
            flex-wrap: wrap;
        }
    }
}

.clear-filtros-btn,
.salvar-filtros-btn,
.mais-filtros-btn {
    background: #f5f5f5;
    border-radius: 18px;
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #505050;
    padding: 8px 12px;
    min-height: 36px;
    svg {
        display: block;
        margin: auto;
    }
}
