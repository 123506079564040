.title-wrapper {
    display: flex;
    align-items: baseline;
    margin-bottom: 16px;
    gap: 8px;
}

.title {
    font-size: 16px;
    font-weight: 600;
}

.subtitle {
    font-size: 12px;
    font-weight: 400;
    margin: 0;
    color: #747474;
}

.bordered-content {
    border: 1px solid #eaeaea;
    border-radius: 6px;
    padding: 8px;
}
