.produto-precificacao-manual-page {
    .produto {
        border: 1px solid #ccc;
        border-radius: 5px;
        margin-top: 10px;

        .description {
            margin-bottom: 0px;

            &__info-geral{
                padding: 20px 20px 0px 20px;
            }

            &__info-niveis{
                padding: 0px 20px;
            }

            &__label {
                color: #adadad;
                font-weight: 600;
                font-size: 13px;
            }

            &__familyId-label{
                color: #adadad;
                font-weight: 600;
                font-size: 13px;

            }
        }

        .image {
            margin-bottom: 0px;
            border-right: 1px solid #ccc;

            &__img {
                width: 100%;
                height: 100%;
            }
        }
    }

    .button-simular-section{
        text-align: end;
    }

    .panel-fora-limite{
        &__title-section{
            color: red;
            font-weight: 600;
        }

        &__title-count{
            border: 2px solid red;
            border-radius: 50%;
            padding: 2px 8px;
            margin-right: 5px;
        }
    }

    .table-fora-limite{
        height: 100% !important;
        &__input-group{
            .rs-table-cell-content{
                padding: 5px;
            }
        }
    }

    .panel-demais-lojas{
        &__title-section{
            color: #2196f3;
            font-weight: 600;
        }

        &__title-count{
            border: 2px solid #2196f3;
            border-radius: 50%;
            padding: 2px 8px;
            margin-right: 5px;
        }
    }

    .table-demais-lojas{
        height: 100% !important;
        &__input-group{
            .rs-table-cell-content{
                padding: 5px;
            }
        }
    }

    .table{
        &__tag-tipo-preco{
            border-radius: 20px;
        }

        &__novo-preco{
            margin-right: 7px;
        }
    }

    .loader-integracao{
        top: 3px;
        position: relative;
    }

    table {
        width: 100%;
    }

    th {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .table-row-second-header {
        overflow: hidden;
        height: 36px;
        width: 100%;
        top: 0;

        th {
            border-style: solid;
            border-width: 0;
            border-color: #f2f2f5;
            overflow: hidden;
            white-space: normal;
            background: #fff;
            padding: 10px 10px;
            font-size: 12px;
            line-height: 1.66666667;
            color: #8e8e93;
            font-weight: 300;
        }
    }

    .table-row-principal-header {
        overflow: hidden;
        height: 36px;
        width: 100%;
        top: 0;
        border-bottom: 1px solid #f2f2f5;

        th {
            border-style: solid;
            border-width: 0;
            border-color: #f2f2f5;
            overflow: hidden;
            white-space: normal;
            background: #fff;
            padding: 10px 10px;
            font-size: 12px;
            line-height: 1.66666667;
            color: #8e8e93;
            font-weight: 300;
            text-align: start;
        }
    }

    .table-row {
        overflow: hidden;
        width: 100%;
        height: 46px;
        top: 0;
        border-bottom: 1px solid #f2f2f5;


        .cell-options{
            padding: 0 !important;
        }

        td {
            border-style: solid;
            border-width: 0;
            border-color: #f2f2f5;
            overflow: hidden;
            white-space: normal;
            background: #fff;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }

        .text-center{
            text-align: center;
        }

    }
}
