.acompanhamento-coletas {
    .description {
        padding: 24px !important;
        background-color: white;
        margin: 20px;

        h1 {
            font-size: 20px;
            line-height: 26px;
            margin-bottom: 12px;

            .tooltip-info {
                margin-left: 10px;
            }
        }

        p {
            font-size: 14px;
        }

        button {
            padding-left: 5px;
        }
    }

    iframe {
        height: 100%;
        width: 100%;
    }

}

.detalhe-coleta {
    iframe {
        width: 100%;
        height: 100%;
    }
}

.modal-legenda-component {
    .rs-modal-content {
        padding: 0;
    }

    .rs-modal-body {
        padding: 24px;
        font-size: 14px;

        h1 {
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 24px;
            line-height: 26px;
        }

        li {
            margin-top: 24px;
        }
    }

    .rs-modal-footer {
        border-top: 1px solid #EAEAEA;
        padding: 24px;

    }

    button {
        text-transform: uppercase;
        font-weight: bold;
        padding: 8px 43px;
    }
}
