.gestao-retorno {

    .description {
        padding: 24px !important;
        background-color: white;
        margin: 20px;

        h1 {
            font-size: 20px;
            line-height: 26px;
            margin-bottom: 12px;

            .tooltip-info {
                margin-left: 10px;
            }
        }

        p {
            font-size: 14px;
        }

        .row-btn {
            background-color: #F5F9FE;
            padding: 12px 21px;
            margin-top: 15px;

            .btn-go-to-review {
                text-transform: uppercase;
                padding: 8px 39px;
                font-weight: bold;
                float: right;
            }

            .info-area {
                line-height: 35px;

                span {
                    display: inline-block;
                }

                .tooltip-info {
                    color: #378EF0;
                    margin-right: 11px;
                }
            }
        }
    }

    .iframe-area {
        min-height: 650px;

        iframe {
            height: 100%;
            width: 100%;
        }
    }

}