.competitor-form {
    display: flex;
    flex-direction: column;
    gap: 16px;

    &__header {
        display: flex;
        flex-direction: column;
        gap: 8px;
        div {
            display: flex;
            align-items: center;
            gap: 8px;
        }
        p {
            color: var(--gray-700, #747474);
            text-align: start;
        }
    }

    &__content {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
}

.concorrente-card {
    background: var(--Gray-100, #f5f5f5);
    &__header {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-block-end: 16px;
    }

    &__content > div {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &__option {
        display: flex;
        align-items: center;
        gap: 8px;

        p {
            text-align: start !important;
            font-size: 14px;
        }

        &-is-active {
            p {
                font-weight: 600;
                color: var(--Gray-900, #323232);
            }
        }

        & .chip {
            display: inline-block;
            pointer-events: none;
            width: min-content;
            white-space: nowrap;
        }

        .rs-radio-checker {
            padding-top: 0px;
            padding-bottom: 0px;
            display: flex;
            align-items: center;
            min-height: 16px;
        }

        .rs-radio-wrapper {
            top: 50%;
            transform: translateY(-50%);
            &::after {
                top: 0;
                bottom: 0;
                left: 0;
                right: 0;
            }
        }
        .rs-radio-wrapper .rs-radio-inner::before {
            background-color: #fff;
        }
        .rs-radio-checked .rs-radio-wrapper .rs-radio-inner::before {
            background-color: #3498ff;
        }
        .rs-radio-disabled:not(.rs-radio-checked)
            .rs-radio-wrapper
            .rs-radio-inner::before {
            border: 1px solid var(--Gray-500, #bcbcbc);
            background: var(--Gray-100, #f5f5f5);
        }
    }
}

.time-period-card {
    margin-block-start: 12px;
    .info-card__title {
        color: var(--Gray-900, #323232);
        font-size: 14px;
        font-weight: 600;
        line-height: 20px; /* 142.857% */
        margin-block-end: 8px;
    }
}
