.saved-header-content {
    margin: 20px 20px 12px !important;
    padding: 12px !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 6px;
    &:global(.rs-content) {
        flex: none;
    }
}

.form {
    width: 60% !important;
}

.search-input-group {
    :global(.rs-input) {
        border: 1px solid #EAEAEA !important;
        border-radius: 6px !important;

        &:hover, &:focus {
            border: 1px solid #EAEAEA !important;
        }
    }
}

.title {
    font-size: 16px;
    font-weight: 600;
    color: #323232;
}
