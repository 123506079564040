.input_container,
.modal__footer,
.trigger_count > div {
    display: flex;
    align-items: center;
}

.modal {
    max-width: 460px;
    color: var(--gray-900);

    &__body {
        display: flex;
        flex-direction: column;
        .input_container {
            display: grid;
            grid-template-columns: 125px 106px;
            align-items: start;
            gap: 48px;
            margin-block-start: 16px;

            label {
                margin-block-end: 0px;
            }

            .trigger_count > div {
                gap: 4px;
                > span {
                    color: var(--gray-700, #747474);
                    font-family: 'Open Sans';
                    font-size: 11px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 12px; /* 109.091% */
                    text-transform: uppercase;
                }
            }
        }
    }

    &__footer {
        gap: 16px;
    }

    .callout {
        margin-block-start: 24px;
    }
}
