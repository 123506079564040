.acompanhamento-page {
    width: 100vh;
    
    .content {
        &__iframe {
            width: 100%;
            height: 86vh;
            display: block;
        }
    }
}
