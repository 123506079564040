.create-account-freemium {
    .rs-grid-container {
        margin: 0;
        padding: 0;

        @media screen and (max-width:600px) {
            width: 100%;
        }
    }

    .rs-row {
        margin: 0;
    }

    .rs-col {
        padding: 0 !important;
    }

    input {
        border: 1px solid #e5e5ea !important;
        border-top: 1px solid #e5e5ea !important;
        border-right: 1px solid #e5e5ea !important;
        border-left: 1px solid #e5e5ea !important;
        border-radius: 6px !important;
    }

    .rs-picker-toggle {
        border: 1px solid #e5e5ea !important;
        border-top: 1px solid #e5e5ea !important;
        border-right: 1px solid #e5e5ea !important;
        border-left: 1px solid #e5e5ea !important;
        border-radius: 6px !important;
    }

    .banner-area {
        height: 100%;
        position: fixed;
    }

    .form-area {
        height: 100vh;
        display: table;
        position: relative;
        float: right;


        .account-form {
            padding: 65px 190px;
            display: table-cell;
            vertical-align: middle;

            .rs-form-group {
                margin-bottom: 15px !important;
            }

            @media screen and (max-width:600px) {
                padding: 20px;
            }

            h4 {
                font-size: 24px;
                margin-bottom: 31px;
            }

            button {
                text-transform: uppercase;
                font-weight: 700;
            }

            .link-login {
                text-align: center;
            }

            .rs-input {
                &.invalid-input {
                    border-color: red !important;
                    border-width: 2px !important;
                }
            }

            .rs-picker-toggle {
                &.invalid-input {
                    border-color: red !important;
                    border-width: 2px !important;
                }
            }

            .input-error {
                color: red;
                padding-top: 0 !important;
            }
        }
    }
}
