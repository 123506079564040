.wrapper strong {
    color: var(--gray-700, #747474);
    font-size: 11px;
    font-weight: 600;
    line-height: 12px; /* 109.091% */
    text-transform: uppercase;
}

.input_wrapper {
    & .input {
        padding: 0px 4px;
    }

    & .input_addon {
        padding: 4px;
        height: 100%;
    }
}
