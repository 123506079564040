.progress-bar-wrapper {
    display: flex;
    border-radius: 10px;
    height: 10px;
    width: 100%;
    background: #D7E8FC;
}

.progress-bar-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 4px;
}

.text-wrapper {
    display: flex;
}

.regular-text {
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    color: #747474;
    margin: 0;
}

.bold-text {
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    color: #505050;
    margin: 0 0 0 3px;
}
