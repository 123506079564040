.bordered-container {
    padding: 12px;
}

.form-container {
    display: flex;
}

.title {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 16px;
}

.title-container {
    width: 180px;
}

.filter-title {
    display: flex;
    align-items: center;
    height: 48px;

    p {
        font-size: 12px;
        font-weight: 600;
    }
}

.filter-selection-wrapper {
    display: flex;
    align-items: center;
    height: 48px;
}

.btn-clear-filters {
    padding: 8px 12px !important;
    background: #f5f5f5;
    border-radius: 18px;
    height: 36px;
    margin-left: auto;
}

.all-selected {
    :global(.rs-picker-toggle-value > .counter) {
        display: none;
    }
}
