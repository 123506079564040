.freemium-success-setting-page {
    .rs-col {
        padding: 0 !important;
    }


    .form-area {
        height: 100vh;
        display: table;

        .text-area {
            padding: 0 147px;
            display: table-cell;
            vertical-align: middle;

            @media screen and (max-width:600px) {
                padding: 0 100px;
            }

            h2 {
                font-size: 24px;
                text-align: center;
            }

            p {
                font-size: 14px;
                text-align: center;
            }
        }
    }
}
