.content {
    background: transparent !important;
    display: flex;
    justify-content: center;
    margin: 0 38px !important;
    position: relative;
}

.content2 {
    background: transparent !important;
    display: flex;
    justify-content: center;
    margin: 0 20px !important;
    position: relative;

    :global(.slick-slider) {
        width: 100%;
    }
}

.content-carousel {
    margin-bottom: 0;
    padding: 0 20px !important;
}

.carousel {
    border-radius: 5px;
    width: 93.5%;
    text-align: left;
    background: #ffffff;

    :global(.slick-arrow) {
        height: 100%;
        width: 32px;
        background: #ffffff;
        border-radius: 4px;

        &:hover,
        &:focus {
            background: #ffffff;
            color: #ffffff;
        }
    }

    :global(.slick-prev:before, .slick-next:before) {
        font-size: 40px;
        color: #ccc;
    }

    :global(.slick-prev) {
        width: 48px;
        left: -64px;
    }

    :global(.slick-next) {
        width: 48px;
        right: -64px;
    }

    :global(.slick-next::before) {
        background: url("../../../../../../assets/icons/icon_arrow-right.svg") no-repeat;
        content: "";
        display: block;
        height: 22px;
        width: 22px;
        margin-left: 18px;
    }

    :global(.slick-prev::before) {
        background: url("../../../../../../assets/icons/icon_arrow-left.svg") no-repeat;
        content: "";
        display: block;
        height: 22px;
        width: 22px;
        margin-left: 16px;
    }

    :global(.slick-active) {
        margin-left: 1px;
        display: flex !important;
        justify-content: center;
    }
}

.carousel-padding {
    padding: 16px 16px 42px 16px;
}

.carousel-padding2 {
    padding: 16px;
}

.wrapper-slick {
    display: flex !important;

    .badge {
        position: absolute;
        font-weight: 700;
        font-size: 8px;
        text-transform: uppercase;
        background: #33AB84;
        border-radius: 10px;
        color: #FFFFFF;
        padding: 1px 6px;
        margin-left: 104px;
        top: 6px;
        z-index: 2;
    }
}

.price-type {
    margin-right: 16px;
}

.bottom-bold-text {
    font-size: 12px;
    font-weight: 600;
    color: #323232;
    margin-right: 4px;
}

.bottom-regular-text {
    font-size: 12px;
    font-weight: 400;
    color: #323232;
}

.see-all {
    position: absolute;
    bottom: 12px;
    display: flex;
}

.see-all-button {
    padding: 0;
    font-size: 12px;
    margin-left: 4px;
    text-decoration: none !important;

    img {
        margin-left: 5px;
    }
}