.body {
    margin-top: 0 !important;
    padding: 24px;
}

.content-title {
    font-size: 16px;
    font-weight: 600;
    color: #323232;
}

.item-title {
    font-size: 12px;
    font-weight: 700;
    text-transform: uppercase;
}

.item-value {
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
}

.item-value-lower {
    font-size: 12px;
    font-weight: 400;
    text-transform: lowercase;
}

.row {
    margin-top: 32px;
}

.last-row {
    padding: 32px 0 24px;
    border-bottom: 1px solid #eaeaea;
}
