@import '../../style/typography.scss';
@import '../../style/mixins.scss';

.input {
    all: unset;
    @extend .font-size-100-regular;

    --text-color: var(--gray-900);

    display: inline-block;
    box-sizing: border-box;

    border-radius: 4px !important;
    padding-inline: 12px;
    height: 36px;
    width: 100%;

    @include input-skin();
}
