.textarea {
    display: flex;
    padding: 8px 12px;
    align-items: flex-start;
    gap: 10px;
    flex: 1 0 0;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--gray-500, #bcbcbc);
    background: var(--white, #fff);
    width: 100%;
    resize: none;
    outline-color: var(--blue-400);
    outline-width: 1px;
}
