@import '../../style/typography.scss';

.table-heading {
    --text-color: var(--gray-900);
    --_table-heading-bg-color: white;
    --_table-heading-border-color: white;

    @extend .font-size-100-regular;
    background-color: var(--_table-heading-bg-color);
    justify-content: space-between;

    &,
    &__container {
        height: 58px;
        display: flex;
        align-items: center;
        gap: 8px;
    }

    padding: 8px 12px 8px 12px;
    border: 1px solid;
    border-radius: 6px;
    border-color: var(--_table-heading-border-color);

    &-is-selected {
        --_table-heading-bg-color: #f5f9fe;
        --_table-heading-border-color: #378ef0;
    }

    strong {
        @extend .font-size-100-semibold;
    }
}
