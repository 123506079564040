.link-button {
    background: none;
    padding: 0px;
    font-family: Open Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    font-feature-settings: 'clig' off, 'liga' off;
    color: #378ef0;
    transition: ease 150ms;
    &:hover {
        color: #1473e6;
    }
    &:focus {
        color: #0d66d0;
    }
}
