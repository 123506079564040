.indicator-wrapper {
    display: flex;
    align-items: center;
}

.text-status-positive,
.text-status-negative,
.text-status-default {
    font-size: 14px;
    font-weight: 600;
    margin: 0;
}

.subtext-status-positive,
.subtext-status-negative,
.subtext-status-default {
    font-size: 11px;
    font-weight: 600;
    margin: 0;
}

.text-status-positive {
    color: #33AB84;
}

.subtext-status-positive {
    color: #33AB84;
    text-transform: uppercase;
}

.text-status-negative {
    color: #EC5B62;
}

.subtext-status-negative {
    color: #EC5B62;
    text-transform: uppercase;
}

.text-status-default {
    color: #323232;
}

.subtext-status-default {
    color: #747474;
    text-transform: uppercase;
}
