.preco-psicologico-page {
    .rounding {
        display: flex;
        justify-content: flex-start;
        flex-direction: column;
        margin: 10px 0px 20px;
        overflow: hidden;

        input{
            &::-webkit-outer-spin-button,
            &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
            }

            &[type=number] {
                -moz-appearance: textfield;
            }
        }

        .switcher{
            height: 32px;
            width: 32px;
            margin-right: 10px;
            text-align: center;
            line-height: 2;
            cursor: pointer;
        }

        &.closed{
            margin-bottom: 10px;
            
            .switcher{
                transform: rotate(-90deg);
            }

            .rounding__decimal-ranges{
                font-size: 0;
                margin: 0;
                opacity: 0;
                padding: 0;
                max-height: 0;
            }
        }
        
        &__decimal-ranges{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            margin: 20px;
            margin-right: 0;
            margin-left: 42px;
            transition: font-size .25s,
            margin .25s,
            padding .25s,
            max-height .25s,
            opacity .25s .25s;
        }
        &__decimal-range-labels span{
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 10px;
            line-height: 14px;
            text-transform: uppercase;
            color: #ABA4A4;
            margin-left: 240px;
            letter-spacing: 0.09em;

            &:first-child{
                margin-left: 0px;
            }
        }
        &__range{
            display: flex;
            flex-direction: row;
        }
        
        &__range-error{
            color: #E51E1E;
            margin-left: 43px;
        }
        

        &__range-min, &__range-max{
            max-width: 96px;
            max-height: 32px;

            input{
                max-width: 64px;
                max-height: 30px;
            }

            .rs-input-group-addon{
                background-color: #AFADAD;
                max-width: 30px;
                min-width: 30px;
                padding: 7px;
            }
        }

        &__range-text, &__decimal-range-text{
            padding: 0 10px;
            align-self: center;
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 19px;
        }

        
        &__decimal-range{
            display: flex;
            margin: 10px 0 0;

            .rounding__decimal-range-text:first-child{
                padding-left: 0;
            }
        }

        &__decimal-range-error{
            color: #E51E1E;
        }
        
        &__decimal-range-min, &__decimal-range-max, &__decimal-range-rounding-to{
            max-width: 96px;

            .rs-input-group{
                text-align: center;

                .rs-input-group-addon{
                    max-width: 30px;
                    min-width: 30px;
                    padding: 8px;
    
                    &:first-child{
                        background-color: #AFADAD;
                    }
                    &:nth-child(2){
                        background-color: #d4d3d3;
                    }
                }

                input{
                    max-width: 34px;
                    min-width: 34px;
                    padding: 5px 8px;
                }
            }
        }

        &__decimal-range-rounding-to{
            margin-left: 95px;
        }
        
        &__plus-range, &__plus-decimal-range{
            margin-top: 10px;
            color: #1E88E5;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            font-family: 'Open Sans', sans-serif;
            cursor: pointer;
            margin-left: 10px;
        }
        &__decimal-range-badges{
            display: flex;
            margin-left: 25px;
        }

        .badge-container{
            max-width: 108px;
            max-height: 28px;
            margin: 0 5px;
        }
        .badge{
            display: flex;
            // max-width: 110px;
            width: 100%;
            height: 100%;
            border-radius: 15px;
            text-align: center;
            color: #FFFFFF;
            cursor: pointer;
            border: 2px solid;

            &__icon{
                min-width: 15px;
                margin-left: 5px;
            }
            &__text{
                min-width: 85px;
                font-weight: bold;
                line-height: 22px;
                font-size: 10px;
            }

            &.badge--down{
                background-color: #78C25C;
            }
            &.badge--equal{
                background-color: #1E88E5;
            }
            &.badge--up{
                background-color: #9C5CC9;
            }

            &.active{
                // border-width: 3px;
                // border-style: solid;
                
                &.badge--down{
                    border-color: #2D8417;
                }
                &.badge--equal{
                    border-color: #004B8D;
                }
                &.badge--up{
                    border-color: #6C1A92;
                }
            }
            &.disabled{
                opacity: 0.3;
                cursor: auto;
                &:hover{
                    opacity: 0.3;
                }
            }
            &:hover{
                opacity: 0.7;
            }
        }

    }


    .description {
        &__label {
            color: #adadad;
            font-family: 'Open Sans', sans-serif;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            text-transform: uppercase;
            letter-spacing: 0.09em;
        }
    }

    .delete-button{
        padding: 9px;
        line-height: 10px;
        max-height: 32px;
        max-width: 32px;
        margin-left: auto;
        cursor: pointer;
    }

    .buttons-wrapper{
        margin-top: 30px;
        >div{
            margin-left: 15px;
            &:first-child{
                margin-left: 0;
            }
        }
    }
}
