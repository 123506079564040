.modal {
    max-width: 674px;
}

.footer {
    display: flex;
    padding: 16px 24px;
    justify-content: flex-end;
    align-items: center;
    gap: 24px;
    align-self: stretch;
}
