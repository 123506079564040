@import '../../../../../../../style/typography.scss';


.detalhes-da-negociacao {
    &__header, &__header > div, &__heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__header p {
        --text-color: var(--gray-600, #959595);
        @extend .font-size-100-regular;
    }

    &__header > div  {
        gap: 10px;
        p {
            color: var(--gray-800, #505050);
            font-family: Open Sans;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }
    }

    &__heading {
        --text-color: var(--Gray-900, #323232);
        gap: 16px;

        & > * {
            display: flex;
            flex-direction: column;
        }

        .name-container {
            display: grid;
            grid-template-areas:
                'name btn'
                'tag tag'
            ;
            justify-items: start;
            column-gap: 8px;
            row-gap: 2px;
        }

        .negociacao-link-btn {
            display: flex;
            align-items: center;
            gap: 4px;
            grid-area: btn;
            i {
                font-size: 12px;
            }
        }

        .negociacao-name { grid-area: name; }

        .negociacao-tag {
            grid-area: tag;
        }

        .text-description {
            --text-color: var(--gray-600, #959595);
            @extend .font-size-75-regular;
        }
    }
}
