.highlight-popover {
    border-radius: 6px !important;
    width: 271px;
    color: #fff;
    background: radial-gradient(143.06% 935.74% at 25.46% 26.39%, rgba(55, 142, 240, 0.64) 0%, rgba(20, 115, 230, 0.72) 100%);
    .rs-flex-box-grid-item {
        margin-bottom: 0 !important;
    }
    i {
        color: #fff;
    }
}

.highlight-popover > .arrow::after {
    border-left-color: #6daef8cc !important;
}

.regra-preco-page {

    .rs-popover {
        .arrow::after {
            border-left-color: aqua !important;
        }
    }

    .highlight {
        .arrow::after {
            border-left-color: aqua !important;
        }
    }

    .regra-preco-filters {
        margin-bottom: 4px !important;
        padding: 16px !important;

        .regra-nome {
            margin-top: 2px;
        }
    }

    .rs-input-group {
        border-top: none !important;
        border-radius: 0 !important;
        border-bottom-left-radius: 6px !important;
        border-right: none !important;
        border-left: none !important;
    }

    .rs-btn-primary.rs-input-group-btn {
        color: white;
    }

    .rule-status-icon.ACTIVE {
        color: #378EF0;
    }

    .rule-status-icon.EXPIRED {
        color: #959595;
    }

    .table {
        &__edit-button {
            padding: 0;
            text-align: center;
            display: table-cell;
            vertical-align: middle;

            .rs-btn-icon {
                margin: 0px 5px;
            }

            .rs-table-cell-content {
                padding: 5px;
            }
        }
    }
}
