.table-scenario-container {
    margin: 0 0 10px 0;
    background: #ffffff;
    border-radius: 6px;
}

.main-bold-text, .regular-bold-text {
    font-size: 14px;
    color: #323232;
    margin: 0;
}

.main-bold-text, .subtext-bold {
    font-weight: 600;
}

.regular-text {
    font-size: 12px;
    font-weight: 400;
}

.subtext {
    font-size: 11px;
    font-weight: 400;
    color: #747474;
    margin: 0;
}

.subtext-bold {
    font-size: 12px;
    color: #747474;
    margin: 0;
}

.table-scenario {
    border-radius: 6px;
}

.table-scenario-row {
    :global(.rs-table-cell-header > .rs-table-cell > .rs-table-cell-content) {
        display: flex;
        align-items: center;
    }
}

.sub-flex {
    display: flex;
    align-items: center;

    :global(.indicator) {
        margin-left: 4px;
    }
}

.flex {
    display: flex;
    align-items: center;
    height: -webkit-fill-available;
}

.input-group {
    margin-bottom: 4px;
    input {
        height: 32px !important;
    }

    span {
        padding: 8px;
    }
}

.neutro-indicator {
    border: 1px solid #BCBCBC;
    border-radius: 4px;
    background: #F5F5F5;
    padding: 1px 2px;
    font-size: 10px;
    font-weight: 600;
    width: fit-content;
    line-height: 10px;
}

.outros-indicator-flex {
    display: flex;
    align-items: center;
    gap: 4px;
    margin-top: 4px;
}

.pmz-indicator {
    margin-top: 4px;
}

.margin-indicator {
    font-size: 11px;
    font-weight: 600;
    color: #747474;
}

.flex-column {
    display: flex;
    flex-direction: column;

    p {
        margin: 0;
        line-height: 14px;
    }
}

.currency-indicator {
    font-size: 11px;
    font-weight: 600;
    color: #747474;
}

.preco-medio-indicator {
    margin-left: 4px;
}

.btn-simulate {
    margin-top: 4px;
}

.edit-indicator, .applied-indicator {
    font-size: 10px;
    font-weight: 700;
    text-transform: uppercase;
    border-radius: 10px;
    color: #ffffff;
    padding: 1px 6px;
    margin-left: 4px;
}

.applied-indicator {
    background: #33AB84;
}

.edit-indicator {
    background: #378EF0;
}

.margem-indicator {
    margin-right: 4px;
}

.line {
    :global(.rs-table-cell-content) {
        border-left: 1px solid #f2f2f5;
    }
}

.leader-text {
    font-size: 10px;
    font-weight: 600;
    text-transform: uppercase;
    color: var(--blue-400);
    margin-left: 6px;
}

.btn-open-modal {
    font-size: 10px;
    font-weight: 600;
    color: var(--blue-400);
    margin: 0;
    padding: 0;

    background: transparent !important;

    &:hover, &:focus {
        background: transparent !important;
        color: var(--blue-400);
    }
}
