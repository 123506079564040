.forecast-details {
    display: flex;
    gap: 12px;
    border-radius: 0.375rem;
    background: var(--white, #fff);
    padding: 12px;
}

.charts-flex {
    display: flex;
    justify-content: space-between;
}

.empty-state {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 12px;
    background: #fef7f7;
    padding: 24px 0;
    // margin-top: 40px;

    p {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #323232;
        margin-top: 8px;
    }

    span {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
        color: #323232;
    }
}

.data-info {
    display: flex;
    flex: 1;
    justify-content: center;
    background: var(--light-Blue-5, #f5f9fe);

    p {
        color: var(--gray-900, #323232);
        text-align: center;

        font-family: 'Open Sans';
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem; /* 142.857% */
    }
}

.data-info-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: #f5f9fe;
    border-radius: 6px;
    padding: 16px 0;

    p {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        text-align: center;
        color: #323232;
    }
}

.forecast-wrapper {
    display: flex;
    flex: 1;
    flex-direction: column;
}

.forecast-volume-info {
    display: flex;
    flex: 1;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;

    h3 {
        display: flex;
        align-items: center;
        gap: 8px;
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 26px;
        color: #323232;
        margin-top: 8px;
    }

    p {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        color: #323232;
        margin-top: 8px;
    }

    .beta-badge {
        display: flex;
        padding: 0.0625rem 0.375rem;
        align-items: center;
        gap: 0.25rem;

        border-radius: 0.625rem;
        border: 1px solid var(--gray-700, #747474);
        background: var(--white, #fff);

        color: var(--gray-700, #747474);

        font-family: 'Open Sans';
        font-size: 0.625rem;
        font-style: normal;
        font-weight: 700;
        line-height: 15px;
        text-transform: uppercase;
    }

    .date {
        color: var(--gray-700, #747474);
        font-family: 'Open Sans';
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 1.25rem; /* 142.857% */
        span {
            font-family: 'Open Sans';
            font-size: 0.875rem;
            font-style: normal;
            font-weight: 600;
            line-height: 1.25rem;
        }
    }
}
