.modal-activate-product-promo {
    :global(.rs-modal-body) {
        padding-bottom: 10px !important;
    }
}

.content {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.form {
    display: flex;
    gap: 16px;
}

.input-wrapper, .input-wrapper-date {
    :global(.rs-input-group) {
        margin-top: 4px;
    }
}

.input-wrapper-date {
    :global(.rs-picker-date) {
        :global(.rs-picker-toggle) {
            padding: 7px 11px !important;
            :global(.rs-picker-toggle-caret) {
                display: none;
            }
        }
    }
}

.calendar-icon {
    width: 14px;
    height: 14px;
}
