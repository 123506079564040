@import '../../style/typography.scss';

$bg-skin-neutral: var(--white);
$clr-skin-neutral: var(--gray-700);

$bg-skin-light-blue: #f5f9fe;
$clr-skin-light-blue: var(--blue-400);

$bg-skin-blue: var(--blue-400);
$clr-skin-blue: var(--white);

$skins: (
    'neutral' $bg-skin-neutral $clr-skin-neutral,
    'light-blue' $bg-skin-light-blue $clr-skin-light-blue,
    'blue' $bg-skin-blue $clr-skin-blue
);

.badge {
    @extend .font-size-50-semibold;
    display: flex;
    padding-inline: 2px;
    align-items: center;
    gap: 2px;
    border-radius: 4px;
    cursor: pointer;

    svg {
        aspect-ratio: 1;
        width: 10px;
    }

    @each $skin, $bg, $clr in $skins {
        &.#{$skin} {
            --text-color: $clr;
            background-color: $bg;
            color: $clr;
        }
    }
}
