.container {
    display: flex;
    margin-right: 16px;
}

.product-image {
    border-radius: 6px;
    border-top: 1px solid #D7E8FC;
    border-bottom: 1px solid #D7E8FC;
    border-left: 1px solid #D7E8FC;
    border-right: 1px dashed #D7E8FC;
    background: #FFF;
    padding: 16px;
    width: 112px;
    height: 112px;
}

.product-info {
    border-radius: 6px;
    border-top: 1px solid #D7E8FC;
    border-right: 1px solid #D7E8FC;
    border-bottom: 1px solid #D7E8FC;
    background: #FFF;
    padding: 12px;
    max-width: 450px;
    min-width: 390px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 1280px) {
        max-width: 390px;
    }
}

.product-info-title {
    color: #323232;
    font-size: 14px;
    font-weight: 600;
    @media (max-width: 1280px) {
        font-size: 13px;
    }
}

.product-detail {
    display: flex;
}

.detail-wrapper {
    margin-right: 34px;
}

.detail-title {
    color: #747474;
    font-size: 10px;
    font-weight: 600;
}

.detail-value {
    color: #959595;
    font-size: 12px;
    font-weight: 400;
    @media (max-width: 1280px) {
        font-size: 11px;
    }
}
