.result-button-content {
    margin: 0 0 20px 0 !important;
    border-radius: 6px;
    display: flex;
    justify-content: space-between;
}

.result-button {
    font-weight: 700;
    font-size: 14px;
}

.download-button-icon {
    margin-right: 14px;
}
