:root {
    --white: #ffffff;
    --black: #000000;

    --gray-100: #f5f5f5;
    --gray-200: #f4f4f4;
    --gray-300: #eaeaea;
    --gray-400: #d3d3d3;
    --gray-500: #bcbcbc;
    --gray-600: #959595;
    --gray-700: #747474;
    --gray-800: #505050;
    --gray-900: #323232;

    --blue-400: #378ef0;
    --blue-500: #2680eb;
    --blue-600: #1473e6;
    --blue-700: #0d66d0;

    --orange-400: #f29423;
    --orange-500: #e68619;
    --orange-600: #da7b11;
    --orange-700: #cb6f10;

    --purple-400: #9256d9;
    --purple-500: #864ccc;
    --purple-600: #7a42bf;
    --purple-700: #6f38b1;

    --green-400: #33ab84;
    --green-500: #2d9d78;
    --green-600: #268e6c;
    --green-700: #12805c;

    --red-400: #ec5b62;
    --red-500: #e34850;
    --red-600: #d7373f;
    --red-700: #c9252d;
}
