.wrapper {
    --_border: 1px solid var(--gray-300, #eaeaea);
    border-radius: 6px;
    border: var(--_border);
    overflow: hidden;
}

.table {
    --cell-padding: 0px 8px;
    border-bottom: var(--_border);
    :global(.rs-table-cell-content) {
        padding: var(--cell-padding);
    }
}

.pagination {
    padding-inline: 8px;
    padding-block: 8px 12px;
    :global(.rs-table-pagination-end) {
        display: flex;
        align-items: center;
    }
}
