.main-container {
    gap: 4px;
    > span {
        color: var(--gray-700, #747474);
        font-family: 'Open Sans';
        font-size: 11px;
        font-style: normal;
        font-weight: 600;
        line-height: 12px; /* 109.091% */
        text-transform: uppercase;
    }
}
