.wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    p {
        font-size: 11px;
        line-height: 12px;
        font-weight: 600px;
        color: #505050;
        background-color: #F5F9FE;
        padding: 0 2px 0 2px;
        border-radius: 4px;

    }
}
