.precificacao-manual-page {
    .filtros {
        &__input {
            margin-right: 0px;
        }
    }

    .Table {
        &__cell-action {
            .rs-table-cell-content {
                width: 120px;
                height: 46px;
                text-align: center;
                padding: 0px;
                display: table-cell;
                vertical-align: middle;
            }
        }

        &__cell-posicionamento-lojas {
            .rs-table-cell-content {
                padding-top: 5px;
                font-size: 12px;
            }

            &_border-right {
                border-right: 1px solid #ccc;
            }
        }

        &__header-cell-break-word {
            .rs-table-cell-content {
                padding-top: 0;
            }

            p {
                margin-top: 0;
                text-align: center;
            }
        }
    }

    Table {
        width: 100%;
        table-layout: fixed;
    }

    th {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }


    .wide{
        width: 200px;
    }


    .table{
        &__description{
            min-width: 200px;
            width: 200px;
        }
    }

    .title-table {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #747474;
        position: relative;
        left: 1104px;
        width: 25px;
        height: 18px;
    }


    .table-row-principal-header {
        overflow: hidden;
        height: 36px;
        width: 100%;
        top: 0;
        border-bottom: 1px solid #f2f2f5;
        border-style: solid;
        border-width: 0;
        border-color: #f2f2f5;
        white-space: normal;
        background: #fff;
        padding: 13px 10px;
        text-overflow: ellipsis;
        white-space: nowrap;

        strong {
            font-weight: 600;
            margin-right: 3px;
        }

        .MuiSvgIcon-root {
            color: #378EF0;
            margin-right: 3px;
        }

        .cell-options{
            position: relative;
            bottom: 9px;
            padding: 0;
        }

    }




}
