.revisao-lista-page {

    &.main-container {
        max-width: 94vw;

        @media (min-width:400px) {
            max-width: 86vw;
        }

        @media (min-width:600px) {
            max-width: 87vw;
        }

        @media (min-width:700px) {
            max-width: 92vw;
        }

        @media (min-width:1000px) {
            max-width: 93vw;
        }

        @media (min-width:1200px) {
            max-width: 94vw;
        }


    }

    .rs-header {
        background-color: #FFFFFF;
        margin: 40px !important;
        margin-bottom: 0 !important;
        padding: 24px !important;

        .header {
            margin-bottom: 40px;

            h1 {
                font-size: 20px;
                margin-bottom: 12px;
                line-height: 26px;
            }

            h2 {
                font-size: 14px;
                font-weight: normal;
                margin: 0;
            }
        }

        .rs-badge-content {
            background-color: #378ef0;
            right: -10px;
            font-weight: 500;
        }
    }

    .rs-content {
        padding: 0 !important;
        margin: 40px !important;
        padding-top: 0 !important;
        margin-top: 0 !important;
        background-color: transparent !important;
    }
}
