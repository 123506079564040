@import '../../../../../../style/abstracts/variables.scss';

.quantity-box {
    padding: 12px;
    height: 123px;
    background: #FFFFFF;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 173px;
}

.quantity {
    font-weight: 300;
    font-size: 32px;
    color: $gray-900;
    margin-bottom: 10px;
}

.quantity-title {
    font-weight: 600;
    font-size: 12px;
    color: $gray-800;
}

.quantity-subtitle {
    font-weight: 400;
    font-size: 12px;
    color: $gray-700;
}
